<template>
  <div>
    <!-- 1. Image -->
  </div>
</template>

<script>
import { CarouselComponent } from "./carouselComponent";

export default {
  name: "imageCardConfig",
  mixins: [CarouselComponent]
};
</script>

<style></style>
