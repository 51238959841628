<template>
  <div v-if="!isEmptyStats" style="height: 100%">
    <h2 class="overview-title">{{ __("Overview") }}</h2>
    <div class="overview-container">
      <el-form class="overview task-overview task-nodes-overview">
        <el-form-item class="overview-item task-overview-item total-canvases">
          <info-card :title="__('Canvases')" :value="canvasesCount">
            <img
              :src="`/icons/canvases.svg`"
              draggable="false"
              onload="SVGInject(this)"
            />
          </info-card>
        </el-form-item>
        <el-form-item class="overview-item task-overview-item total-nodes">
          <info-card :title="__('Nodes')" :value="nodesCount">
            <img
              :src="`/icons/nodes.svg`"
              draggable="false"
              onload="SVGInject(this)"
            />
          </info-card>
        </el-form-item>

        <el-form-item class="overview-item task-overview-item total-node-types">
          <info-card :title="__('Node Types')" :value="nodeTypesCount">
            <img
              :src="`/icons/node_types.svg`"
              draggable="false"
              onload="SVGInject(this)"
            />
          </info-card>
        </el-form-item>
      </el-form>
    </div>

    <h2 class="overview-title">{{ __("Nodes") }}</h2>
    <div class="overview-container">
      <el-form class="overview ">
        <el-form-item
          :key="nodeType"
          v-for="(nodeTypeCount, nodeType) in nodeTypeToCount"
          class="overview-item"
        >
          <info-card
            :title="prettify(getNodeTypeLabel(nodeType))"
            :value="nodeTypeCount"
            :id="nodeType"
          >
            <el-popover :open-delay="500" placement="top-start" trigger="hover">
              {{ prettify(getNodeTypeLabel(nodeType)) }}
              <div slot="reference" class="node-cell">
                <img
                  class="node-cell-icon"
                  v-if="nodeType"
                  :src="getIconForNodeType(nodeType)"
                  draggable="false"
                  onload="SVGInject(this)"
                />
              </div> </el-popover
          ></info-card>
        </el-form-item>
      </el-form>
    </div>

    <div v-if="filteredContentProps.length !== 0">
      <h2 class="overview-title">{{ __("Contents") }}</h2>
      <div class="overview-container">
        <el-form class="overview">
          <el-form-item
            :key="index"
            v-for="(contentProp, index) in filteredContentProps"
            class="overview-item"
          >
            <info-card
              :id="contentProp.key"
              :title="prettify(contentProp.label)"
              :value="getContentCount(contentProp.key)"
            >
              <el-popover
                :open-delay="500"
                placement="top-start"
                trigger="hover"
              >
                {{ prettify(contentProp.label) }}
                <div slot="reference" class="content-icon">
                  <img
                    :src="getCardIcon(contentProp.key)"
                    draggable="false"
                    onload="SVGInject(this)"
                  />
                </div>
              </el-popover>
            </info-card>
            <div class="overview-item-actions">
              <div
                @click="configureContent(contentProp)"
                class="action"
                v-if="
                  contentProp.configurable &&
                    contentProp.canConfigure(getContent(contentProp.key))
                "
              >
                {{ __("Configure") }}
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div v-if="filteredAdvancedContentProps.length !== 0">
      <h2 class="overview-title">{{ __("Contents (Advanced)") }}</h2>
      <div class="overview-container">
        <el-form class="overview">
          <el-form-item
            :key="index"
            v-for="(contentProp, index) in filteredAdvancedContentProps"
            class="overview-item"
          >
            <info-card
              :title="prettify(contentProp.label)"
              :value="getContentCount(contentProp.key)"
              :id="contentProp.key"
            >
              <el-popover
                :open-delay="500"
                placement="top-start"
                trigger="hover"
              >
                {{ prettify(contentProp.label) }}
                <div slot="reference" class="content-icon">
                  <img
                    :src="getCardIcon(contentProp.key)"
                    draggable="false"
                    onload="SVGInject(this)"
                  />
                </div>
              </el-popover>
            </info-card>
            <div class="overview-item-actions">
              <div
                @click="configureContent(contentProp)"
                class="action"
                v-if="
                  contentProp.configurable &&
                    contentProp.canConfigure(getContent(contentProp.key))
                "
              >
                {{ __("Configure") }}
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog
      v-if="contentToConfigure && showContentConfigureModal"
      :visible.sync="showContentConfigureModal"
      :before-close="handleClose"
      custom-class="content-configure-modal"
      append-to-body
      destroy-on-close
    >
      <div slot="title" class="dialog-header">
        <h2 class="title">Configure {{ prettify(contentToConfigure) }}</h2>
      </div>

      <el-scrollbar :native="false">
        <component
          style="max-height: 60vh; padding-bottom: 25px;"
          :is="component"
          :config="contentConfig"
          :contents="getContent(contentToConfigure)"
        />
      </el-scrollbar>
      <span
        v-if="isContentTypeConfigurable(contentToConfigure)"
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          :disabled="false"
          type="primary"
          @click="handleConfigChangeSaveClick(contentToConfigure)"
          >{{ __("Save Changes") }}</el-button
        >
        <el-button
          type="primary"
          @click="handleConfigChangeCancelClick(contentToConfigure)"
          >{{ __("Cancel") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import InfoCard from "@/views/build/tasks/create-task/components/TaskExport/InfoCard";
import { getNodeConfig } from "@/constants/nodes";
import DataStoresExportConfig from "./ContentConfigure/DataStoresExportConfig";
import VariablesExportConfig from "./ContentConfigure/VariablesExportConfig";
import AuthProfilesExportConfig from "./ContentConfigure/AuthProfilesExportConfig";

export default {
  props: {
    taskStats: {
      required: true,
      type: Object
    },
    exportConfig: {
      required: true,
      type: Object
    },
    task: {
      required: true,
      type: Object
    }
  },
  components: {
    InfoCard,
    DataStoresExportConfig,
    VariablesExportConfig,
    AuthProfilesExportConfig
  },
  data() {
    const VARIABLES = "variables";
    const JAVASCRIPT_FUNCTIONS = "javascript_functions";
    const DATA_STORES = "data_stores";
    const PROMPTS = "prompts";
    const FORM_TYPES = "form_types";
    const OPEN_FORM_TYPES = "open_form_types";
    const DYNAMIC_FORM_TYPES = "dynamic_form_types";
    const HOLIDAYS = "holidays";
    const VOICES = "voices";
    const EMAIL_PROFILES = "email_profile";
    const SMS_PROFILES = "sms_profiles";
    const PAYMENT_PROFILES = "payment_profiles";
    const QUEUES = "queues";
    const AUTH_PROFILES = "auth_profiles";
    const ASR_LANGUAGES = "asr_languages";
    const INTENT = "intents";
    return {
      contentPropNames: {
        VARIABLES,
        JAVASCRIPT_FUNCTIONS,
        DATA_STORES,
        PROMPTS,
        FORM_TYPES,
        OPEN_FORM_TYPES,
        DYNAMIC_FORM_TYPES,
        HOLIDAYS,
        VOICES,
        EMAIL_PROFILES,
        SMS_PROFILES,
        PAYMENT_PROFILES,
        QUEUES,
        ASR_LANGUAGES,
        AUTH_PROFILES,
        INTENT
      },

      contentIcons: {
        [VARIABLES]: "content-icon-variable.svg",
        [JAVASCRIPT_FUNCTIONS]: "icon-play.svg",
        [DATA_STORES]: "content-icon-datastore.svg",
        [PROMPTS]: "icon-microphone.svg",
        [FORM_TYPES]: "content-icon-custom-form.svg",
        [OPEN_FORM_TYPES]: "icon-open-form-grey.svg",
        [DYNAMIC_FORM_TYPES]: "icon-open-form-grey.svg",
        [HOLIDAYS]: "content-icon-holiday.svg",
        [VOICES]: "sidebar/icon-voices.svg",
        [EMAIL_PROFILES]: "sidebar/icon-email-profiles.svg",
        [SMS_PROFILES]: "sidebar/icon-email-profiles.svg",
        [PAYMENT_PROFILES]: "sidebar/icon-content.svg",
        [QUEUES]: "sidebar/icon-queue.svg",
        [ASR_LANGUAGES]: "sidebar/icon-voices.svg",
        [AUTH_PROFILES]: "sidebar/icon-users.svg",
        [INTENT]: "icon-intent.svg"
      },
      contentProps: [
        {
          key: VARIABLES,
          label: __("Variables"),
          configurable: true,
          // only show configure button if there is at least 1 secure variable
          canConfigure: function(variables) {
            let secureVariables = _.filter(
              variables,
              variable => variable.variable_type === "secure"
            );
            return !_.isEmpty(secureVariables);
          }
        },
        {
          key: JAVASCRIPT_FUNCTIONS,
          label: __("JavaScript Functions"),
          configurable: false
        },
        {
          key: DATA_STORES,
          label: __("Datastores"),
          configurable: true,
          // only show configure button if there is at least 1 datastore
          canConfigure: function(dataStores) {
            return !_.isEmpty(dataStores);
          }
        },
        { key: PROMPTS, label: __("Prompts"), configurable: false },
        { key: FORM_TYPES, label: __("Form Types"), configurable: false },
        {
          key: OPEN_FORM_TYPES,
          label: __("Open Form Types"),
          configurable: false
        },
        {
          key: DYNAMIC_FORM_TYPES,
          label: __("Dynamic Form Types"),
          configurable: false
        },
        { key: HOLIDAYS, label: __("Holidays"), configurable: false }
      ],

      advancedContentProps: [
        { key: VOICES, label: __("Voices"), configurable: false },
        { key: INTENT, label: __("Intent"), configurable: false },
        {
          key: EMAIL_PROFILES,
          label: __("Email Profiles"),
          configurable: false
        },
        { key: SMS_PROFILES, label: __("SMS Profiles"), configurable: false },
        {
          key: PAYMENT_PROFILES,
          label: __("Payment Profiles"),
          configurable: false
        },
        { key: QUEUES, label: __("Queues"), configurable: false },
        { key: ASR_LANGUAGES, label: __("ASR Languages"), configurable: false },
        {
          key: AUTH_PROFILES,
          label: __("Authentications"),
          configurable: true,
          canConfigure: function(authProfiles) {
            return !_.isEmpty(authProfiles);
          }
        }
      ],
      showContentConfigureModal: false,
      contentToConfigure: null,
      exportConfigCopy: {}
    };
  },
  computed: {
    isEmptyStats() {
      return _.isEmpty(this.taskStats);
    },
    nodes() {
      return _.get(this.taskStats, "nodes", []);
    },
    stats() {
      return _.get(this.taskStats, "stats", {});
    },
    nodesCount() {
      return _.get(this.stats, "nodes_count", 0);
    },
    canvasesCount() {
      return _.get(this.stats, "canvases_count", 0);
    },
    nodeTypesCount() {
      return _.get(this.stats, "node_types_count", 0);
    },
    nodeTypeToCount() {
      return _.get(this.stats, "node_type_to_count", {});
    },
    getContentCount() {
      return contentType => this.getContent(contentType).length;
    },
    getContent() {
      return contentType => {
        let contents = _.get(this.taskStats, contentType, []);
        let filteredContents = contents;
        switch (contentType) {
          case this.contentPropNames["VARIABLES"]:
            filteredContents = _.filter(
              contents,
              content => content.variable_type !== "array"
            );
            break;
          case this.contentPropNames["DATA_STORES"]:
            filteredContents = _.filter(
              contents,
              content => !content.is_log && !content.is_system
            );
        }
        return filteredContents;
      };
    },
    filteredContentProps() {
      return _.filter(this.contentProps, contentProp =>
        this.getContentCount(contentProp.key)
      );
      // return this.contentProps;
    },

    filteredAdvancedContentProps() {
      return _.filter(this.advancedContentProps, contentProp =>
        this.getContentCount(contentProp.key)
      );

      // return this.advancedContentProps;
    },

    getCardIcon() {
      return contentType => {
        return require("@/assets/icons/" + this.contentIcons[contentType]);
      };
    },

    isContentTypeConfigurable() {
      return contentType =>
        _.get(
          _.find(
            _.concat(this.contentProps, this.advancedContentProps),
            contentProp => contentProp.key === contentType
          ),
          "configurable",
          false
        );
    },
    contentConfig() {
      let path = "";
      if (this.contentToConfigure) {
        path = this.getConfigPath(this.contentToConfigure);
      }
      return _.get(this.exportConfigCopy, path, {});
    },
    component() {
      switch (this.contentToConfigure) {
        case this.contentPropNames["DATA_STORES"]:
          return "DataStoresExportConfig";
        case this.contentPropNames["VARIABLES"]:
          return "VariablesExportConfig";
        case this.contentPropNames["AUTH_PROFILES"]:
          return "AuthProfilesExportConfig";
        default:
          return "";
      }
    }
  },
  methods: {
    getIconForNodeType(nodeType) {
      if (nodeType === "start_node") {
        return "/icons/" + this.task.task_type + "_" + nodeType + ".svg";
      } else {
        return "/icons/" + nodeType + ".svg";
      }
    },
    getNodeTypeLabel(nodeType) {
      return getNodeConfig(nodeType).LABEL || nodeType;
    },
    prettify(val) {
      return _.startCase(_.replace(val, /_/g, " ").toLowerCase());
    },
    configureContent(contentType) {
      this.contentToConfigure = contentType.key;
      this.showContentConfigureModal = true;
    },
    getConfigPath(key) {
      switch (key) {
        case this.contentPropNames["DATA_STORES"]:
          return "exportDataStoreWithData";
        case this.contentPropNames["VARIABLES"]:
          return "variables";
        case this.contentPropNames["AUTH_PROFILES"]:
          return "authProfiles";
        default:
          return "";
      }
    },
    setExportConfigObject(contentType, obj) {
      this.$set(
        this.exportConfig,
        this.getConfigPath(contentType),
        _.cloneDeep(obj)
      );
      this.$set(
        this.exportConfigCopy,
        this.getConfigPath(contentType),
        _.cloneDeep(obj)
      );
    },
    initializeDataStoresExportConfig() {
      let dataStores = this.getContent(this.contentPropNames["DATA_STORES"]);
      let obj = {};
      _.map(dataStores, dataStore => (obj[dataStore.data_store_id] = false));
      this.setExportConfigObject(this.contentPropNames["DATA_STORES"], obj);
    },
    initializeVariablesExportConfig() {
      let defaultVariablesConfig = {
        attachSecureVariableValue: false
      };
      this.setExportConfigObject(
        this.contentPropNames["VARIABLES"],
        defaultVariablesConfig
      );
    },

    /**
     * initialise the javascript functions config
     */
    initializeJavascriptFunctionsExportConfig() {
      this.setExportConfigObject(
        this.contentPropNames["JAVASCRIPT_FUNCTIONS"],
        this.getContent(this.contentPropNames["JAVASCRIPT_FUNCTIONS"])
      );
    },

    initializeAuthProfilesExportConfig() {
      let authProfiles = this.getContent(
        this.contentPropNames["AUTH_PROFILES"]
      );
      let obj = {};
      _.map(
        authProfiles,
        authProfile => (obj[authProfile.auth_profile_id] = false)
      );
      this.setExportConfigObject(this.contentPropNames["AUTH_PROFILES"], obj);
    },
    initializeExportConfigs() {
      this.initializeDataStoresExportConfig();
      this.initializeVariablesExportConfig();
      this.initializeJavascriptFunctionsExportConfig();
      this.initializeAuthProfilesExportConfig();
    },
    handleConfigChangeCancelClick(contentType) {
      let path = this.getConfigPath(contentType);
      this.$set(
        this.exportConfigCopy,
        path,
        _.cloneDeep(_.get(this.exportConfig, path, {}))
      );
      this.showContentConfigureModal = false;
    },
    handleConfigChangeSaveClick(contentType) {
      let path = this.getConfigPath(contentType);
      this.$set(
        this.exportConfig,
        path,
        _.cloneDeep(_.get(this.exportConfigCopy, path, {}))
      );
      this.showContentConfigureModal = false;
    },
    handleClose() {
      let path = this.getConfigPath(this.contentToConfigure);
      if (
        !_.isEqual(
          _.get(this.exportConfig, path),
          _.get(this.exportConfigCopy, path)
        )
      ) {
        this.$confirm("You have unsaved changes, close without saving?", {
          confirmButtonText: "Yes",
          cancelButtonText: "No"
        })
          .then(() => {
            this.handleConfigChangeCancelClick(this.contentToConfigure);
          })
          .catch(() => {});
      } else {
        this.handleConfigChangeCancelClick(this.contentToConfigure);
      }
    }
  },
  watch: {
    taskStats: {
      immediate: true,
      handler() {
        this.initializeExportConfigs();
      }
    },
    showContentConfigureModal: {
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          if (!val) {
            this.contentToConfigure = null;
          }
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node-palette.scss";

.node-cell {
  height: 60px;
  width: 60px;
  background-color: #f5f5f8;
  border-radius: 6px;
  margin-top: 5px;
  outline-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 24px;
    width: 24px;
  }

  ::v-deep svg {
    fill: #222222;
    path {
      fill: #222222;
    }
  }
}

.overview-title {
  font-weight: 400;
  color: #454545;
  line-height: 1.2rem;
  &:not(:first-child) {
    margin-top: 35px;
  }
}

.overview-container {
  overflow: hidden;
  padding: 0 0 15px 0;
  margin-bottom: 25px;

  .overview {
    margin: -15px;
  }

  .overview {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .overview-item {
      margin: 5px;

      .overview-item-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -15px;
        font-size: 0.85em;

        .action {
          cursor: pointer;
          color: #31b0ec;
          background-color: #cce9ff;
          border-radius: 5px;
          height: 16px;
          line-height: 16px;
          padding: 2px 5px;
        }
      }

      .content-icon {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>

<style lang="scss">
.content-configure-modal {
  width: 65%;
  .el-dialog__header {
    .title {
      margin: 0;
    }
  }
}
</style>
