<template>
  <el-tooltip
    placement="right"
    effect="dark"
    trigger="hover"
    :disabled="!getFinalExpression(value)"
  >
    <template slot="content">
      <div class="eb-final-string">
        {{ getFinalExpression(value) }}
      </div></template
    >
    <el-input
      class="expression-builder-input"
      :class="inactive ? 'inactive' : 'active'"
      disabled
      :value="getFinalExpression(value)"
      :placeholder="placeholder"
    >
      <div class="action-buttons" slot="suffix">
        <i
          v-show="getFinalExpression(value)"
          style="cursor:pointer"
          class="el-icon-circle-close clear-expression"
          @click="emptyExpression"
        >
        </i>
        <i
          style="cursor:pointer"
          class="el-icon-edit el-input__icon"
          @click="$emit('edit-input')"
        >
        </i>
      </div>
    </el-input>
  </el-tooltip>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

/**
 * The maximum length of expression parameter to display
 */
const MAX_EXPRESSION_PARAM_LENGTH = 25;

export default {
  props: {
    // expects the value to be parsable as expected. rule_value should be conforming to the following sample (but stringified version)
    // {
    //    "expressions": {
    //    "Expr1": {
    //        "function": "get_array_element",
    //            "isFinal": true,
    //            "parameter1": 116,
    //            "parameter2": "root['zcc']['body{{1}}']"
    //    }
    // },
    //     "variables": {
    //     "1": "dddfaf"
    // },
    //     "jsonExpressions": {
    //     "Expr1": ""
    // },
    //     "expression_to_evaluate": "Expr1"
    // }
    value: {
      required: true,
      type: String
    },
    // variables: {
    //   required: true,
    //   type: Array
    // },
    complexVariables: {
      required: true,
      type: Array
    },
    placeholder: {
      required: false,
      type: String,
      default: ""
    },
    inactive: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("expressionbuilder", {
      parameterCountFor: "parameterCountFor",
      isJsonFunction: "isJsonFunction",
      javascriptFunctions: "javascriptFunctions"
    }),

    ...mapGetters("variables", {
      variablesUsedInExpressionBuilder: "variablesUsedInExpressionBuilder"
    })
  },

  methods: {
    /**
     * Generate expression label string from a given expression function
     * @argument {string} name Name of the expression function
     * @argument {string[]} parameters List of parameters for the function
     * @returns {string}
     */
    generateExpressionLabel(name, parameters) {
      const jsfPrefix = name.match("jsf_");

      if (jsfPrefix != null && jsfPrefix.index === 0) {
        name = this.javascriptFunctions.operations.find(
          functions => functions.value === name
        ).label;
      }

      return name + "(" + parameters.join(", ") + ")";
    },

    emptyExpression() {
      // eslint-disable-next-line
      let message = __("Are you sure you want to delete current value? This will also delete any associated expressions");
      this.$confirm(message, __("Warning"), {
        confirmButtonText: __("Delete"),
        cancelButtonText: __("Cancel"),
        type: "warning",
        showClose: false
      })
        .then(() => {
          this.$emit("input", "");
          this.$notify({
            title: __("Success"),
            type: "success",
            message: __("Expressions reset")
          });
        })
        .catch(() => {
          this.$notify({
            title: __("Delete cancelled"),
            message: __("User aborted"),
            type: "info"
          });
        });
    },

    /**
     * Truncate expression arguments
     * @argument {string} argument The expression argument to truncate
     * @returns {string}
     */
    truncateExpressionArgument(argument) {
      const subExprPattern = new RegExp("({{Expr\\d+?}})", "g");
      return argument
        .split(subExprPattern)
        .map(exprComponent => {
          const refToExpr =
            exprComponent.indexOf("{{") >= 0 &&
            exprComponent.indexOf("}}") >= 0;
          const shortArgLen =
            exprComponent.length <= MAX_EXPRESSION_PARAM_LENGTH;

          if (refToExpr || shortArgLen) {
            return exprComponent;
          }

          const exprCompTruncated = exprComponent.substr(
            0,
            MAX_EXPRESSION_PARAM_LENGTH
          );

          return `${exprCompTruncated}[...]`;
        })
        .join("");
    },

    generateExp(expressionsObj, finalExpression, variableIds) {
      let parametersUsed = [];

      for (let item in _.range(
        this.parameterCountFor(finalExpression.function)
      )) {
        let parameterValue = finalExpression["parameter" + String(+item + 1)];
        _.map(variableIds, variableId => {
          let variable =
            _.find(this.variablesUsedInExpressionBuilder, {
              variable_id: +variableId
            }) ||
            _.find(this.variablesUsedInExpressionBuilder, {
              variable_name: variableId
            });
          if (!_.isEmpty(variable)) {
            let re = new RegExp(`{{(${variableId})(\\|cav)?}}`, "g");
            parameterValue = parameterValue
              .toString()
              .replace(re, `{{${variable.variable_name}}}`);
          }
        });

        // Temporary fix for release. Proper fix will be implemented in a different ticket
        // parameterValue = this.truncateExpressionArgument(parameterValue);

        let subExprPattern = new RegExp("{{(Expr\\d+?)}}", "g");

        if (subExprPattern.test(parameterValue)) {
          // matchAll not returning results if not used along with match . weird!!!
          // eslint-disable-next-line no-unused-vars
          let matches = Array.from(parameterValue.match(subExprPattern));
          let expressionsUsed = Array.from(
            parameterValue.matchAll(subExprPattern)
          );

          let replacedValues = {};
          _.map(expressionsUsed, expression => {
            let subExpr = expressionsObj.expressions[expression[1]];
            replacedValues[expression[1]] = this.generateExp(
              expressionsObj,
              subExpr,
              variableIds
            );
          });

          // replace until all subExpr are replaced
          while (subExprPattern.test(parameterValue)) {
            _.map(replacedValues, (value, exprName) => {
              let regex = new RegExp(`{{${exprName}}}`, "g");
              parameterValue = parameterValue.replaceAll(regex, value);
            });
          }
        }

        parametersUsed.push(parameterValue);
      }

      if (this.isJsonFunction(finalExpression.function)) {
        let complexVariable = _.find(this.complexVariables, {
          node_id: finalExpression["parameter1"]
        });

        if (!_.isEmpty(complexVariable)) {
          parametersUsed.splice(0, 1, complexVariable.name);
        }
      }

      return this.generateExpressionLabel(
        finalExpression.function,
        parametersUsed
      );
    },

    getFinalExpression(expressions) {
      let finalExpressionString = "";
      if (expressions && !_.isEmpty(JSON.parse(expressions))) {
        let expressionsObj = JSON.parse(expressions);
        let finalExpressionName = expressionsObj.expression_to_evaluate;
        let finalExpression = expressionsObj.expressions[finalExpressionName];
        let variableIds = Object.keys(expressionsObj.variables);

        if (!_.isEmpty(finalExpression)) {
          finalExpressionString = this.generateExp(
            expressionsObj,
            finalExpression,
            variableIds
          );
        }
      }
      this.$emit("generated-expression", finalExpressionString);
      return finalExpressionString;
    }
  }
};
</script>

<style scoped lang="scss">
.expression-builder-input.active {
  & ::v-deep .el-input__inner {
    cursor: default !important;
  }
}

.expression-builder-input {
  .clear-expression {
    visibility: hidden;
  }

  &:hover {
    .clear-expression {
      visibility: visible;
    }
  }

  & ::v-deep .el-input__inner {
    padding-right: 50px;
  }
}
</style>

<style lang="scss">
.eb-final-string {
  max-width: 250px;
  word-break: break-word;
}
</style>
