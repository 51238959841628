<template>
  <i class="el-icon-close" style="font-size: 20px;"></i>
</template>

<script>
export default {
  name: "DynamicCollapseDeleteButton"
};
</script>

<style></style>
