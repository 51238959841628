<template>
  <div>
    <el-form-item :label="__('Response')" class="response">
      <el-divider></el-divider>
      <test-web-service
        :response="httpResponse"
        :get-response-using="requestResponseFromTestURL"
        :test-data="testData"
        response-editable
        json-path-editable
        :show-edit-btn="!textSelected"
        :enable-preview-button="isPreviewButtonEnabled"
        :assign-var-on-response-json-path="assignVarOnResponseJsonPath"
        :preview-response-popover-content="
          __('Fill above fields to activate this button')
        "
        @rule-added="addVariableRule"
        @change="updateHttpResponse"
      >
      </test-web-service>
    </el-form-item>
    <el-row v-if="!emptyRules" type="flex" style="margin-top: 20px">
      <el-col>
        <el-table class="json-paths" fit :data="getRules" style="width: 100%">
          <el-table-column :label="__('JSON Path')" prop="rule_value">
            <template slot-scope="scope">
              {{ prettify(jsonPathValue(scope.row.rule_value, items)) }}
            </template>
          </el-table-column>
          <el-table-column :label="__('Variable')" prop="variable_name">
            <template slot-scope="scope">
              <create-or-select
                :current_select="currentSelection(scope.row)"
                :items="singleValuedAndSecureVariables"
                label="variable_name"
                value="variable_id"
                :new-item-message="__('new variable')"
                :placeholder="__('Variable Name')"
                @change="handleChange($event)(scope.row)"
              />
            </template>
          </el-table-column>

          <el-table-column :label="__('Default Value')" prop="default_value">
            <template slot-scope="scope">
              <el-input
                v-if="scope.row.variable_type !== 'audio'"
                :placeholder="__('Default Value')"
                v-model="scope.row.default_value"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column class-name="row-message" width="100px" prop="msg" />
          <el-table-column class-name="cell-item-pointer" width="50px">
            <template slot-scope="scope">
              <span @click="removeJsonPath(scope.$index)">
                <i class="el-icon-circle-close"></i>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TestWebService from "./TestWebService";
import CreateOrSelect from "./CreateOrSelect";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import { jsonPathValue } from "@/utils/canvas";
import { requestResponseFromTestURL } from "@/api/services";
import SchemaGenerator from "@/utils/SchemaGenerator";
import { prettifyJsonPath } from "@/utils/transformers";
import WebservicesConstants from "../web-service/WebservicesConstants.js";

export default {
  components: {
    TestWebService,
    CreateOrSelect
  },
  props: {
    nodeToBind: {
      required: true,
      type: Object
    },
    items: {
      required: true,
      type: Array
    },
    httpResponse: {
      required: false,
      type: Object,
      default: () => {}
    },
    variableRules: {
      required: true,
      type: Array
    },
    arrayRule: {
      required: true,
      type: Function
    },
    customUrl: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      rules: {},
      requestResponseFromTestURL: requestResponseFromTestURL,
      variableRulesBckup: [],
      jsonPathValue,
      prettify: prettifyJsonPath
    };
  },

  computed: {
    ...mapState("canvas", {
      isNodeSubmit: state => state.isNodeSubmit,
      clickedNode: state => state.clickedNode
    }),
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),
    ...mapGetters("variables", {
      singleValuedAndSecureVariables: "singleValuedAndSecureVariables"
    }),
    currentSelection() {
      return row => {
        const { variable_id, variable_name } = row;
        return variable_id === -1 ? variable_name : variable_id;
      };
    },

    testData() {
      return {
        fetch_timeout: this.nodeToBind.web_service_node.data.fetch_timeout,
        profile_id: this.nodeToBind.web_service_node.data
          .web_service_profile_id,
        node_type: this.nodeToBind.node_type,
        task_id: +this.clickedNode.task_id,
        // LATER: Ask how to handle these fields as they are required as specified for the integration node
        url: this.customUrl ? this.customUrl : "test",
        http_method: "post",
        ac_id: this.selectedAccountId,
        post_raw_data: this.formatPostParameters(
          this.nodeToBind.web_service_node.data.post_body_placeholder
        ),
        additional_settings: JSON.stringify(
          this.nodeToBind.web_service_node.data.additional_settings
        )
      };
    },
    emptyRules() {
      return _.isEmpty(this.getRules);
    },
    isAutomaticVarAssignAllowed() {
      let ruleExist = _.some(
        this.getRules,
        rule =>
          rule.rule_value ===
          "root['ws_response_data']['choices']['0']['message']['content']"
      );
      return this.isOpenAISelected && !ruleExist;
    },
    assignVarOnResponseJsonPath() {
      return this.isAutomaticVarAssignAllowed
        ? "root.choices[0].message.content"
        : "";
    },
    getRules() {
      return _.filter(
        this.isNodeSubmit ? this.variableRulesBckup : this.variableRules,
        rule => !rule.array_variable
      );
    },
    textSelected() {
      if (this.isDialogflowUsingEventInput) {
        return (
          this.isDialogflowEventInputNameSet &&
          this.isDialogflowEventInputParamSet
        );
      }

      return !!this.nodeToBind.web_service_node.data.input_text;
    },
    areOpenAIFieldsSet() {
      return (
        this.isOpenAISelected &&
        !_.isEmpty(
          this.nodeToBind.web_service_node.data.additional_settings.open_ai
            .model
        ) &&
        !_.isEmpty(
          this.nodeToBind.web_service_node.data.additional_settings.open_ai
            .api_key
        )
      );
    },
    isCustomUrlSet() {
      return !_.isEmpty(this.customUrl);
    },

    /**
     * Check if the preview button should be enabled
     */
    isPreviewButtonEnabled() {
      return (
        this.isCustomUrlSet || this.textSelected || this.areOpenAIFieldsSet
      );
    },

    /**
     * Check if the dialogflow's input type is EventInput
     */
    isDialogflowEventInputSelected() {
      return (
        this.nodeToBind.web_service_node.data.additional_settings.input_type ===
        WebservicesConstants.DIALOGFLOW_EVENT_INPUT
      );
    },

    /**
     * Check if dialogflow is using EventInput for its input type
     */
    isDialogflowUsingEventInput() {
      return this.isDialogflowSelected && this.isDialogflowEventInputSelected;
    },

    /**
     * Check if dialogflow is the selected web service
     */
    isDialogflowSelected() {
      return (
        this.nodeToBind.web_service_node.data.action_name ===
        WebservicesConstants.DIALOGFLOW
      );
    },

    /**
     * Check if Open AI is the selected web service
     */
    isOpenAISelected() {
      return (
        this.nodeToBind.web_service_node.data.action_name ===
        WebservicesConstants.OPEN_AI_ACTION
      );
    },

    /**
     * Check if dialogflow input name is set
     */
    isDialogflowEventInputNameSet() {
      if (this.isDialogflowSelected) {
        if (
          this.nodeToBind.web_service_node.data.additional_settings
            .input_type === WebservicesConstants.DIALOGFLOW_EVENT_INPUT
        ) {
          return !_.isEmpty(
            this.nodeToBind.web_service_node.data.additional_settings
              .event_input_name
          );
        }
      }
      return false;
    },

    /**
     * Check if dialogflow event input parameter is set
     */
    isDialogflowEventInputParamSet() {
      if (this.isDialogflowSelected) {
        if (
          this.nodeToBind.web_service_node.data.additional_settings
            .input_type === WebservicesConstants.DIALOGFLOW_EVENT_INPUT
        ) {
          return !_.isEmpty(
            this.nodeToBind.web_service_node.data.additional_settings
              .event_input_parameters
          );
        }
      }
      return false;
    }
  },

  methods: {
    handleChange(option) {
      return row => {
        this.$nextTick(() => {
          this.$set(row, "variable_name", option.label);
          this.$set(row, "variable_id", option.value);
          this.$set(row, "msg", option.msg);
        });
      };
    },
    updateHttpResponse(response) {
      this.$emit("update-response", response);
    },
    formatPostParameters(parameters) {
      return JSON.stringify({
        ...parameters
      });
    },
    removeJsonPath(index) {
      let rule = this.getRules[index];
      this.$emit("remove-rule", rule);
    },
    addVariableRule(rule) {
      this.$emit("add-rule", rule);
    }
  },
  watch: {
    httpResponse: {
      deep: true,
      handler() {
        let rule = this.arrayRule();
        let sg = new SchemaGenerator(this.httpResponse);
        // The schema generated by the original response
        // and schema generated by the sample json after parsing the original response should be identical
        rule.default_value = JSON.stringify(sg.sampleJSON);

        // quick check to see if the above mentioned comment is right by checking the sample json generated by
        // identical schema are same (the difference in schema would be some constraints such as minLength of property
        // or something else, which we are not very concerned about)

        // let sg2 = new SchemaGenerator(JSON.stringify(sg.sampleJSON));
        // console.log(_.isEqual(sg.sampleJSON, sg2.sampleJSON));
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";

.json-paths {
  ::v-deep .new-item-or-select > .el-form-item {
    margin-bottom: 0;
  }

  ::v-deep .row-message {
    font-size: 0.75rem;
    color: $--color-success;
    margin-left: 10px;
  }
}
</style>
