<template>
  <div class="additional-option-line-item">
    <div class="line-item-detail">
      <div v-if="lineItemTitle" class="item-heading">{{ lineItemTitle }}</div>
      <div class="item-label">
        {{ item[itemLabel] }}
      </div>
    </div>
    <div class="line-item-options flex-grow">
      <div class="item-heading" v-if="lineItemOptionTitle">
        {{ lineItemOptionTitle }}
      </div>
      <el-select
        :value="value[item[itemValue]]"
        @input="$set(value, item[itemValue], $event)"
        :placeholder="__(lineItemPlaceholder)"
        filterable
        :clearable="clearable"
        default-first-option
        :class="!getErrorMsg(item) ? 'no-red-highlight' : ''"
        ><template v-if="hasOptionGroup">
          <slot
            name="groupItem"
            :item="item"
            :lineItemOptionsConfig="lineItemOptionsConfig"
            :lineItemOptionConfig="lineItemOptionConfig"
          >
            <el-option-group
              v-for="(groupItems, groupName) in item[
                lineItemOptionsConfig.prop
              ]"
              :key="groupName"
              :label="groupName"
            >
              <el-option
                v-for="lineItem in groupItems"
                :key="lineItem[lineItemOptionConfig.value]"
                :label="lineItem[lineItemOptionConfig.label]"
                :value="lineItem[lineItemOptionConfig.value]"
              >
              </el-option>
            </el-option-group>
          </slot>
        </template>
        <template v-else>
          <slot
            name="item"
            :item="item"
            :lineItemOptionsConfig="lineItemOptionsConfig"
            :lineItemOptionConfig="lineItemOptionConfig"
          >
            <el-option
              v-for="lineItem in item[lineItemOptionsConfig.prop]"
              :key="lineItem[lineItemOptionConfig.value]"
              :label="lineItem[lineItemOptionConfig.label]"
              :value="lineItem[lineItemOptionConfig.value]"
            >
            </el-option>
          </slot>
        </template>
      </el-select>
      <div class="line-item-info" v-if="lineItemInfo">{{ lineItemInfo }}</div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "TaskImportAdditionalOptionLineItem",
  props: {
    item: {
      required: true,
      type: Object
    },
    itemLabel: {
      required: true,
      type: String
    },
    itemValue: {
      required: true,
      type: String
    },
    lineItemPlaceholder: {
      required: false,
      type: String,
      default: ""
    },
    value: {
      required: true,
      type: [Array, Object]
    },
    errorFnConfig: {
      type: Object,
      required: false,
      default: () => {}
    },
    errorMsg: {
      type: String,
      required: false,
      default: ""
    },
    hasOptionGroup: {
      type: Boolean,
      default: true,
      required: false
    },
    lineItemTitle: {
      required: false,
      type: String,
      default: ""
    },
    lineItemInfo: {
      required: false,
      type: String,
      default: ""
    },
    lineItemOptionConfig: {
      required: true,
      type: Object
    },
    lineItemOptionsConfig: {
      required: true,
      type: Object
    },
    clearable: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    getErrorMsg() {
      return item => {
        return !_.isEmpty(this.errorFnConfig) &&
          this.errorFnConfig.fn(this.errorFnConfig.type)(item[this.itemKey])
          ? __(this.errorMsg)
          : "";
      };
    },
    lineItemOptionTitle() {
      return _.get(this.lineItemOptionConfig, "title", "");
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";

.additional-option-line-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px;

  .line-item-detail,
  .line-item-options {
    display: flex;
    flex-direction: column;
    line-height: 1.7;

    .item-heading,
    .line-item-info {
      line-height: 1.4;
      font-size: 0.825rem;
      color: #777777;
      word-break: break-word;
      padding-inline: 0;
    }

    .line-item-info {
      font-size: 0.725rem;
      padding-top: 2px;
    }
  }

  .line-item-detail {
    .item-heading {
      padding-inline: 0;
    }

    .item-label {
      min-width: 250px;
      max-width: 300px;
      line-height: 1.4;
      word-break: break-word;
    }
  }

  .line-item-options {
    .item-heading {
      padding-bottom: 1px;
    }
  }
  .flex-grow {
    flex: 1 0 0;
  }

  ::v-deep .no-red-highlight .el-input__inner,
  ::v-deep .no-red-highlight .el-textarea__inner,
  ::v-deep .empty-row .el-input__inner,
  ::v-deep .empty-row .el-textarea__inner,
  ::v-deep .non-empty-val .el-input__inner,
  ::v-deep .non-empty-val .el-textarea__inner {
    border-color: $--border-color-base !important;

    &:focus {
      border-color: $--color-text-regular !important;
    }
  }
}
</style>
