<template>
  <el-container class="container">
    <div class="inner-container" @keypress="restrictChars($event)" tabindex="0">
      <el-row :gutter="20" class="call-status" v-if="this.muteStatus">
        <el-col>
          <span class="error">{{ __("MUTED") }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="call-status" v-if="!this.muteStatus">
        <el-col>
          <i class="el-icon-loading" v-if="getCallStatus === 'Call Setup'"></i>
          <span :class="getNotificationColor"> {{ getCallStatusLabel }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="dtmf-display-pad">
        <el-col>
          <span class="dtmf-span" :class="getClassBasedOnLength">
            {{ enteredDTMF }}
          </span>
        </el-col>
      </el-row>
      <el-row type="flex" class="timer" justify="center">
        <el-col :span="12">
          <span>
            {{ time }}
          </span>
        </el-col>
      </el-row>
      <el-row
        v-for="(dpNumber, index) in dialpadNumbers"
        :key="index"
        v-bind="dpNumber"
        type="flex"
        :gutter="40"
        class="dialpad"
      >
        <el-col
          v-for="dpNum in dpNumber"
          :key="dpNum.dtmf"
          v-bind="dpNum"
          :span="4"
          :offset="1"
          ><el-button
            :id="`dtmf-${dpNum.dtmf}`"
            class="ellipse"
            circle
            @click="dialButtonClick(dpNum)"
            style="background: #F5F5F8;"
            :disabled="getCallStatus !== 'Connected'"
            >{{ dpNum.dtmf }}</el-button
          ></el-col
        >
      </el-row>
      <el-row :gutter="20">
        <el-col :span="3" :offset="8" style="padding-left: 8px;">
          <el-button
            class="hangUpEllipse"
            circle
            @click="hangUpClick('outgoing-bye')"
          >
            <img
              :src="require('@/assets/icons/icon-webrtc-hangup.svg')"
              :alt="__('Hangup')"
              class="hangupIcon"
              onload="SVGInject(this)"
            />
          </el-button>
        </el-col>
        <el-col :span="4" :offset="3">
          <el-button
            class="muteEllipse"
            circle
            @click="manageMute()"
            icon="el-icon-my-export"
            :disabled="getCallStatus !== 'Connected'"
          >
            <img
              v-if="this.muteStatus === true"
              :src="require('@/assets/icons/icon-muted.svg')"
              :alt="__('Mute')"
              class="hangupIcon"
            />
            <img
              v-if="this.muteStatus === false"
              :src="require('@/assets/icons/icon-unmuted.svg')"
              :alt="__('Unmute')"
              class="hangupIcon"
            />
          </el-button>
        </el-col>
      </el-row>
      <audio ref="remoteAudio" autoplay></audio>
    </div>
  </el-container>
</template>

<script>
import _ from "lodash";
import SipDialerUtil from "@/views/build/callflow/components/webRTC/SipDialerUtil";
import { mapActions, mapGetters } from "vuex";

const createVirtualPhoneNumber = {
  ac_id: null,
  channel: "voice",
  phone_number: "",
  sp_id: null,
  tags: "",
  trunk_id: null,
  task_id: null,
  action: "create"
};
export default {
  name: "DialpadComponent",
  mixins: [SipDialerUtil],
  components: {},
  props: {
    startCall: {
      required: true,
      type: Boolean
    },
    task: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      versionLoaded: null,
      processLoading: false,
      time: "00:00",
      timeBegan: null,
      timeStopped: null,
      stoppedDuration: 0,
      started: null,
      running: false,
      enteredDTMF: "",
      audio: "",
      virtualNumberPayload: _.cloneDeep(createVirtualPhoneNumber),
      muteStatus: false,
      dialpadNumbers: [
        [
          {
            dtmf: "7",
            audio_src: "7"
          },
          {
            dtmf: "8",
            audio_src: "8"
          },
          {
            dtmf: "9",
            audio_src: "9"
          }
        ],
        [
          {
            dtmf: "4",
            audio_src: "4"
          },
          {
            dtmf: "5",
            audio_src: "5"
          },
          {
            dtmf: "6",
            audio_src: "6"
          }
        ],
        [
          {
            dtmf: "1",
            audio_src: "1"
          },
          {
            dtmf: "2",
            audio_src: "2"
          },
          {
            dtmf: "3",
            audio_src: "3"
          }
        ],
        [
          {
            dtmf: "*",
            audio_src: "star"
          },
          {
            dtmf: "0",
            audio_src: "0"
          },
          {
            dtmf: "#",
            audio_src: "pound"
          }
        ]
      ]
    };
  },
  computed: {
    ...mapGetters("app", {
      ctEnabled: "ctEnabled"
    }),
    getCallStatus() {
      return this.callStatusObj.status;
    },
    /**
     * get the call status label
     * @returns {*}
     */
    getCallStatusLabel() {
      return this.callStatusObj.label;
    },
    //return the font color for notification text
    getNotificationColor() {
      return this.callStatusObj.severity;
    },
    getClassBasedOnLength() {
      let length = this.enteredDTMF.length;
      if (length < 8) {
        return "sm";
      }
      if (length < 10) {
        return "md";
      }
      if (length < 12) {
        return "lg";
      }

      if (length >= 12) {
        return "xlg";
      }
      return "sm";
    },
    getMuteIcon() {
      return this.muteStatus ? "muted" : "unmuted";
    }
  },
  methods: {
    ...mapActions("phonenumbers", {
      manageVirtualPhoneNumbers: "manageVirtualPhoneNumbers"
    }),
    addVirtualPhoneNumber() {
      this.virtualNumberPayload = _.cloneDeep(createVirtualPhoneNumber);
      this.$set(
        this.virtualNumberPayload,
        "sp_id",
        this.selectedServiceProviderId
      );
      this.$set(this.virtualNumberPayload, "ac_id", this.task.ac_id);
      this.$set(this.virtualNumberPayload, "task_id", this.task.task_id);
      this.setCallStatus("Call Setup", "info", "", __("Call Setup"));

      return new Promise((resolve, reject) => {
        this.manageVirtualPhoneNumbers(this.virtualNumberPayload)
          .then(res => {
            this.virtualNumberPayload = res.data;
            resolve();
          })
          .catch(err => {
            this.setCallStatus(
              "Call Setup Failed",
              "error",
              err.message,
              __("Call Setup Failed")
            );
            reject();
          });
      });
    },
    startTimer() {
      if (this.running) {
        return;
      }

      if (this.timeBegan === null) {
        this.resetTimer();
        this.timeBegan = new Date();
      }

      if (this.timeStopped !== null) {
        this.stoppedDuration += new Date() - this.timeStopped;
      }

      this.started = setInterval(this.clockRunning, 10);
      this.running = true;

      return "";
    },
    stopTimer() {
      this.running = false;
      this.timeStopped = new Date();
      clearInterval(this.started);
    },
    resetTimer() {
      this.running = false;
      clearInterval(this.started);
      this.stoppedDuration = 0;
      this.timeBegan = null;
      this.timeStopped = null;
      this.time = "00:00";
    },
    clockRunning() {
      let currentTime = new Date(),
        timeElapsed = new Date(
          currentTime - this.timeBegan - this.stoppedDuration
        ),
        min = timeElapsed.getUTCMinutes(),
        sec = timeElapsed.getUTCSeconds();

      this.time = this.zeroPrefix(min, 2) + ":" + this.zeroPrefix(sec, 2);
    },
    zeroPrefix(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },
    dialButtonClick(input) {
      this.enteredDTMF = _.cloneDeep(this.enteredDTMF) + input.dtmf;
      this.sendDTMF(input);
    },
    sendDTMF(input) {
      this.sipSendDTMF(input.dtmf);
      this.playAudio(input.audio_src, "play");
    },
    playAudio(audio_file_name, delay) {
      let audio = new Audio(this.generatedAudioUrl(audio_file_name));
      audio.play();
      if (delay > 0) {
        setTimeout(function() {
          audio.pause();
        }, delay);
      }
    },
    resetDTMF() {
      this.enteredDTMF = "";
    },

    hangUpClick(type) {
      if (type !== "incoming-bye") {
        this.hangUp();
      }
      this.stopTimer();
      this.playAudio("disconnected", 1500);
      let self = this;
      setTimeout(function() {
        self.resetTimer();
        self.resetDTMF();
        self.$emit("show", false);
      }, 1000);
      // reset the virtual number payload
      this.virtualNumberPayload = _.cloneDeep(createVirtualPhoneNumber);
      this.muteStatus = false;
    },
    manageMute() {
      this.muteStatus = !_.cloneDeep(this.muteStatus);
      this.mute(this.muteStatus);
    },
    timeNow() {
      let d = new Date(),
        h = (d.getHours() < 10 ? "0" : "") + d.getHours(),
        m = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
      let s = (d.getSeconds() < 10 ? "0" : "") + d.getSeconds();
      return h * m * s;
    },
    initiateCall(wsserver, useragent) {
      this.addVirtualPhoneNumber()
        .then(() => {
          this.playAudio("ringtone", 0);
          let register_flag = !this.ctEnabled;
          this.registerUA(wsserver, useragent, register_flag);
          this.makeCall(this.virtualNumberPayload.phone_number, wsserver);
          // this.makeCall("WR-123456789", wsserver);
          let self = this;
          setTimeout(function() {
            if (this.getCallStatus === "Ringing") {
              self.playAudio("ringtone", 2);
              self.playAudio("disconnected", 1000);
              self.setCallStatus("Connection Failure", "error", "");
              setTimeout(function() {
                self.$emit("show", false);
              }, 1000);
            }
          }, 3000);
        })
        .catch(err => {
          this.setCallStatus(
            "Call Setup Failed",
            "error",
            err.message,
            __("Call Setup Failed")
          );
        });
    },
    generatedAudioUrl(audio_file_name) {
      return require("@/assets/audio/" + audio_file_name + ".wav");
    },
    restrictChars: function($event) {
      if (
        $event.keyCode >= 48 &&
        $event.keyCode <= 57 &&
        this.getCallStatus === "Connected"
      ) {
        let dtmfObj = _.find(_.flatten(this.dialpadNumbers), {
          dtmf: $event.key
        });
        this.dialButtonClick(dtmfObj);
        return true;
      } else {
        $event.preventDefault();
      }
    }
  },
  watch: {
    startCall: {
      immediate: true,
      handler(val) {
        if (
          !_.isEmpty(process.env.VUE_APP_WEBRTC_WSSERVER) &&
          !_.isEmpty(process.env.VUE_APP_WEBRTC_USER_AGENT)
        ) {
          if (val) {
            const wsserverUrl = process.env.VUE_APP_WEBRTC_WSSERVER;
            let userAgent = process.env.VUE_APP_WEBRTC_USER_AGENT;
            this.initiateCall(wsserverUrl, userAgent);
          }
        } else {
          this.$emit("show", false);
          console.log(
            "Required environment variables are missing. Kindly check the env file."
          );
        }
      }
    },

    callStatusObj: {
      immediate: true,
      handler(val) {
        if (val.status === "Connected") {
          this.startTimer();
        } else if (val.severity === "error") {
          this.hangUpClick("incoming-bye");
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;

.span {
  display: inline-block;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hangupIcon {
  width: 18px;
  height: 18px;
  /*border: 1.44px solid #454545;*/
}

.el-row {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}
.error {
  color: #f84856;
}

.info {
  color: $content-theme-color;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  .inner-container {
    width: 88%;
    outline: none;
    .timer {
      padding-left: 25px;
      font-size: 14px;
      line-height: 20px;
    }
    .ellipse {
      position: inherit;
      width: 39px;
      height: 39px;
      background: #f5f5f8;
      padding: 10px;
    }
    .hangUpEllipse {
      position: inherit;
      width: 39px;
      height: 39px;
      background: #f84856;
      padding: 5px;
      border-color: transparent;
    }
    .muteEllipse {
      position: inherit;
      width: 39px;
      height: 39px;
      background: transparent;
      padding: 5px;
      border-color: transparent;
    }
    .dtmf-display-pad {
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      color: #454545;

      &:focus {
        outline: none;
      }

      .dtmf-span {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        direction: rtl;

        &.md {
          font-size: 16px;
        }

        &.lg {
          font-size: 14px;
        }

        &.xlg {
          font-size: 12px;
        }
      }
    }
    .call-status {
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }
    .dialpad {
      flex: 1 0 auto;
      display: flex;
      align-items: center;

      .el-col {
        .save-version-btn {
          width: 100%;

          &:hover {
            background-color: $content-theme-hover-color;
            border-color: $content-theme-hover-color;
          }

          &.is-disabled {
            color: #a0a8b5;
            background-color: #f5f5f8;
            border-color: #f5f5f8;

            &:hover {
              background-color: #f5f5f8;
            }
          }
        }
      }
    }

    .footer {
      text-align: center;
      margin-bottom: 12px;
      .foot-note {
        word-break: break-word;
        font-size: 0.715rem;
        color: #696969;
      }
    }
  }
}
</style>
