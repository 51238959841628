var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"white-space":"pre-wrap"}},[(_vm.param.label)?_c('span',[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.param.label)+" ")]),(
        _vm.nodeLogLabels.includes(_vm.navType) &&
          _vm.nodeIdToNavigate !== -1 &&
          !_vm.disableNavigation
      )?_c('el-tooltip',{attrs:{"content":_vm.__('Click to navigate to the node or Ctrl+hover to peek'),"placement":"bottom"}},[_c('span',{class:`${
            _vm.isSubTaskLog ? 'navigateToNodeLinkSubTask' : 'navigateToNodeLink'
          }`,staticStyle:{"white-space":"pre-wrap"},attrs:{"id":`node-link-sys-log-${_vm.nodeIdToNavigate}-${_vm.componentId}`},on:{"click":function($event){return _vm.$emit('navigate-to-node', _vm.nodeIdToNavigate, _vm.subTaskIdToNavigate)},"mouseover":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.$emit('handle-node-link-hover', _vm.nodeIdToNavigate, _vm.componentId)},"keydown":function($event){return _vm.handleKeyPressOnHover($event, _vm.nodeIdToNavigate, _vm.componentId)},"mouseleave":function($event){return _vm.$emit('handle-node-link-mouse-leave')},"keyup":function($event){return _vm.$emit('handle-ctrl-key-release', $event)},"mouseout":function($event){return _vm.$emit('end-peek-node')}}},[_vm._v(_vm._s(_vm.param.value))])]):(_vm.navType === 'task' && _vm.taskIdToNavigate !== -1)?_c('el-tooltip',{attrs:{"content":_vm.__('Click to navigate to the Task'),"placement":"bottom"}},[_c('span',{class:`${
            _vm.isSubTaskLog ? 'navigateToNodeLinkSubTask' : 'navigateToNodeLink'
          }`,staticStyle:{"white-space":"pre-wrap"},on:{"click":function($event){return _vm.$emit('navigate-to-task', _vm.taskIdToNavigate)}}},[_vm._v(_vm._s(_vm.param.value))])]):(_vm.param.label.includes('Audio URL'))?_c('span',[_c('audio-player',{staticClass:"simple-player",staticStyle:{"display":"inline-block","width":"24px"},attrs:{"file":_vm.param.value,"simple-player":true,"showClose":"true"}})],1):_c('read-more',{attrs:{"text":_vm.param.value}})],1):_c('read-more',{attrs:{"text":_vm.param.value}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }