<template>
  <el-select
    v-model="languageObj"
    style="width: 100%;"
    :placeholder="__('Select a language')"
    default-first-option
    filterable
    clearable
    :loading-text="__('fetching languages')"
    @change="changeLanguageValue"
    v-loading="asrLanguagesLoading"
    :no-data-text="noText"
  >
    <el-option
      v-for="(item, index) in filteredLanguages"
      :label="item.language_name"
      :value="item.language_code"
      :key="index"
    >
      <span style="float: left; font-size: 13px; color: #181F29;">{{
        item.language_name
      }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px;">{{
        item.language_code
      }}</span>
    </el-option>
  </el-select>
</template>

<script>
import { mapActions, mapState } from "vuex";
import _ from "lodash";

export default {
  name: "LanguageDropdown",
  props: {
    default_language: {
      required: false,
      type: String
    },
    nlp_engine: {
      type: String,
      default: "dialogflow"
    },
    return_type: {
      type: String,
      default: "id"
    }
  },
  data() {
    return {
      preText: "",
      languageObj: ""
    };
  },
  computed: {
    ...mapState("asrLanguages", {
      asrLanguages: state => state.asrLanguages,
      asrLanguagesLoading: state => state.loading
    }),
    noText() {
      // eslint-disable-next-line
      return __(":pre_text languages are not provisioned in your business plan", {
          pre_text: this.preText
        }
      );
    },
    //  filters language based on the language provider
    //  return Array
    filteredLanguages() {
      let asrLanguages = [];
      let self = this;
      if (this.nlp_engine === "lex") {
        asrLanguages = _.filter(this.asrLanguages, [
          "language_provider",
          "amazon"
        ]);
        self.preText = __("Amazon Lex");
      } else if (this.nlp_engine === "dialogflow") {
        asrLanguages = _.filter(this.asrLanguages, function(o) {
          return (
            o.language_provider === "google" &&
            o.language_used_in.includes("dialogflow")
          );
        });
        self.preText = __("Google dialogflow");
      } else if (this.nlp_engine === "dialogflow_cx") {
        asrLanguages = _.filter(this.asrLanguages, function(o) {
          return (
            o.language_provider === "google" &&
            o.language_used_in.includes("df_cx")
          );
        });
        self.preText = __("Google Dialogflow CX");
      } else {
        asrLanguages = _.filter(this.asrLanguages, function(o) {
          return (
            o.language_provider === "google" &&
            o.language_used_in.includes("cloud_speech")
          );
        });
        self.preText = __("Google Cloud Speech to Text");
      }
      if (!asrLanguages.length && !self.asrLanguagesLoading) {
        this.$message({
          type: "error",
          message: self.noText
        });
      }
      return asrLanguages;
    }
  },
  async created() {
    await this.initializeLanguages();
  },

  mounted() {
    if (!_.isEmpty(this.default_language)) {
      this.setLanguageObj();
    }
  },

  methods: {
    ...mapActions("asrLanguages", {
      getASRLanguages: "getASRLanguages"
    }),

    async initializeLanguages() {
      await this.getASRLanguages({ fetch_all: 0 });
    },
    //set the language object to dropdown from the filtered languages
    setLanguageObj() {
      this.languageObj = _.cloneDeep(this.default_language);
      // let language = _.cloneDeep(this.default_language);
      // this.languageObj = _.find(this.filteredLanguages, {
      //   language_code: language
      // });
    },

    changeLanguageValue(languageObj) {
      this.$emit("language-changed", languageObj);
    }
  },
  watch: {
    nlp_engine: {
      handler() {
        if (
          !_.isEmpty(this.languageObj) &&
          !_.isEmpty(this.filteredLanguages)
        ) {
          let acceptableLanguage = _.find(this.filteredLanguages, [
            "language_code",
            this.languageObj
          ]);
          if (_.isEmpty(acceptableLanguage)) {
            this.languageObj = "";
          }
        }
      }
    }
  }
};
</script>

<style scoped></style>
