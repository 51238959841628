<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="66"
    height="42"
    viewBox="0 0 66 42"
    fill="none"
  >
    <mask id="path-1-inside-1_653_106808" fill="white">
      <path
        d="M1 2C1 0.895431 1.89543 0 3 0H63.0066C64.1112 0 65.0066 0.89543 65.0066 2V21.1685H1V2Z"
      />
    </mask>
    <path
      d="M1 2C1 0.895431 1.89543 0 3 0H63.0066C64.1112 0 65.0066 0.89543 65.0066 2V21.1685H1V2Z"
      fill="#EEEEEE"
    />
    <path
      d="M1 0H65.0066H1ZM65.0066 22.1685H1V20.1685H65.0066V22.1685ZM1 21.1685V0V21.1685ZM65.0066 0V21.1685V0Z"
      fill="#DDDDDD"
      mask="url(#path-1-inside-1_653_106808)"
    />
    <path
      d="M4 24H45.6555"
      stroke="#444444"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M4 29H61.4973"
      stroke="#707070"
      stroke-width="2"
      stroke-linecap="round"
    />
    <rect x="5" y="34" width="11" height="4" rx="2" fill="#C6C6C6" />
    <rect x="5" y="34" width="11" height="4" rx="2" stroke="#707070" />
    <rect x="20" y="34" width="11" height="4" rx="2" fill="#C6C6C6" />
    <rect x="20" y="34" width="11" height="4" rx="2" stroke="#707070" />
    <rect x="35" y="34" width="11" height="4" rx="2" fill="#C6C6C6" />
    <rect x="35" y="34" width="11" height="4" rx="2" stroke="#707070" />
    <rect x="50" y="34" width="11" height="4" rx="2" fill="#C6C6C6" />
    <rect x="50" y="34" width="11" height="4" rx="2" stroke="#707070" />
    <rect
      x="0.890625"
      y="0.5"
      width="64.4587"
      height="41"
      rx="1.5"
      stroke="#DDDDDD"
    />
  </svg>
</template>

<script>
export default {
  name: "defaultCard"
};
</script>
