<template>
  <div>
    <div
      class="web-service-container"
      :style="{
        '--btn-color-normal': btnColorScheme.normal,
        '--btn-color-disabled': btnColorScheme.disabled,
        '--btn-color-hover': btnColorScheme.hover
      }"
    >
      <el-button-group class="response-button-group">
        <el-button
          size="mini"
          @click="setShowData(true)"
          :class="{ active: showData }"
          type="primary"
          >{{ __("Data") }}</el-button
        >
        <el-button
          size="mini"
          @click="setShowData(false)"
          :class="{ active: !showData && !showRawResponse }"
          type="primary"
          v-if="showMetaTab"
          >{{ __("Meta") }}</el-button
        >
        <el-button
          size="mini"
          @click="setShowRawResponse(true)"
          :class="{ active: showRawResponse }"
          type="primary"
          v-if="allowRawResponse"
          :disabled="displayEditor"
          >{{ __("Raw") }}</el-button
        >
      </el-button-group>
      <el-row :gutter="10" type="flex">
        <el-col
          v-loading="requestingHttpResponse"
          class="json-result-container"
        >
          <div style="position: absolute; right: 25px; top: 5px ; z-index: 2">
            <el-popover
              v-if="showEditorBtn"
              placement="top-end"
              trigger="hover"
              :content="__('provide sample json response')"
            >
              <div slot="reference">
                <el-button
                  @click="customizeHttpResponse"
                  type="primary"
                  icon="el-icon-edit"
                  circle
                ></el-button>
              </div>
            </el-popover>

            <el-popover
              v-if="showSetBtn && !editorError"
              placement="top-end"
              trigger="hover"
              :content="__('go back to preview')"
            >
              <div slot="reference">
                <el-button
                  @click="displayEditor = false"
                  type="primary"
                  icon="el-icon-check"
                  circle
                ></el-button>
              </div>
            </el-popover>
          </div>
          <el-scrollbar>
            <div class="json-box" style="max-height: 300px">
              <vue-json-pretty
                v-if="!displayEditor && !showRawResponse"
                :data="
                  showData ? dataFromResponse : statusAndHeadersFromResponse
                "
                @node-click="handleJSONClick"
                :deep="10"
                :highlightMouseoverNode="true"
                :highlightSelectedNode="true"
                style="line-height: 20px"
              >
              </vue-json-pretty>
              <v-json-editor
                v-if="displayEditor"
                v-model="relevantHttpResponseItem"
                :options="editorOptions"
                @error="editorError = true"
                :plus="false"
                style="height: 100%"
              ></v-json-editor>
              <div v-if="showRawResponse && !displayEditor">
                <code class="raw-data">{{
                  httpResponse["ws_response_raw"]
                }}</code>
              </div>
            </div>
          </el-scrollbar>
        </el-col>
      </el-row>
      <el-row :gutter="10" type="flex">
        <el-col>
          <div class="json-path-container">
            <div>
              <el-row
                v-if="showPreviewButton"
                class="preview-row"
                :gutter="5"
                type="flex"
                justify="flex-end"
              >
                <el-popover
                  v-if="!enablePreviewButton"
                  placement="top-end"
                  width="275"
                  trigger="hover"
                  :content="previewResponsePopoverContent"
                >
                  <el-col style="width:100%" slot="reference">
                    <el-button :disabled="!enablePreviewButton" type="primary"
                      >{{ __("Preview Response") }}
                    </el-button>
                  </el-col>
                </el-popover>

                <el-col
                  style="display: flex; justify-content: flex-end; align-items: center"
                  v-else
                >
                  <el-button
                    type="primary"
                    :disabled="requestingHttpResponse"
                    @click="requestJSONResponse"
                    >{{ __("Preview Response") }}
                  </el-button>
                </el-col>
              </el-row>
              <el-form-item :label="__('Assign Values to Variables')">
                <div v-show="!showRawResponse">
                  <el-row
                    style="margin-top: 10px"
                    :gutter="5"
                    type="flex"
                    justify="space-between"
                  >
                    <el-col :span="8"
                      ><span class="inner-title">{{
                        __("JSON Path")
                      }}</span></el-col
                    >
                    <el-checkbox
                      type="primary"
                      size="md"
                      v-show="jsonPathEditable && JSONPath"
                      v-model="editJsonPath"
                      >{{ __("Editable JSON") }}
                    </el-checkbox>
                  </el-row>
                  <el-row :gutter="5" type="flex">
                    <el-col style="margin-top: 5px;" v-if="!editJsonPath">
                      <el-input
                        disabled
                        :placeholder="__('JSON path')"
                        :value="prettify(jsonPathValue(JSONPath, variables))"
                      ></el-input>
                    </el-col>
                    <el-col @mouseleave.native="highlightedIndex = null" v-else>
                      <div
                        style="display:flex; flex-wrap: wrap; align-items: center"
                      >
                        <div class="json-path-part">
                          {{ __("response") }}
                        </div>
                        <div
                          class="json-path-part"
                          :class="isVisible(index) ? 'highlight' : ''"
                          style="margin-left:5px"
                          v-for="(part, index) in parts"
                          :key="index"
                          @mouseover="updateHoveringIndex(index)"
                        >
                          <template v-if="(part && index !== 0) || !part">
                            <span>['</span>
                            <input-variable-popper
                              class="json-part-content"
                              :is-text-area="false"
                              :include-secure-variables-="false"
                              :value="part"
                              force-reinitialize
                              @input="updatePart($event)(index)"
                              :ats="getOptionsInitiators"
                            ></input-variable-popper>
                            <span>']</span>
                            <span style="padding-left: 5px; width: 10px">
                              <span
                                class="cell-item-pointer"
                                v-if="isVisible(index)"
                                @click="removePart(index)"
                              >
                                <i class="el-icon-circle-close"></i>
                              </span>
                            </span>
                          </template>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row
                    class="json-path-result-row"
                    type="flex"
                    justify="end"
                  >
                    <el-col
                      v-loading="evaluatingJsonPath"
                      class="json-path-result-container"
                    >
                      <span v-if="jsonPathResult" class="json-path-result">{{
                        truncate(jsonPathResult, 85)
                      }}</span>
                    </el-col>
                  </el-row>
                </div>
              </el-form-item>
            </div>

            <div style="margin-top: 10px">
              <slot
                name="rule"
                :ruleValue="JSONPath"
                :disableSubmit="editJsonPath || evaluatingJsonPath"
                :resetRuleWhen="requestingHttpResponse"
                :addRule="addRule"
                :parts="parts"
                :audioCols="audioVariableCols"
                :secureCols="secureVariableCols"
                :isRawResponse="showRawResponse"
                :currentRawResponseVariableId="currentRawResponseVariableId"
              >
                <json-path-to-variable
                  :is-result-object="isJsonPathResultObject"
                  :audio-cols="audioVariableCols"
                  :secure-cols="secureVariableCols"
                  :parts="parts"
                  :disable-submit="editJsonPath || evaluatingJsonPath"
                  :rule-value="JSONPath"
                  :reset-rule-when="requestingHttpResponse"
                  :is-raw-response="showRawResponse"
                  :current-raw-response-variable-id="
                    currentRawResponseVariableId
                  "
                  :assign-new-variable="assignNewVariable"
                  @finish="addRule"
                />
              </slot>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import VJsonEditor from "v-jsoneditor";
import _ from "lodash";
import {
  primary,
  primaryDisabled,
  primaryHover
} from "@/styles/element-variables.scss";
import JsonPathToVariable from "./JsonPathToVariable";
import {
  convertDotNotationToBracketNotation
  // getSubKeyObject
} from "@/utils/collection";
import { jsonPathValue } from "@/utils/canvas";
import { mapGetters } from "vuex";
import { evaluateJsonPath } from "@/api/services";
import JsonPath from "@/mixins/JsonPath";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import { prettifyJsonPath } from "@/utils/transformers";

export default {
  components: {
    InputVariablePopper,
    VueJsonPretty,
    VJsonEditor,
    JsonPathToVariable
  },
  mixins: [JsonPath],
  props: {
    response: {
      required: true,
      type: [Object, Array, String],
      default: () => ""
    },
    getResponseUsing: {
      required: false,
      type: Function,
      default: () => {}
    },
    testData: {
      required: false,
      type: [Object, Array],
      default: () => ({})
    },
    responseEditable: {
      required: false,
      type: Boolean,
      default: false
    },
    jsonPathEditable: {
      required: false,
      type: Boolean,
      default: false
    },
    showEditBtn: {
      required: false,
      type: Boolean,
      default: true
    },
    showPreviewButton: {
      required: false,
      type: Boolean,
      default: true
    },
    enablePreviewButton: {
      required: true,
      type: Boolean
    },
    assignVarOnResponseJsonPath: {
      required: false,
      type: String,
      default: ""
    },
    previewResponsePopoverContent: {
      required: false,
      type: String,
      default: __("Set Test URL above to activate this button")
    },
    btnColorScheme: {
      required: false,
      type: Object,
      default: () => ({
        normal: primary,
        disabled: primaryDisabled,
        hover: primaryHover
      })
    },
    audioVariableCols: {
      required: false,
      type: Array,
      default: () => []
    },
    secureVariableCols: {
      required: false,
      type: Array,
      default: () => []
    },
    showMetaTab: {
      required: false,
      type: Boolean,
      default: true
    },
    allowRawResponse: {
      required: false,
      type: Boolean,
      default: false
    },
    showRawResponse: {
      required: false,
      type: Boolean,
      default: false
    },
    currentRawResponseVariableId: {
      required: false,
      type: Number,
      default: null
    }
  },

  data() {
    return {
      requestingHttpResponse: false,
      displayEditor: false,
      assignNewVariable: false,
      httpResponse: {},
      isJsonPathResultObject: false,
      // transformedJsonPath: false,
      // switched: false,
      JSONPath: "",
      JSONPathCopy: "",
      jsonPathResult: "",
      editorError: false,
      jsonPathValue,
      evaluatingJsonPath: false,
      editJsonPath: false,
      highlightedIndex: null,
      prettify: prettifyJsonPath
      // dataFromResponse: {},
      // statusAndHeadersFromResponse: {}
    };
  },

  computed: {
    ...mapGetters("variables", {
      variables: "variables"
    }),

    getOptionsInitiators() {
      return ["{{", "[["];
    },

    isVisible() {
      return index => {
        return index === this.highlightedIndex;
      };
    },

    responseAvailable() {
      return !_.isEmpty(this.httpResponse);
    },

    showEditorBtn() {
      return (
        this.responseEditable &&
        this.showEditBtn &&
        !this.displayEditor &&
        !this.showRawResponse
      );
    },
    showSetBtn() {
      return this.responseEditable && this.showEditBtn && this.displayEditor;
    },

    editorOptions() {
      return {
        mainMenuBar: false,
        mode: "code"
      };
    },

    dataFromResponse: {
      get() {
        return _.get(this.httpResponse, "ws_response_data", {});
      },
      set(val) {
        this.$set(this.httpResponse, "ws_response_data", val);
      }
    },

    statusAndHeadersFromResponse: {
      get() {
        // return getSubKeyObject(this.httpResponse, ["ws_response_meta"]);
        return _.get(this.httpResponse, "ws_response_meta", {});
      },

      set(val) {
        // this.httpResponse = Object.assign({}, this.httpResponse, val);
        this.$set(this.httpResponse, "ws_response_meta", val);
      }
    },

    relevantHttpResponseItem: {
      get() {
        return this.showData
          ? this.dataFromResponse
          : this.statusAndHeadersFromResponse;
      },

      set(val) {
        if (this.showData) {
          this.dataFromResponse = val;
        } else {
          this.statusAndHeadersFromResponse = val;
        }
      }
    }
  },

  methods: {
    initializeComponent() {
      let res;
      if (_.isObject(this.response)) {
        res = _.cloneDeep(this.response);
      } else {
        try {
          res = JSON.parse(this.response);
        } catch (err) {
          res = {
            ws_response_meta: { status_code: 200, headers: {} },
            ws_response_data: {},
            ws_response_raw: ""
          };
        }
      }

      if (!_.isEqual(res, this.httpResponse)) {
        this.httpResponse = res;
      }
    },
    truncate(value, limit = 15) {
      if (value && value.length <= limit) {
        return value;
      } else if (value) {
        return value.substring(0, limit) + "...";
      } else {
        return value;
      }
    },

    setShowData(val) {
      this.showData = val;
      this.editJsonPath = false;
      this.resetJsonPath();
      this.$emit("select-raw", false);
    },

    setShowRawResponse(val) {
      this.showData = false;
      this.resetJsonPath();
      this.$emit("select-raw", val);
    },

    resetJsonPath() {
      this.JSONPath = this.JSONPathCopy = this.jsonPathResult = "";
    },

    updateHoveringIndex(index) {
      this.highlightedIndex = index;
    },

    handleJSONClick({ path }) {
      path = convertDotNotationToBracketNotation(path);
      if (path === "root") {
        this.$message({
          type: "info",
          // eslint-disable-next-line
          message: __("the entire response is automatically associated with the node")
        });
      } else {
        let regex = new RegExp("^root", "g");
        if (this.showData) {
          path = path.replace(regex, "root['ws_response_data']", path);
        } else {
          path = path.replace(regex, "root['ws_response_meta']", path);
        }
        this.JSONPath = this.JSONPathCopy = path;
      }
    },
    requestJSONResponse() {
      this.editJsonPath = false;
      this.requestingHttpResponse = true;
      this.getResponseUsing(this.testData)
        .then(res => {
          this.httpResponse = res.data;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.requestingHttpResponse = false;
          if (!_.isEmpty(this.assignVarOnResponseJsonPath)) {
            this.assignJsonPathToNewVariable();
          }
        });
    },

    assignJsonPathToNewVariable() {
      this.handleJSONClick({ path: this.assignVarOnResponseJsonPath });
      this.assignNewVariable = true;
      this.$message({
        message: __("Please check the variable section for assigned variable "),
        type: "success"
      });
    },

    async evaluateJsonPath(val = this.JSONPath) {
      if (val !== this.JSONPath) {
        this.JSONPath = val;
      }
      if (this.JSONPath) {
        const jsonPath = this.JSONPath.replace("root", "$");
        let result;
        try {
          if (this.responseAvailable) {
            if (/.*?{{.*?}}.*/g.test(jsonPath)) {
              this.jsonPathResult = __("unable to evaluate - variables used");
              return;
            }

            this.evaluatingJsonPath = true;

            evaluateJsonPath({
              json: this.httpResponse,
              path: jsonPath
            })
              .then(({ data }) => {
                result = data.result;

                let tempResult = result;
                try {
                  tempResult = JSON.parse(tempResult);
                  this.isJsonPathResultObject =
                    _.isObject(tempResult) || jsonPath.includes("?(");
                } catch (err) {
                  this.isJsonPathResultObject = jsonPath.includes("?(");
                }

                this.jsonPathResult =
                  (!this.isJsonPathResultObject &&
                    (result === null || result === undefined)) ||
                  (this.isJsonPathResultObject && _.isEmpty(result))
                    ? "no data"
                    : // : JSON.stringify(result);
                      result.toString();
              })
              .catch(err => {
                this.jsonPathResult = err.message || "unable to evaluate";
                console.log(err);
              })
              .finally(() => {
                this.evaluatingJsonPath = false;
              });
          }
        } catch (err) {
          console.log(err);
        }
      }
    },

    customizeHttpResponse() {
      if (this.httpResponse === null || this.httpResponse === undefined) {
        this.httpResponse = {
          ws_response_meta: { status_code: 200, headers: {} },
          ws_response_data: {}
        };
      }
      this.displayEditor = true;
    },

    addRule(rule) {
      this.$emit("rule-added", rule);
      this.JSONPath = this.JSONPathCopy = this.jsonPathResult = "";
      this.editJsonPath = false;
    }
  },
  watch: {
    response: {
      immediate: true,
      deep: true,
      handler: "initializeComponent"
    },
    testData: {
      deep: true,
      handler() {
        this.displayEditor = false;
      }
    },
    JSONPath: {
      immediate: true,
      handler: function() {
        this.jsonPath = this.JSONPath;
        this.evaluateJsonPath();
      }
    },
    requestingHttpResponse(val) {
      if (val) {
        this.resetJsonPath();
      }
    },
    httpResponse: {
      deep: true,
      immediate: true,
      handler() {
        this.editorError = false;
        if (!this.responseAvailable) {
          this.jsonPathResult = "";
        }
        if (this.responseAvailable && this.JSONPath) {
          this.evaluateJsonPath();
        }
        this.$emit("change", _.cloneDeep(this.httpResponse));
      }
    },
    editJsonPath: function(val) {
      if (!val) {
        let path = this.parseParts();
        this.JSONPath = path === "root" ? "" : path;
      }
    },
    showRawResponse: {
      immediate: true,
      handler(val) {
        if (val) {
          this.showData = false;
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";
@import "~@/styles/node-palette.scss";

.json-box {
  ::v-deep .jsoneditor {
    border: none;
    line-height: 20px;

    .ace-jsoneditor.ace_editor {
      line-height: 20px;
    }
  }
}

.json-result-container {
  position: relative;
  margin-bottom: 10px;
}

.json-path-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  line-height: 28px;
  word-break: normal;

  .preview-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.json-path-part {
  margin-top: 5px;
  padding: 3px 5px;
  display: flex;
  align-items: center;

  &:focus-within,
  &.highlight {
    background: #e3e3e3;
    border-radius: 6px;
  }

  .json-part-content {
    margin: 0 2px;
  }

  ::v-deep .editableContent {
    min-width: 80px;
    border-radius: 6px;
    border: 1px solid #a0a8b5;
    outline: none;
    padding: 0 10px;
    overflow: auto;

    &:focus {
      border: 1px solid black;
    }
  }
}

.web-service-container {
  ::v-deep .el-button--primary {
    background-color: var(--btn-color-normal) !important;
    border: none;
    color: $--color-white !important;

    &:hover {
      background-color: var(--btn-color-hover) !important;
      border-color: var(--btn-color-hover) !important;
    }

    &.is-disabled {
      background-color: var(--btn-color-disabled) !important;
      border-color: var(--btn-color-disabled) !important;
    }
  }

  ::v-deep .el-checkbox__input {
    &.is-focus {
      & .el-checkbox__inner {
        border-color: var(--btn-color-normal) !important;
      }
    }

    & .el-checkbox__inner {
      &:hover,
      &:active {
        border-color: var(--btn-color-normal) !important;
      }
    }

    &.is-checked,
    &.is-checked.is-focus {
      & .el-checkbox__inner {
        background-color: var(--btn-color-normal) !important;
        border-color: var(--btn-color-normal) !important;
      }

      & + .el-checkbox__label {
        color: #111111;
      }
    }
  }

  .response-button-group {
    padding-bottom: 5px;
    ::v-deep .active {
      background-color: var(--btn-color-hover) !important;
      border-color: var(--btn-color-hover) !important;
    }
  }
}

.json-path-result-row {
  background-color: black;
  color: white;
  font-size: 0.75rem;
  height: 25px;
  margin-top: 10px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.json-path-result-container {
  display: flex;
  justify-content: flex-end;
}

.json-path-result {
  padding-right: 20px;
}

.raw-data {
  line-height: 18px;
}
</style>
