<template>
  <el-dialog
    :visible.sync="dialogVisible"
    v-if="dialogVisible"
    @close="handleDialogClose"
    :close-on-click-modal="true"
    :close-on-press-escape="true"
    show-close
    custom-class="filePreviewModal"
    top="20vh"
    append-to-body
  >
    <el-row>
      <el-col>
        <div class="preview-outer">
          <div v-if="this.fileType === 'image'" class="preview-inner">
            <el-image :src="this.fileUrl" fit="contain"></el-image>
          </div>

          <div v-else-if="this.fileType === 'audio'" class="preview-inner">
            <audio-player
              class="audio-player"
              :simplePlayer="true"
              :show-duration="true"
              :show-seek-bar="true"
              :show-download="true"
              icon-play="/icons/play1.svg"
              :file="this.fileUrl"
            />
          </div>

          <div v-if="this.fileType === 'document'" class="preview-inner">
            <iframe
              :src="this.fileUrl"
              type="application/pdf"
              style="z-index:999; display: block; width: 100%; height: 400px"
              ref="frame"
            >
            </iframe>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import { EventBus } from "@/EventBus";
import AudioPlayer from "@/components/AudioPlayer";

export default {
  name: "FilePreview",
  components: {
    AudioPlayer
  },
  data() {
    return {
      dialogVisible: false,
      fileUrl: "",
      fileType: ""
    };
  },
  created() {
    EventBus.$on("open-file-preview-modal", (file_url, file_type) => {
      this.fileUrl = file_url;
      this.fileType = file_type;
      this.dialogVisible = true;
    });
    EventBus.$on("close-file-preview-modal", () => {
      this.dialogVisible = false;
    });
  },
  computed: {},
  methods: {
    handleDialogClose() {}
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.btnAdd {
  margin-top: 30px;
}

.dialog-container {
  padding: 30px;
}
</style>

<style lang="scss">
.filePreviewModal {
  padding-top: 70px;
  border-radius: 0;
  .el-dialog__header {
    padding: 0;
    .el-dialog__headerbtn {
      z-index: 100;
    }
  }

  .el-dialog__body {
    padding: 0 !important;
    height: 50vh;
    /*overflow: hidden;*/
  }

  .el-dialog__footer {
    padding: 0;
  }
}

::v-deep .preview-outer {
  background-color: #0b8e6b;
  display: flex;
  justify-content: center;
  height: 100%;
}

::v-deep .preview-inner {
  background-color: #00afaf;
  position: absolute;
  width: 300px;
  height: 100px;
  top: -25%;
}

.audio-player {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-color: #0b8e6b;
  color: #454545;

  ::v-deep .audio-bar-contents {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.7rem;
    padding-left: 5px;
    padding-right: 5px;

    .control-group {
      display: flex;
      flex-direction: row;
      min-width: 10px;
      align-items: center;
    }

    .audio-bar-content {
      padding-left: 0;
      padding-right: 3px;
    }

    .audio-control {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .audio-progress {
      padding-left: 5px;
      padding-right: 10px;
      flex: 1;

      &.disabled {
        ::v-deep .el-slider__button {
          background-color: #a0a8b5 !important;
          border-color: #a0a8b5 !important;
        }

        ::v-deep .el-slider__runway {
          height: 1px;
          background-color: #454545;

          ::v-deep .el-slider__bar {
            height: 1px;
            background-color: #4db3f6;
          }

          ::v-deep .el-slider__button {
            width: 3px;
            height: 3px;
            border-radius: 2px;
          }
        }
      }

      ::v-deep .el-slider__runway {
        height: 3px;
        background-color: #454545;
        ::v-deep .el-slider__bar {
          height: 3px;
          background-color: #4db3f6;
        }

        ::v-deep.el-slider__button-wrapper {
          height: 34px;
          width: 12px;

          ::v-deep .el-tooltip {
            vertical-align: middle;
          }
        }
        ::v-deep .el-slider__button {
          width: 10px;
          height: 10px;
          border-radius: 8px;
          border-width: 1px;
          border-color: #454545;
        }
      }
    }
  }
}
</style>
