<template>
  <div style="display: flex; width: 100%;align-items: center; min-width:690px">
    <div style="display: flex; justify-self: flex-start">
      <el-dropdown
        v-for="(dropdown, index) in ssml_dropdowns"
        v-bind:key="index"
        @command="handleSSMLCommand"
      >
        <span
          class="el-dropdown-link"
          style="display: flex; align-items: center; padding: 5px"
        >
          <img
            :src="getIcon(dropdown)"
            style="padding: 0 5px 0 0"
            :alt="`${dropdown.icon}`"
          />
          {{ dropdown.title }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(item, index) in dropdown.items"
            v-bind:key="`${item.value}_${index}`"
            :command="{
              command_info: dropdown.command_info,
              value: item.value
            }"
            :disabled="textMode"
            >{{ item.label }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div style="display:flex;margin-left: auto;padding-right: 15px">
      <el-tooltip :content="getModeTooltip" placement="top">
        <el-switch
          v-model="textMode"
          style="margin-right: 10px"
          @change="$emit('toggle_content')"
        >
        </el-switch>
      </el-tooltip>
      <el-button plain style="padding:0; border: none" @click="$emit('undo')">
        <el-tooltip placement="top" :content="__('Undo SSML tag')">
          <img
            :src="require(`@/assets/icons/ssml_editor/undo_ssml.svg`)"
            alt="undo ssml"
            height="20px"
            width="20px"
          />
        </el-tooltip>
      </el-button>
      <el-button plain style="padding:0; border: none" @click="$emit('redo')">
        <el-tooltip placement="top" :content="__('Redo SSML tag')">
          <img
            :src="require(`@/assets/icons/ssml_editor/redo_ssml.svg`)"
            alt="redo ssml"
            height="20px"
            width="20px"
          />
        </el-tooltip>
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SSMLElements",
  data() {
    return {
      textMode: false,
      ssml_dropdowns: [
        {
          title: __("Add Pause"),
          items: [
            { value: 0.2, label: __("0.2") },
            { value: 0.4, label: __("0.4") },
            { value: 0.6, label: __("0.6") },
            { value: 1, label: __("1") },
            { value: 2, label: __("2") }
          ],
          icon: "add_pause_ssml.svg",
          command_info: {
            command: "break",
            openingTag: "break time",
            closingTag: ""
          }
        },
        {
          title: __("Volume"),
          items: [
            { value: "X-loud", label: __("X-loud") },
            { value: "Loud", label: __("Loud") },
            { value: "Medium", label: __("Medium") },
            { value: "Soft", label: __("Soft") },
            { value: "X-Soft", label: __("X-Soft") }
          ],
          icon: "volume_ssml.svg",
          command_info: {
            command: "prosody",
            openingTag: "prosody volume",
            closingTag: "prosody"
          }
        },
        {
          title: __("Pitch"),
          items: [
            { value: "X-high", label: __("X-high") },
            { value: "High", label: __("High") },
            { value: "Low", label: __("Low") },
            { value: "X-Low", label: __("X-Low") }
          ],
          icon: "pitch_ssml.svg",
          command_info: {
            command: "pitch",
            openingTag: "prosody pitch",
            closingTag: "prosody"
          }
        },
        {
          title: __("Emphasis"),
          items: [
            { value: "strong", label: __("strong") },
            { value: "moderate", label: __("moderate") },
            { value: "reduced", label: __("reduced") }
          ],
          icon: "emphasis_ssml.svg",
          command_info: {
            command: "emphasis",
            openingTag: "emphasis level",
            closingTag: "emphasis"
          }
        },
        {
          title: __("Say As"),
          items: [
            { value: "Cardinal", label: __("Cardinal") },
            { value: "Ordinal", label: __("Ordinal") },
            { value: "Characters", label: __("Characters") },
            { value: "Fraction", label: __("Fraction") },
            { value: "Expletive", label: __("Expletive") },
            { value: "Unit", label: __("Unit") },
            { value: "Verbatim", label: __("Verbatim") },
            { value: "Telephone", label: __("Telephone") }
          ],
          icon: "say_as_ssml.svg",
          command_info: {
            command: "say-as",
            openingTag: "say-as interpret-as",
            closingTag: "say-as"
          }
        },
        {
          title: __("Rate"),
          items: [
            { value: "X-Slow", label: __("X-Slow") },
            { value: "Slow", label: __("Slow") },
            { value: "Medium", label: __("Medium") },
            { value: "Fast", label: __("Fast") },
            { value: "X-Fast", label: __("X-Fast") }
          ],
          icon: "rate_ssml.svg",
          command_info: {
            command: "rate",
            openingTag: "prosody rate",
            closingTag: "prosody"
          }
        }
      ]
    };
  },
  computed: {
    getModeTooltip() {
      const textmode = this.textMode ? __("Edit Mode") : __("Text Mode");
      return __("Switch to :textmode", { textmode });
    }
  },
  methods: {
    handleSSMLCommand({ command_info, value }) {
      this.$emit("insert_ssml", {
        openingTag: `<b contenteditable="false"><i>&lt;${
          command_info.openingTag
        }='</i></b><b contenteditable="true"><i>${
          command_info.command === "break"
            ? value * 1000 + "ms"
            : value.toLowerCase()
        }</i></b><b contenteditable="false"><i>'${
          command_info.command === "break" ? "/" : ""
        }&gt;</i></b>`,
        closingTag: `<b contenteditable="false"><i>&lt;/${command_info.closingTag}&gt;</i></b>`,
        command: command_info.command
      });
    },
    getIcon(dropdown) {
      return require("@/assets/icons/ssml_editor/" + dropdown.icon);
    }
  }
};
</script>

<style scoped>
.el-dropdown-link {
  cursor: pointer;
  /*color: #409eff;*/
  color: var(--theme-color);
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>
