<template>
  <div>
    <el-form-item
      v-for="setting in profileSettings"
      :key="setting.key"
      :label="__(setting.label)"
      :prop="`web_service_node.data.${setting.key}`"
    >
      <input-variable-popper
        v-if="setting.type === 'input'"
        :value="nodeToBind.web_service_node.data.input_text"
        @input="$set(nodeToBind.web_service_node.data, 'input_text', $event)"
        :include-secure-variables="false"
        :is-text-area="false"
        :placeholder="__('Enter your text')"
      />
    </el-form-item>
  </div>
</template>

<script>
import InputVariablePopper from "../components/InputVariablePopper";

export default {
  components: {
    InputVariablePopper
  },
  props: {
    selectedProfile: {
      type: Object,
      required: true
    },
    nodeToBind: {
      type: Object,
      required: true
    },
    appendToPostBody: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      profileSettings: []
    };
  },
  mounted() {
    this.setProfileSettings();
  },
  computed: {},

  methods: {
    setProfileSettings() {
      this.profileSettings = JSON.parse(this.selectedProfile.profile_setting);
    }
  },
  watch: {
    "nodeToBind.web_service_node.data.input_text": function(val) {
      this.appendToPostBody(val);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node_common.scss";
</style>
