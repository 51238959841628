<template>
  <el-scrollbar
    class="version-history-scrollbar"
    :native="false"
    ref="version-scroll"
  >
    <el-container class="version-history">
      <transition-group name="list">
        <version
          :id="`version-index-${version.version_id}`"
          :ref="`version-index-${version.version_id}`"
          v-for="version in $data.$versions"
          :key="version.version_id"
          class="version-card"
          :version="version"
          :current-version-id="currentVersionId"
          :task-updated="!!taskUpdated"
          @update="informVersionManagerAboutVersionUpdate"
          @restore="requestVersionManagerToRestoreRequestedVersion"
        />
      </transition-group>
    </el-container>
  </el-scrollbar>
</template>

<script>
import _ from "lodash";
import Version from "@/views/build/callflow/components/versionManager/Version";
import gsap from "gsap";

export default {
  components: { Version },
  props: {
    versions: {
      required: true,
      type: Array
    },
    value: {
      required: true,
      validator: prop => typeof prop === "number" || prop === null
    },
    taskUpdated: {
      required: true,
      type: [Boolean, Number]
    }
  },
  data() {
    return {
      $versions: [],
      currentVersionId: null,
      scrollHeight: null,
      tweenedScrollHeight: null
    };
  },
  methods: {
    informVersionManagerAboutVersionUpdate(version) {
      this.$emit("update", version);
    },
    requestVersionManagerToRestoreRequestedVersion(version) {
      this.$emit("restore", version);
    }
  },
  watch: {
    versions: {
      immediate: true,
      handler(val) {
        let currentVersion = _.find(val, {
          version_id: this.value
        });

        let versions = _.orderBy(val, ["created_at"], "desc");

        if (!currentVersion) {
          this.$data.$versions = _.cloneDeep(versions);
        } else {
          let indexOfCurrentVersion = _.findIndex(versions, {
            version_id: this.value
          });

          // remove that version and append it to top
          versions.splice(indexOfCurrentVersion, 1);
          this.$data.$versions = _.cloneDeep(
            _.concat(currentVersion, versions)
          );
        }
      }
    },
    tweenedScrollHeight(val) {
      if (val !== null) {
        this.$refs["version-scroll"].wrap.scrollTop = val;
      }

      if (val === 0) {
        this.scrollHeight = null;
        this.tweenedScrollHeight = null;
      }
    },
    scrollHeight(val) {
      if (val !== null) {
        gsap.to(this.$data, { duration: 0.5, tweenedScrollHeight: 0 });
      } else {
        this.tweenedScrollHeight = null;
      }
    },
    value: {
      immediate: true,
      handler(val) {
        this.currentVersionId = val;
        if (this.$refs["version-scroll"]) {
          this.tweenedScrollHeight = this.$refs[
            "version-scroll"
          ].wrap.scrollTop;
          this.scrollHeight = this.tweenedScrollHeight;
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.version-history-scrollbar {
  ::v-deep .el-scrollbar__bar.is-vertical {
    width: 4px;
  }

  .version-history {
    display: flex;
    flex-direction: column;
    max-height: 380px;

    .version-card {
      margin-top: 5px;
      &:not(:first-child) {
        margin-top: 45px;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .list-enter-active,
  .list-leave-active {
    transition: all 0.75s;
  }
  .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(-30px);
  }
  .list-move {
    transition: transform 0.5s;
  }
}
</style>
