<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="42"
    viewBox="0 0 64 42"
    fill="none"
  >
    <mask id="path-1-inside-1_653_106827" fill="white">
      <path
        d="M0 2C0 0.89543 0.895431 0 2 0H61C62.1046 0 63 0.895431 63 2V21H0V2Z"
      />
    </mask>
    <path
      d="M0 2C0 0.89543 0.895431 0 2 0H61C62.1046 0 63 0.895431 63 2V21H0V2Z"
      fill="#EEEEEE"
    />
    <path
      d="M0 0H63H0ZM63 22H0V20H63V22ZM0 21V0V21ZM63 0V21V0Z"
      fill="#DDDDDD"
      mask="url(#path-1-inside-1_653_106827)"
    />
    <path
      d="M11.5 25H51.5"
      stroke="#444444"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M11.5 29H51.5"
      stroke="#707070"
      stroke-width="2"
      stroke-linecap="round"
    />
    <rect
      x="0.5"
      y="33.5"
      width="62"
      height="3.84668"
      fill="#EFEFEF"
      stroke="#CCCCCC"
    />
    <mask id="path-6-inside-2_653_106827" fill="white">
      <path
        d="M0 36.8467H63V38.7676C63 39.8721 62.1046 40.7676 61 40.7676H2C0.89543 40.7676 0 39.8721 0 38.7676V36.8467Z"
      />
    </mask>
    <path
      d="M0 36.8467H63V38.7676C63 39.8721 62.1046 40.7676 61 40.7676H2C0.89543 40.7676 0 39.8721 0 38.7676V36.8467Z"
      fill="#EFEFEF"
    />
    <path
      d="M0 35.8467H63V37.8467H0V35.8467ZM63 40.7676H0H63ZM0 40.7676V36.8467V40.7676ZM63 36.8467V40.7676V36.8467Z"
      fill="#CCCCCC"
      mask="url(#path-6-inside-2_653_106827)"
    />
    <rect x="0.5" y="0.5" width="62" height="41" rx="1.5" stroke="#DDDDDD" />
  </svg>
</template>

<script>
export default {
  name: "stackCard"
};
</script>
