import _ from "lodash";

export const CarouselComponent = {
  props: {
    value: {
      required: true,
      type: Object
    },

    /**
     * List of nodes in the task
     * @type [{label: String, value: String}]
     */
    gotoOptions: {
      required: false,
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      model: {},
      buttons: []
    };
  },

  methods: {
    /**
     * Create or update key in the model
     * @param {string} key New key or existing key to override in the model
     * @param {*} value Value to override the key with
     */
    setKeyForModel(key, value) {
      // Make sure we have the latest value in the model
      this.$set(this.model, key, value);
      this.$emit("input", this.model);
    }
  },

  mounted() {
    this.buttons = _.get(this.value, "messaging_node_card_button") || [];
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        this.model = this.value;
      }
    }
  }
};
