import { render, staticRenderFns } from "./TaskImportAdditionalOptionItem.vue?vue&type=template&id=5af6f0ac&scoped=true"
import script from "./TaskImportAdditionalOptionItem.vue?vue&type=script&lang=js"
export * from "./TaskImportAdditionalOptionItem.vue?vue&type=script&lang=js"
import style0 from "./TaskImportAdditionalOptionItem.vue?vue&type=style&index=0&id=5af6f0ac&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5af6f0ac",
  null
  
)

export default component.exports