<template>
  <div>
    <el-form-item
      :label="__('Title')"
      :prop="`${prop}.card_title`"
      :rules="[
        {
          required: true,
          message: __('Card title is required'),
          trigger: 'blur'
        }
      ]"
    >
      <input-variable-popper
        :placeholder="__('Card title')"
        v-model="cardTitle"
        :is-text-area="false"
        :force-reinitialize="true"
        :include-secure-variables="false"
        include-audio-variables
      />
    </el-form-item>

    <el-form-item
      :label="__('Message Content')"
      :prop="`${prop}.card_content`"
      :rules="[
        {
          required: true,
          message: __('Card content is required'),
          trigger: 'blur'
        }
      ]"
    >
      <input-variable-popper
        :placeholder="__('Card content')"
        v-model="cardContent"
        :is-markdown-editor="true"
        :force-reinitialize="true"
        :include-secure-variables="false"
        include-audio-variables
      />
    </el-form-item>

    <div v-show="displayUpload">
      <el-form-item>
        <el-radio-group v-model="cardMediaType">
          <el-radio-button label="Upload">{{ __("Upload") }}</el-radio-button>
          <el-radio-button label="URL">{{ __("URL") }}</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item
        :label="__('Media URL')"
        prop="file_url"
        v-if="cardMediaType === 'URL'"
      >
        <input-variable-popper
          v-model="cardMediaUrl"
          :force-reinitialize="true"
          :is-text-area="false"
          :include-secure-variables="false"
          :placeholder="__('Media URL')"
          include-audio-variables
        />
      </el-form-item>

      <el-form-item
        :label="__('Media')"
        prop="card_file"
        v-if="cardMediaType === 'Upload'"
      >
        <file-uploader
          :fileUrl="fileUrl"
          :show-file-name="true"
          :tipText="tipText"
          :allow-types="allowFileTypes"
          @file-info="setFileInfo"
          @on-success="handleSuccess"
          @on-error="handleError"
          @on-delete="handleFileDelete"
        />
      </el-form-item>
    </div>
    <!-- Hard code to hide Card Style section until the function done-->
    <el-form-item :label="__('Card Style')" class="section-head">
      <card-layout-config v-model="cardLayout" />
    </el-form-item>

    <span v-if="hasButton" style="font-size: 18px; font-weight: 700;">{{
      __("Card :cardNum Buttons", { cardNum })
    }}</span>
    <div :style="{ background: cardBtnBackground }">
      <DynamicRow
        v-model="cardButtons"
        type="secondary"
        :immutable="false"
        :valueTemplate="cardButtonTemplate()"
        addValueLabel="Add Another Button"
        :minValue="1"
        :maxValue="5"
        :implicitAddItemRowCheck="shouldAddNewButton"
      >
        <template slot="body" slot-scope="scope">
          <el-row>
            <el-col :span="12">
              <el-form-item
                style="padding-right: 16px;"
                :label="__('Button Text')"
                prop="card_type"
              >
                <input-variable-popper
                  :placeholder="__('Button Text')"
                  v-model="scope.row.card_button_text"
                  @input="() => scope.implicitAddItem()"
                  :is-text-area="false"
                  :force-reinitialize="true"
                  :include-secure-variables="false"
                  include-audio-variables
                />
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                prop="goto"
                :label="__('Goto Node')"
                class="is-required"
              >
                <create-or-select
                  :items="gotoOptionsCleaned"
                  :current_select="selectedGoToNodeForButton(scope.row)"
                  :fail-to-create-message="scope.row.error"
                  new-item-message="new node"
                  :placeholder="__('Node Name')"
                  @change="
                    event => {
                      handleButtonGotoNode(event, scope.row);
                      scope.implicitAddItem();
                    }
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </DynamicRow>
    </div>
  </div>
</template>

<script>
import { CarouselComponent } from "./carouselComponent";
import DynamicRow from "@/components/Nimbus/DynamicRow";
import { ButtonTypes } from "../constants";
import CreateOrSelect from "@/views/build/callflow/components/node-type-forms/components/CreateOrSelect";
import FileUploader from "@/components/uploaders/types/FileUploader.vue";
import { mapActions } from "vuex";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper.vue";
import CardLayoutConfig from "@/views/build/callflow/components/node-type-forms/messaging/carousel/component/cardLayoutConfig.vue";

export default {
  name: "sharedCardConfig",
  mixins: [CarouselComponent],

  props: {
    /**
     * Card number. To be displayed for card buttons label (i.e. "Card 1 Buttons")
     */
    cardNum: {
      required: true,
      type: Number
    },

    /**
     * @see https://element.eleme.io/#/en-US/component/form#form-item-attributes Form-Item Attributes/prop
     */
    prop: {
      required: false,
      type: String,
      default: () => ""
    }
  },

  components: {
    CardLayoutConfig,
    InputVariablePopper,
    FileUploader,
    DynamicRow,
    CreateOrSelect
  },
  data() {
    return {
      isUploading: false,
      file_url: "",
      file_path: "",
      uploaded_file_name: "",
      file_type: "",
      uploadedFilePath: ""
    };
  },
  computed: {
    cardTitle: {
      get() {
        return this.value.card_title || "";
      },

      set(value) {
        this.setKeyForModel("card_title", value);
      }
    },

    cardContent: {
      get() {
        return this.value.card_content || "";
      },

      set(value) {
        this.setKeyForModel("card_content", value);
      }
    },

    cardLayout: {
      get() {
        return this.value.card_layout || "Default";
      },

      set(value) {
        this.setKeyForModel("card_layout", value);
      }
    },
    /**
     * Check if the card contains any button
     */
    hasButton() {
      return Array.isArray(this.cardButtons)
        ? this.cardButtons.length > 0
        : false;
    },

    /**
     * Background for the card buttons input
     */
    cardBtnBackground() {
      return this.hasButton ? "#F5F5F5" : "transparent";
    },

    /**
     * The currently selected goto node ID or name to be displayed in the dropdown
     */
    selectedGoToNodeForButton() {
      return row => {
        if (row.card_button_goto_node === -1) {
          return row.card_button_goto_node_name || "";
        }

        return row.card_button_goto_node || "";
      };
    },

    /**
     * Cleanup the list of nodes that we can navigate
     * @returns {[{label: String, value: String}]}
     */
    gotoOptionsCleaned() {
      let tmp = this.gotoOptions;

      if (!Array.isArray(tmp)) {
        tmp = [];
      }

      return tmp.map(option => {
        return {
          label: option.node_name,
          value: option.node_id
        };
      });
    },

    cardMediaUrl: {
      get() {
        return this.value.card_media_url || "";
      },

      set(value) {
        this.setKeyForModel("card_media_url", value);
      }
    },

    cardMediaType: {
      get() {
        return this.value.card_media_type || "Upload";
      },

      set(value) {
        this.setKeyForModel("card_media_type", value);
      }
    },

    fileUrl: {
      get() {
        return this.value.file_url || "";
      },

      set(value) {
        this.setKeyForModel("file_url", value);
      }
    },

    fileName: {
      get() {
        return this.value.file_name || "";
      },

      set(value) {
        this.setKeyForModel("file_name", value);
      }
    },

    fileType: {
      get() {
        return this.value.file_type || "";
      },

      set(value) {
        this.setKeyForModel("file_type", value);
      }
    },

    filePath: {
      get() {
        return this.value.file_path || "";
      },

      set(value) {
        this.setKeyForModel("file_path", value);
      }
    },

    uploadedFileName: {
      get() {
        return this.value.file_name || "";
      },

      set(value) {
        this.setKeyForModel("file_name", value);
      }
    },

    cardButtons: {
      get() {
        return this.value.messaging_node_card_button || [];
      },

      set(value) {
        this.setKeyForModel("messaging_node_card_button", value);
      }
    },

    tipText() {
      let fileType = "";
      let size = 0;
      if (this.value.card_type === "video") {
        fileType = "mp4";
        size = 20;
      } else {
        fileType = "jpeg/png";
        size = 2;
      }
      // eslint-disable-next-line
      return __(":fileType with a size less than :sizemb",{fileType:fileType,size:size});
    },

    displayUpload() {
      return (
        this.value.card_type === "video" || this.value.card_type === "image"
      );
    },

    allowFileTypes() {
      switch (this.value.card_type) {
        case "image":
          return ["jpg", "png"];
        case "video":
          return ["mp4"];
        default:
          return [];
      }
    }
  },

  methods: {
    ...mapActions("upload", {
      deleteFileFromStorage: "deleteLogo"
    }),

    cardButtonTemplate() {
      return {
        card_button_text: "",
        card_button_type: ButtonTypes.default,
        card_button_url: "",
        card_button_goto_node: 0,

        // This value is only used for creating new nodes
        card_button_goto_node_name: ""
      };
    },

    handleButtonGotoNode(event, row) {
      row.card_button_goto_node = event.value;

      // The event could return label other than string. This issue is really hard to debug.
      // So we'll ensure that the value returned by node_name to always be of type string
      row.card_button_goto_node_name =
        typeof event.label !== "string"
          ? row.card_button_goto_node_name
          : event.label;
    },

    handleSuccess(res) {
      this.fileUrl = res.url;
      this.filePath = res.path;
      this.uploadedFileName = res.uploaded_file_name;
    },

    handleError(err) {
      console.log(err);
      this.$message({
        type: "error",
        message: err.message.split(":")[1].slice(1, -2)
      });
    },

    setFileInfo(file) {
      console.log(file);
      this.fileType = file.type;
    },

    handleFileDelete() {
      this.fileUrl = "";
      this.filePath = "";
      this.uploadedFileName = "";
      this.$set(this.value, "card_media_id", null);
      this.deleteUploadedFile();
    },

    deleteUploadedFile() {
      if (this.file_id === -1) {
        this.deleteFileFromStorage({ path: this.file_path }).catch(err => {
          console.log(err);
        });
      }
    },

    shouldAddNewButton(rowData) {
      return !!rowData.card_button_goto_node_name && !!rowData.card_button_text;
    }
  },

  watch: {
    buttons: function() {
      this.setKeyForModel("messaging_node_card_button", this.buttons);
    },
    "value.card_type": {
      handler() {
        //reset file upload info when card type is switched
        this.handleFileDelete();
      }
    }
  }
};
</script>

<style scoped>
.section-head ::v-deep .el-form-item__label {
  font-weight: bolder !important;
}
</style>
