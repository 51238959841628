<template>
  <div>
    <el-row class="schedule-repeat-row">
      <el-col
        :xs="{ span: 7 }"
        :sm="{ span: 7 }"
        :md="{ span: 8 }"
        :lg="{ span: 8 }"
      >
        <span>{{ __("Repeat Every") }}</span>
      </el-col>
      <el-col
        :xs="{ span: 17 }"
        :sm="{ span: 17 }"
        :md="{ span: 16 }"
        :lg="{ span: 16 }"
      >
        <div style="width: 220px">
          <el-input-number
            size="small"
            :min="1"
            :max="30"
            style="width: 100px; margin-right: 10px;"
            v-model="schedule_item_repeat.repeat_interval"
            @change="handleUpdateScheduleItemRepeat"
            :disabled="disabled"
          ></el-input-number>
          <el-select
            size="small"
            style="width: 100px;"
            v-model="schedule_item_repeat.repeat_frequency"
            default-first-option
            placeholder="Select"
            @change="handleUpdateScheduleItemRepeat"
            :disabled="disabled"
          >
            <el-option
              v-for="item in repeat_frequency_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>
    <el-row
      v-show="
        schedule_item_repeat.repeat_frequency === 'Weekly' ||
          schedule_item_repeat.repeat_frequency === 'Monthly'
      "
      class="schedule-repeat-row"
    >
      <el-col
        :xs="{ span: 7 }"
        :sm="{ span: 7 }"
        :md="{ span: 8 }"
        :lg="{ span: 8 }"
      >
        <span>{{ __("Repeat On") }}</span>
      </el-col>
      <el-col
        :xs="{ span: 17 }"
        :sm="{ span: 17 }"
        :md="{ span: 16 }"
        :lg="{ span: 16 }"
      >
        <el-checkbox-group
          v-model="days_of_week_selected"
          fill="var(--theme-color)"
          size="small"
          style="width: 300px"
          :disabled="disabled"
        >
          <el-checkbox-button
            v-model="repeat_in"
            v-for="day in days_of_week"
            :label="day.value"
            :key="day.value"
            v-show="schedule_item_repeat.repeat_frequency === 'Weekly'"
            @change="handleUpdateScheduleItemRepeat"
            >{{ day.label }}</el-checkbox-button
          >
        </el-checkbox-group>
        <el-select
          size="small"
          style="width: 150px;"
          v-show="schedule_item_repeat.repeat_frequency === 'Monthly'"
          v-model="repeat_in"
          default-first-option
          placeholder="Select repeat on"
          @change="handleUpdateScheduleItemRepeat"
          :disabled="disabled"
        >
          <el-option
            v-for="item in repeat_on_options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row class="schedule-repeat-row">
      <el-col
        :xs="{ span: 7 }"
        :sm="{ span: 7 }"
        :md="{ span: 8 }"
        :lg="{ span: 8 }"
      >
        <span>{{ __("Ends") }}</span>
      </el-col>
      <el-col
        :xs="{ span: 17 }"
        :sm="{ span: 17 }"
        :md="{ span: 16 }"
        :lg="{ span: 16 }"
      >
        <div style="width: 300px">
          <el-select
            size="small"
            style="width: 100px; margin-right: 10px;"
            v-model="schedule_item_repeat.end_type"
            default-first-option
            placeholder="Select"
            @change="handleUpdateScheduleItemRepeat"
            :disabled="disabled"
          >
            <el-option
              v-for="item in end_type_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input-number
            size="small"
            v-model="end_type_value_number"
            :min="1"
            :max="30"
            style="width: 100px; margin-right: 10px;"
            v-show="schedule_item_repeat.end_type === 'After'"
            @change="handleUpdateScheduleItemRepeat"
            :disabled="disabled"
          ></el-input-number
          ><span
            v-show="schedule_item_repeat.end_type === 'After'"
            style="white-space: nowrap;"
            >{{ __("Occurrences") }}</span
          >
          <el-date-picker
            size="small"
            value-format="yyyy-MM-dd"
            :format="displayDateFormat"
            type="date"
            style="width: 150px;"
            placeholder="Pick a date"
            v-model="end_type_value_date"
            v-show="schedule_item_repeat.end_type === 'On'"
            @change="handleUpdateScheduleItemRepeat"
            :disabled="disabled"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </div>
      </el-col>
    </el-row>
    <el-row class="schedule-summary;">
      <el-col
        :xs="{ span: 7 }"
        :sm="{ span: 7 }"
        :md="{ span: 8 }"
        :lg="{ span: 8 }"
      >
        <span>{{ __("Summary") }}</span>
      </el-col>
      <el-col
        :xs="{ span: 17 }"
        :sm="{ span: 17 }"
        :md="{ span: 16 }"
        :lg="{ span: 16 }"
      >
        <div style="width: 300px; word-break: normal">
          <span>{{ this.schedule_item_repeat.repeat_summary }}</span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import { mapGetters } from "vuex";

export default {
  name: "ScheduleRepeatEdit",
  components: {},
  props: {
    scheduleItemRepeat: {
      type: Object,
      required: false,
      default: () => ({})
    },
    minDate: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      schedule_item_repeat: [],
      end_type_value_number: "",
      end_type_value_date: this.minDate,
      commaRegex: new RegExp(",", "g"),
      repeat_on_options: [
        {
          value: "DayOfMonth",
          label: __("Day of the month")
        },
        {
          value: "DayOfWeek",
          label: __("Day of the week")
        }
      ],
      repeat_frequency_options: [
        {
          value: "Daily",
          label: __("Day")
        },
        {
          value: "Weekly",
          label: __("Week")
        },
        {
          value: "Monthly",
          label: __("Month")
        },
        {
          value: "Yearly",
          label: __("Year")
        }
      ],
      end_type_options: [
        {
          value: "Never",
          label: __("Never")
        },
        {
          value: "After",
          label: __("After")
        },
        {
          value: "On",
          label: __("On")
        }
      ],
      days_of_week: [
        {
          value: "Sunday",
          label: __("S"),
          order: 1
        },
        {
          value: "Monday",
          label: __("M"),
          order: 2
        },
        {
          value: "Tuesday",
          label: __("T"),
          order: 3
        },
        {
          value: "Wednesday",
          label: __("W"),
          order: 4
        },
        {
          value: "Thursday",
          label: __("T"),
          order: 5
        },
        {
          value: "Friday",
          label: __("F"),
          order: 6
        },
        {
          value: "Saturday",
          label: __("S"),
          order: 7
        }
      ],
      days_of_week_selected: ["Monday"],
      repeat_in: "DayOfMonth",
      pickerOptions: {
        disabledDate: this.disabledDates
      }
    };
  },
  computed: {
    ...mapGetters("app", {
      displayDateFormat: "displayDateFormat",
      displayDateFormatMoment: "displayDateFormatMoment"
    }),
    dayNames() {
      return _.map(
        _.orderBy(this.days_of_week, ["order"], ["asc"]),
        day => day.value
      );
    }
  },
  methods: {
    disabledDates(date) {
      if (moment(date).isBefore(this.minDate, "day")) {
        return true;
      } else {
        return false;
      }
    },
    handleError(err) {
      console.log(err);
    },
    orderDaysInWeek(daysInWeek) {
      return _.intersection(this.dayNames, daysInWeek);
    },
    handleUpdateScheduleItemRepeat() {
      //assign repeat_in field from appropriate local variable based on repeat_frequency
      if (this.schedule_item_repeat.repeat_frequency === "Weekly") {
        if (this.days_of_week_selected.length === 0) {
          //If field is empty select Monday as default
          this.days_of_week_selected = ["Monday"];
        }
        this.schedule_item_repeat.repeat_in = this.orderDaysInWeek(
          this.days_of_week_selected
        ).toString();
      } else if (this.schedule_item_repeat.repeat_frequency === "Monthly") {
        this.schedule_item_repeat.repeat_in = this.repeat_in;
      }

      //assign end_type_value field from appropriate local variable based on end_type
      if (this.schedule_item_repeat.end_type === "After") {
        this.schedule_item_repeat.end_type_value = this.end_type_value_number;
      } else if (this.schedule_item_repeat.end_type === "On") {
        if (moment(this.end_type_value_date).isBefore(this.minDate)) {
          // console.log("is before");
          this.end_type_value_date = this.minDate;
        }
        this.schedule_item_repeat.end_type_value = moment(
          this.end_type_value_date
        ).format("YYYY-MM-DD");
      }

      this.updateRepeatSummary();
      this.$emit(
        "update-schedule-repeat",
        _.cloneDeep(this.schedule_item_repeat)
      );
    },
    updateRepeatSummary() {
      let d_start = new Date(this.schedule_item_repeat.starts_on);
      let position = ["first", "second", "third", "fourth", "last"];

      let summary = "";
      if (this.schedule_item_repeat.repeat_frequency === "Daily") {
        summary = "Daily";
        if (this.schedule_item_repeat.repeat_interval > 1) {
          summary =
            "Every " + this.schedule_item_repeat.repeat_interval + " days";
        }
      } else if (this.schedule_item_repeat.repeat_frequency === "Weekly") {
        summary = "Weekly";
        if (this.schedule_item_repeat.repeat_interval > 1) {
          summary =
            "Every " + this.schedule_item_repeat.repeat_interval + " weeks";
        }
        summary += " on ";
        if (this.schedule_item_repeat.repeat_in.split(",").length === 7) {
          summary += " all days";
        } else {
          summary += this.schedule_item_repeat.repeat_in
            .toString()
            .replace(this.commaRegex, ", ")
            .trim();
        }
      } else if (this.schedule_item_repeat.repeat_frequency === "Monthly") {
        summary = "Monthly";
        if (this.schedule_item_repeat.repeat_interval > 1) {
          summary =
            "Every " + this.schedule_item_repeat.repeat_interval + " months";
        }
        if (this.schedule_item_repeat.repeat_in === "DayOfMonth") {
          summary += " on day " + d_start.getDate();
        } else if (this.schedule_item_repeat.repeat_in === "DayOfWeek") {
          summary +=
            " on " +
            position[Math.ceil(d_start.getDate() / 7) - 1] +
            " " +
            this.dayNames[d_start.getDay()];
        }
      } else if (this.schedule_item_repeat.repeat_frequency === "Yearly") {
        summary = "Annually";
        if (this.schedule_item_repeat.repeat_interval > 1) {
          summary =
            "Every " + this.schedule_item_repeat.repeat_interval + " years";
        }
        summary += " on " + moment(d_start).format("MMMM Do");
      }

      if (this.schedule_item_repeat.end_type === "Never") {
        summary += "";
      } else if (this.schedule_item_repeat.end_type === "After") {
        summary += ", " + this.end_type_value_number + " time(s)";
      } else if (this.schedule_item_repeat.end_type === "On") {
        if (this.end_type_value_date !== "") {
          let d = moment(this.end_type_value_date);
          summary += ", until " + d.format(this.displayDateFormatMoment);
        }
      }

      //console.log(summary);
      this.schedule_item_repeat.repeat_summary = summary;
    }
  },
  mounted() {
    let d = new Date();

    this.days_of_week_selected = [this.days_of_week[d.getDay()].value];
    //assign prop value to local variable
    this.schedule_item_repeat = _.cloneDeep(this.scheduleItemRepeat);

    //assign repeat_in field to appropriate local variable based on repeat_frequency
    if (this.schedule_item_repeat.repeat_frequency === "Weekly") {
      if (this.schedule_item_repeat.repeat_in !== "") {
        this.days_of_week_selected = this.schedule_item_repeat.repeat_in.split(
          ","
        );
      }
    } else if (this.schedule_item_repeat.repeat_frequency === "Monthly") {
      this.repeat_in = this.schedule_item_repeat.repeat_in;
    }

    //assign end_type_value field to appropriate local variable based on end_type
    if (this.schedule_item_repeat.end_type === "After") {
      this.end_type_value_number = this.schedule_item_repeat.end_type_value;
      //If number is empty assign 1 as default
      if (this.end_type_value_number === "") {
        this.end_type_value_number = 1;
      }
    } else if (this.schedule_item_repeat.end_type === "On") {
      this.end_type_value_date = this.schedule_item_repeat.end_type_value;
      //If date is empty assign today as default
      if (this.end_type_value_date === "") {
        this.end_type_value_date = moment(d).format("YYYY-MM-D");
      }
    }

    this.updateRepeatSummary();
  },
  watch: {
    schedule_item_repeat: {
      deep: true,
      handler: function(newVal, oldVal) {
        this.updateRepeatSummary();
        if (!_.isEqual(oldVal, newVal)) {
          this.$emit(
            "update-schedule-repeat",
            _.cloneDeep(this.schedule_item_repeat)
          );
        }
      }
    },
    scheduleItemRepeat: {
      deep: true,
      handler: function(newVal) {
        this.schedule_item_repeat = _.cloneDeep(newVal);
      }
    },
    minDate: {
      handler: function() {
        if (moment(this.end_type_value_date).isBefore(this.minDate)) {
          this.end_type_value_date = this.minDate;
          this.handleUpdateScheduleItemRepeat();
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node_common.scss";
.el-row {
  //margin-top: 10px;
  //margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}
.schedule-repeat-row {
  display: flex;
  flex-wrap: nowrap;
}
</style>
