<template>
  <div>
    <!-- 3. Video -->
  </div>
</template>

<script>
import { CarouselComponent } from "./carouselComponent";

export default {
  name: "videoCardConfig",
  mixins: [CarouselComponent]
};
</script>

<style></style>
