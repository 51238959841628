<template>
  <div>
    <el-form class="form-container" label-position="top">
      <el-row>
        <el-col>
          <div class="primary-title">
            {{ __("Web Services") }}
          </div>

          <div style="margin-top:24px">
            <el-form class="form-container">
              <el-form-item
                prop="searchProfile"
                :label="__('Select web service')"
              >
                <el-input
                  :placeholder="__('Search for web services')"
                  class="searchInput"
                  v-model="searchProfile"
                >
                  <img
                    slot="suffix"
                    :src="require('@/assets/icons/icon-search.svg')"
                    class="input-icon"
                /></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col>
          <div v-loading="loading" class="mt-8">
            <div class="contentCardsContainer">
              <el-row
                type="flex"
                justify="start"
                class="list-cards"
                v-loading="loading"
              >
                <el-col
                  :span="4"
                  :key="index"
                  v-for="(profile, index) in profilesList"
                >
                  <div
                    style="display: flex; align-items: center; justify-content: center; padding: 5px 10px;"
                  >
                    <div
                      style="display: flex;flex-direction: column; align-items:center;"
                    >
                      <el-card
                        @click.native.stop="setProfile(index, profile)"
                        shadow="hover"
                        :class="{ selected: selectedIndex === index }"
                        style="display: flex; margin-bottom: 5px;width:58px;border-radius:15px"
                      >
                        <div class="node-cell">
                          <img
                            class="node-cell-icon"
                            :src="getIconForProfile(profile.action_name)"
                            draggable="false"
                            onload="SVGInject(this)"
                          />
                        </div>
                      </el-card>
                      <div style="display: flex;margin-bottom: 5px;">
                        <span
                          :class="{ selected: selectedIndex === index }"
                          style="font-weight:600;"
                        >
                          {{ profile.display_name.toUpperCase() }}
                        </span>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row style="margin-top: 25px" type="flex">
        <el-col
          :span="12"
          style="display: flex; align-items: flex-start;margin-top:20px"
        >
          <el-button
            :disabled="!isProfileSet"
            @click="handleActionSelect(selectedProfile)"
            class="submitBtn"
          >
            {{ __("Next") }}
          </el-button>
          <el-button @click="handleCancel" class="cancelBtn">{{
            __("Cancel")
          }}</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "SelectWebService",
  components: {},
  data() {
    return {
      loading: false,
      selectedProfile: "",
      selectedIndex: null,
      searchProfile: "",
      profilesList: []
    };
  },
  props: {
    webProfiles: {
      required: true,
      type: Array
    }
  },

  mounted() {
    this.showAllProfiles(this.webProfiles);
  },
  computed: {
    isProfileSet() {
      return this.selectedProfile !== "";
    }
  },
  methods: {
    setProfile(index, profile) {
      this.selectedProfile = profile;
      this.selectedIndex = index;
    },
    handleActionSelect(event) {
      this.$emit("action-selected", event);
    },
    handleCancel() {
      this.$emit("action-canceled");
    },
    getIconForProfile(actionType) {
      // can be replaced later when svg icons are available
      return "/icons/" + actionType + ".png";
      // return "/icons/web_service.svg";
    },
    showAllProfiles(webProfiles) {
      this.profilesList = webProfiles;
    },
    handleSearch(searchParam) {
      // Convert searchParam to a regular expression, escaping special characters
      const regex = new RegExp(searchParam, "i");

      this.profilesList = _.filter(
        this.profilesList,
        profile =>
          regex.test(profile.display_name) || regex.test(profile.action_name)
      );
    }
  },
  watch: {
    searchProfile: {
      handler() {
        if (this.searchProfile) {
          let searchParam = new RegExp(_.lowerCase(this.searchProfile));
          this.handleSearch(searchParam);
        } else {
          this.showAllProfiles(this.webProfiles);
        }
      }
    },
    webProfiles: {
      handler() {
        this.showAllProfiles(this.webProfiles);
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$card-image-filter: 1;

@import "~@/styles/typography.scss";
@import "~@/styles/content-card.scss";
@import "~@/styles/button.scss";
@import "~@/styles/node_common.scss";

.el-card {
  padding: 8px 12px !important;
}

.searchInput {
  input {
    height: 44px !important;
    line-height: 44px !important;
    border-color: #f5f5f8;
    color: #a0a8b5;
  }
  input:hover {
    border-color: #f5f5f8;
  }
}

.input-icon {
  padding-top: 10px;
}

.node-cell {
  height: 60px;
  border-radius: 25px;
  margin-top: 5px;
  outline-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 32px;
    width: 32px;
    //margin-top: 18px;
    //margin-left: 18px;
  }
}
</style>
