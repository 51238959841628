export const CardTypes = {
  Text: "text",
  Image: "image",
  Video: "video",
  default: "text"
};

export const ButtonTypes = {
  PostBack: "postBack",
  default: "postBack"
};
