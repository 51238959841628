<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="66"
    height="42"
    viewBox="0 0 66 42"
    fill="none"
  >
    <mask id="path-1-inside-1_653_106818" fill="white">
      <path d="M0 0H64V29H0V0Z" />
    </mask>
    <path
      d="M64 28H0V30H64V28Z"
      fill="#DDDDDD"
      mask="url(#path-1-inside-1_653_106818)"
    />
    <path
      d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H31.2642V28.355H0.5V2Z"
      fill="#EEEEEE"
      stroke="#DDDDDD"
    />
    <path
      d="M35 6H61"
      stroke="#444444"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M35 11H61"
      stroke="#707070"
      stroke-width="1.4"
      stroke-linecap="round"
    />
    <path
      d="M35 16H61"
      stroke="#707070"
      stroke-width="1.4"
      stroke-linecap="round"
    />
    <path
      d="M35 21H61"
      stroke="#707070"
      stroke-width="1.4"
      stroke-linecap="round"
    />
    <rect x="4" y="33" width="11" height="4" rx="2" fill="#C6C6C6" />
    <rect x="4" y="33" width="11" height="4" rx="2" stroke="#707070" />
    <rect x="19" y="33" width="11" height="4" rx="2" fill="#C6C6C6" />
    <rect x="19" y="33" width="11" height="4" rx="2" stroke="#707070" />
    <rect x="34" y="33" width="11" height="4" rx="2" fill="#C6C6C6" />
    <rect x="34" y="33" width="11" height="4" rx="2" stroke="#707070" />
    <rect x="49" y="33" width="11" height="4" rx="2" fill="#C6C6C6" />
    <rect x="49" y="33" width="11" height="4" rx="2" stroke="#707070" />
    <rect
      x="0.5"
      y="0.5"
      width="64.0459"
      height="41"
      rx="1.5"
      stroke="#DDDDDD"
    />
  </svg>
</template>

<script>
export default {
  name: "horizontalCard"
};
</script>
