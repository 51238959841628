import { render, staticRenderFns } from "./Dialpad.vue?vue&type=template&id=16ff36c1&scoped=true"
import script from "./Dialpad.vue?vue&type=script&lang=js"
export * from "./Dialpad.vue?vue&type=script&lang=js"
import style0 from "./Dialpad.vue?vue&type=style&index=0&id=16ff36c1&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16ff36c1",
  null
  
)

export default component.exports