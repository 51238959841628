<template>
  <div :style="{ padding: contentPadding }">
    <el-collapse v-model="activeNames">
      <el-collapse-item
        v-for="itr in iterableDisplay"
        v-bind:key="itr.idx"
        :name="itr.idx"
        :style="{ padding: contentPadding }"
        style="background: inherit;"
      >
        <template slot="title">
          <div style="font-size: 18px; font-weight: 700;">
            <slot
              name="title"
              :row="itr.v"
              :index="itr.idx"
              :implicitAddItem="implicitAddItem"
            ></slot>
          </div>
        </template>
        <div>
          <slot name="body" :row="itr.v" :index="itr.idx"></slot>
        </div>
        <el-row v-if="!immutable" type="flex" justify="end">
          <div style="padding-top: 16px;">
            <el-link :underline="false" @click="removeItem(itr.idx)">
              <span>
                <slot name="deleteButton" :row="itr.v" :index="itr.idx">
                  <DynamicCollapseDeleteButton
                    v-if="removeValueLabel === 'X'"
                  />
                  <span v-else>{{ removeValueLabel }}</span>
                </slot>
              </span>
            </el-link>
          </div>
        </el-row>
      </el-collapse-item>
    </el-collapse>
    <div v-if="!immutable && explicitAddItem" style="padding-top: 24px;">
      <el-button
        type="primary"
        @click="addItem"
        v-if="!hasMaxItem"
        icon="el-icon-plus"
        >{{ addValueLabel }}</el-button
      >
      <el-button type="primary" disabled v-else icon="el-icon-plus">{{
        addValueLabel
      }}</el-button>
    </div>
  </div>
</template>

<script>
import DynamicRowInput from "./mixins/DynamicRowInput";
import DynamicCollapseDeleteButton from "./DynamicCollapseDeleteButton";

export default {
  name: "DynamicCollapse",
  mixins: [DynamicRowInput],
  components: {
    DynamicCollapseDeleteButton
  }
};
</script>

<style></style>
