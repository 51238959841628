/* eslint-disable no-undef */
export default {
  genericNode() {
    OrgChart.templates.generic = Object.assign({}, OrgChart.templates.rony);
    OrgChart.templates.generic.size = [255, 68];
    OrgChart.templates.generic.node =
      '<svg width="255" height="125" viewBox="0 -2 255 125" fill="transparent" xmlns="http://www.w3.org/2000/svg" style="cursor: pointer">\n' +
      '<filter id="dropshadow">\n' +
      '<feFlood flood-opacity="0" result="BackgroundImageFix"/>' +
      '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>' +
      '<feOffset dy="2"/>' +
      '<feGaussianBlur stdDeviation="1"/>' +
      '<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"/>' +
      '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>' +
      '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>' +
      '<feOffset dy="5.61737"/>' +
      '<feGaussianBlur stdDeviation="2.66004"/>' +
      '<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0282725 0"/>' +
      '<feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>' +
      '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>' +
      '<feOffset dy="13.3392"/>' +
      '<feGaussianBlur stdDeviation="5.00862"/>' +
      '<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.035 0"/>' +
      '<feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow"/>' +
      '<feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape"/>' +
      // '  <feGaussianBlur in="SourceAlpha" stdDeviation="4"/> <!-- stdDeviation is how much to blur -->\n' +
      // '  <feOffset dx="0" dy="2" result="offsetblur"/> <!-- how much to offset -->\n' +
      // "  <feComponentTransfer>\n" +
      // '    <feFuncA type="linear" slope="0.15"/> <!-- slope is the opacity of the shadow -->\n' +
      // "  </feComponentTransfer>\n" +
      // "  <feMerge> \n" +
      // "    <feMergeNode/> <!-- this contains the offset blurred image -->\n" +
      // '    <feMergeNode in="SourceGraphic"/> <!-- this contains the element that the filter is applied to -->\n' +
      // "  </feMerge>\n" +
      "</filter>" +
      '<filter id="dropshadowdark" height="130%">\n' +
      '  <feGaussianBlur in="SourceAlpha" stdDeviation="6"/> <!-- stdDeviation is how much to blur -->\n' +
      '  <feOffset dx="0" dy="3" result="offsetblur"/> <!-- how much to offset -->\n' +
      "  <feComponentTransfer>\n" +
      '    <feFuncA type="linear" slope="0.3"/> <!-- slope is the opacity of the shadow -->\n' +
      "  </feComponentTransfer>\n" +
      "  <feMerge> \n" +
      "    <feMergeNode/> <!-- this contains the offset blurred image -->\n" +
      '    <feMergeNode in="SourceGraphic"/> <!-- this contains the element that the filter is applied to -->\n' +
      "  </feMerge>\n" +
      "</filter>" +
      '<rect  width="241" height="63" rx="20" x=6 y=3 fill="white" filter="url(#dropshadow)" class="nodeRect"/>\n' +
      '<rect  width="221" height="40" rx="5" x=15 y=80 fill="transparent" class="drop-box" stroke="grey" stroke-width="2" stroke-dasharray="10 3" style="display: none"/>\n' +
      "</svg>";

    OrgChart.templates.generic.ripple = {
      radius: 10,
      color: "#ffffff00",
      rect: null
    };

    OrgChart.templates.generic.field_1 =
      '<text width="145" text-overflow="ellipsis" style="font-size: 14px;cursor: pointer" fill="#454545" x="65" y="47" class="nodeName">{val}</text>';

    OrgChart.templates.generic.field_0 =
      '<text width="145" text-overflow="ellipsis" style="font-size: 10px; font-weight: 700; cursor: pointer" x="65" y="27" class="nodeType">{val}</text>';

    OrgChart.templates.generic.edge =
      '<path  stroke="#686868" stroke-width="1px" fill="none" edge-id="[{id}][{child-id}]" d="M{xa},{ya} C{xb},{yb} {xc},{yc} {xd},{yd}"/>';

    OrgChart.templates.generic.plus = "";
    // '<rect x="13" y="9" width="10" height="10" rx="5" ry="5" fill="#D0EAFB" stroke="#D0EAFB" stroke-width="1"></rect>' +
    // '<line x1="15" y1="10" x2="21" y2="10" stroke-width="1" stroke="#4DB3F6"></line>' +
    // '<line x1="18" y1="7" x2="18" y2="17" stroke-width="1" stroke="#4DB3F6"></line>';

    OrgChart.templates.generic.minus = "";
    // '<rect x="13" y="9" width="10" height="10" rx="5" ry="5" fill="#D0EAFB" stroke="#D0EAFB" stroke-width="1"></rect>' +
    // '<line x1="15" y1="14" x2="21" y2="14" stroke-width="1" stroke="#4DB3F6"></line>';

    OrgChart.templates.generic.expandCollapseSize = 36;

    OrgChart.templates.generic.pointer =
      '<g data-pointer="pointer" transform="matrix(0,0,0,0,100,100)" style="cursor: grabbing"><g transform="matrix(0.3,0,0,0.3,-17,-17)"><polygon fill="transparent" points="53.004,173.004 53.004,66.996 0,120"/><polygon fill="transparent" points="186.996,66.996 186.996,173.004 240,120"/><polygon fill="transparent" points="66.996,53.004 173.004,53.004 120,0"/><polygon fill="transparent" points="120,240 173.004,186.996 66.996,186.996"/><circle fill="transparent" cx="120" cy="120" r="30"/></g></g>';

    OrgChart.templates.generic.nodeMenuButton =
      '<g style="cursor:pointer;" transform="matrix(1,0,0,1,236,30)" control-node-menu-id="{id}" class="ellipses"><rect fill="#000000" fill-opacity="0" width="30" height="63" x="-13" y="-22"></rect><circle cx="0" cy="0" r="2" fill="#A0A8B5" class="menuButton"></circle><circle cx="0" cy="7" r="2" fill="#A0A8B5" class="menuButton"></circle><circle cx="0" cy="14" r="2" fill="#A0A8B5" class="menuButton"></circle></g>';

    OrgChart.templates.generic.exportMenuButton =
      '<div style="position:absolute;right:{p}px;top:{p}px; width:40px;height:50px;cursor:pointer;" control-export-menu=""><hr style="background-color: #0890D3; height: 3px; border: none;"><hr style="background-color: #0890D3; height: 3px; border: none;"><hr style="background-color: #0890D3; height: 3px; border: none;"></div>';

    OrgChart.templates.generic.img_0 =
      '<image preserveAspectRatio="xMidYMid slice" clip-path="url(#ulaImg)" xlink:href="{val}" x="20" y="22" width="30" height="30" class="nodeImage" style="cursor: pointer"></image>';

    OrgChart.templates.generic.img_1 =
      '<image preserveAspectRatio="xMidYMid slice" clip-path="url(#ulaImg)" xlink:href="{val}" x="200" y="16" width="15" height="15" copy-node-url-id="{id}" class="copyImage" style="cursor: pointer" display="none"></image>';

    OrgChart.templates.generic.link =
      '<path stroke="#686868" stroke-width="2px" fill="none" link-id="[{id}][{child-id}]" d="M{xa},{ya} {xb},{yb} L{xc},{yc} {xd},{yd}" />';

    OrgChart.toolbarUI.fitIcon = "";
    OrgChart.toolbarUI.zoomInIcon = "";
    OrgChart.toolbarUI.zoomOutIcon = "";
  }
};
