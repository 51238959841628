<template>
  <el-form>
    <el-form-item>
      <el-row type="flex" style="padding: 0 10px;">
        <el-col :offset="8" :span="16" style="padding-right: 10px">
          <!-- eslint-disable-next-line -->
          {{ __("checking the box will securely attach the credentials to the Authentication") }}
        </el-col>
      </el-row>
    </el-form-item>

    <el-row v-for="profile in authProfiles" :key="profile.type">
      <el-col :span="24" v-if="profile.profiles.length">
        <div>{{ profile.type }}</div>
        <el-form-item
          class="auth-profile-item"
          :key="authProfile.auth_profile_id"
          v-for="authProfile in profile.profiles"
        >
          <el-row style="padding: 0 10px" type="flex">
            <el-col :span="8">{{ authProfile.auth_profile_name }}</el-col>
            <el-col :span="7">
              <el-checkbox
                v-model="config[authProfile.auth_profile_id]"
              ></el-checkbox>
              <span
                @click="handleClick(authProfile.auth_profile_id)"
                class="pointer"
                style="padding-left: 10px;"
              >
                {{ __("Export with credentials data") }}
              </span>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import BaseExportConfig from "./BaseExportConfig";
import _ from "lodash";

export default {
  mixins: [BaseExportConfig],
  data() {
    return {
      auth_types: [
        {
          value: "basic",
          label: __("Basic Profiles")
        },
        {
          value: "oauth2",
          label: __("OAuth2 Profiles")
        },
        {
          value: "cert",
          label: __("Certificate Profiles")
        },
        {
          value: "api_key",
          label: __("API Key Profiles")
        }
      ]
    };
  },
  computed: {
    authProfiles() {
      return _.map(this.auth_types, type => {
        return {
          type: type.label,
          profiles: this.contents.filter(content =>
            content.auth_profile_type.toLowerCase().includes(type.value)
          )
        };
      });
    }
  },
  methods: {
    handleClick(key) {
      this.config[key] = !this.config[key];
    }
  }
};
</script>

<style scoped lang="scss">
.auth-profile-item {
  .pointer {
    cursor: pointer;
  }
}
</style>
