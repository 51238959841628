import Quill from "quill";

var ListItem = Quill.import("formats/list");

class CustomListAlignStyle extends ListItem {
  static create(value) {
    let node = super.create(value);
    node.setAttribute("style", "list-style-position:inside;");
    return node;
  }
}

export { CustomListAlignStyle };
