<style lang="scss" scoped>
@import "~@/styles/At.scss";
.groupTitle {
  font-size: 0.7em;
  text-transform: uppercase;
  padding: 12px 0 12px 6px;
  font-weight: bold;
  line-height: 10px !important;
}

/*::v-deep .atwho-wrap {*/
/*  position: relative;*/
/*}*/

/*.style2 {*/
/*  position: absolute !important;*/
/*  !*top: 175px !important;*!*/
/*}*/
</style>

<template>
  <div
    ref="wrap"
    :class="`atwho-wrap ${popperClass}`"
    @compositionstart="handleCompositionStart"
    @compositionend="handleCompositionEnd"
    @input="handleInput()"
    @keydown.capture="handleKeyDown"
    v-on-clickaway="closePanel"
  >
    <div v-if="atwho" class="atwho-panel" :style="style">
      <div class="atwho-inner">
        <el-scrollbar class="atwho-view" :native="false">
          <div class="atwho-list">
            <div v-for="(values, group) in atwho.list" :key="group">
              <div class="groupTitle">{{ group }}</div>
              <ul class="atwho-ul">
                <li
                  v-for="(item, index) in values"
                  class="atwho-li"
                  :key="`${group}_${index}`"
                  :class="isCur(`${group}_${index}`) && 'atwho-cur'"
                  :ref="isCur(`${group}_${index}`) && 'cur'"
                  :data-index="`${group}_${index}`"
                  @mouseenter="handleItemHover"
                  @click="handleItemClick"
                >
                  <slot name="item" :item="item">
                    <span v-text="itemName(item)"></span>
                  </slot>
                </li>
              </ul>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <span v-show="false" ref="embeddedItem">
      <slot name="embeddedItem" :current="currentItem"></slot>
    </span>
    <slot></slot>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
export default {
  mixins: [clickaway]
};
</script>
