<template>
  <el-dialog
    :visible.sync="dialogVisible"
    v-if="dialogVisible"
    @close="handleDialogClose"
    :close-on-click-modal="isInListMode"
    :close-on-press-escape="isInListMode"
    show-close
    custom-class="globalContentItemModal"
    top="4vh"
    width="90%"
    append-to-body
  >
    <el-row>
      <el-col :span="6"><folders-sidebar></folders-sidebar></el-col>
      <el-col :span="18">
        <files-index
          @add-attachment="handleAddAttachment"
          can-attach-as-file
          :can-multi-select="canMultiSelect"
          :show-only="showOnly"
        ></files-index>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import { EventBus } from "@/EventBus";
import FoldersSidebar from "@/views/build/content/folders/FoldersSidebar";
import FilesIndex from "@/views/build/content/files/index";
import { mapActions } from "vuex";

export default {
  name: "FileExplorer",
  components: {
    FoldersSidebar,
    FilesIndex
  },
  props: {
    canMultiSelect: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialogVisible: false,
      mode: "list",
      itemToEdit: {},
      showOnly: "all"
    };
  },
  created() {
    EventBus.$on("open-node-attachment-modal", (source, type) => {
      this.showOnly = type;
      this.setGlobalContentModalIsOpen(true);
      this.dialogVisible = true;
    });
    EventBus.$on("close-node-attachment-modal", () => {
      this.setGlobalContentModalIsOpen(false);
      this.dialogVisible = false;
    });
  },
  computed: {
    isInListMode() {
      return this.mode === "list";
    }
  },
  methods: {
    ...mapActions("app", {
      setGlobalContentModalIsOpen: "setGlobalContentModalIsOpen"
    }),

    handleAddAttachment(file_url, file_id, file_name) {
      this.$emit("add-attachment", file_url, file_id, file_name);
      this.setGlobalContentModalIsOpen(false);
      this.dialogVisible = false;
    },

    changeMode() {
      this.itemToEdit = {};
      this.mode = "edit";
    },

    handleEdit(item) {
      this.itemToEdit = item;
      this.mode = "edit";
    },

    handleDialogClose() {
      this.setGlobalContentModalIsOpen(false);
    }
  },
  mounted() {
    this.contentType = "File";
    this.primaryKey = "file_id";
  }
};
</script>

<style lang="scss" scoped>
.btnAdd {
  margin-top: 30px;
}

.dialog-container {
  padding: 30px;
}
</style>

<style lang="scss">
.globalContentItemModal {
  padding: 0;
  border-radius: 0;
  .contentCardsContainer {
    max-height: calc(100vh - 400px) !important;
  }
  .el-dialog__header {
    padding: 0;
    .el-dialog__headerbtn {
      z-index: 100;
    }
  }

  .el-dialog__body {
    padding: 0 !important;
    //height: 90vh;
    /*overflow: hidden;*/
  }

  .el-dialog__footer {
    padding: 0;
  }
}
</style>
