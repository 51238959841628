<template>
  <div :class="classAttachments">
    <span>
      {{ displayText }}
      <span v-if="displayEllipses">
        <el-button type="text" @click="dialogTableVisible = true">
          . . .{{ __("More") }}</el-button
        >
      </span>
    </span>

    <div v-if="displayEllipses">
      <el-dialog :visible.sync="dialogTableVisible" :append-to-body="true">
        <vue-json-pretty
          v-if="isOutputJson"
          style="margin: 10px; font-size: 0.8em"
          :data="previewResult"
          :deep="10"
          :highlightMouseoverNode="true"
          :highlightSelectedNode="true"
        >
        </vue-json-pretty>
        <span v-else>{{ displayTextToString }}</span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
/**
 * The maximum character for expression preview output
 */
const MAX_PREVIEW_LENGTH = 50;

import VueJsonPretty from "vue-json-pretty";

export default {
  name: "ExpressionBuilderPreviewOutput",
  components: {
    VueJsonPretty
  },
  props: {
    previewResult: null,
    classAttachments: null
  },
  data: () => {
    return {
      dialogTableVisible: false
    };
  },
  computed: {
    isOutputJson: function() {
      return (
        typeof this.previewResult === "object" ||
        Array.isArray(this.previewResult)
      );
    },
    displayTextToString: function() {
      let displayString;

      if (this.previewResult === null || this.previewResult === undefined) {
        return "";
      }

      // Convert preview result to string
      if (this.isOutputJson) {
        displayString = JSON.stringify(this.previewResult);
      } else {
        displayString = String(this.previewResult);
      }

      return displayString;
    },
    displayText: function() {
      return this.displayTextToString.substring(0, MAX_PREVIEW_LENGTH);
    },
    displayEllipses: function() {
      return this.displayTextToString.length > MAX_PREVIEW_LENGTH;
    }
  }
};
</script>

<style scoped></style>
