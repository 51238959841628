<script>
import SortableElTable from "element-ui-el-table-draggable";
import sortable from "sortablejs";

export default {
  mixins: [SortableElTable],
  props: {
    draggableTableId: {
      required: false,
      type: String,
      default: ""
    }
  },
  methods: {
    makeTableSortAble() {
      let querySelector = ".el-table__body-wrapper tbody";
      if (this.draggableTableId !== "") {
        querySelector = `#${this.draggableTableId} > ${querySelector}`;
      }
      const table = this.$children[0].$el.querySelector(querySelector);
      sortable.create(table, {
        handle: this.handle,
        animation: this.animate,
        onStart: () => {
          this.$emit("drag");
        },
        onEnd: ({ newIndex, oldIndex }) => {
          this.keepWrapperHeight(true);
          this.tableKey = Math.random();
          const arr = this.$children[0].data;
          const targetRow = arr.splice(oldIndex, 1)[0];
          arr.splice(newIndex, 0, targetRow);
          this.$emit("drop", { targetObject: targetRow, list: arr });
        }
      });
    }
  }
};
</script>

<style scoped></style>
