export const MESSAGE_FORMAT_PLAIN_TEXT = "plainText";
export const MESSAGE_FORMAT_CARD = "card";
export const MESSAGE_FORMAT_RICH_TEXT = "richText";

export const MESSAGE_FORMAT_LABELS = {
  plainText: "Plain Text",
  card: "Card",
  richText: "Rich Text"
};

/** Pre-'card and carousel' feature default message format */
export const DEFAULT_MESSAGE_FORMAT = MESSAGE_FORMAT_PLAIN_TEXT;
