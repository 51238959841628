<template>
  <div class="msg-counter">
    <div class="content">
      <span class="label">{{ __("SMS") }}:</span>
      <span class="value">{{ smsCount }}</span>
    </div>
    <div class="content">
      <span class="label">{{ __("Characters Left") }}:</span>
      <span class="value">{{ charsLeft }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      type: String
    },
    truncateSMS: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      maxSMSNum: 10,
      lengths: {
        ascii: [160, 306, 459, 612, 765, 918, 1071, 1224, 1377, 1530],
        unicode: [70, 134, 201, 268, 335, 402, 469, 536, 603, 670]
      },
      smsCount: -1,
      charsLeft: 0
    };
  },
  methods: {
    computeSMSCountAndCharsLeft(sms) {
      let smsType,
        smsLength = 0,
        isUnicode = false,
        cutStrLength = 0,
        redundantCharCount = 0;
      this.smsCount = -1;
      this.charsLeft = 0;

      for (let charPos = 0; charPos < sms.length; charPos++) {
        switch (sms[charPos]) {
          case "\n":
          case "[":
          case "]":
          case "\\":
          case "^":
          case "{":
          case "}":
          case "|":
          case "€":
            smsLength += 2;
            // this is required as the chars above has actually 1 length
            // so, if this removed, charsLeft will show as 0, but we can
            // actually write more as substring function to truncate the
            // sms will only count actual length
            redundantCharCount += 1;
            break;

          default:
            smsLength += 1;
        }

        isUnicode = sms.charCodeAt(charPos) > 127 && sms[charPos] !== "€";
      }

      smsType = isUnicode ? this.lengths.unicode : this.lengths.ascii;

      for (let sCount = 0; sCount < this.maxSMSNum; sCount++) {
        cutStrLength = smsType[sCount];
        if (smsLength <= smsType[sCount]) {
          this.smsCount = sCount + 1;
          this.charsLeft = smsType[sCount] - smsLength;
          break;
        }
      }

      if (this.truncateSMS) {
        sms = sms.substring(0, cutStrLength - redundantCharCount);
        this.$emit("input", sms);
      }

      this.smsCount === -1 &&
        ((this.smsCount = this.maxSMSNum), (this.charsLeft = 0));
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: "computeSMSCountAndCharsLeft"
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";

.msg-counter {
  display: flex;
  flex-direction: row;
  margin: 10px 0 0 0;
  justify-content: flex-end;
  font-size: 0.75rem;
  color: $--color-text-secondary;
}

.content {
  padding: 2px 10px 0px 10px;
}

.value {
  padding: 0px 2px;
}
</style>
