import jsonSchemaGenerator from "json-schema-generator";
import _ from "lodash";

const initialValues = {
  string: "",
  object: {},
  array: [],
  boolean: false,
  number: 0
};

function parseJson(json) {
  return _.isObject(json) ? _.cloneDeep(json) : JSON.parse(json);
}

function generateSampleJSON(schema, parent = {}) {
  if (_.isEmpty(schema.children)) {
    let json;
    if (_.isEmpty(parent) && !json) {
      json = {};
    } else {
      if (!json) {
        if (parent.type === "object") {
          json = {};
        } else {
          json = [];
        }
      }
    }
    if (schema.isPlainArr) {
      return json;
    } else {
      json[schema.label] = _.cloneDeep(initialValues[schema.type]);
    }
    return json;
  } else {
    let json = {};
    let mapper;
    if (schema.type === "object") {
      json[schema.label] = {};
    } else {
      json[schema.label] = [];
      mapper = {};
    }
    _.map(schema.children, child => {
      const generatedJSON = generateSampleJSON(child, schema);
      if (child.isPlainArr) {
        if (schema.hasPlainArrChild) {
          if (!_.isArray(mapper)) {
            mapper = [];
          }
          mapper.push(generatedJSON);
        } else {
          mapper = _.merge(mapper, generatedJSON);
        }
      } else {
        mapper = _.merge(mapper, generatedJSON);
      }
    });

    if (schema.hasPlainArrChild && schema.isPlainArr) {
      json = _.cloneDeep(mapper);
    } else if (schema.hasPlainArrChild) {
      json[schema.label] = _.cloneDeep(mapper);
    } else if (_.isArray(json[schema.label])) {
      json[schema.label].push(_.cloneDeep(mapper));
    } else {
      json[schema.label] = _.cloneDeep(mapper);
    }

    if (!_.isEmpty(parent)) {
      return json;
    } else {
      return _.get(json, "root", {});
    }
  }
}

function generateTree(
  node,
  schema = { label: "root", id: "root", type: node.type, children: [] }
) {
  if (node.type === "array") {
    generateTree(node.items, schema);
  } else {
    let childContainsPlainArr = false;
    _.map(node.properties, (property, name) => {
      let newId = schema.id + "_" + name;
      let newChild = {
        id: newId,
        label: name,
        type: property.type,
        children: [],
        isPlainArr: property.type === "array" && _.isInteger(+name)
      };
      if (!childContainsPlainArr) {
        childContainsPlainArr = newChild.isPlainArr;
      }
      schema.children.push(newChild);
      schema.hasPlainArrChild = childContainsPlainArr;
      generateTree(property, newChild);
    });
  }

  return _.cloneDeep([schema]);
}

function SchemaGenerator(json) {
  this.json = parseJson(json || {});
  this.schema = jsonSchemaGenerator(this.json);
  this.tree = generateTree(this.schema);
  this.sampleJSON = generateSampleJSON(this.tree[0]);
}

export default SchemaGenerator;
