<template>
  <div class="messagingNodeAttachmentTable">
    <el-table-draggable
      @drag="onDragStart"
      @drop="onDragEnd"
      handle=".action-icon"
      class="draggable"
    >
      <el-table
        class="variable-rules"
        fit
        :data="messageAttachments"
        style="width: 100%;"
        row-key="keyword"
        :row-class-name="generateUniqueRowClass"
      >
        <el-table-column
          prop="attachment_type"
          :label="__('Attachment type')"
          min-width="140px"
        >
          <template slot-scope="scope">
            <img
              alt="icon"
              class="action-icon"
              :src="require('@/assets/icons/icon-drag-handle.svg')"
            />
            <el-row type="flex">
              <el-col :class="classes(scope.row, 'attachment_type')">
                <el-form-item>
                  <el-select
                    v-model="scope.row.attachment_type"
                    :placeholder="__('Select type')"
                    default-first-option
                    style="width: 120px; padding-top: 12px;"
                    @change="updateSelectedLabel(scope.row)"
                  >
                    <el-option
                      v-for="item in mediaOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column
          prop="file_url"
          :label="__('File url/name')"
          min-width="320px"
        >
          <template slot-scope="scope">
            <el-row type="flex">
              <el-col :class="classes(scope.row, 'file_url')">
                <el-form-item>
                  <input-variable-popper
                    v-model="scope.row.file_name"
                    :force-reinitialize="true"
                    :is-text-area="false"
                    :include-secure-variables="false"
                    style="width: 300px; padding-top: 12px;"
                    :readonly-text="readonlyText(scope.row)"
                    :placeholder="__('File url')"
                    @input="updateFileUrl(scope.row)"
                    include-audio-variables
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column min-width="200px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-paperclip"
              @click="addAttachment(scope.row)"
              style="width: 80px;"
            />
            <el-button
              type="default"
              icon="el-icon-view"
              @click="showPreview(scope.row)"
              style="width: 80px;"
              v-show="false"
            />
          </template>
        </el-table-column>
        <el-table-column class-name="cell-item-pointer" width="40px">
          <template slot-scope="scope">
            <span
              v-show="readonlyText(scope.row)"
              @click="clearMessageAttachment(scope.$index)"
            >
              <i class="el-icon-refresh-right"></i>
            </span>
          </template>
        </el-table-column>
        <el-table-column class-name="cell-item-pointer" width="40px">
          <template slot-scope="scope">
            <span @click="removeMessageAttachment(scope.$index)">
              <i class="el-icon-circle-close"></i>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-table-draggable>
    <file-explorer
      @add-attachment="handleAddAttachment"
      :can-multi-select="false"
    />
    <file-preview />
  </div>
</template>

<script>
import FileExplorer from "@/views/build/callflow/components/node-type-forms/components/FileExplorer";
import FilePreview from "@/views/build/callflow/components/node-type-forms/components/FilePreview";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import VariablePopperMixin from "@/views/build/callflow/components/node-type-forms/components/VariablePopperMixin";
import _ from "lodash";
import { EventBus } from "@/EventBus";
import { mapState, mapGetters } from "vuex";
// import draggable from "vuedraggable";
import ElTableDraggable from "element-ui-el-table-draggable";

export default {
  name: "MessagingNodeAttachment",
  mixins: [VariablePopperMixin],
  components: {
    // draggable,
    ElTableDraggable,
    FileExplorer,
    FilePreview,
    InputVariablePopper
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    filterDefaultMediaOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const message_attachment = {
      attachment_type: "",
      file_url: "",
      file_name: "",
      file_id: null,
      validate_audio: true
    };
    return {
      containsAudioVariable: false,
      message_attachment: message_attachment,
      selected_row: null,
      $message_attachments: [{ ...message_attachment }],
      default_media_options: [
        {
          value: "image",
          label: __("Image")
        },
        {
          value: "video",
          label: __("Video"),
          disabled: true
        },
        {
          value: "audio",
          label: __("Audio")
        },
        {
          value: "document",
          label: __("Document")
        },
        {
          value: "sticker",
          label: __("Sticker")
        }
      ]
    };
  },
  computed: {
    ...mapState("canvas", {
      isNodeSubmit: state => state.isNodeSubmit
    }),

    ...mapGetters("variables", {
      audioVariables: "audioVariables"
    }),

    /**
     * based on the filter media options passed, add media options to the select
     * @returns {[{label: string, value: string},{disabled: boolean, label: string, value: string},{label: string, value: string},{label: string, value: string},{label: string, value: string}]|({label: string, value: string}|{disabled: boolean, label: string, value: string}|{label: string, value: string}|{label: string, value: string}|{label: string, value: string})[]}
     */
    mediaOptions() {
      if (this.filterDefaultMediaOptions.length > 0) {
        return _.filter(this.default_media_options, mediaOption =>
          this.filterDefaultMediaOptions.includes(mediaOption.value)
        );
      }
      return this.default_media_options;
    },
    classes() {
      return (row, key) => {
        if (!row.attachment_type && !row.file_url) {
          return "empty-row empty-val";
        }
        if (!row[key]) {
          return "empty-val";
        } else {
          return "non-empty-val";
        }
      };
    },
    messageAttachments() {
      return this.$data.$message_attachments;
    },
    readonlyText() {
      return row => {
        return !!row.file_id;
      };
    }
  },

  methods: {
    generateUniqueRowClass({ rowIndex }) {
      return "row row-" + rowIndex;
    },

    onDragStart() {
      let selector = ".el-table__body-wrapper tbody .row";
      const rows = document.querySelectorAll(selector);

      for (let i = 0; i < rows.length; i++) {
        let row = rows[i];
        row.classList.add("grabbing");
      }
    },

    onDragEnd() {
      let selector = ".el-table__body-wrapper tbody .row";
      const rows = document.querySelectorAll(selector);

      for (let i = 0; i < rows.length; i++) {
        let row = rows[i];
        if (row.classList.contains("grabbing")) {
          row.classList.remove("grabbing");
        }
      }
    },

    updateFileUrl(row) {
      if (!row.file_id) row.file_url = row.file_name;
      this.validateAudio(row);
    },

    addAttachment(row) {
      this.selected_row = row;
      EventBus.$emit("open-node-attachment-modal", "node-attachment");
    },

    handleAddAttachment(file_url, file_id, file_name) {
      this.selected_row.file_id = file_id;
      this.selected_row.file_url = file_url;
      this.selected_row.file_name = file_name;
    },

    showPreview(row) {
      EventBus.$emit(
        "open-file-preview-modal",
        row.file_url,
        row.attachment_type
      );
    },

    addNewMessageAttachment() {
      this.$data.$message_attachments.push({ ...this.message_attachment });
    },

    clearMessageAttachment(index) {
      this.$data.$message_attachments[index].file_id = null;
      this.$data.$message_attachments[index].file_name = "";
      this.$data.$message_attachments[index].file_url = "";
    },

    removeMessageAttachment(index) {
      this.$data.$message_attachments.splice(index, 1);
    },

    initializeMessageAttachments(messageAttachments) {
      if (!this.isNodeSubmit) {
        if (!_.isEmpty(messageAttachments) && messageAttachments.length) {
          this.$data.$message_attachments = _.cloneDeep(messageAttachments);
        }
        if (
          _.every(this.$data.$message_attachments, messageAttachment => {
            return (
              messageAttachment.file_name.length &&
              messageAttachment.attachment_type.length
            );
          })
        ) {
          this.addNewMessageAttachment();
        }
      }
    },

    validateAudio(row) {
      let contentArray = this.parseContent(
        this.parseVariablePopperContentForFrontend(row.file_name)
      );
      this.checkAudioVariable(contentArray);
      row.validate_audio = true;
      if (row.attachment_type !== "audio" && this.containsAudioVariable) {
        this.$message({
          type: "error",

          // eslint-disable-next-line
          message: __("The variable selected is invalid. The 'Audio' variable can only be chosen when the attachment type is set to 'Audio'.")
        });
        row.validate_audio = false;
      }
    },

    parseContent(content) {
      const regex = /\{\{([^}]+)\}\}/g;
      const matches = [];
      let match;
      while ((match = regex.exec(content))) {
        matches.push(match[1]);
      }
      return matches;
    },

    checkAudioVariable(contentArray) {
      this.containsAudioVariable = false;
      for (let i = 0; i < contentArray.length; i++) {
        if (
          Array.from(this.audioVariables, obj => obj.variable_name).includes(
            contentArray[i]
          )
        ) {
          this.containsAudioVariable = true;
        }
      }
    },

    updateSelectedLabel(row) {
      this.validateAudio(row);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: "initializeMessageAttachments"
    },
    messageAttachments: {
      handler: function(messageAttachments) {
        this.$nextTick(() => {
          this.$emit("input", _.cloneDeep(messageAttachments));
        });
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";
.messagingNodeAttachmentTable ::v-deep .el-table--medium td {
  padding: 0;
}

.messagingNodeAttachmentTable ::v-deep .el-table--medium th {
  padding: 0;
}

.el-table {
  .el-form-item {
    padding-bottom: 15px;
    /*padding-top: 4px;*/
  }
}

.messagingNodeAttachmentTable ::v-deep .el-form-item__error {
  padding-top: 1px;
  font-size: 10px;
}

.messagingNodeAttachmentTable {
  ::v-deep .empty-row .el-input__inner,
  ::v-deep .empty-row .el-textarea__inner,
  ::v-deep .non-empty-val .el-input__inner,
  ::v-deep .non-empty-val .el-textarea__inner {
    border-color: $--border-color-base !important;

    &:focus {
      border-color: $--color-text-regular !important;
    }
  }
}

.messagingNodeAttachmentTable ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.variable-rules ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.messagingNodeAttachmentTable ::v-deep .el-table::before {
  background-color: white !important;
}

.messagingNodeAttachmentTable ::v-deep .el-table__body-wrapper {
  margin-top: 10px;
}

.draggable .action-icon {
  cursor: grab;
}

.grabbing {
  cursor: grabbing !important;
}

.grabbing * {
  cursor: grabbing !important;
}
</style>
