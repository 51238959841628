<script>
import _ from "lodash";

export const HARD_LIMIT_MAX_ITEM = 1000;
export default {
  props: {
    value: {
      type: Array,
      required: true
    },

    /**
     * Type of the container.
     * Primary: Root-level element
     * Secondary: Child element of any other object (i.e. as a child of a collapse component)
     */
    type: {
      type: String,
      validator(value) {
        return ["primary", "secondary"].includes(value);
      },
      default: () => "primary"
    },

    /**
     * When set to true, the value passed to this component is immutable
     */
    immutable: {
      type: Boolean,
      default: true
    },

    /**
     * Label for the button that adds new entry to the list
     */
    addValueLabel: {
      type: String,
      default: __("Add Item")
    },

    /**
     * Label for the button that removes an entry from the list
     */
    removeValueLabel: {
      type: String,
      default: __("Delete Item")
    },

    /**
     * The template to use when adding a new item
     */
    valueTemplate: {
      type: Object,
      default: () => {
        return {};
      },
      required: false
    },

    /**
     * Maximum value the container is allowed to store
     */
    maxValue: {
      type: Number,
      required: false,
      default: () => 10
    },

    /**
     * Minimum value the container must store
     */
    minValue: {
      type: Number,
      required: false,
      default: () => 1
    },

    /**
     * When this value is set, dynamic row input will add new items implicitly when
     * the function evaluates to true.
     *
     * Callback function signature is: `callback(row<valueTemplate>): bool`
     * When callback returns true, a new row will be added.
     */
    implicitAddItemRowCheck: {
      type: Function,
      required: false
    }
  },

  data() {
    return {
      /**
       * List of expanded collapse
       * @see https://element.eleme.io/#/en-US/component/collapse#basic-usage
       */
      activeNames: [],

      /**
       * Reactive component to be used within this component
       */
      reactiveValue: [],

      /**
       * Template object that is guaranteed to be reactive to be moved to reactiveValue
       */
      templateValue: {}
    };
  },

  computed: {
    /**
     * Check if the user is explicitly required to click a button to add a new entry
     */
    explicitAddItem() {
      return (
        this.implicitAddItemRowCheck === undefined ||
        this.implicitAddItemRowCheck === null
      );
    },

    /**
     * Wrap the value props as an object that we can use to iterate for rendering
     * @returns {[{idx: Number, v: Object}]} idx is the index of the object, v is the raw object from value props
     */
    iterableDisplay() {
      let counter = 0;
      return this.reactiveValue.map(itr => {
        return {
          idx: counter++,
          v: itr
        };
      });
    },

    /**
     * Get the max count of entry allowed
     * @returns {Number}
     */
    getMaxItem() {
      return Math.min(this.maxValue, HARD_LIMIT_MAX_ITEM);
    },

    /**
     * Check if the container has max item inside
     * @returns {Boolean}
     */
    hasMaxItem() {
      return this.iterableDisplay.length >= this.getMaxItem;
    },

    /**
     * Check if the container has min item inside
     * @returns {Boolean}
     */
    hasMinItem() {
      return this.iterableDisplay.length >= this.minValue;
    },

    /**
     * Calculate the content padding given the component type
     * @returns {String} CSS size
     */
    contentPadding() {
      switch (this.type) {
        case "secondary":
          return "16px";

        case "primary":
        default:
          return "0px";
      }
    },

    /**
     * Type of the button that adds new content to the model (i.e. Button will be a primary button)
     * @see https://element.eleme.io/#/en-US/component/button
     */
    addContentButtonType() {
      if (this.type === "primary") {
        return "primary";
      }

      return "default";
    }
  },

  methods: {
    /**
     * Clone templateValue and add it to the container
     * @returns {void}
     */
    addItem() {
      if (!Array.isArray(this.reactiveValue)) {
        this.reactiveValue = [];
      }

      if (this.hasMaxItem) {
        return;
      }

      this.reactiveValue.push(this.templateValue);
      this.templateValue = _.cloneDeep(this.valueTemplate);
      this.$emit("input", this.reactiveValue);
    },

    /**
     * Remove an item from the container
     * @param {Integer} index Index to remove
     */
    removeItem(index) {
      if (!Array.isArray(this.reactiveValue)) {
        this.reactiveValue = [];
      }

      this.reactiveValue.splice(index, 1);
      this.$emit("input", this.reactiveValue);

      if (!this.hasMinItem) {
        this.addItem();
      }
    },

    /**
     * Implicitly add new item
     */
    implicitAddItem() {
      if (this.explicitAddItem) {
        return;
      }

      if (!Array.isArray(this.value)) {
        return;
      }

      // Handle the edge case where min value is 0 and the container is empty. In that case, we'd always inject 1 dummy content
      if (this.value.length == 0 && this.minValue == 0) {
        return this.addItem();
      }

      const lastItem = this.value[this.value.length - 1];
      if (this.implicitAddItemRowCheck(lastItem)) {
        this.addItem();
      }
    }
  },

  mounted() {
    this.templateValue = _.cloneDeep(this.valueTemplate);
    if (!this.hasMinItem) {
      this.addItem();
    }
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        // Let vue know that the content of value has changed and must be reflected in reactive value
        this.reactiveValue = this.value || [];
      }
    }
  }
};
</script>
