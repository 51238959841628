<template>
  <expression-node-group-renderless
    ref="expression-node-group-renderless"
    :node="node"
    v-slot="{
      node, // the current node (node group in this case)
      index, // the index of this node amongst its parent's children, if it is the root, returns -1
      deleteSelf, // deletes the current node group with all its children
      toggleConnectionType, // toggles between 'and' and 'or', or 'all', and 'any'
      // insert, // inserts a new node, at a given index
      addNode, // pushes a node to the end of the children list of this node group
      addGroup, // pushes a node group to the end of the children list of this node group
      conditionFactory // it stores the defined fields, fieldTypes and operators, and creates new conditions with its create method
    }"
  >
    <div class="expression-node-group">
      <header>
        <!-- The connection type property of the node group determines,
                whether all or any of their children have to eval to true, in order for the group to eval to true -->
        <div class="toggle-ct" @click="toggleConnectionType">
          <span :class="{ selected: node.connectionType === 'and' }">{{
            __("AND")
          }}</span>
          <span :class="{ selected: node.connectionType === 'or' }">{{
            __("OR")
          }}</span>
        </div>

        <div class="controls">
          <el-button
            v-if="index == -1"
            @click="$emit('previewDataStore')"
            :loading="showLoading"
            >{{ __("Preview") }}</el-button
          >

          <!-- Adds a new node group as the last child of the current node group -->
          <el-button v-if="showAddGroup" @click="addGroup()">{{
            __("Add Group")
          }}</el-button>
          <!-- Adds a new node as the last child of the current node group -->
          <el-button
            @click="addNode(conditionFactory.create(defaultCol, '=', ''))"
            >{{ __("Add Node") }}</el-button
          >

          <!-- Deletes the current node group -->
          <i
            v-if="node.parentNode"
            class="el-icon-close deleteSelf"
            @click="deleteSelf"
          ></i>
        </div>
      </header>
      <main>
        <ul>
          <li v-for="(child, index) in node.children" :key="index">
            <!-- The ExpressionNodeGroup component has to be recursive by nature -->
            <expression-node-group
              v-if="child.children"
              :node="child"
              :default-col="defaultCol"
            ></expression-node-group>
            <expression-node v-else :node="child"></expression-node>
          </li>
        </ul>
      </main>
    </div>
  </expression-node-group-renderless>
</template>

<script>
import { Core, Components } from "vue-renderless-expression-builder";
import ExpressionNode from "./QueryConditionNode";
import { mapState } from "vuex";
const { ExpressionNodeGroupRenderless } = Components;

export default {
  name: "ExpressionNodeGroup",
  props: {
    node: {
      type: Core.ExpressionNodeGroup,
      required: true
    },
    defaultCol: {
      type: String,
      required: false,
      default: "_id"
    },
    showAddGroup: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    // renderless group component
    ExpressionNodeGroupRenderless,

    // your node implementation
    ExpressionNode
  },
  computed: {
    ...mapState("datastorecollection", {
      datastorecollectionloading: state => state.loading
    }),
    ...mapState("canvas", {
      isNodeSubmit: state => state.isNodeSubmit
    }),
    showLoading() {
      return this.isNodeSubmit ? false : this.datastorecollectionloading;
    }
  }
};
</script>

<style scoped lang="scss">
.expression-node-group {
  box-sizing: border-box;
  border: 1px solid darkgray;
  border-radius: 8px;
  border-left: 2px solid darkseagreen;
  margin: 1rem 0;

  .deleteSelf {
    margin-left: 10px;
    font-size: large;
    font-weight: lighter;
    &:hover {
      font-weight: bold;
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    margin-bottom: 1rem;
    background: whitesmoke;
    padding: 1rem;
    border-bottom: 1px solid darkgray;
    border-radius: 8px 8px 0 0;

    .toggle-ct {
      span {
        background: lightgray;
      }

      span.selected {
        background: darkseagreen;
      }

      span:first-child {
        padding: 0.3rem 0.15rem 0.3rem 0.3rem;
        border-radius: 3px 0 0 3px;
      }
      span:last-child {
        padding: 0.3rem 0.3rem 0.3rem 0.15rem;
        border-radius: 0 3px 3px 0;
      }
    }

    .controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        margin-right: 1rem;
      }
    }

    svg {
      height: 1.5rem;
      width: 1.5rem;
      cursor: pointer;
    }
  }

  main {
    padding: 1rem;
  }
}
ul {
  list-style: none;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}
</style>
