<template>
  <div :id="`version-${version.version_id}`">
    <div class="version-info" v-loading="loading">
      <el-tooltip
        v-if="!editVersionName"
        effect="dark"
        :content="version.version_name"
        placement="left"
        :disabled="!hasLongVersionName"
        :ref="`version-tooltip-${version.version_id}`"
      >
        <span class="version-info">
          <span class="version-name" @click="showInput">
            {{ version.version_name }}
          </span>
        </span>
      </el-tooltip>
      <el-input
        v-else
        ref="version"
        class="version-name-input"
        @keyup.esc.native.exact.prevent.stop="revertNameChange"
        @keyup.enter.native.exact.prevent.stop="saveVersionName"
        @blur="saveVersionName"
        :value="version.version_name"
        @input="$set(version, 'version_name', $event)"
        :maxlength="maxLength"
      ></el-input>
      <transition name="slide-fade" mode="out-in">
        <div :key="createdAt(version.created_at)" class="time">
          {{ createdAt(version.created_at) }}
        </div>
      </transition>
    </div>
    <div class="version-control">
      <el-button
        v-if="currentVersionId !== version.version_id || !!taskUpdated"
        class="restore-btn"
        :loading="amIBeingRestored"
        @click="restoreMe"
        :disabled="restoring"
      >
        {{ __("Restore") }}
      </el-button>
      <span class="current-version-tag" v-else>{{
        __("Current version")
      }}</span>
    </div>
  </div>
</template>

<script>
import { convertToTimeStamp, formatTimeStampAsCalendar } from "@/utils/time";
import moment from "moment-timezone";
import { renameVersion } from "@/api/tasks";
import _ from "lodash";
import { EventBus } from "@/EventBus";

export default {
  name: "VersionComponent",
  inject: ["$restoring"],
  props: {
    version: {
      required: true,
      type: Object
    },
    currentVersionId: {
      required: true,
      validator: prop => typeof prop === "number" || prop === null
    },
    taskUpdated: {
      required: true,
      type: [Boolean, Number]
    },
    maxLength: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      now: moment().format(),
      interval: null,
      editVersionName: false,
      prevName: "",
      loading: false,
      amIBeingRestored: false
    };
  },

  methods: {
    restoreMe() {
      this.amIBeingRestored = true;
      this.$emit("restore", this.version);
    },
    revertNameChange() {
      this.editVersionName = false;
      this.$set(this.version, "version_name", this.prevName);
    },
    showInput() {
      this.prevName = this.version.version_name;
      this.editVersionName = true;
      this.$nextTick(() => {
        this.$refs.version.$refs.input.focus();
      });
    },
    saveVersionName() {
      if (this.editVersionName) {
        this.$set(
          this.version,
          "version_name",
          this.version.version_name.toString().trim()
        );
        if (this.prevName.toString().trim() !== this.version.version_name) {
          this.loading = true;
          renameVersion(this.version)
            .then(() => {
              EventBus.$emit(
                "log-user-activity",
                __("renamed version from :prevName to :versionName", {
                  prevName: this.prevName,
                  versionName: this.version.version_name
                }),
                null
              );
              this.prevName = this.version.version_name;
              this.$emit("update", _.cloneDeep(this.version));
            })
            .catch(err => {
              this.$set(this.version, "version_name", this.prevName);
              this.$message({
                type: "error",
                message: err.message || __("operation failed")
              });
            })
            .finally(() => (this.loading = false));
        }
        this.editVersionName = false;
      }
    }
  },

  mounted() {
    let self = this;
    this.interval = setInterval(function() {
      self.now = moment().format();
    }, 1000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    restoring() {
      return this.$restoring();
    },
    createdAt() {
      return createdAt => {
        if (this.now) {
          return formatTimeStampAsCalendar(convertToTimeStamp(createdAt));
        }
      };
    },
    hasLongVersionName() {
      return this.version.version_name.length > 20;
    }
  },
  watch: {
    restoring(val) {
      if (!val) {
        this.amIBeingRestored = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.version-info {
  max-width: 160px;
  display: flex;
  flex-direction: column;

  .version-name {
    cursor: pointer;
    color: #454545;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
      outline: none;
    }
  }

  .version-name-input {
    ::v-deep .el-input__inner {
      padding-left: 5px;
      height: 32px;
    }
  }

  .time {
    padding-top: 3px;
    font-size: 0.7125rem;
    color: #696969;
  }
}

.version-control {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 12px;
  align-self: stretch;

  .current-version-tag {
    color: $content-theme-color;
    align-self: baseline;
  }

  .restore-btn {
    padding: 5px 15px;
    border-color: #454545;
    color: #454545;

    &:hover,
    &:focus {
      color: var(--theme-color);
      border-color: var(--theme-tag-border-color);
      background-color: var(--theme-hover-background-color);
    }
  }
}
</style>
