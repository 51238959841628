<template>
  <div>
    <el-form-item
      v-for="setting in enabledProfileSettings"
      :key="setting.key"
      :label="__(setting.label)"
      :prop="customizePropPath(setting)"
    >
      <input-variable-popper
        v-if="setting.type === 'input'"
        v-model="inputText"
        :is-text-area="false"
        :placeholder="__('Enter your text')"
      />
      <!-- Additional settings based on service node profile -->
      <input-variable-popper
        v-if="setting.type === 'other_input'"
        :value="additionalSettings[setting.key] || ''"
        @input="setAdditionalSetting(setting.key, $event)"
        :is-text-area="false"
        :placeholder="__('Enter your text')"
      />
      <input-variable-popper
        v-if="setting.type === 'textarea'"
        :value="additionalSettings[setting.key] || ''"
        @input="setAdditionalSetting(setting.key, $event)"
        :placeholder="__('Enter your text')"
      />
      <language-dropdown
        v-if="setting.type === 'dropdown_input'"
        return_type="code"
        nlp_engine="dialogflow"
        :default_language="additionalSettings[setting.key]"
        @language-changed="handleLanguageChange"
      ></language-dropdown>
      <FileUploader
        v-if="setting.type === 'file_upload'"
        :fileUrl="keyFileUrl"
        :fileList="jsonFileList"
        fit="compact"
        :tip-text="__('cert, txt or json file with a size less than 2mb')"
        @on-success="handleSuccess"
        @on-error="handleError"
        @on-change="handleFileChange"
        @on-delete="handleFileDelete"
        encrypt-contents
        :folder="`tasks/${taskId}`"
      />
      <el-radio-group
        v-if="setting.type === 'radio'"
        :value="additionalSettings[setting.key]"
        @input="setAdditionalSetting(setting.key, $event)"
        @change="radioChangeHandler"
      >
        <el-radio
          v-for="option in setting.options"
          :key="option.value"
          :label="option.value"
        >
          {{ option.label }}
        </el-radio>
      </el-radio-group>
    </el-form-item>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import InputVariablePopper from "../components/InputVariablePopper";
import LanguageDropdown from "@/components/LanguageDropdown.vue";
import FileUploader from "@/components/uploaders/types/FileUploader";
import BaseUploader from "@/components/uploaders/BaseUploader";
import _ from "lodash";

export default {
  mixins: [BaseUploader],
  emits: ["update-file-url", "delete-file-url", "on-change"],
  components: {
    InputVariablePopper,
    LanguageDropdown,
    FileUploader
  },
  props: {
    fileUrl: {
      type: String,
      required: false,
      default: ""
    },
    selectedProfile: {
      type: Object,
      required: true
    },
    nodeToBind: {
      type: Object,
      required: true
    },
    appendToPostBody: {
      type: Function,
      required: true
    },
    taskId: {
      required: true,
      type: [Number, String]
    }
  },
  data() {
    return {
      jsonFileList: [],
      webProfiles: [],
      profileSettings: [],
      keyFileUrl: "",
      uploadedFilePath: ""
    };
  },
  mounted() {
    this.setProfileSettings();
    this.setKeyFileUrl();
  },
  computed: {
    textSelected() {
      return this.nodeToBind.web_service_node.data.input_text;
    },

    inputText: {
      get() {
        return this.nodeToBind.web_service_node.data.input_text || "";
      },

      set(val) {
        this.$set(this.nodeToBind.web_service_node.data, "input_text", val);
      }
    },

    additionalSettings() {
      return this.nodeToBind.web_service_node.data.additional_settings;
    },

    customizePropPath() {
      return setting => {
        let path;
        if (setting.type !== "input") {
          if (setting.type === "file_upload") {
            path = `web_service_node.data.additional_settings.file_details.${setting.key}`;
          } else {
            path = `web_service_node.data.additional_settings.${setting.key}`;
          }
        } else {
          path = `web_service_node.data.${setting.key}`;
        }
        return path;
      };
    },

    /**
     * Get the list of profile settings that should be enabled based on the enable_if property. If enable_if is not defined (or null),
     * the the component is considered to be enabled. enable_if must be a dictionary. Key is the data in additional_settings.
     * Return true if all of the data defined in the dictionary equals to the value in additional_settings (strict comparison).
     */
    enabledProfileSettings() {
      return this.profileSettings.filter(param => {
        // Only filter if enable_if exists
        if (param.enable_if) {
          for (const key in param.enable_if) {
            const settingValue = this.getAdditionalSettingsValue(key);

            if (param.enable_if[key] !== settingValue) {
              return false;
            }
          }
        }

        return true;
      });
    }
  },

  methods: {
    ...mapActions("upload", {
      deleteFileFromStorage: "deleteLogo"
    }),

    /**
     * Generate path in additional settings for a given key
     * @param { String } key Setting key to generate the path from
     * @returns { String } Path to the key
     */
    generatePathForAdditionalSetting(key) {
      return `web_service_node.data.additional_settings.${key}`;
    },

    /**
     * Get the value stored in additional settings
     * @param { String } key Setting key to retrieve the value from
     * @returns Whatever value is stored in the key
     */
    getAdditionalSettingsValue(key) {
      const path = this.generatePathForAdditionalSetting(key);
      return _.get(this.nodeToBind, path);
    },

    setAdditionalSetting(key, value) {
      const path = this.generatePathForAdditionalSetting(key);
      return _.set(this.nodeToBind, path, value);
    },

    /**
     * Radio group change handler
     */
    radioChangeHandler() {
      const newObj = Object.assign(
        {},
        this.nodeToBind.web_service_node.data.additional_settings
      );
      this.$set(
        this.nodeToBind.web_service_node.data,
        "additional_settings",
        newObj
      );
    },

    setProfileSettings() {
      this.profileSettings = JSON.parse(this.selectedProfile.profile_setting);

      // Load default value for additional settings if we must
      this.profileSettings.forEach(param => {
        if (param.type !== "input") {
          const path = this.generatePathForAdditionalSetting(param.key);
          const currentValue = this.getAdditionalSettingsValue(param.key);
          if (param.default !== undefined && !currentValue) {
            _.set(this.nodeToBind, path, param.default);
          }
        }
      });
    },
    setKeyFileUrl() {
      this.keyFileUrl = this.fileUrl;
    },
    async handleLanguageChange(languageObj) {
      this.$set(
        this.nodeToBind.web_service_node.data.additional_settings,
        "lang",
        languageObj
      );
    },
    handleFileDelete() {
      this.deleteUploadedFile();
    },
    deleteUploadedFile() {
      this.deleteFileFromStorage({ path: this.uploadedFilePath })
        .then(() => {
          this.jsonFileList = [];
          this.keyFileUrl = "";
          this.$emit("delete-file-url");
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleSuccess(url) {
      this.keyFileUrl = url.url;
      this.uploadedFilePath = url.path;
      this.$emit("update-file-url", this.keyFileUrl, url.path);
    },
    handleError(err) {
      console.log(err);
    },
    handleFileChange(file, fileList) {
      this.$emit("on-change", file, fileList);
    }
  },
  watch: {
    "nodeToBind.web_service_node.data.input_text": function(val) {
      this.appendToPostBody(val);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node_common.scss";
</style>
