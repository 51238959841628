<template>
  <el-table :data="returnValueData" style="width: 100%">
    <el-table-column prop="rule_value" :label="__('Value')">
      <template slot-scope="scope">
        {{ getLabel(scope.row.rule_value) }}
      </template>
    </el-table-column>
    <el-table-column
      prop="variable_id"
      :label="__('Assign the value to variable')"
    >
      <template slot-scope="scope">
        <create-or-select
          :items="singleValuedAndSecureVariables"
          label="variable_name"
          value="variable_id"
          :current_select="currentSelection(scope.row)"
          :placeholder="__('Variable Name')"
          :new-item-message="__('new variable')"
          @change="handleChange($event)(scope.row)"
        />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapGetters } from "vuex";
import CreateOrSelect from "@/views/build/callflow/components/node-type-forms/components/CreateOrSelect";
import _ from "lodash";

export default {
  components: {
    CreateOrSelect
  },

  props: {
    returnValue: Array,
    labels: {
      required: false,
      default: null,
      type: Object
    }
  },

  data() {
    return {
      returnValueData: [...this.returnValue]
    };
  },

  computed: {
    currentSelection() {
      return row => {
        const { variable_id, variable_name } = row;
        return variable_id === -1 ? variable_name : variable_id;
      };
    },

    ...mapGetters("variables", {
      singleValuedAndSecureVariables: "singleValuedAndSecureVariables"
    })
  },

  methods: {
    handleChange(option) {
      return row => {
        this.$nextTick(() => {
          this.$set(row, "variable_name", option.label);
          this.$set(row, "variable_id", option.value);
        });
      };
    },

    getLabel(label) {
      if (
        this.labels &&
        Object.prototype.hasOwnProperty.call(this.labels, label)
      ) {
        return this.labels[label];
      }
      return _.startCase(label);
    }
  },

  watch: {
    returnValueData: {
      deep: true,
      handler(val) {
        this.$emit("update-return-value", val);
      }
    }
  }
};
</script>
