import tags from "@/utils/nodeMenus/menus";
import { NODE_CATEGORIES, NODE_TYPES } from "@/constants/nodes";
import store from "@/store";
import router from "@/router";

export const editForm = function() {
  this.nodeId = null;
};
editForm.prototype.init = function(chart) {
  this.chart = chart;
};
editForm.prototype.show = function(nodeId) {
  // const node = this.chart.get(nodeId);
  if (nodeId && nodeId !== "new") {
    const node = store.getters["canvas/renderedNode"](nodeId);
    const { category, node_type } = node.node_type.data;
    const isNonInteractiveNode =
      ([NODE_CATEGORIES.SYSTEM].includes(category) &&
        NODE_TYPES.START.NODE_TYPE !== node_type) ||
      [NODE_TYPES.CONDITION_LABEL.NODE_TYPE].includes(node_type);
    store.dispatch("canvas/changeCanvasNodeMode", !isNonInteractiveNode);

    if (!isNonInteractiveNode) {
      store.dispatch("canvas/setClickedNode", nodeId);
      router
        .push({
          name: "edit_node",
          params: { task_id: node.task_id, open_node_id: node.node_id }
        })
        .catch(() => {});
    }
  }
};
editForm.prototype.hide = function() {
  store.dispatch("canvas/changeCanvasNodeMode", false);
  store.dispatch("canvas/setClickedNode", null);
};

export default {
  levelSeparation: 70,
  enableDragDrop: true,
  enableSearch: false,
  template: "generic",
  // eslint-disable-next-line no-undef
  nodeMouseClick: OrgChart.action.none,
  // eslint-disable-next-line no-undef
  nodeMouseDbClick: OrgChart.action.edit,
  scaleMin: 0.2,
  scaleMax: 1.5,
  zoom: {
    speed: 70,
    smooth: 6
  },
  sticky: false,
  editUI: new editForm(),
  nodeBinding: {
    field_0: "title",
    field_1: "display_name",
    img_0: "img",
    img_1: "img_copy"
  },
  toolbar: {
    zoom: true,
    fit: true,
    expandAll: false
  },
  tags,
  nodeMenu: {
    details: { text: "Details" },
    edit: { text: "Edit" },
    add: { text: "Add" },
    remove: { text: "Remove" }
  },
  // eslint-disable-next-line no-undef
  orientation: OrgChart.orientation.top,
  onExportStart: function(sender, options, png) {
    console.log(sender, options, png);
    return false;
  }
};
