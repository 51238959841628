<template>
  <div style="display: none;">
    <!-- Theres currently no special config for text type -->
    <!-- File is added for consistency purpose -->
  </div>
</template>

<script>
import { CarouselComponent } from "./carouselComponent";

export default {
  name: "textCardConfig",
  mixins: [CarouselComponent]
};
</script>

<style></style>
