<template>
  <div class="otherwise-node">
    <el-row type="flex" :gutter="20">
      <el-col>
        <create-or-select
          :items="extractGotoNodeOptions"
          :current_select="currentSelection"
          :new-item-message="__('new node')"
          :fail-to-create-message="otherwiseGotoNode.error"
          :placeholder="__('Node Name')"
          @change="handleChange"
        />
      </el-col>
      <el-col class="otherwise-node-msg" :span="6">
        {{ otherwiseGotoNode.msg }}
      </el-col>
    </el-row>
  </div>
</template>

<script>
import _ from "lodash";
import CreateOrSelect from "./CreateOrSelect";

export default {
  components: {
    CreateOrSelect
  },
  props: {
    value: {
      required: true,
      type: Object
    },
    gotoOptions: {
      required: true,
      type: Array
    },
    fromNodeType: {
      required: false,
      type: String
    }
  },
  data() {
    let otherwiseCondition = {
      keyword: "Otherwise",
      node_name: "",
      node_id: -1,
      msg: "",
      error: "",
      keyword_error: ""
    };
    return {
      $otherwiseCondition: otherwiseCondition,
      $gotoOptions: []
    };
  },
  computed: {
    extractGotoNodeOptions() {
      const options = _.map(this.gotoOptions, child => ({
        label: child.node_name,
        value: child.node_id
      }));
      return this.$data.$gotoOptions.concat(options);
    },

    otherwiseGotoNode() {
      return this.$data.$otherwiseCondition;
    },

    currentSelection() {
      const { node_id, node_name } = this.otherwiseGotoNode;
      return node_id === -1 ? node_name : node_id;
    }
  },
  methods: {
    initializeOtherwiseNode(otherwiseCondition) {
      if (!_.isEmpty(otherwiseCondition)) {
        this.$data.$otherwiseCondition = _.cloneDeep(otherwiseCondition);
      }
    },

    handleChange(option) {
      this.$set(this.$data.$otherwiseCondition, "node_id", option.value);
      this.$set(this.$data.$otherwiseCondition, "node_name", option.label);
      this.$set(this.$data.$otherwiseCondition, "msg", option.msg);
    }
  },

  watch: {
    otherwiseGotoNode: {
      handler: function(value) {
        switch (this.fromNodeType) {
          case "decision":
            value.decision_condition = "Otherwise";
            break;
          default:
            break;
        }
        this.$emit("input", {
          ...value,
          node_name: value.node_name.toString().trim()
        });
      },
      deep: true,
      immediate: true
    },
    value: {
      immediate: true,
      handler: "initializeOtherwiseNode"
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";
.otherwise-node ::v-deep .otherwise-node-msg {
  font-size: 0.75rem;
  color: $--color-success;
}
</style>
