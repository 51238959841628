<script>
export default {
  props: {
    config: {
      required: true,
      type: Object
    },
    contents: {
      required: true,
      type: Array
    }
  }
};
</script>
