<template>
  <div>
    <el-row class="template-description">
      <el-col>
        {{ templateDescription }}
      </el-col>
    </el-row>
    <div v-if="showInputVariables">
      <el-row>
        <el-col class="input-output-title">
          {{ __("Input Variables") }}
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24">
          <ul class="input-output-list">
            <li
              v-for="(item, index) in inputVariable"
              :key="index"
              class="input-items"
            >
              {{ formatDisplayItem(item) }}
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
    <div v-if="showOutputVariables">
      <el-row>
        <el-col class="input-output-title">
          {{ __("Output Variables") }}
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24">
          <ul class="input-output-list">
            <li
              v-for="(item, index) in outputVariable"
              :key="index"
              class="output-items"
            >
              {{ formatDisplayItem(item) }}
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "InputOutputVariablesDisplay",
  props: ["templateDescription", "inputVariable", "outputVariable"],
  computed: {
    showInputVariables() {
      return !_.isEmpty(this.inputVariable);
    },
    showOutputVariables() {
      return !_.isEmpty(this.outputVariable);
    }
  },
  methods: {
    formatDisplayItem(item) {
      return item.variable_name + ": " + item.description + ".";
    }
  }
};
</script>

<style scoped>
.template-description {
  margin-bottom: 10px;
}
.input-output-title {
  font-weight: bold;
}
.input-output-list {
  margin: 5px 5px 10px 5px;
  padding-left: 25px;
}
</style>
