<template>
  <div :class="{ hidden: hasActiveSession, 'begin-preview': pretendAsWidget }">
    <script
      v-if="!isChatWindowLibAvailable()"
      type="application/javascript"
      :src="chatbotLibUrl"
    ></script>
    <el-tooltip
      :open-delay="1000"
      :visible-arrow="false"
      :placement="tooltipPlacement"
    >
      <template #content>
        <div
          style="
          display: flex;
          align-self: stretch;
          justify-content: center;
        "
        >
          {{ __("Preview Chatbot Task") }}
        </div>
      </template>
      <el-popover placement="bottom" width="170" trigger="manual">
        <el-button
          id="start-session-button"
          slot="reference"
          type="text"
          @click="beginPreviewSession"
        >
          <div class="icon-container">
            <div :class="{ hidden: isLoadingSession || disabled }">
              <img
                :src="icon"
                class="versioningIcon"
                onload="SVGInject(this)"
              />
            </div>
            <div
              v-loading="isLoadingSession"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0)"
            ></div>
          </div>
        </el-button>
      </el-popover>
    </el-tooltip>
  </div>
</template>

<script>
import { prepareTaskForChatbotPreview } from "@/api/tasks";
import _ from "lodash";

const MS_WAIT_BEFORE_RETRY = 1000;
const MS_WAIT_BEFORE_AUTO_CLOSE_WINDOW = 2000;
const GIVE_UP_LAUNCHING_COUNT = 10;
const GIVE_UP_OPEN_WINDOW_COUNT = 4;
const GIVE_UP_DESTROY_SESSION_COUNT = 4;
const MS_PREVIEW_SESSION_INIT_TIMEOUT = 60000;

export default {
  MS_WAIT_BEFORE_RETRY,
  GIVE_UP_LAUNCHING_COUNT,
  GIVE_UP_OPEN_WINDOW_COUNT,
  GIVE_UP_DESTROY_SESSION_COUNT,
  name: "ChatbotTaskStartPreview",
  props: {
    taskKey: {
      required: true,
      type: String
    },
    taskId: {
      required: true,
      type: Number
    },

    /**
     * If set to true, the trigger will be styled as if its the actual chatbot widget
     */
    pretendAsWidget: {
      required: false,
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      sessionId: "",
      disabled: false,
      chatbotCdn: process.env.VUE_APP_CHATBOT_TASK_PREVIEW_CDN,
      chatbotLibUrl: process.env.VUE_APP_CHATBOT_TASK_PREVIEW_FRONTEND_LIB_URL,
      chatbotIntegrationId:
        process.env.VUE_APP_CHATBOT_TASK_PREVIEW_INTEGRATION_ID,
      waitingForSession: false,

      // Status of the web messenger init.
      // Set to true when this.launchChatWindow is called.
      // Set to false when F9.Chat.Wrapper.Events.MessengerReady is fired.
      // Value is reset when destroySession is called
      initMessenger: false,
      initMessengerTimeout: -1,

      // When set to true, callbacks for the chatbot preview has been registered.
      // Don't register more than once
      callbackRegistered: false,

      // Active session status
      // Set to true when F9.Chat.Wrapper.Events.MessengerReady is fired.
      // Set to false when destroySession is called.
      hasActiveSession: false,

      // ID of the timeout that attempts to open the chatbot window
      retryOpenTimeout: -1,

      // The destroyed state of the chatbot window.
      // Sometimes, after calling chatbot destroy, it will not destroy the chatbot
      // session. We want to keep trying to destroy the chatbot session until our
      // internal state updates
      chatbotDestroyed: true,

      // ID of the timeout that attempts to destroy the chatbot window
      retryDestroyTimeout: -1,

      // How long should we wait before automatically closing the chat window after
      // the session has been ended by the messaging client
      autoCloseWindowTimeout: MS_WAIT_BEFORE_AUTO_CLOSE_WINDOW
    };
  },
  computed: {
    icon() {
      return require("@/assets/icons/icon-chatbot-preview.svg");
    },

    /**
     * Check if the loading indicator should be displayed. Only show if we're waiting for a chatbot session to start
     * @see this.waitingForSession
     * @see this.initMessenger
     * @returns {boolean}
     */
    isLoadingSession() {
      if (!this.waitingForSession && !this.initMessenger) {
        return false;
      }

      if (!this.isChatWindowLibAvailable()) {
        return true;
      }

      if (this.initMessenger) {
        return true;
      }

      return false;
    },

    /**
     * Where the tooltip should be placed
     */
    tooltipPlacement() {
      return this.pretendAsWidget ? "top" : "bottom";
    }
  },
  methods: {
    /**
     * Check if the chatbot window library is ready
     */
    isChatWindowLibAvailable() {
      // We're using Five9's chatbot interface
      return window.F9 !== undefined;
    },

    /**
     * Perform preview chatbot initialization routine
     */
    beginPreviewSession() {
      // Do nothing if disabled
      if (this.disabled) {
        return;
      }

      // Don't do anything when we're already loading a session
      if (this.isLoadingSession) {
        return;
      }

      this.destroySession();

      this.waitingForSession = true;

      prepareTaskForChatbotPreview(this.taskId, this.taskKey)
        .then(session => {
          const integrationId = _.get(session, "data.integration_id");
          if (
            !_.isEmpty(integrationId) &&
            this.chatbotIntegrationId !== integrationId
          ) {
            this.chatbotIntegrationId = integrationId;
          }

          this.sessionId = _.get(session, "data.session_id", "");
          this.launchChatWindow();
        })
        .catch(error =>
          this.notifyError(_.get(error, "message", "Unknown error"))
        )
        .finally(() => {
          this.waitingForSession = false;
        });
    },

    /**
     * Begin the timeout state of the chatbot messaging interface init
     */
    initMessengerBeginTimeout() {
      this.hasActiveSession = false;
      this.initMessenger = true;

      // If after 60 seconds we're still waiting for messenger to init, automatically abort
      this.initMessengerTimeout = setTimeout(() => {
        if (this.initMessenger) {
          this.notifyError(__("Timeout while starting preview session"));
          this.destroySession();
        }
      }, MS_PREVIEW_SESSION_INIT_TIMEOUT);
    },

    /**
     * End the timeout state of the chatbot messaging interface init
     */
    initMessengerDestroyTimeout() {
      this.initMessenger = false;

      if (this.initMessengerTimeout != -1) {
        clearTimeout(this.initMessengerTimeout);
        this.initMessengerTimeout = -1;
      }
    },

    /**
     * Callback for the web messenger ready events
     */
    webMessengerReadyEventCallback() {
      this.initMessengerDestroyTimeout();
      this.hasActiveSession = true;
      this.forceOpenChatWindow();
    },

    /**
     * Attempt to launch chatbot window
     * @param {Number|undefined} retryCount Number of attempts done launching the window
     * @returns {Boolean} True when chat window init function is called
     */
    launchChatWindow(retryCount) {
      retryCount = retryCount === undefined ? 0 : retryCount;

      if (retryCount === 0) {
        this.initMessengerBeginTimeout();
      }

      // Keep retrying until the limit has been reached
      if (retryCount > GIVE_UP_LAUNCHING_COUNT) {
        this.sessionId = "";
        // eslint-disable-next-line
        this.notifyError(__("Failed to launch chatbot preview session"));
        return false;
      }

      if (!this.isChatWindowLibAvailable()) {
        // If chat window library is not ready, retry until its available
        setTimeout(() => {
          this.launchChatWindow(retryCount + 1);
        }, MS_WAIT_BEFORE_RETRY);
        return;
      }

      // If init function is unavailable, notify error
      if (_.isNil(window.F9, "Chat.Wrapper.init")) {
        this.sessionId = "";
        // eslint-disable-next-line
        this.notifyError(__("Failed to initialize chatbot session"));
        return false;
      }

      if (
        !this.callbackRegistered &&
        _.has(window.F9, "Chat.Wrapper.messenger.on")
      ) {
        window.F9.Chat.Wrapper.messenger.on(
          window.F9.Chat.Wrapper.Events.MessengerReady,
          this.webMessengerReadyEventCallback
        );

        window.F9.Chat.Wrapper.messenger.on(
          window.F9.Chat.Wrapper.Events.Ready,
          this.webMessengerReadyEventCallback
        );

        window.F9.Chat.Wrapper.messenger.on(
          window.F9.Chat.Wrapper.Events.ChatSystemConversationTerminated,
          this.onChatEndedByWrapper
        );

        window.F9.Chat.Wrapper.messenger.on(
          window.F9.Chat.Wrapper.Events.Destroy,
          () => (this.chatbotDestroyed = true)
        );

        // Prevent callback registration in the future
        this.callbackRegistered = true;
      }

      window.F9.Chat.Wrapper.init({
        cdn: this.chatbotCdn,
        useBusinessHours: false,
        languages: { enabled: false, backgroundColor: "#244CDE" },
        l10n: {
          en: {
            messenger: { customText: {} },
            systemMessages: {
              transferredToParticipant:
                "The chat has been transferred to {name}.",
              transferredToGroup:
                "That chat has been transferred to group {group}."
            },
            captureFields: [
              { k: "name", l: "Name", p: "Enter your name..." },
              { k: "email", l: "Email Address", p: "Enter your email..." },
              {
                k: "Question",
                l: "Question",
                p: "What can we help you with today?"
              }
            ]
          }
        },
        prepopulatedFields: [
          { k: "campaign", v: "chatbot demo" },
          { k: "studio.preview_session_id", v: this.sessionId }
        ],
        messenger: {
          integrationId: this.chatbotIntegrationId,
          soundNotificationEnabled: true,
          transcriptPrintingEnabled: false,
          menuItems: {
            imageUpload: true,
            fileUpload: true,
            shareLocation: true
          },
          embedded: false,
          browserStorage: "memory",
          fixedHeader: false,
          displayStyle: "button",
          buttonWidth: "48",
          buttonHeight: "48",
          customColors: {
            brandColor: "244CDE",
            conversationColor: "181F29",
            actionColor: "244CDE"
          }
        },
        clearMessagesTimeout: 3
      });

      return true;
    },

    /**
     * Handle event emitted by F9.Chat.Wrapper.Events.ChatSystemConversationTerminated
     */
    onChatEndedByWrapper() {
      const timeout = Number.isFinite(this.autoCloseWindowTimeout)
        ? this.autoCloseWindowTimeout
        : MS_WAIT_BEFORE_AUTO_CLOSE_WINDOW;

      setTimeout(() => {
        this.destroySession();
      }, timeout);
    },

    /**
     * Force open the chat window
     */
    forceOpenChatWindow(retryCount) {
      retryCount = retryCount === undefined ? 0 : retryCount;

      // So we're not stuck in an unlimited loop
      if (retryCount > GIVE_UP_OPEN_WINDOW_COUNT) {
        return;
      }

      // Retry to open the chat window after 500ms if the open widget
      // functions is not available
      if (
        window.FIVN === undefined ||
        window.FIVN.isOpened == undefined ||
        window.FIVN.open == undefined
      ) {
        // Only allow 1 timer to be started
        if (this.retryOpenTimeout !== -1) {
          return;
        }

        this.retryOpenTimeout = setTimeout(() => {
          this.retryOpenTimeout = -1;
          this.forceOpenChatWindow(retryCount + 1);
        }, 500);
        return;
      }

      window.FIVN.open();

      // If our internal state of the window is closed but chatbot state
      // says its opened, retry to open the window again
      setTimeout(() => {
        window.FIVN.open();
      }, 500);
    },

    /**
     * Attempt to destroy existing chatbot preview session
     * @returns {Boolean} True when successfully destroyed the previous session
     */
    destroySession(retryCount) {
      retryCount = retryCount === undefined ? 0 : retryCount;

      // So we're not stuck in an unlimited loop
      if (retryCount > GIVE_UP_DESTROY_SESSION_COUNT) {
        return;
      }

      let wrapperDestroyed = false;
      this.initMessengerDestroyTimeout();
      this.hasActiveSession = false;
      this.chatbotWindowOpened = false;

      // Attempt to destroy previous session
      if (!_.isNil(window.F9, "Chat.Wrapper.destroy")) {
        window.F9.Chat.Wrapper.destroy();
        wrapperDestroyed = true;
      }

      if (window.FIVN && window.FIVN.destroy) {
        window.FIVN.destroy();
        wrapperDestroyed = true;
      }

      if (!this.chatbotDestroyed) {
        // Only allow 1 timer to be started
        if (this.retryDestroyTimeout !== -1) {
          return false;
        }

        this.retryDestroyTimeout = setTimeout(() => {
          this.retryDestroyTimeout = -1;
          this.destroySession(retryCount + 1);
        }, 500);
        return false;
      }

      return wrapperDestroyed;
    },

    /**
     * Disable preview button if chatbot URL config is missing
     */
    disableOnError() {
      if (_.isNil(this.chatbotLibUrl)) {
        this.disabled = true;
        // eslint-disable-next-line
        const message = __("Library URL missing from configuration. Value is \":chatbotUrl\"", {
            chatbotUrl: this.chatbotLibUrl
          }
        );
        return this.notifyError(message);
      }
    },

    /**
     * Display an error notification
     * @param {String} message The error message to display
     */
    notifyError(message) {
      this.$notify({
        title: __("Preview Chatbot Task Error"),
        type: "error",
        message
      });
    }
  },

  mounted() {
    this.disableOnError();
  },

  beforeDestroy() {
    // Make sure to cleanup before the component is destroyed
    this.destroySession();
  }
};
</script>

<style scoped>
#start-session-button .hidden {
  opacity: 0.2;
}

.begin-preview {
  width: 100%;
  height: 100%;
  min-width: 48px;
  min-height: 48px;
  margin: 0;
  padding: 0;
  background: #244cde;
  border-radius: 50%;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.begin-preview.hidden {
  display: none;
}

.begin-preview .icon-container {
  transform-origin: center;
}
</style>

<style>
#web-messenger-container,
#fn-chat-iframe-container .f9ChatWidget {
  z-index: 9998;
  bottom: 81px;
  right: 16px;
}
</style>
