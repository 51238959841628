/* eslint-disable no-undef */
export default {
  startNode() {
    OrgChart.templates.start_node = Object.assign(
      {},
      OrgChart.templates.generic
    );
    OrgChart.templates.start_node.size = [255, 68];
    OrgChart.templates.start_node.node =
      '<svg width="255" height="140" viewBox="0 -2 255 140" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<filter id="dropshadow" height="130%">\n' +
      '  <feGaussianBlur in="SourceAlpha" stdDeviation="4"/> <!-- stdDeviation is how much to blur -->\n' +
      '  <feOffset dx="0" dy="2" result="offsetblur"/> <!-- how much to offset -->\n' +
      "  <feComponentTransfer>\n" +
      '    <feFuncA type="linear" slope="0.1"/> <!-- slope is the opacity of the shadow -->\n' +
      "  </feComponentTransfer>\n" +
      "  <feMerge> \n" +
      "    <feMergeNode/> <!-- this contains the offset blurred image -->\n" +
      '    <feMergeNode in="SourceGraphic"/> <!-- this contains the element that the filter is applied to -->\n' +
      "  </feMerge>\n" +
      "</filter>" +
      '<filter id="dropshadowdark" height="130%">\n' +
      '  <feGaussianBlur in="SourceAlpha" stdDeviation="6"/> <!-- stdDeviation is how much to blur -->\n' +
      '  <feOffset dx="0" dy="2" result="offsetblur"/> <!-- how much to offset -->\n' +
      "  <feComponentTransfer>\n" +
      '    <feFuncA type="linear" slope="0.2"/> <!-- slope is the opacity of the shadow -->\n' +
      "  </feComponentTransfer>\n" +
      "  <feMerge> \n" +
      "    <feMergeNode/> <!-- this contains the offset blurred image -->\n" +
      '    <feMergeNode in="SourceGraphic"/> <!-- this contains the element that the filter is applied to -->\n' +
      "  </feMerge>\n" +
      "</filter>" +
      '<rect  width="63" height="63" rx="20" x=95 y=3 fill="#4AD433" filter="url(#dropshadow)" class="nodeRect" style="cursor: pointer"/>\n' +
      '<rect data-id="{id}" width="221" height="40" rx="5" x=15 y=80 fill="transparent" class="drop-box" stroke="grey" stroke-width="2" stroke-dasharray="10 3" style="display: none"/>\n' +
      "</svg>";

    OrgChart.templates.start_node.field_0 = "";

    OrgChart.templates.start_node.field_1 = "";

    OrgChart.templates.start_node.edge =
      '<path  stroke="#686868" stroke-width="1px" fill="none" edge-id="[{id}][{child-id}]" d="M{xa},{ya} C{xb},{yb} {xc},{yc} {xd},{yd}"/>';

    OrgChart.templates.start_node.plus = "";

    OrgChart.templates.start_node.minus = "";

    OrgChart.templates.start_node.expandCollapseSize = 36;

    OrgChart.templates.start_node.nodeMenuButton =
      '<g style="cursor:pointer;" transform="matrix(0.9,0,0,0.9,150,30)" control-node-menu-id="{id}" class="ellipses"><rect fill="white" fill-opacity="0" width="30" height="63" x="-13" y="-22"></rect><circle cx="0" cy="0" r="2" fill="white"></circle><circle cx="0" cy="7" r="2" fill="white"></circle><circle cx="0" cy="14" r="2" fill="white"></circle></g>';

    OrgChart.templates.start_node.img_1 = "";

    OrgChart.templates.start_node.img_0 =
      '<image preserveAspectRatio="xMidYMid slice" clip-path="url(#ulaImg)" xlink:href="{val}" x="113" y="24" width="28" height="28" class="nodeImage" style="cursor: pointer" ></image>';
  }
};
