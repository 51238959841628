<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapState } from "vuex";
export default {
  name: "FilesIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },
  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    }),

    ...mapState("files", {
      contents: state => state.files
    })
  },

  methods: {
    initContent() {
      return {
        file_name: "",
        tts_value: "",
        unique_code: "",
        audio_file_path: "",
        ac_id: "",
        file_url: "",
        file_path: "",
        is_archived: 0,
        file_values: [
          {
            file_value_id: 1,
            tts_voice_id: 1,
            tts_value: "test"
          }
        ]
      };
    }
  },

  mounted() {
    this.contentType = "AttachmentFile";
    this.primaryKey = "file_id";
  }
};
</script>

<style lang="scss" scoped></style>
