<template>
  <div>
    <el-upload
      v-if="!fileUrl"
      class="wav-uploader"
      :headers="headers"
      :action="getUploadUrl"
      :data="formData"
      :show-file-list="true"
      :on-success="handleSuccess"
      :on-error="handleError"
      :on-progress="handleUploadProgress"
      :before-upload="beforeFileUpload"
      :limit="1"
      :multiple="false"
      accept="audio/wav, audio/mpeg"
    >
      <el-button class="upload-btn" size="small" type="primary"
        >{{ __("Click to upload") }}
      </el-button>
      <div slot="tip" class="el-upload__tip">
        {{
          __("wav files with a size less than :max MB", {
            max: this.maxSizeInMb
          })
        }}
      </div>
    </el-upload>
    <div v-if="fileUrl">
      <div
        style="display: flex;align-items: center;justify-content: flex-start"
      >
        <div
          style="height: 120px; width: 140px; background: var(--theme-color);display: flex;align-items: center;justify-content: center;border-radius: 6px"
        >
          <div style="display: flex; flex-direction: column">
            <div
              style="display: flex; justify-content: center; align-items: center"
            >
              <audio-player
                :file="fileUrl"
                :simple-player="true"
                class="simple-player"
                style="display: inline-block;width: 32px"
                showClose="true"
              ></audio-player>
            </div>
            <div
              style="color: white; max-width: 100px; text-overflow: ellipsis ;white-space: nowrap;overflow: hidden;font-size: 0.8em; margin-top: 5px"
            >
              {{ fileName }}
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: normal;">
        <el-button
          @click="handleDelete"
          size="small"
          style="padding-left: 45px; border: none;"
          plain
          >{{ __("Delete") }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseUploader from "@/components/uploaders/BaseUploader";
import AudioPlayer from "@/components/AudioPlayer";

export default {
  name: "WavFileUploader",
  components: { AudioPlayer },
  mixins: [BaseUploader],
  props: {
    fileName: {
      required: false,
      type: String
    }
  },
  methods: {
    beforeFileUpload(file) {
      this.isLoading = true;
      const isWAV = "audio/wav";
      const isMP3 = "audio/mpeg";
      const isLt2M = file.size / 1000 / 1000 < this.maxSizeInMb;

      if (file.type === isWAV || file.type === isMP3) {
        if (!isLt2M) {
          this.$message({
            // eslint-disable-next-line
            message: __("File size exceeded. Please try uploading a file within :max MB", {
                max: this.maxSizeInMb
              }
            ),
            type: "error"
          });
          return false;
        }
        this.$emit("on-progress");
        this.$emit("file-info", file);
      } else {
        this.$message({
          message: __("Only wav or mp3 files are allowed"),
          type: "error"
        });
        return false;
      }
    },
    handleDelete() {
      this.$emit("on-delete");
    }
  }
};
</script>

<style scoped lang="scss">
.wav-uploader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ::v-deep .el-upload-dragger {
    width: 140px;
    height: 140px;
    padding: 5px;
  }
}

.simple-player {
  border-radius: 16px;
  display: inline-block;
  background: white;

  ::v-deep img {
    filter: invert(58%) sepia(52%) saturate(1516%) hue-rotate(166deg)
      brightness(96%) contrast(96%);
  }
}
.upload-btn {
  background: var(--theme-color);
  border: var(--theme-color);
  &:hover {
    background: var(--theme-hover-color);
    color: white;
  }
}
</style>
