<template>
  <div class="node-value">
    <div class="log-event">
      <span @click="$emit('clicked')" class="node-name"
        >[ x ] &nbsp; {{ nodeName }}</span
      >
      <el-tooltip content="View data" placement="top">
        <el-button
          icon="el-icon-share"
          size="medium"
          class="external-button"
          @click="openModal"
        >
        </el-button>
      </el-tooltip>
    </div>

    <!-- Modal for displaying node log data -->
    <el-dialog
      :visible.sync="isModalVisible"
      :title="__('Node Values - ') + nodeName"
      append-to-body
      @open="focusModal"
      @close="handleCloseModal"
      modal-append-to-body
    >
      <div class="modal-content">
        <pre>{{ nodelog }}</pre>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseModal">{{ __("Close") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "NodeValue",
  props: {
    nodelog: {
      type: String,
      required: true
    },
    nodeName: {
      type: String,
      default: "Variable"
    }
  },
  data() {
    return {
      isModalVisible: false
    };
  },
  methods: {
    openModal() {
      this.isModalVisible = true;
    },
    handleCloseModal() {
      this.isModalVisible = false;
    },
    focusModal() {
      // Automatically focus the modal content when opened
      this.$nextTick(() => {
        const modalContent = this.$refs.modal?.$el.querySelector(
          ".el-dialog__body"
        );
        if (modalContent) {
          modalContent.focus();
        }
      });
    }
  }
};
</script>

<style lang="scss">
$content-theme-color: var(--theme-color) !default;
$highlight-color: #f0f8ff;

.node-value {
  margin: 0 10px 0 8px;

  .log-event {
    display: flex;
    align-items: stretch;
    background-color: #f7f7f7;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: fit-content;

    .node-name {
      padding: 10px;
      margin: 0 10px;
      font-size: 14px;
      color: #333;
      white-space: nowrap;
      display: flex;
      align-items: center;
      font-weight: bold;
    }

    .external-button {
      padding: 5px 10px 5px 10px;
      border: none;
      margin-left: 10px;
      background-color: $highlight-color;
      border-left: 1px solid #ddd;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover,
      &:focus {
        color: $content-theme-color;
        background-color: lighten($highlight-color, 5%);
      }
    }
  }

  .node-variable {
    overflow-x: auto;
    background-color: #f7f7f7;
    padding: 10px;
    border-radius: 5px;
    margin-top: 5px;
    white-space: pre-wrap;
  }
}

.modal-content {
  margin: 10px;
  padding: 10px;
  background-color: #f7f7f7;
  border-radius: 5px;
  max-height: 80vh;
  overflow-y: auto;
  white-space: pre-wrap;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
