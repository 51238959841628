export const REPLY_BUTTON = "REPLY_BUTTON";
export const LINK_BUTTON = "LINK_BUTTON";
export const LOCATION_BUTTON = "LOCATION_REQUEST_BUTTON";
export const POSTBACK_BUTTON = "POSTBACK_BUTTON";
export const PROACTIVE_POSTBACK_BUTTON = "PROACTIVE_POSTBACK_BUTTON";
import { NODE_TYPES } from "@/constants/nodes";

export const BUTTON_TYPES = [
  {
    get label() {
      return __("link");
    },
    value: LINK_BUTTON,
    available: () => {
      return true;
    }
  },
  {
    get label() {
      return __("reply");
    },
    value: REPLY_BUTTON,
    available: messagingType => {
      return messagingType !== NODE_TYPES.PROACTIVE_MESSAGE.NODE_TYPE;
    }
  },
  {
    get label() {
      return __("postback");
    },
    value: POSTBACK_BUTTON,
    available: messagingType => {
      return messagingType !== NODE_TYPES.PROACTIVE_MESSAGE.NODE_TYPE;
    }
  },
  {
    get label() {
      return __("postback");
    },
    value: PROACTIVE_POSTBACK_BUTTON,
    available: messagingType => {
      return messagingType === NODE_TYPES.PROACTIVE_MESSAGE.NODE_TYPE;
    }
  },
  {
    get label() {
      return __("location");
    },
    value: LOCATION_BUTTON,
    available: () => {
      return true;
    }
  }
];
