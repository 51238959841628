<template>
  <div>
    <el-select
      :class="setPrompt ? 'addVoice' : 'ttsVoiceDropDown'"
      v-model="tts_voice"
      :placeholder="setPrompt ? __('Add Voice') : __('Please select TTS voice')"
      style="width: 100%"
      default-first-option
      filterable
      @change="changeTtsVoice"
      :disabled="disabled"
    >
      <el-option-group
        v-for="(language, language_code) in languages"
        :key="language_code"
        :label="language_code"
      >
        <el-option
          v-for="languageItem in language"
          :key="languageItem.tts_voice_id"
          :label="languageItem.voice_name"
          :value="languageItem.tts_voice_id"
        >
          <span style="float: left">
            {{ languageItem.voice_name }}
          </span>
          <span
            style="float: right; color: #8492a6; font-size: 13px; margin-right: 2px"
          >
            {{ languageItem.provider }}
          </span>
          <span
            style="float: right; color: #8492a6; font-size: 13px; margin-right: 10px"
          >
            {{ formatGender(languageItem.gender) }}
          </span>
        </el-option>
      </el-option-group>
    </el-select>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapState } from "vuex";

export default {
  name: "TtsDropdownDetail",
  props: {
    setPrompt: {
      required: false,
      type: Boolean,
      default: false
    },
    default_tts_id: {
      required: false,
      type: [String, Number]
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tts_voice: ""
    };
  },

  async created() {
    if (!this.languages) {
      await this.initializeTtsVoice();
    }

    if (!this.setPrompt && this.default_tts_id) {
      this.tts_voice = _.find(_.flatMap(this.languages), {
        tts_voice_id: this.default_tts_id
      }).voice_name;
    } else {
      this.tts_voice = "";
    }
  },

  computed: {
    ...mapState("tasks", {
      languages: state => state.languages
    })
  },

  methods: {
    ...mapActions("tasks", {
      getLanguages: "getLanguages"
    }),

    formatGender(gender) {
      return gender === "F" ? "Female" : "Male";
    },

    async initializeTtsVoice() {
      if (_.isEmpty(this.ttsVoices)) {
        await this.getLanguages();
      }
    },

    async changeTtsVoice(tts) {
      this.$emit("tts-changed", tts);

      // reset for Prompt Node
      if (this.setPrompt) {
        this.tts_voice = "";
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/node_common.scss";
.addVoice {
  ::v-deep .el-input__inner {
    background: white;
    text-align: center;
    color: $content-theme-color;
    border: $content-theme-color 2px solid;
    &::placeholder {
      color: $content-theme-color;
      font-weight: bold;
    }
  }

  ::v-deep .el-input.is-focus {
    border-color: $content-theme-color;
    outline: none;
  }
  ::v-deep .el-input__suffix {
    display: none;
  }
}

.ttsVoiceDropDown {
  ::v-deep .el-select {
    .el-input.is-focus .el-input__inner {
      border-color: var(--theme-color);
    }

    .el-input__inner:hover {
      border-color: var(--theme-color);
    }

    .el-select-dropdown__item.selected {
      color: var(--theme-color);
    }

    .el-input__inner:focus {
      border-color: var(--theme-color);
    }
  }

  .el-select-group__title {
    color: var(--theme-color) !important;
  }
}
</style>
