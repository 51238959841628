<template>
  <div class="new-item-or-select">
    <el-form-item
      v-if="value === 'variable_id'"
      :error="failToCreateMessage"
      :label="formItemLabel"
      :required="formItemRequired"
    >
      <el-select
        class="w-full"
        v-model="select"
        filterable
        :value-key="value"
        :remote-method="filterList"
        :allow-create="allowCreate"
        default-first-option
        clearable
        remote
        :placeholder="placeholder"
        :disabled="isDisabled"
        @keyup.native="updateUserInput"
        @visible-change="handleChange"
        @change="handleSelect"
        @clear="initializeAfterClear"
      >
        <div class="infinite-list" v-infinite-scroll="load">
          <el-option
            v-for="item in $data.$items"
            :key="item[value]"
            :label="item[label]"
            :value="item[value]"
          >
            <div>
              {{ item[label]
              }}<i
                v-if="item['variable_type'] === 'secure'"
                class="el-icon-lock"
                style="padding-left: 5px"
              ></i>
            </div>
          </el-option>
        </div>
      </el-select>
    </el-form-item>
    <el-form-item v-else :error="failToCreateMessage">
      <el-select
        class="w-full"
        v-model="select"
        filterable
        :value-key="value"
        :remote-method="filterList"
        :allow-create="allowCreate"
        default-first-option
        clearable
        :remote="!isGrouped"
        :placeholder="placeholder"
        :disabled="isDisabled"
        @keyup.native="updateUserInput"
        @visible-change="handleChange"
        @change="handleSelect"
        @clear="initializeAfterClear"
      >
        <template v-if="isGrouped">
          <el-option-group
            v-for="(groupItems, groupName) in groupedItems"
            :key="groupName"
            :label="groupName"
          >
            <el-option
              v-for="(item, index) in groupItems"
              :key="index"
              :label="item[label]"
              :value="item[value]"
            >
            </el-option>
          </el-option-group>
        </template>
        <template v-else>
          <div class="infinite-list" v-infinite-scroll="load">
            <el-option
              v-for="item in $data.$items"
              :key="item[value]"
              :label="item[label]"
              :value="item[value]"
            >
            </el-option>
          </div>
        </template>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    items: {
      required: true,
      type: Array
    },
    current_select: {
      required: true,
      type: [String, Number]
    },
    placeholder: {
      required: false,
      type: String,
      default: "Choose item"
    },
    label: {
      required: false,
      type: String,
      default: "label"
    },
    formItemLabel: {
      required: false,
      type: String,
      default: ""
    },
    formItemRequired: {
      required: false,
      type: Boolean,
      default: false
    },
    value: {
      required: false,
      type: String,
      default: "value"
    },
    newItemMessage: {
      required: false,
      type: String,
      default: ""
    },
    exclude: {
      required: false,
      type: Array,
      default: () => []
    },
    failToCreateMessage: {
      required: false,
      type: String,
      default: ""
    },
    allowCreate: {
      required: false,
      type: Boolean,
      default: true
    },
    isDisabled: {
      required: false,
      type: Boolean,
      default: false
    },
    isGrouped: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      $completeList: [],
      $pendingList: [],
      $items: [],
      groupedItems: [],
      select: null,
      newItemName: "",
      isNewItem: false,
      query: ""
    };
  },

  computed: {
    isExcluded() {
      return _.map(this.exclude, this.label).includes(this.select);
    }
  },

  methods: {
    load() {
      let removedItems = this.$data.$pendingList.splice(0, 10);
      this.$data.$items = this.$data.$items.concat(removedItems);
    },
    filterList($query) {
      this.query = $query;
      if (!$query) {
        this.$data.$pendingList = _.cloneDeep(this.$data.$completeList);
      } else {
        // find a perfect match
        let perfectMatches = _.cloneDeep(
          _.filter(
            this.$data.$completeList,
            item => item[this.label].toLowerCase() === $query.toLowerCase()
          )
        );

        let perfectMatchNames = _.map(perfectMatches, item =>
          item[this.label].toLowerCase()
        );

        this.$data.$pendingList = perfectMatches.concat(
          _.cloneDeep(
            _.filter(
              this.$data.$completeList,
              item =>
                item[this.label].toLowerCase().includes($query.toLowerCase()) &&
                !perfectMatchNames.includes(item[this.label].toLowerCase())
            )
          )
        );
      }
      this.$data.$items = this.$data.$pendingList.splice(0, 10);
    },

    initializeItems(val) {
      this.$data.$completeList = _.cloneDeep(val);
      this.$data.$pendingList = _.cloneDeep(this.$data.$completeList);
      let removedItems = this.$data.$pendingList.splice(0, 10);
      this.$data.$items = _.cloneDeep(removedItems);
      if (this.isGrouped) {
        this.groupedItems = _.groupBy(val, "group_name");
      }
    },

    initializeAfterClear() {
      if (!this.isGrouped) {
        this.$data.$pendingList = _.cloneDeep(this.$data.$completeList);
        let removedItems = this.$data.$pendingList.splice(0, 10);
        this.$data.$items = _.cloneDeep(removedItems);
      }
    },

    initializeSelect(newVal) {
      this.filterList("");

      this.select = (!+newVal && newVal.length) || !!+newVal ? newVal : null;

      if (!+newVal && !newVal.length) {
        this.isNewItem = false;
      } else if (!this.isGrouped) {
        let index = _.findIndex(
          this.$data.$pendingList,
          item => item[this.value] === this.select
        );
        if (index > -1) {
          let item = this.$data.$pendingList.splice(index, 1);
          this.$data.$items = item.concat(this.$data.$items);
        }
      }
    },

    notifyParent() {
      if (this.select != null) {
        let item;

        item = _.find(this.items, item => item[this.value] === this.select);

        const value = _.isEmpty(item) ? -1 : item[this.value];

        this.isNewItem =
          _.isEmpty(item) || (!_.isEmpty(item) && item[this.value] === -1);
        const label = value === -1 ? this.select : item[this.label];
        this.$emit("change", {
          label,
          value,
          msg:
            value === -1 && label.length && this.isNewItem && !this.isExcluded
              ? this.newItemMessage
              : ""
        });
      }
    },

    handleChange(isOptionsOpen) {
      this.$nextTick(() => {
        if (!isOptionsOpen && !this.isGrouped) {
          this.$data.$pendingList = _.cloneDeep(this.$data.$completeList);
          let index = _.findIndex(
            this.$data.$pendingList,
            item => item[this.value] === this.select
          );
          if (index > -1) {
            let item = this.$data.$pendingList.splice(index, 1);
            let removedItems = this.$data.$pendingList.splice(0, 10);
            this.$data.$items = item.concat(_.cloneDeep(removedItems));
          } else {
            let removedItems = this.$data.$pendingList.splice(0, 10);
            this.$data.$items = _.cloneDeep(removedItems);
          }
        }
        if (this.newItemName && !isOptionsOpen) {
          const item = _.find(
            this.items,
            item => item[this.label] === this.newItemName
          );

          this.select = _.isEmpty(item) ? this.newItemName : item[this.value];
          this.newItemName = "";
        }
      });
    },

    handleSelect(option) {
      const item = _.find(
        this.$data.$items,
        item => item[this.value] === option
      );

      if (!_.isEmpty(item)) {
        this.newItemName = item[this.label];
      }
    },

    updateUserInput(event) {
      if (this.allowCreate) this.newItemName = event.target.value;
    }
  },
  created() {
    this.initializeItems(this.items);
    this.initializeSelect(this.current_select);
  },
  watch: {
    items: {
      deep: true,
      handler: "initializeItems"
    },
    current_select: {
      handler: "initializeSelect"
    },
    select: {
      immediate: true,
      handler: "notifyParent"
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";

.new-item-or-select {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  .w-full {
    width: 100%;
  }

  ::v-deep .el-form-item {
    @extend .w-full;
  }

  .item-message {
    z-index: 99999 !important;
    position: absolute;
    padding-right: 5px;
  }

  .new-item-message {
    font-size: 0.75rem;
    color: $--color-success;
    margin-left: 10px;
  }

  .new-item-error-message {
    font-size: 0.75rem;
    color: $--color-danger;
    margin-left: 10px;
  }
}
</style>
