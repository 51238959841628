<template>
  <el-container class="container">
    <div class="inner-container">
      <el-row type="flex" class="version-history-create">
        <el-col>
          <el-button
            :disabled="!task.version_in_use_modified"
            class="save-version-btn"
            :loading="exportingTask"
            @click="handleNewVersionClick"
            type="primary"
            >{{
              task.version_in_use_modified
                ? !task.is_template
                  ? __("Save current version")
                  : __("Re-Publish")
                : __("No changes")
            }}
          </el-button>
        </el-col>
      </el-row>
      <div v-if="versions.length">
        <el-row class="history-group">
          <span class="title">{{ __("Version history") }}</span>
          <version-history
            class="version-history-collection"
            :versions="versions"
            v-model="versionLoaded"
            :task-updated="task.version_in_use_modified"
            @update="replaceUpdatedVersion"
            @restore="handleVersionRestore"
          />
        </el-row>

        <el-divider class="version-history-footer-divider" />
        <el-row class="footer">
          <span v-if="!task.is_template" class="foot-note">
            <!-- eslint-disable-next-line -->
            {{ __("Any time you restore a version, your current task will be saved as a new version automatically.") }}
          </span>
          <span v-else class="foot-note">
            <!-- eslint-disable-next-line -->
            {{ __("You will need to publish your current changes, before you can restore to a previous version") }}
          </span>
        </el-row>
      </div>
    </div>
    <el-dialog
      v-if="showTaskExportConfigurationModal"
      :visible.sync="showTaskExportConfigurationModal"
      custom-class="task-export-modal"
      append-to-body
      destroy-on-close
      fullscreen
    >
      <div slot="title" class="dialog-header">
        <responsive-container>
          <h1 class="title">{{ taskToExport.task_name }}</h1>
        </responsive-container>
      </div>

      <div
        v-loading="loadingSnapshot"
        :element-loading-text="getLoadingText"
        class="export-stats-container"
      >
        <responsive-container>
          <task-export-configure
            :task-stats="taskToExportStats"
            :export-config="exportConfiguration"
            :task="taskToExport"
          />
        </responsive-container>
      </div>

      <div slot="footer" class="dialog-footer">
        <responsive-container>
          <el-button
            :disabled="loadingSnapshot || exportingTask"
            type="primary"
            :loading="exportingTask"
            @click="createNewVersion"
            >{{ isTemplateLan }}
          </el-button>
          <el-button
            type="primary"
            @click="showTaskExportConfigurationModal = false"
            >{{ __("Cancel") }}
          </el-button>
        </responsive-container>
      </div>
    </el-dialog>
    <el-dialog
      v-if="showTaskRestoreConfigurationModal"
      :visible.sync="showTaskRestoreConfigurationModal"
      custom-class="task-import-modal"
      append-to-body
      destroy-on-close
      fullscreen
    >
      <configure-task-create-options
        :config-options="configImportOptions"
        :load-config="loadConfig"
        style="margin-top: 34px; width: 100%"
        is-version-switch
        create-type="version"
        @cancel="clearContextAndHandleCancel"
        @configured="restoreVersionAfterConfiguration($event)"
      ></configure-task-create-options>
    </el-dialog>
  </el-container>
</template>

<script>
import VersionHistory from "@/views/build/callflow/components/versionManager/VersionHistory";
import { mapActions } from "vuex";
import { createNewVersion, decodeVersion, restoreVersion } from "@/api/tasks";
import _ from "lodash";
import TaskExport from "@/views/build/tasks/create-task/components/TaskExport/TaskExport";
import ResponsiveContainer from "@/components/ResponsiveContainer";
import TaskExportConfigure from "@/views/build/tasks/create-task/components/TaskExport/TaskExportConfigure";
import ConfigureTaskCreateOptions from "@/views/build/tasks/create-task/components/ConfigureTaskCreateOptions";

export default {
  name: "VersionManager",
  components: {
    ConfigureTaskCreateOptions,
    TaskExportConfigure,
    ResponsiveContainer,
    VersionHistory
  },
  mixins: [TaskExport],
  props: {
    task: {
      required: true,
      type: Object
    }
  },
  provide() {
    return {
      $restoring: () => this.restoring
    };
  },
  data() {
    return {
      versionLoaded: null,
      versions: [],
      restoring: false,
      showTaskRestoreConfigurationModal: false,
      configImportOptions: {},
      configureImportOptions: false,
      versionToLoad: {},
      loadConfig: {}
    };
  },
  computed: {
    isTemplateLan() {
      return this.task.is_template
        ? __("Publish Version")
        : __("Create Version");
    }
  },
  methods: {
    ...mapActions("tasks", {
      getVersions: "getVersions"
    }),

    ...mapActions("canvas", {
      setTaskVersionInUse: "setTaskVersionInUse",
      setTaskModifiedFlag: "setTaskModifiedFlag",
      changeLoadingState: "changeLoadingState"
    }),

    clearContextAndHandleCancel() {
      this.restoring = false;
      this.changeLoadingState(false);
      this.showTaskRestoreConfigurationModal = false;
    },

    restoreVersionAfterConfiguration(config) {
      this.showTaskRestoreConfigurationModal = false;
      this.restoreVersion(this.versionToLoad, config);
    },

    handleVersionRestore(version) {
      this.versionToLoad = _.cloneDeep(version);
      if (!this.task.is_template) {
        this.restoreVersion(version);
      } else {
        this.restoring = true;
        decodeVersion(version.version_id)
          .then(({ data }) => {
            this.showTaskRestoreConfigurationModal = true;
            this.configImportOptions = _.get(data, "required_replacements", {});
            this.loadConfig = _.get(data, "load_config", {});
          })
          .catch(() => {
            this.versionToLoad = {};
          })
          .finally(() => {
            this.restoring = false;
            this.changeLoadingState(false);
          });
      }
    },

    handleNewVersionClick() {
      if (!this.task.is_template) {
        this.createNewVersion();
      } else {
        this.handleInitiateExport();
      }
    },

    handleInitiateExport() {
      this.showTaskExportConfigurationModal = true;
      this.getTaskSnapshot(this.task);
    },

    replaceUpdatedVersion(version) {
      let index = _.findIndex(this.versions, {
        version_id: version.version_id
      });
      this.versions.splice(index, 1, version);
    },

    initializeVersions() {
      this.getVersions(this.task.task_id)
        .then(data => {
          this.versions = _.cloneDeep(data);
        })
        .catch(err => console.error(err));
    },

    restoreVersion(version, config = {}) {
      this.restoring = true;
      restoreVersion(this.task.task_id, version.version_id, config, true)
        .then(({ data }) => {
          this.listenForTaskImportExportUpdates(
            data,
            this.task.task_id,
            this.onTaskImportExportCompletionEventOnVersionRestore,
            this.onTaskImportExportErroredEventOnVersionRestore
          );
        })
        .catch(err => {
          this.restoring = false;
          this.changeLoadingState(false);
          this.versionToLoad = {};
          this.$message({
            type: "error",
            message: err.message || __("operation failed")
          });
        });
    },

    onTaskImportExportCompletionEventOnVersionCreation(
      taskId,
      { request_id: requestId, request_info: requestInfo }
    ) {
      this.$emit("version-created");
      this.stopListeningToTaskImportExportChannel(taskId, requestId);

      this.setTaskVersionInUse(requestInfo.version.version_id);
      this.versions.push(requestInfo.version);

      let message = !this.task.is_template
        ? __("version created successfully")
        : __("template published successfully");
      this.$message.success(message);

      this.exportingTask = false;
      this.$emit("version-creating", false);
      this.showTaskExportConfigurationModal = false;
    },

    onTaskImportExportCompletionEventOnVersionRestore(
      taskId,
      { request_id: requestId }
    ) {
      this.stopListeningToTaskImportExportChannel(taskId, requestId);

      this.setTaskVersionInUse(this.versionToLoad.version_id);
      this.initializeVersions();
      this.$emit("version-changed");

      this.restoring = false;
      this.versionToLoad = {};

      this.$message.success(__("version switched successfully"));
    },

    onTaskImportExportErroredEventOnVersionCreation(
      taskId,
      { request_id: requestId, request_info: requestInfo }
    ) {
      this.stopListeningToTaskImportExportChannel(taskId, requestId);

      this.$message({
        type: "error",
        message: _.get(requestInfo, "error") || __("Task versioning failed")
      });
      this.exportingTask = false;
      this.$emit("version-creating", false);
      this.showTaskExportConfigurationModal = false;
    },

    onTaskImportExportErroredEventOnVersionRestore(
      taskId,
      { request_id: requestId, request_info: requestInfo }
    ) {
      this.stopListeningToTaskImportExportChannel(taskId, requestId);

      this.$message({
        type: "error",
        message: _.get(requestInfo, "error") || __("Task version switch failed")
      });
      this.changeLoadingState(false);
      this.setTaskModifiedFlag(_.get(requestInfo, "task_modified", false));
      this.restoring = false;
      this.versionToLoad = {};
    },

    createNewVersion() {
      this.$emit("version-creating", true);
      this.exportingTask = true;

      createNewVersion(
        this.task.task_id,
        {
          exportConfig: this.exportConfiguration
        },
        true
      )
        .then(({ data }) => {
          this.listenForTaskImportExportUpdates(
            data,
            this.task.task_id,
            this.onTaskImportExportCompletionEventOnVersionCreation,
            this.onTaskImportExportErroredEventOnVersionCreation
          );
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err.message || __("operation failed")
          });
          this.exportingTask = false;
          this.$emit("version-creating", false);
        })
        .finally(() => {
          this.showTaskExportConfigurationModal = false;
        });
    }
  },
  watch: {
    task: {
      immediate: true,
      deep: true,
      handler: function() {
        this.versionLoaded = this.task.task_version_in_use || null;
        this.initializeVersions();
      }
    },
    restoring: {
      handler(val) {
        this.$emit("restoring", val);
      }
    },
    loadingText: {
      handler(val) {
        this.$emit("loadingText", val);
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  .inner-container {
    width: 90%;

    .version-history-create {
      flex: 1 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;

      .el-col {
        .save-version-btn {
          width: 100%;
          background-color: var(--theme-color);
          border-color: var(--theme-color);

          &:hover {
            background-color: $content-theme-hover-color;
            border-color: $content-theme-hover-color;
          }

          &.is-disabled {
            color: #a0a8b5;
            background-color: #f5f5f8;
            border-color: #f5f5f8;

            &:hover {
              background-color: #f5f5f8;
            }
          }
        }
      }
    }

    .history-group {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .title {
        font-size: 0.725rem;
        color: #696969;
      }

      .version-history-collection {
        margin-top: 10px;
        align-self: stretch;
      }
    }

    .version-history-footer-divider {
      background-color: #ebeef5;
      margin-bottom: 20px;
    }

    .footer {
      text-align: center;
      margin-bottom: 12px;

      .foot-note {
        word-break: break-word;
        font-size: 0.715rem;
        color: #696969;
      }
    }
  }
}
</style>
