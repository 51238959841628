<template>
  <div class="edit-history">
    <el-row :gutter="20" class="header">
      <el-col :span="22" class="title">
        <div class="user-display-name">
          {{ __("Edit History") }}
        </div>
      </el-col>
      <el-col :span="2" class="actions">
        <el-button
          icon="el-icon-close"
          type="text"
          @click="handleCloseHistory"
        ></el-button>
      </el-col>
    </el-row>

    <div class="body">
      <el-timeline :reverse="true" v-loading="isLoading">
        <el-timeline-item
          v-for="(history, index) in editHistory"
          :key="'edit_history_' + index"
          :timestamp="history.readable_time"
          :ref="'EditHistory' + history.history_id + 'Ref'"
          :hide-timestamp="true"
        >
          <span v-if="history.user" slot="dot">
            <el-tooltip class="item" effect="light" placement="left">
              <div slot="content" style="text-align:center;">
                <div>
                  <b>{{ history.user.data.display_name }}</b>
                </div>
                <div>{{ history.user.data.email }}</div>
              </div>
              <avatar
                :username="history.user.data.display_name"
                :size="30"
              ></avatar>
            </el-tooltip>
          </span>

          <div class="edit-history-details">
            <div class="edit-history-description">
              <b>{{ __(history.description) }}</b>
            </div>

            <edit-history-content :history="history"></edit-history-content>

            <div v-if="history.user">
              {{ history.user.data.display_name }}
            </div>

            <el-tooltip
              class="item"
              placement="bottom-start"
              :content="history.updated_at"
              ><span class="el-timeline-item__timestamp">{{
                history.readable_time
              }}</span></el-tooltip
            >
          </div>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
import EditHistoryContent from "@/views/build/callflow/components/edit-history/EditHistoryContent";
import { mapActions } from "vuex";

export default {
  components: {
    Avatar,
    EditHistoryContent
  },
  props: {
    taskId: {
      required: true
    },
    needsReload: {
      default: false
    }
  },
  data() {
    return {
      editHistory: [],
      isLoading: false
    };
  },
  mounted() {
    this.loadEditHistory();
  },
  methods: {
    ...mapActions("tasks", {
      getEditHistories: "getEditHistories"
    }),
    handleCloseHistory() {
      this.$emit("hideDetails");
    },
    loadEditHistory() {
      this.isLoading = true;
      this.getEditHistories(this.taskId)
        .then(data => {
          this.editHistory = data;
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.isLoading = false;
          this.$emit("reloaded");
        });
    }
  },

  watch: {
    needsReload: function(newVal, oldVal) {
      if (newVal != oldVal && newVal == true) {
        this.loadEditHistory();
      }
    }
  }
};
</script>

<style lang="scss">
$content-theme-color: var(--theme-color) !default;

.edit-history {
  .el-timeline {
    .el-timeline-item {
      padding-bottom: 40px;

      .el-timeline-item__tail {
        left: 14px;
      }

      .el-timeline-item__dot {
        .edit-history-user-avatar-wrapper {
          .hover-over-user-details {
            visibility: hidden;
          }

          &:hover {
            .hover-over-user-details {
              visibility: visible;
            }
          }
        }
      }
      .el-timeline-item__wrapper {
        margin-left: 44px;
        padding-left: 0;

        .el-timeline-item__content {
          .edit-history-description {
            line-height: 30px;
          }
          .el-alert {
            padding: 8px;
            .el-alert__description {
              margin: 0;
            }
          }
        }

        .el-timeline-item__timestamp.is-bottom {
          margin-top: 6px;
        }
      }
    }
  }
}
</style>
