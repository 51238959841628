<template>
  <div class="active-users-list">
    <el-tabs
      tab-position="left"
      v-model="activeName"
      @tab-click="handleTabClick"
      :class="showDetails ? 'show-details' : ''"
    >
      <el-tab-pane v-if="phoneNumber.length > 0 && showLiveCall">
        <span slot="label">
          <el-tooltip
            class="item"
            effect="light"
            :content="__('Live Call Monitoring')"
            placement="left"
          >
            <div class="edit-history-icon">
              <i class="el-icon-phone"></i>
            </div>
          </el-tooltip>
        </span>
        <live-call-monitoring
          :task-id="taskId"
          :phone-number="phoneNumber"
          class="active-user-details"
          v-if="showDetails"
          :class="isSliding ? 'sliding' : ''"
          @hideDetails="showDetails = false"
          @updateActiveNode="handleUpdate"
        ></live-call-monitoring>
      </el-tab-pane>
      <el-tab-pane name="default">
        <edit-history
          :task-id="taskId"
          :needs-reload="reloadEditHistory"
          class="active-user-details"
          v-if="showDetails && !showValidator"
          :class="isSliding ? 'sliding' : ''"
          @hideDetails="showDetails = false"
          @reloaded="reloadEditHistory = false"
        ></edit-history>

        <task-validator
          class="active-user-details"
          v-else-if="showDetails && showValidator"
          :class="isSliding ? 'sliding' : ''"
          @hideDetails="showDetails = false"
          :task-id="taskId"
        ></task-validator>
      </el-tab-pane>
      <template v-if="activeUsersValid.length > 1">
        <el-tab-pane
          v-for="(activeUser, index) in activeUsersValid"
          :key="'active_user_' + index"
        >
          <span slot="label">
            <el-tooltip class="item" effect="light" placement="left">
              <div slot="content" style="text-align:center;">
                <div>
                  <b>{{ activeUser.display_name }}</b>
                </div>
                <div>{{ activeUser.email }}</div>
              </div>
              <avatar
                :id="'user_avatar_' + activeUser.user_id"
                :username="activeUser.display_name"
                :size="30"
              ></avatar>
            </el-tooltip>
          </span>
          <div
            class="active-user-details"
            v-if="showDetails && !showValidator"
            :class="isSliding ? 'sliding' : ''"
          >
            <el-row :gutter="20" class="header">
              <el-col :span="22" class="title">
                <div class="user-display-name">
                  {{ activeUser.display_name }}
                </div>
                <span class="user-email">{{ activeUser.email }}</span>
              </el-col>
              <el-col :span="2" class="actions">
                <el-button
                  icon="el-icon-close"
                  type="text"
                  @click="showDetails = false"
                ></el-button>
              </el-col>
            </el-row>

            <div class="body">
              <el-timeline :reverse="true">
                <el-timeline-item
                  v-for="(activity, index) in activeUser.activities"
                  :key="'active_user_activities_' + index"
                  :timestamp="activity.timestamp"
                >
                  <div>
                    {{ __(activity.action) }}
                    <span v-if="activity.node && activity.node.node_id"
                      >&nbsp;{{ __("node") }}&nbsp;</span
                    >
                    <el-button
                      v-if="activity.node && activity.node.node_id"
                      type="text"
                      :disabled="
                        activity.action == 'deleted' ||
                          activity.action == 'unlinked' ||
                          ((activity.action == 'pasted' ||
                            activity.action == 'added') &&
                            isDisabled)
                      "
                      @click="directToNode(activity.node.node_id)"
                      >{{ activity.node.node_name }}</el-button
                    >
                  </div>
                </el-timeline-item>
              </el-timeline>
            </div>
          </div>
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
import { mapActions, mapGetters, mapState } from "vuex";
import { EventBus } from "@/EventBus";
import moment from "moment-timezone";
import EditHistory from "@/views/build/callflow/components/edit-history/EditHistory";
import LiveCallMonitoring from "@/views/build/callflow/components/live-call-monitoring/LiveCallMonitoring";
import localStorageSession from "@/utils/localStorageManager";
import TaskValidator from "@/views/build/callflow/components/task-validator/TaskValidator";

export default {
  components: {
    TaskValidator,
    Avatar,
    EditHistory,
    LiveCallMonitoring
  },
  props: {
    canvasName: {
      type: String,
      default: __("Main Dialog")
    },
    acId: {
      required: true
    },
    taskId: {
      required: true
    },
    editingNode: {
      default: null
    },
    phoneNumber: {
      required: true
    }
  },
  data() {
    return {
      activeUsers: [],
      channel: null,
      showDetails: false,
      showValidator: false,
      isSliding: false,
      isDisabled: false,
      cancelForm: false,
      reloadEditHistory: false,
      activeName: "default",
      showLiveCall: false
    };
  },

  computed: {
    ...mapState("app", {
      token: state => state.token,
      selectedAccountId: "selectedAccountId"
    }),

    ...mapGetters("canvas", {
      getOriginalNode: "getOriginalNode"
    }),

    userPresenceTaskChannel() {
      return "user_presence." + this.acId + "_" + this.taskId;
    },

    activeUsersValid() {
      return this.activeUsers.filter(u => u && u.user_id);
    }
  },

  created() {
    EventBus.$on("log-user-activity", (action, node = null) => {
      this.reloadEditHistory = true;
      this.updateUserActivity(this.$auth.user().user_id, action, node, false);
      this.channel.whisper("node_updated", {
        node: node,
        action: action,
        user_id: this.$auth.user().user_id
      });
    });

    EventBus.$on("show-task-validator", () => {
      this.activeName = "default";
      this.handleTabClick();
      this.showValidator = true;
    });

    EventBus.$on("show-task-edit-history", () => {
      this.activeName = "default";
      this.handleTabClick();
    });
  },

  mounted() {
    this.activeUsers = [];
    this.joinUserPresenceTaskChannel();
    this.joinUserPresenceCanvasChannel(this.canvasName);
  },

  methods: {
    ...mapActions("canvas", {
      getNodes: "getNodes",
      toggleValidationsInProgress: "toggleValidationsInProgress"
    }),

    async showLiveCallMonitoring() {
      this.showLiveCall = await this.showFeature(
        this.$getConst("CALLFLOW_VISUALISER")
      );
    },

    userPresenceCanvasChannel(canvasName) {
      return this.userPresenceTaskChannel + "." + canvasName.replace(/ /g, "_");
    },

    joinUserPresenceTaskChannel() {
      if (this.$echo && this.$echo.connector.socket.connected && this.token) {
        this.$echo.connector.options.auth.headers.Authorization =
          "Bearer " + localStorageSession.get("studio7-user-token");
        this.$echo
          .private(this.userPresenceTaskChannel)
          .listen("TaskUpdated", async eventData => {
            this.reloadEditHistory = true;
            if (eventData && eventData.user_id != this.$auth.user().user_id) {
              this.$notify.closeAll();
              let message = __("Task has been updated by ");

              if (this.editingNode && this.editingNode.node_id) {
                try {
                  // edit mode
                  let nodeUpdated = await this.getUpdatedNode(
                    this.editingNode.node_id
                  );
                  if (nodeUpdated) {
                    // set nodeToBind
                    EventBus.$emit(
                      "update-connecting-nodes",
                      nodeUpdated.connector_child,
                      nodeUpdated.connector_parent
                    );
                  } else {
                    this.toggleValidationsInProgress(true);
                    this.cancelForm = true;
                    message = __("This node might have been deleted by ");
                  }
                } catch (e) {
                  console.error(__("error listening to task update event"), e);
                  message = __("Task has been updated ");
                }
              }
              this.$notify({
                title: __("Task updated"),
                duration: 0,
                customClass: "reload-task-notif",
                message: __(":message :displayName: Click here to refresh", {
                  message: message,
                  displayName: eventData.display_name
                }),
                onClick: this.reloadTask
              });
            }
          });
      }
    },

    joinUserPresenceCanvasChannel(canvasName) {
      if (this.$echo && this.$echo.connector.socket.connected && this.token) {
        this.$echo.connector.options.auth.headers.Authorization =
          "Bearer " + localStorageSession.get("studio7-user-token");
        let canvasChannel = this.userPresenceCanvasChannel(canvasName);

        try {
          this.channel = this.$echo
            .join(canvasChannel)
            .here(users => {
              this.activeUsers = users;
            })
            .joining(user => {
              if (user && user.user_id) {
                let userIndex = this.activeUsers.findIndex(
                  u => u.user_id == user.user_id
                );
                userIndex === -1 ? this.activeUsers.push(user) : "";
              }
            })
            .leaving(user => {
              console.log(user);
              if (user && user.user_id) {
                this.activeUsers = this.activeUsers.filter(
                  activeUser => user.user_id !== activeUser.user_id
                );
              } else {
                console.log("leaving", user);
              }
            })
            .listenForWhisper("joined_canvas", e => {
              this.updateUserActivity(e.user_id, "joined canvas");
            })
            .listenForWhisper("node_updated", e => {
              if (
                e.action == "deleted" ||
                e.action == "pasted" ||
                e.action == "added"
              ) {
                this.isDisabled = true;
              } else {
                this.isDisabled = false;
              }
              this.updateUserActivity(
                e.user_id,
                e.action,
                e.node,
                e.action == "editing" ? true : false
              );
            })
            .error(error => {
              console.error(error);
            });
        } catch (e) {
          console.error("error landing on canvas", e);
        }
      } else {
        console.log(
          this.$echo,
          this.$echo.connector.socket.connected,
          this.token
        );
      }
    },

    leaveUserPresenceTaskChannel() {
      this.$echo.leave(this.userPresenceTaskChannel);
    },

    leaveUserPresenceCanvasChannel(canvasName) {
      let channel = this.userPresenceCanvasChannel(canvasName);
      this.$echo.leave(channel);
      this.channel = null;
    },

    updateUserActivity(userId, action, node = null, isEditing = false) {
      let userIndex = this.activeUsers.findIndex(u => u.user_id == userId);
      if (
        userIndex >= 0 &&
        this.activeUsers[userIndex] &&
        this.activeUsers[userIndex].activities
      ) {
        this.activeUsers[userIndex].activities.push({
          action: action,
          node: node,
          timestamp: moment().format("YYYY-MM-DD HH:mm:ss")
        });
        this.$set(this.activeUsers[userIndex], "is_editing", isEditing);
      }
    },

    reloadTask() {
      this.isDisabled = false;
      this.$notify.closeAll();
      EventBus.$emit(
        "reload-task-and-overwrite",
        this.canvasName,
        this.cancelForm
      );
      this.cancelForm = false;
      this.toggleValidationsInProgress(false);
    },

    handleTabClick() {
      this.isSliding = this.showDetails === false;
      this.showDetails = true;
      this.showValidator = false;
    },

    directToNode(nodeId) {
      EventBus.$emit("direct-to-node", nodeId);
    },

    async getUpdatedNode(nodeId) {
      try {
        await this.getNodes({
          ac_id: this.acId,
          task_id: this.taskId
        });
        return this.getOriginalNode(nodeId);
      } catch (e) {
        console.error("error getting updated node", e);
      }
      return null;
    },

    handleUpdate(value) {
      this.$emit("active-node-id", value, false);
    }
  },

  watch: {
    canvasName: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.leaveUserPresenceCanvasChannel(oldVal);
        this.joinUserPresenceCanvasChannel(newVal);
      }
    },
    selectedAccountId: {
      immediate: true,
      async handler(newVal) {
        if (newVal === "all") {
          this.showLiveCall = false;
        } else {
          await this.showLiveCallMonitoring();
        }
      }
    },

    editingNode: {
      deep: true,
      handler(newVal) {
        if (newVal && newVal.node_id) {
          this.updateUserActivity(
            this.$auth.user().user_id,
            "editing",
            newVal,
            true
          );
          this.channel.whisper("node_updated", {
            node: newVal,
            action: "editing",
            user_id: this.$auth.user().user_id
          });
        }
      }
    }
  },

  beforeDestroy() {
    EventBus.$off("log-user-activity");
    EventBus.$off("show-task-validator");
    EventBus.$off("show-task-edit-history");

    this.$notify.closeAll();
    this.leaveUserPresenceTaskChannel();
    this.leaveUserPresenceCanvasChannel(this.canvasName);
  }
};
</script>

<style lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;

.active-users-list {
  position: absolute;
  right: 0;
  top: 65px;
  height: 100vh;

  .el-tabs.el-tabs--left {
    &.show-details {
      .el-tabs__item.is-active {
        .vue-avatar--wrapper {
          border-color: $content-theme-color;
        }

        .edit-history-icon {
          border-color: $content-theme-row-hover-color;
        }
      }
    }
    .el-tabs__header {
      .el-tabs__item {
        .vue-avatar--wrapper {
          border: 3px solid transparent;
        }
      }

      .el-tabs__nav-wrap.is-left::after {
        opacity: 0;
      }

      .el-tabs__active-bar {
        opacity: 0;
      }
    }

    .el-tabs__content {
      z-index: 2000;

      .active-user-details {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
        overflow-wrap: break-word;
        background: white;
        height: 100vh;
        border-left: 1px solid rgb(245, 245, 248);

        &.sliding {
          animation-duration: 150ms;
          animation-fill-mode: both;
          -webkit-animation-duration: 150ms;
          -webkit-animation-fill-mode: both;
          opacity: 0;
          animation-name: easeInOut;
          -webkit-animation-name: easeInOut;

          @keyframes easeInOut {
            from {
              transform: translateX(100%);
            }

            to {
              opacity: 1;
              transform: translateX(0px);
            }
          }

          @-webkit-keyframes easeInOut {
            from {
              transform: translateX(100%);
            }

            to {
              opacity: 1;
              transform: translateX(0px);
            }
          }
        }

        .header {
          display: flex;
          flex-direction: row;
          align-items: stretch;
          border-bottom: 1px solid #f5f5f8;
          padding: 10px 20px 15px 10px;

          .title {
            .user-display-name {
              font-size: 14px;
              font-weight: bold;
              margin-top: 5px;
              margin-bottom: 5px;
            }
            .user-email {
              color: #80868b;
              font-size: 12px;
              font-weight: 500;
            }
          }

          .actions {
            align-self: flex-start;
          }
        }

        .body {
          padding: 20px 10px 100px 10px;
          overflow-y: scroll;
          .el-timeline {
            padding-left: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .active-users-list {
    top: 110px;
  }
}

.main-editor-notify {
  padding-top: 25px;
  padding-bottom: 25px;

  .el-alert {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.15);
    border: 1px solid;
    color: #000000;

    &.el-alert--success.is-light {
      background-color: #daf6e9;
      border-color: #66daa4;
    }

    &.el-alert--warning.is-light {
      background: #ffeebf;
      border-color: #ffe08d;
    }
  }
}

.reload-task-notif {
  cursor: pointer;
  .el-notification__group {
    cursor: pointer;
    .el-notification__content {
      text-align: left;
    }
  }
}

.edit-history-icon {
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  text-align: center;
  user-select: none;
  background-color: $content-theme-color;
  color: rgb(238, 238, 238);
  border: 3px solid transparent;
}
</style>
