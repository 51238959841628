<template>
  <el-form>
    <el-form-item>
      <el-row type="flex" style="padding: 0 10px;">
        <el-col :offset="8" :span="16" style="padding-right: 10px">
          <!-- eslint-disable-next-line -->
          {{ __("checking the box will attach latest 10000 records from the respective datastore") }}
        </el-col>
      </el-row>
    </el-form-item>
    <!--    <el-scrollbar class="datastore-items" :native="false">-->
    <el-form-item
      class="datastore-item"
      :key="key"
      v-for="(value, key) in config"
    >
      <el-row style="padding: 0 10px" type="flex">
        <el-col :span="8">{{ dataStoreName(key) }}</el-col>
        <el-col :span="7">
          <el-checkbox
            :disabled="hasAudioColumn(key)"
            v-model="config[key]"
          ></el-checkbox>
          <span
            @click="handleClick(key)"
            class="title"
            :class="!hasAudioColumn(key) ? 'pointer' : ''"
            style="padding-left: 10px;"
          >
            {{ __("Attach data") }}
          </span>
        </el-col>
        <el-col :span="9" v-if="hasAudioColumn(key)">
          {{
            __("cannot export data as this datastore has audio columns")
          }}</el-col
        >
      </el-row>
    </el-form-item>
    <!--    </el-scrollbar>-->
  </el-form>
</template>

<script>
import _ from "lodash";
import BaseExportConfig from "./BaseExportConfig";

export default {
  mixins: [BaseExportConfig],
  computed: {
    dataStore() {
      return id =>
        _.find(
          this.contents,
          content => content.data_store_id.toString() === id.toString()
        );
    },
    dataStoreName() {
      return id => {
        let ds = this.dataStore(id);
        return !_.isEmpty(ds) ? ds.display_name : "";
      };
    },
    hasAudioColumn() {
      return id => {
        let ds = this.dataStore(id);
        if (_.isEmpty(ds)) {
          return true;
        }
        return _.some(
          ds.collection_structure,
          column => column.type === "audio"
        );
      };
    }
  },
  methods: {
    handleClick(key) {
      if (!this.hasAudioColumn(key)) {
        this.config[key] = !this.config[key];
      }
    }
  }
};
</script>

<style scoped lang="scss">
.datastore-item {
  .pointer {
    cursor: pointer;
  }
}

//.datastore-items {
//  ::v-deep .el-scrollbar__wrap {
//    max-height: 40vh;
//  }
//}
</style>
