<template>
  <div>
    <div v-if="log.event === 'STUDIO_CONVERSATION'" class="message-wrapper">
      <div
        :class="['message', isIva ? 'iva-message' : 'customer-message']"
        :style="{ alignSelf: isIva ? 'flex-start' : 'flex-end' }"
      >
        <div v-if="isIva" class="avatar">IVA</div>
        <div class="message-bubble">{{ log.log_parameters.message }}</div>
      </div>
      <div :class="['timestamp', isIva ? 'iva' : '']">
        {{ formattedTimestamp }}
      </div>
    </div>

    <div
      v-if="log.event === 'NODE_LOG' && audioRecordingUrl"
      class="message-wrapper"
    >
      <div style="align-self: flex-end; max-width: 300px">
        <div class="message-bubble">
          <audio :src="audioRecordingUrl" controls></audio>
        </div>
      </div>
      <div class="timestamp">
        {{ formattedTimestamp }}
      </div>
    </div>

    <div
      v-if="
        log.event === 'NODE_LOG' &&
          isNodeLogsEnabled &&
          log.log_parameters.payload
      "
    >
      <node-variable-log
        :nodelog="formatLogNode(log)"
        :nodeName="log.log_parameters.payload.node_name"
        @clicked="$emit('clicked')"
      ></node-variable-log>
      <br />
    </div>
  </div>
</template>

<script>
import NodeVariableLog from "@/views/build/callflow/components/live-call-monitoring/NodeVariableLog.vue";

export default {
  components: { NodeVariableLog },
  props: {
    log: {
      type: Object,
      required: true
    },
    isNodeLogsEnabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isIva() {
      return this.log.log_parameters.conversation_from === "Virtual Agent";
    },
    formattedTimestamp() {
      const timestampStr = this.log.log_parameters.timestamp;

      if (!timestampStr) {
        return "No timestamp available";
      }

      const timestamp = parseInt(timestampStr, 10);
      if (isNaN(timestamp)) {
        return "No timestamp available";
      }

      const date = new Date(timestamp);
      if (isNaN(date.getTime())) {
        return "No timestamp available";
      }

      return date.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit"
      });
    },
    audioRecordingUrl() {
      return this.getRecordingUrl(this.log);
    }
  },
  methods: {
    replacer(key, value) {
      if (key === "variables") {
        return undefined;
      }
      return value;
    },
    formatLogNode(log) {
      if (
        log.log_parameters.payload &&
        log.log_parameters.payload.node_values &&
        log.log_parameters.payload.node_values.form_data
      ) {
        return JSON.stringify(
          log.log_parameters.payload.node_values.form_data,
          null,
          2
        );
      } else if (
        log.log_parameters.payload &&
        log.log_parameters.payload.node_values
      ) {
        return JSON.stringify(
          log.log_parameters.payload.node_values,
          this.replacer,
          2
        );
      } else {
        return JSON.stringify(log.log_parameters, null, 2);
      }
    },
    getRecordingUrl(log) {
      if (log.log_parameters) {
        const params = log.log_parameters;
        if (params.rec_audio_public_url_var) {
          return params.rec_audio_public_url_var;
        }

        if (params.payload && params.payload.node_values) {
          const nodeValues = params.payload.node_values;
          if (nodeValues.rec_audio_public_url_var) {
            return nodeValues.rec_audio_public_url_var;
          }
        }
      }
      return null;
    }
  }
};
</script>

<style scoped>
.message-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  padding: 0 8px;
  position: relative;
  max-width: 30vw;
}

.message {
  display: flex;
  align-items: center;
  word-wrap: break-word;
  border-radius: 18px;
  padding: 12px 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 4px;
}

.iva-message {
  align-self: flex-start;
  flex-direction: row;
  background-color: #f8f8f8;

  .avatar {
    min-width: 35px;
    height: 35px;
    background-color: #e0e0e0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 12px;
    margin-right: 10px;
  }

  .message-bubble {
    color: #333;
    border-top-left-radius: 0;
    margin-left: 5px;
    max-width: 80%;
  }
}

.customer-message {
  align-self: flex-end;
  flex-direction: column;
  background-color: #0057ff;
  color: white;

  .message-bubble {
    border-top-right-radius: 0;
    text-align: left;
  }
}

.timestamp {
  font-size: 12px;
  color: #888;
  margin-top: 4px;
  text-align: right;
  padding-right: 5px;
  align-self: flex-end;
  margin-right: 10px;
}

.iva {
  align-self: flex-start;
}
</style>
