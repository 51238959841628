<template>
  <div id="nodes-palette" :class="`nodes-palette ${darkModeClass}`">
    <div>
      <el-button
        icon="el-icon-arrow-left"
        plain
        text
        class="backBtn"
        @click="handleBackBtnClick"
        >{{ __("Back") }}</el-button
      >
      <div
        v-if="!isTaskReadOnly"
        style="display: flex; justify-content: center"
      >
        <el-input
          @focus="$emit('searching-nodes', true)"
          @blur="$emit('searching-nodes', false)"
          v-model="search"
          :placeholder="__('Search')"
          class="searchInput"
        >
          <img
            slot="suffix"
            :src="require('@/assets/icons/icon-search.svg')"
            class="input-icon"
            style="padding-right: 10px"
        /></el-input>
      </div>
    </div>
    <el-scrollbar class="palette-scroll" :native="false" v-if="!isTaskReadOnly">
      <div v-for="group in groups" :key="group" class="palette-row">
        <el-row class="group-title">
          <h3 :class="getClassFor(group)">{{ group }}</h3>
        </el-row>
        <el-row type="flex" class="group-row" :gutter="4">
          <el-col
            v-for="item in groupItemsToShowFor(group)"
            :key="item.NODE_TYPE"
            :span="6"
            style="max-width: 66px"
          >
            <!-- <el-tooltip
              v-if="item.NODE_TYPE"
              effect="dark"
              :content="item.TOOLTIP"
              placement="top"
            > -->
            <div>
              <div
                :class="getNodeClass(item.NODE_TYPE)"
                :data-drop-rules="JSON.stringify(item.DROP_RULES)"
                :data-node-type="item.NODE_TYPE"
                :data-node-name="item.TOOLTIP"
              >
                <!--<img
                  class="node-cell-icon"
                  v-if="item.NODE_TYPE"
                  :src="`/icons/${item.NODE_TYPE}.svg`"
                  draggable="false"
                />-->
                <img
                  class="node-cell-icon"
                  v-if="item.NODE_TYPE"
                  :src="`/icons/${item.NODE_TYPE}.svg`"
                  draggable="false"
                  onload="SVGInject(this)"
                />
              </div>
              <div style="textAlign: center; fontSize: 10px; paddingTop: 8px;">
                {{ item.TOOLTIP }}
              </div>
            </div>
            <!-- </el-tooltip>
            <div v-else :class="getNodeClass(item.NODE_TYPE)" /> -->
          </el-col>
        </el-row>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";
import { NODE_GROUP } from "@/constants/nodes";

export default {
  name: "nodesPalette",
  props: {
    isTemplate: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      groupConfig: NODE_GROUP,
      maxRowsInARow: 4,
      search: ""
    };
  },
  computed: {
    ...mapState("canvas", {
      task_type: state => state.task_type
    }),

    ...mapState("app", {
      ac_id: state => state.selectedAccountId
    }),

    ...mapGetters("canvas", {
      nodeTypeOptions: "nodeTypeOptions",
      isTaskReadOnly: "isTaskReadOnly"
    }),
    ...mapState("app", {
      darkTheme: state => state.darkTheme
    }),
    groups() {
      if (!this.task_type) {
        return [];
      }
      let groupNames = _.uniq(
        _.map(_.sortBy(this.groupConfig, ["DISPLAY_PRIORITY"]), "NAME")
      );

      return _.filter(groupNames, groupName => {
        const items = this.getAllItemsForGroup(groupName);
        return !_.isEmpty(items);
      });
    },

    groupItemsToShowFor() {
      return group => {
        // the new design by STUD-7276 indicates no place holder items are needed

        // const itemsNeededToCompleteTheRow = this.maxRowsInARow - items.length;
        // for (let i = 0; i < itemsNeededToCompleteTheRow; i++) {
        //   items.push(NODE_TYPES.EMPTY_NODE);
        // }
        return this.getAllItemsForGroup(group);
      };
    },
    darkModeClass() {
      return this.darkTheme ? "dark" : "";
    }
  },
  methods: {
    ...mapActions("nodeTypes", {
      getNodeTypes: "getNodeTypes"
    }),

    ...mapActions("canvas", {
      setPaletteMounted: "setPaletteMounted"
    }),

    handleBackBtnClick() {
      if (!this.isTemplate) {
        this.$router.push({ name: "tasks" });
      } else {
        this.$router.push({ name: "task-library" });
      }
    },

    getClassFor(group) {
      if (group === undefined || group === null) {
        return "group";
      } else {
        return (
          "group " +
          group
            .toLowerCase()
            .split(" ")
            .join("-") +
          "-block"
        );
      }
    },

    getAllItemsForGroup(groupName) {
      const groupFound = _.find(this.groupConfig, { NAME: groupName }) || {};
      const allowedNodeTypes = this.nodeTypeOptions(
        this.ac_id,
        this.task_type,
        true
      );
      return _.cloneDeep(
        _.filter(groupFound.NODES, node => {
          return (
            _.map(allowedNodeTypes, "NODE_TYPE").includes(node.NODE_TYPE) &&
            node.TOOLTIP.toLowerCase().includes(this.search.toLowerCase())
          );
        })
      );
    },

    getNodeClass(node) {
      if (node === undefined || node === null) {
        return "node-cell empty";
      } else {
        return "node-cell";
      }
    }
  },
  watch: {
    search: function() {
      this.$nextTick(() => this.$emit("node-type-search"));
    }
  }
};
</script>
<style scoped lang="scss">
@import "~@/styles/node-palette.scss";
@import "~@/styles/callflow-editor.scss";

.nodes-palette {
  background: $node-palette-background;
  .node-cell {
    background-color: $node-cell-background;
    &:not(.empty):hover {
      background-color: $node-cell-hover-background;
    }
  }

  .backBtn {
    color: black;
  }

  .nodes-block {
    color: black;
  }
}

.nodes-palette.dark {
  background: $dark-node-palette-background;

  .node-cell {
    background-color: $dark-node-cell-background;

    &:not(.empty):hover {
      background-color: $dark-node-cell-hover-background;
    }
  }

  .backBtn {
    color: lightgrey;
    background: $dark-node-palette-background;
  }

  .nodes-block {
    color: lightgrey;
  }

  .more-block {
    color: lightgrey;
  }
}

.palette-scroll {
  height: calc(100vh - 110px);
  width: 284px;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  .palette-row {
    margin-left: 10px;
    margin-right: 14px;

    &:last-child {
      margin-bottom: 10px;
    }

    h3 {
      font-size: 14px;
      margin-top: 12px;
      margin-bottom: 4px;
    }

    .group-title {
      margin-top: 0;
      padding-top: 12px;
      margin-bottom: -10px;
    }

    .group-row {
      flex-wrap: wrap;
    }

    .messaging-block {
      //color: $--color-messaging_reply-node;
    }

    .web-service-block {
      //color: $--color-integration-node;
    }

    .data-block {
      //color: $--color-variable-node;
    }

    .node-cell {
      height: 60px;
      border-radius: 6px;
      margin-top: 16px;
      outline-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        height: 24px;
        width: 24px;
        /*margin-top: 18px;*/
        /*margin-left: 18px;*/
      }

      &:not(.empty):hover {
        box-shadow: 4px 0 5px -3px rgba(0, 0, 0, 0.1),
          -4px 0 50px -3px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        ::v-deep svg {
          fill: var(--theme-color);
          path {
            fill: var(--theme-color);
          }
        }
        .node-cell-icon {
          height: 26px;
          width: 26px;
        }
      }
    }
  }
}

.nodes-block {
  padding-left: 20px;
  font-size: 16px;
  margin-bottom: 0;
}

.backBtn {
  border: none;
  font-size: 14px;
  margin-top: 15px;
  font-weight: bold;
  ::v-deep i {
    font-size: 14px;
    font-weight: bolder;
  }
  ::v-deep span {
    margin-bottom: 6px;
  }
}

.searchInput {
  width: 88%;
  margin-top: 10px;
  padding: 0 10px;
  input {
    border-color: #f5f5f8;
    padding-right: 10px;
    color: #a0a8b5;
    width: 100%;
  }
}
</style>
