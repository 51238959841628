<template>
  <div class="additional-option-item">
    <h3 class="item-heading">{{ itemHeading }}</h3>

    <el-form-item
      class="option-item"
      required
      v-for="item in items"
      :key="item[itemKey]"
      :ref="getRef(item)"
      :show-message="showErrorMsg"
      :error="getErrorMsg(item)"
    >
      <slot
        name="lineItem"
        :item="item"
        :itemLabel="itemLabel"
        :itemValue="itemValue"
        :lineItemPlaceholder="lineItemPlaceholder"
        :lineItemTitle="lineItemTitle"
        :lineItemInfo="lineItemInfo"
        :value="value"
        :errorFnConfig="errorFnConfig"
        :errorMsg="errorMsg"
        :hasOptionGroup="hasOptionGroup"
        :lineItemOptionsConfig="lineItemOptionsConfig"
        :lineItemOptionConfig="lineItemOptionConfig"
        :clearable="clearable"
      >
        <task-import-additional-option-line-item
          :item="item"
          :item-label="itemLabel"
          :item-value="itemValue"
          :line-item-placeholder="lineItemPlaceholder"
          :line-item-title="lineItemTitle"
          :line-item-info="lineItemInfo"
          :value="value"
          :error-fn-config="errorFnConfig"
          :error-msg="errorMsg"
          :has-option-group="hasOptionGroup"
          :line-item-options-config="lineItemOptionsConfig"
          :line-item-option-config="lineItemOptionConfig"
          :clearable="clearable"
        />
      </slot>
    </el-form-item>
  </div>
</template>

<script>
import _ from "lodash";
import TaskImportAdditionalOptionLineItem from "@/views/build/tasks/create-task/components/TaskImportAdditionalOptionLineItem";

export default {
  name: "TaskImportAdditionalOptionItem",
  components: { TaskImportAdditionalOptionLineItem },
  props: {
    items: {
      required: true,
      type: Array
    },
    itemConfig: {
      required: true,
      type: Object
    },
    errorFnConfig: {
      type: Object,
      required: false,
      default: () => {}
    },
    errorMsg: {
      type: String,
      required: false,
      default: ""
    },
    showErrorMsg: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      required: true,
      type: [Array, Object]
    },
    hasOptionGroup: {
      type: Boolean,
      default: true,
      required: false
    },
    lineItemOptionConfig: {
      required: true,
      type: Object
    },
    lineItemOptionsConfig: {
      required: true,
      type: Object
    },
    clearable: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    getErrorMsg() {
      return item => {
        return !_.isEmpty(this.errorFnConfig) &&
          this.errorFnConfig.fn(this.errorFnConfig.type)(item[this.itemKey])
          ? __(this.errorMsg)
          : "";
      };
    },

    itemKey() {
      return _.get(this.itemConfig, "keyProp");
    },

    itemHeading() {
      return __(_.get(this.itemConfig, "heading", ""));
    },

    itemValue() {
      return _.get(this.itemConfig, "valueProp");
    },

    itemLabel() {
      return _.get(this.itemConfig, "labelProp");
    },

    lineItemTitle() {
      return __(_.get(this.itemConfig, "title", ""));
    },

    lineItemPlaceholder() {
      return __(_.get(this.lineItemOptionConfig, "placeholder", ""));
    },

    lineItemInfo() {
      return __(_.get(this.lineItemOptionConfig, "info", ""));
    }
  },
  methods: {
    getRef(item) {
      return `${this.lineItemTitle}-${item[this.itemKey]}`;
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";

.additional-option-item {
  .item-heading {
    margin-bottom: 0;
  }
  .option-item {
    margin-bottom: 0.25em;
  }
}
</style>
