<template>
  <div class="info-card">
    <div class="info-card-icon">
      <slot />
    </div>
    <div class="info-card-title info-card-text">{{ title }}</div>
    <div class="info-card-value info-card-text">{{ xValue(value) }}</div>
  </div>
</template>

<script>
export default {
  name: "InfoCard",
  props: {
    title: {
      required: true,
      type: String
    },
    value: {
      required: true
    }
  },
  computed: {
    xValue() {
      return value => "x" + value;
    }
  }
};
</script>

<style scoped lang="scss">
.info-card {
  height: 135px;
  width: 75px;
  padding: 0 15px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;

  .info-card-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .info-card-text {
    line-height: 24px;
  }

  .info-card-title {
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .info-card-value {
    align-self: center;
    font-size: 1.05em;
  }
}
</style>
