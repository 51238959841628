<template>
  <div v-if="formAction" style="padding: 20px">
    <div v-if="formAction === 'select'">
      <div v-if="multipleItemsSelectedCount" class="side-panel-selected-count">
        <p>
          {{ __("Selected") }}
          <el-tag>
            <strong>{{ multipleItemsSelectedCount }}</strong>
          </el-tag>
          {{ __("items") }}.
        </p>
      </div>
      <div v-else class="side-panel-content-info">
        <div class="field-group">
          <div class="titles">{{ __("File Name") }}</div>
          <div class="details">{{ contentFormInfo.file_name }}</div>
        </div>
        <div class="field-group">
          <div class="titles">{{ __("File Type") }}</div>
          <div class="details">{{ contentFormInfo.file_type }}</div>
        </div>
        <div v-if="contentFormInfo.file_exists">
          <template
            v-if="certExtensionsAllowed.includes(contentFormInfo.file_type)"
          >
            <div class="field-group">
              <div class="titles">{{ __("Valid from") }}</div>
              <div class="details">{{ contentFormInfo.valid_from }}</div>
            </div>
            <div class="field-group">
              <div class="titles">{{ __("Valid to") }}</div>
              <div class="details">{{ contentFormInfo.valid_to }}</div>
            </div>
            <div class="field-group">
              <div class="titles">{{ __("MD5 Fingerprint") }}</div>
              <div class="details">{{ contentFormInfo.md5_finger_print }}</div>
            </div>
            <div class="field-group">
              <div class="titles">{{ __("SHA1 Fingerprint") }}</div>
              <div class="details">{{ contentFormInfo.sha1_finger_print }}</div>
            </div>
          </template>
          <template v-else>
            <div class="field-group">
              <div class="titles">{{ __("File URL") }}</div>
              <div class="details">
                <el-link
                  type="primary"
                  :href="contentFormInfo.file_url"
                  target="_blank"
                  :underline="false"
                  >{{ contentFormInfo.file_url }}</el-link
                >
              </div>
            </div>
          </template>
        </div>
      </div>

      <div style="margin-top: 20px;" class="side-panel-action-list">
        <el-button
          type="primary"
          v-if="canAttachAsFile"
          @click="attachFileToNode()"
          style="margin-right: 10px"
          >{{ __("Attach") }}</el-button
        >
        <el-dropdown
          @command="command => handleAction(command)"
          placement="bottom-start"
        >
          <el-button plain class="btn-plain">{{ __("Actions") }}</el-button>
          <el-dropdown-menu slot="dropdown" style="width: 200px;">
            <el-dropdown-item
              v-show="!multipleItemsSelectedCount && !isArchivedStatus"
              :disabled="!can('content.files.write')"
              command="edit"
            >
              <span>{{ __("Edit") }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              command="move"
              :disabled="!can('content.files.write')"
            >
              <span>{{ __("Move to folder") }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              v-show="!multipleItemsSelectedCount"
              command="check_in_use"
              :disabled="!!contentFormInfo.is_refreshing"
            >
              <span>{{ __("Check in use") }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              v-show="!multipleItemsSelectedCount"
              command="refresh"
            >
              <span>{{ __("Refresh check in use") }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              v-show="!multipleItemsSelectedCount && isArchivedStatus"
              :disabled="!can('content.files.write')"
              command="restore"
            >
              <span>{{ __("Restore") }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              v-show="!multipleItemsSelectedCount"
              :disabled="!can('content.files.write')"
              command="delete"
            >
              <span>{{ __("Delete") }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      :show-close="false"
      top="6vh"
      destroy-on-close
      fullscreen
      :modal="false"
    >
      <div class="account-form-dialog">
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <page-header :title="__('File')" :content-id="this.id" />
          </el-col>
        </el-row>
        <el-scrollbar :native="false">
          <el-form
            class="max-vh"
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
            :disabled="isArchivedStatus"
          >
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  prop="file_name"
                  :label="__('File name')"
                  required
                >
                  <el-input v-model="contentForm.file_name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Upload File')"
                  prop="file_url"
                  required
                >
                  <file-uploader
                    :folder="getUniqueFolder"
                    :fileUrl="contentForm.file_url ? contentForm.file_url : ''"
                    :fileList="fileList"
                    :show-file-name="false"
                    fit="compact"
                    @file-info="setFileInfo"
                    @on-success="handleSuccess"
                    @on-error="handleError"
                    @on-change="handleChange"
                    @on-delete="handleFileDelete"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" v-if="!isArchivedStatus">
              <el-col :span="12" :offset="6">
                <save-button
                  :disabled="!contentUpdated || !can('content.files.write')"
                  type="primary"
                  :loading="isLoading"
                  @click="submitForm"
                  class="submitBtn"
                  :primaryKey="id"
                  variant="AddUpdate"
                />
                <el-button class="cancelBtn" @click="cancelForm">{{
                  __("Cancel")
                }}</el-button>
              </el-col>
            </el-row>
          </el-form>
          <el-row type="flex" v-if="isArchivedStatus">
            <el-col :span="12" :offset="6">
              <el-button class="cancelBtn" @click="cancelForm">{{
                __("Cancel")
              }}</el-button>
            </el-col>
          </el-row>
        </el-scrollbar>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";
import FileUploader from "@/components/uploaders/types/FileUploader";
import { mapActions, mapState } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import { EventBus } from "@/EventBus";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import {
  CHECK_IN_USE_ACTION,
  DELETE_ACTION,
  EDIT_ACTION,
  MOVE_ACTION,
  REFRESH_ACTION,
  RESTORE_ACTION
} from "@/constants/contents";
// import axios from "axios";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    SaveButton,
    FileUploader
  },
  props: {
    canAttachAsFile: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      contentForm: {},
      fileList: [],
      isUploading: false,
      rules: {
        file_name: [
          { required: true, trigger: "blur", message: __("Name is required") }
        ],
        file_url: [
          { required: true, trigger: "blur", message: __("File is required") }
        ]
      },
      contentFormKey: 0,
      fileFolder: "file",
      isSubmitting: false,
      additionalValidateRoute: "files",
      certExtensionsAllowed: ["cert", "key", "pem", "crt", "csr"],
      isContent: true,
      viewMode: "allow_read",
      forceDeleteEventName: "initiate-force-delete-AttachmentFile",
      safeDeleteEventName: "initiate-safe-delete-AttachmentFile",
      uploadedFilePath: ""
    };
  },

  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    }),
    ...mapState("files", {
      isLoading: state => state.isLoading
    }),

    getUniqueFolder: {
      get: function() {
        return this.contentForm.unique_code;
      },
      // setter
      set: function(newValue) {
        this.contentForm.unique_code = newValue;
      }
    },
    ...mapState("folders", {
      selectedFolderId: state => state.selectedFolderId
    })
  },

  methods: {
    ...mapActions("files", {
      createFile: "createFile",
      updateFile: "updateFile",
      downloadFile: "downloadFile",
      updateContent: "updateFile",
      deleteContentMethod: "deleteFile",
      undoDeleteContent: "undoDeleteFile",
      safeDeleteContent: "safeDeleteFile",
      refreshFile: "refreshFile"
    }),

    ...mapActions("upload", {
      deleteFileFromStorage: "deleteLogo"
    }),

    isCertFile(fileName) {
      if (fileName) {
        const fileExtension = fileName.slice(
          ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
        );
        return this.certExtensionsAllowed.includes(fileExtension)
          ? fileExtension
          : false;
      }
      return false;
    },

    handleSuccess(res) {
      this.contentForm.file_url = res.url;
      this.contentForm.file_path = res.path;
      this.uploadedFilePath = res.path;
      this.$refs.contentForm.validateField("file_url");
      const certFileExtension = this.isCertFile(res.uploaded_file_name);
      if (certFileExtension != false) {
        this.contentForm.file_type = certFileExtension;
      }
    },

    handleError(err) {
      console.log(err);
      this.$message({
        type: "error",
        message: err.message.split(":")[1].slice(1, -2)
      });
    },

    setFileInfo(file) {
      const certFileExtension = this.isCertFile(file.name);
      if (certFileExtension == false) {
        this.contentForm.file_type = file.type;
      } else {
        this.contentForm.file_type = certFileExtension;
      }
    },

    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },

    handleFileDelete() {
      this.fileList = [];
      this.contentForm.file_type = "";
      this.contentForm.file_url = "";
      this.contentForm.file_path = "";
      this.deleteUploadedFile();
    },

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          const process = this.id === -1 ? this.createFile : this.updateFile;
          this.contentForm.ac_id = this.selectedAccountId;
          this.contentForm.folder_id = this.selectedFolderId;
          process(this.contentForm)
            .then(data => {
              this.isSubmitting = false;

              this.id === -1
                ? this.$message({
                    type: "success",
                    message: __("File Added Successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("File updated successfully")
                  });
              this.fileList = [];
              EventBus.$emit("list-changed", data.data);
              if (this.id !== -1) EventBus.$emit("canvas-changed");
              this.handleCancel();
            })
            .catch(err => {
              // console.log(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },

    cancelForm() {
      this.deleteUploadedFile();
      this.fileList = [];
      this.handleCancel();
    },

    deleteUploadedFile() {
      if (this.id === -1) {
        this.deleteFileFromStorage({ path: this.uploadedFilePath })
          .then(() => {
            this.fileList = [];
          })
          .catch(err => {
            console.log(err);
          });
      }
    },

    openMoveToFolderDialog() {
      let content_ids = [this.contentForm.file_id];
      if (this.checkedContents.length) {
        content_ids = this.checkedContents.map(item => {
          if (item && item.file_id) {
            return item.file_id;
          }
        });
      }

      EventBus.$emit("open-move-to-folder-modal", {
        content_ids: [...content_ids],
        content_model: "AttachmentFile"
      });
    },

    openCheckInUseDialog() {
      EventBus.$emit("open-check-in-use-modal", {
        content_model: "AttachmentFile",
        content_id: this.contentForm.file_id
      });
    },

    refreshCheckInUse() {
      // call backend api
      EventBus.$emit("call-refresh-check-in-use-job", {
        content_model: "AttachmentFile",
        content_id: this.contentForm.file_id,
        content_name: this.contentForm.file_name,
        refresh: this.refreshFile
      });
    },

    downloadContent() {
      this.downloadFile(this.contentForm);
    },

    attachFileToNode() {
      this.$emit(
        "add-attachment",
        this.contentFormInfo.file_url,
        this.contentFormInfo.file_id,
        this.contentFormInfo.file_name
      );
    },

    handleAction(command) {
      switch (command) {
        case EDIT_ACTION:
          this.handleEdit();
          break;
        case MOVE_ACTION:
          this.openMoveToFolderDialog();
          break;
        case CHECK_IN_USE_ACTION:
          this.openCheckInUseDialog();
          break;
        case REFRESH_ACTION:
          this.refreshCheckInUse();
          break;
        case RESTORE_ACTION:
          this.restoreContent(this.contentForm);
          break;
        case DELETE_ACTION:
          this.handleDelete(
            this.contentForm,
            "AttachmentFile",
            this.contentForm.file_id
          );
          break;
      }
    }
  },

  watch: {
    formAction: {
      immediate: true,
      deep: true,
      handler: async function(val) {
        if (val === "add") {
          this.getUniqueFolder = "file" + Date.now() + Math.random();
        }
        if (val === "edit") {
          this.getUniqueFolder = this.contentFormInfo.unique_code;
          if (this.contentForm.file_url.length) {
            this.fileList = [
              {
                name: this.contentForm.file_name,
                url: this.contentForm.file_url
              }
            ];
          }
        }
      }
    }
  },

  created() {
    EventBus.$on(this.forceDeleteEventName, ({ content }) => {
      this.initiateDelete(content);
    });
    EventBus.$on(this.safeDeleteEventName, ({ content }) => {
      this.initiateSafeDelete(content);
    });
  },

  beforeDestroy() {
    EventBus.$off(this.forceDeleteEventName);
    EventBus.$off(this.safeDeleteEventName);
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/button.scss";
@import "~@/styles/content-buttons.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

::v-deep .account-form-dialog {
  max-height: 90vh !important;
}
::v-deep .el-link--inner {
  width: 233px !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
</style>
