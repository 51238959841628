<template>
  <span style="white-space: pre-wrap;">
    <span v-if="param.label">
      <span class="label">
        {{ param.label }}
      </span>
      <el-tooltip
        :content="__('Click to navigate to the node or Ctrl+hover to peek')"
        placement="bottom"
        v-if="
          nodeLogLabels.includes(navType) &&
            nodeIdToNavigate !== -1 &&
            !disableNavigation
        "
      >
        <span
          :id="`node-link-sys-log-${nodeIdToNavigate}-${componentId}`"
          :class="
            `${
              isSubTaskLog ? 'navigateToNodeLinkSubTask' : 'navigateToNodeLink'
            }`
          "
          @click="
            $emit('navigate-to-node', nodeIdToNavigate, subTaskIdToNavigate)
          "
          @mouseover.exact="
            $emit('handle-node-link-hover', nodeIdToNavigate, componentId)
          "
          @keydown="
            handleKeyPressOnHover($event, nodeIdToNavigate, componentId)
          "
          @mouseleave="$emit('handle-node-link-mouse-leave')"
          @keyup="$emit('handle-ctrl-key-release', $event)"
          @mouseout="$emit('end-peek-node')"
          style="white-space: pre-wrap;"
          >{{ param.value }}</span
        >
      </el-tooltip>
      <el-tooltip
        :content="__('Click to navigate to the Task')"
        placement="bottom"
        v-else-if="navType === 'task' && taskIdToNavigate !== -1"
      >
        <span
          :class="
            `${
              isSubTaskLog ? 'navigateToNodeLinkSubTask' : 'navigateToNodeLink'
            }`
          "
          @click="$emit('navigate-to-task', taskIdToNavigate)"
          style="white-space: pre-wrap;"
          >{{ param.value }}</span
        >
      </el-tooltip>
      <span v-else-if="param.label.includes('Audio URL')">
        <audio-player
          :file="param.value"
          :simple-player="true"
          class="simple-player"
          style="display: inline-block;width: 24px;"
          showClose="true"
        ></audio-player>
      </span>
      <read-more v-else :text="param.value" />
    </span>
    <read-more v-else :text="param.value" />
  </span>
</template>

<script>
import AudioPlayer from "@/components/AudioPlayer";
import ReadMore from "@/components/ReadMore.vue";
import _ from "lodash";

export default {
  name: "SystemLogNodeNavigation",
  components: {
    AudioPlayer,
    ReadMore
  },
  props: {
    parameter: {
      type: String,
      required: true
    },
    nodeIdToNavigate: {
      type: Number,
      required: false,
      default: -1
    },
    taskIdToNavigate: {
      type: Number,
      required: false,
      default: -1
    },
    subTaskIdToNavigate: {
      type: Number,
      required: false,
      default: -1
    },
    isSubTaskLog: {
      type: Boolean,
      required: false,
      default: false
    },
    disableNavigation: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      param: { label: null, value: null },
      navType: "",
      nodeLogLabels: ["current_node", "next_node"],
      componentId: null
    };
  },
  mounted() {
    this.componentId = this._uid;
  },
  methods: {
    initializeLabelAndValueForParam() {
      let paramDetails = [""];
      if (this.parameter) {
        paramDetails = this.parameter.split("|||", 2);
      }
      this.param.value = paramDetails[0];
      if (paramDetails.length === 2) {
        this.navType = _.toLower(paramDetails[0].replace(/ /, "_"));
        this.param.label = paramDetails[0] + ": ";
        this.param.value = paramDetails[1] !== "-1" ? paramDetails[1] : "none";
      }
    },
    handleKeyPressOnHover(event, node_id, component_id) {
      const { key } = event;
      this.$emit("handle-ctrl-key-press-on-hover", {
        key: key,
        node_id: node_id,
        component_id: component_id
      });
    }
  },
  watch: {
    parameter: {
      immediate: true,
      handler: "initializeLabelAndValueForParam"
    }
  }
};
</script>

<style scoped lang="scss">
.label {
  color: white;
  background-color: var(--theme-color);
  border-radius: 6px;
  padding: 5px;
  margin-right: 5px;
  font-weight: bold;
  letter-spacing: 0.03em;
}

.navigateToNodeLink {
  color: var(--theme-color);
  cursor: pointer;
  text-decoration: underline;
}
.navigateToNodeLinkSubTask {
  color: black;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
}
.simple-player {
  padding: 0;
  border-radius: 12px;
  margin-left: 10px;
  display: inline-block;
  background: white !important;

  ::v-deep img {
    filter: invert(58%) sepia(52%) saturate(1516%) hue-rotate(166deg)
      brightness(96%) contrast(96%);
  }
}
</style>
