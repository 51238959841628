<template>
  <div class="regex-option">
    <div class="title-group">
      <span class="title" v-html="regexFlagOption.label"></span
      ><i v-if="selected" class="el-icon-check"></i>
    </div>
    <div class="desc">{{ regexFlagOption.desc }}</div>
  </div>
</template>

<script>
export default {
  props: {
    regexFlagOption: {
      required: true,
      type: Object
    },
    selectedFlags: {
      required: true,
      type: [String, Array]
    }
  },
  computed: {
    selected() {
      return this.selectedFlags.includes(this.regexFlagOption.identifier);
    }
  }
};
</script>

<style lang="scss" scoped>
.regex-option {
  color: black;
  display: flex;
  flex-direction: column;

  .title-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #222222;
    }
  }

  .desc {
    color: #a0a8b5;
    font-size: 0.8rem;
    line-height: 0.9rem;
    padding-bottom: 10px;
  }
}
</style>
