<script>
import At from "./At.vue";
import { getAtAndIndex } from "@/utils/AtUtil";
import { Quill } from "vue2-editor";
import { IndentStyle } from "@/utils/IndentStyle";
import { CustomListAlignStyle } from "@/utils/CustomListAlignStyle";
import _ from "lodash";
import { mapState, mapActions } from "vuex";
import { EventBus } from "@/EventBus";
// import { filter } from "vue/types/umd";

var quill = null;
var Delta = Quill.import("delta");

//Custom styling to replace defauly quill classes
Quill.register(IndentStyle, true);
Quill.register(CustomListAlignStyle, true);
var AlignStyle = Quill.import("attributors/style/align");
Quill.register(AlignStyle, true);
var BackgroundStyle = Quill.import("attributors/style/background");
Quill.register(BackgroundStyle, true);
var ColorStyle = Quill.import("attributors/style/color");
Quill.register(ColorStyle, true);
var DirectionStyle = Quill.import("attributors/style/direction");
Quill.register(DirectionStyle, true);

export default {
  extends: At,
  name: "AtRichTextarea",
  data() {
    return {
      cursorIndex: 0,
      insertedImagesArray: []
    };
  },
  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId,
      token: state => state.token
    }),
    ...mapState("canvas", {
      clickedNode: state => state.clickedNode
    }),
    style() {
      //Styling for variable popper
      if (this.atwho) {
        const { x, y } = this.atwho;
        const { wrap } = this.$refs;
        const el = this.$el.querySelector(".ql-editor");
        if (wrap) {
          const left = x + el.offsetLeft - el.scrollLeft + "px";
          const top = 165 + y + el.offsetTop - el.scrollTop + "px";
          return { left, top, "z-index": "111111 !important" };
        }
      }
      return null;
    }
  },
  created() {
    //Remove all images from storage that is not used in editor
    EventBus.$on("close-rich-text-editor", () => {
      this.removeImagesFromStorage("cancel");
    });

    EventBus.$on("save-rich-text-editor", () => {
      this.removeImagesFromStorage("save");
    });
  },

  mounted() {
    quill = Quill.find(this.$el.querySelector("#quill-container"));
    // quill editor add image handler
    quill.getModule("toolbar").addHandler("image", () => {
      this.selectImage();
    });
  },

  beforeDestroy() {
    EventBus.$off("close-rich-text-editor");
    EventBus.$off("save-rich-text-editor");
  },

  methods: {
    ...mapActions("upload", {
      upload: "upload",
      deleteUpload: "deleteUpload",
      removeUnusedFiles: "removeUnusedFiles"
    }),

    //------------------VARIABLE POPPER LOGIC---------------------
    handleInput(keep) {
      //Text - text from start to current cursor position
      //atItems - {{
      if (this.hasComposition) return;
      if (!this.editorHasFocus) return;

      this.cursorIndex = quill.getSelection(true).index;
      const text = quill.getText(0, this.cursorIndex);
      if (text) {
        const { atItems } = this;
        let show = true; //Bool to show variable popper
        //at - the {{ prefix
        //index - where the prefix starts e.g. for the text abcd{{, index would be 4
        const { at, index } = getAtAndIndex(text, atItems);
        if (index < 0) show = false;
        const chunk = text.slice(index + at.length, text.length); //Chunk is whatever we put into the {{}}, e.g. abcd{{ee, chunk would be ee

        if (!show) {
          this.closePanel();
        } else {
          const { members, filterMatch, itemName } = this;
          if (!keep) {
            this.$emit("at", chunk);
          }
          //Finding for a matching variable
          let matched = {};
          _.map(members, (member, key) => {
            if (!_.isEmpty(members[key])) {
              const filterMembers = _.filter(members[key], function(value) {
                const name = itemName(value);
                return filterMatch(name, chunk, at);
              });
              if (!_.isEmpty(filterMembers)) {
                matched[key] = filterMembers;
              }
            }
          });

          let matchedProps = {};
          let keysToFind = this.prefixToProp[at];
          _.map(keysToFind, keyToFind => {
            if (!_.isEmpty(matched[keyToFind])) {
              matchedProps[keyToFind] = _.cloneDeep(matched[keyToFind]);
            }
          });
          this.filteredMembers = _.cloneDeep(matchedProps);
          if (!_.isEmpty(matchedProps)) {
            this.openPanel(matchedProps, chunk, index, at, keep);
          } else {
            this.closePanel();
          }
        }
      } else {
        this.closePanel();
      }
    },
    openPanel(list, chunk, offset, at) {
      //Opens variable popper
      const fn = () => {
        const el = this.$el.querySelector(".ql-editor");
        const atEnd = offset + at.length;
        const rect = quill.getBounds(this.cursorIndex);
        const textAreaPos = el.getBoundingClientRect();
        this.atwho = {
          chunk,
          offset,
          list,
          atEnd,
          x: rect.left + textAreaPos.left,
          y: rect.top - 4 + textAreaPos.top,
          cur: _.head(_.keys(this.filteredMembers)) + "_0"
        };
      };
      if (this.atwho) {
        fn();
      } else {
        setTimeout(fn, 10);
      }
    },
    handleDelete() {
      this.cursorIndex = quill.getSelection(true).index;
      const text = quill.getText(0, this.cursorIndex);
      //Handles variable matching
      if (text) {
        const { atItems, members, deleteMatch, itemName } = this;
        const { at, index } = getAtAndIndex(text, atItems);
        if (index > -1) {
          const chunk = text.slice(index + at.length);
          let matched = {};
          let self = this;
          _.map(members, (member, key) => {
            if (!_.isEmpty(members[key])) {
              matched[key] = _.some(members[key], function(value) {
                const name = itemName(value);
                return deleteMatch(name, chunk, self.suffixes[at]);
              });
            }
          });
        }
      }
    },
    insertItem() {
      //When user chooses a variable, call insert text
      const { list, cur, atEnd } = this.atwho;
      const { atItems, itemName } = this;
      const el = this.$el.querySelector(".ql-editor");
      const text = el.textContent.slice(0, atEnd);
      const { at, index } = getAtAndIndex(text, atItems);
      el.selectionStart = index + at.length;
      el.focus();
      const current = cur.split("_");
      const curItem = list[current[0]][current[1]];
      const t = itemName(curItem) + this.suffixes[at];
      this.insertText(t);
      this.$emit("insert", curItem);
      this.handleInput();
      this.closePanel();
    },
    insertText(text) {
      //Inserts text into editor, e.g. if current input is abc{{test and user chooses test_passed variable, "text" will be test_passed}}
      const oldText = quill.getText(0, this.cursorIndex);
      const { atItems } = this;
      const { at, index } = getAtAndIndex(oldText, atItems);
      const chunk = oldText.slice(index + at.length, oldText.length);
      quill.updateContents(
        new Delta()
          .retain(this.cursorIndex - chunk.length)
          .delete(chunk.length)
          .insert(text)
      );
      quill.setSelection(this.cursorIndex - chunk.length + text.length);
      this.dispatchInput();
    },

    handleEditorInFocus() {
      this.editorHasFocus = true;
    },
    handleEditorNotInFocus() {
      this.editorHasFocus = false;
      this.handleInput();
    },

    //Referencing At.vue, only handling keystrokes for editor
    handleKeyDown(e) {
      if (e.target.className === "ql-editor") {
        this.handleEditorInFocus();
        const { atwho } = this;
        if (atwho) {
          if (e.keyCode === 38 || e.keyCode === 40) {
            // ↑/↓
            if (!(e.metaKey || e.ctrlKey)) {
              e.preventDefault();
              e.stopPropagation();
              this.selectByKeyboard(e);
            }
            return;
          }
          if (
            e.keyCode === 13 ||
            e.keyCode === 32 ||
            (this.tabSelect && e.keyCode === 9)
          ) {
            // enter or space or tab
            this.insertItem();
            e.preventDefault();
            e.stopPropagation();
            return;
          }
          if (e.keyCode === 27 || (!this.tabSelect && e.keyCode === 9)) {
            // esc or tab away
            this.closePanel();
            return;
          }
        }

        const isValid = (e.keyCode >= 48 && e.keyCode <= 90) || e.keyCode === 8;
        if (isValid) {
          setTimeout(() => {
            this.handleInput();
          }, 50);
        }

        if (e.keyCode === 8) {
          this.handleDelete(e);
        }
      } else {
        this.handleEditorNotInFocus();
      }
    },

    //--------------------UPLOADING IMAGE TO STORAGE LOGIC---------------------
    //Custom image uploader
    getImgUrls(delta) {
      return delta.ops
        .filter(i => i.insert && i.insert.image)
        .map(i => i.insert.image);
    },
    selectImage() {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.click();

      // Listen upload local image and save to server
      input.onchange = () => {
        const file = input.files[0];

        // file type is only image.
        if (/^image\//.test(file.type)) {
          this.saveImageToStorage(file);
        } else {
          this.$message({
            message: __("Only images are allowed"),
            type: "error"
          });
        }
      };
    },

    async saveImageToStorage(file) {
      var formData = new FormData();
      formData.append("file", file);
      let folder = this.getFolderName();
      formData.append("folder", folder);

      await this.upload(formData)
        .then(res => {
          this.insertedImagesArray.push(res.data.path);
          this.insertToEditor(res.data.url);
          console.log(res);
        })
        .catch(() => {
          this.$notify({
            message: __("Failed to upload images"),
            type: "error"
          });
        });
    },
    insertToEditor(url) {
      const range = quill.getSelection();
      quill.insertEmbed(range.index, "image", url);
    },
    getFolderName() {
      return `sp_${this.selectedServiceProviderId}/ac_${this.selectedAccountId}/images/node_${this.clickedNode.node_id}`;
    },
    removeImagesFromStorage(action) {
      //If action is cancel, should remove all newly uploaded images.
      //If action is save, check all the images in the editor and remove those that are not needed from storage
      if (action === "cancel") {
        for (let i = 0; i < this.insertedImagesArray.length; i++) {
          let path = this.insertedImagesArray[i];
          this.deleteUpload({ path: path })
            .then(() => {})
            .catch(() => {
              this.$notify({
                message: __("Failed to delete unused images"),
                type: "error"
              });
            });
        }
      }
      if (action === "save") {
        //Get content of editor
        let editorContent = quill.getContents();
        //Get all image url
        let imgUrls = this.getImgUrls(editorContent);
        let folder = this.getFolderName();
        //pass to backend
        this.removeUnusedFiles({ folder: folder, fileUrls: imgUrls })
          .then(() => {})
          .catch(() => {
            this.$notify({
              message: __("Failed to remove unused images"),
              type: "error"
            });
          });
      }
    },

    //Added for consistency with At.vue
    handleValueUpdate(value) {
      const el = this.$el.querySelector(".ql-editor");
      if (value !== el.innerHTML) {
        el.innerHTML = value;
        this.dispatchInput();
      }
    },
    //Added for consistency with At.vue
    dispatchInput() {
      let el = this.$el.querySelector(".ql-editor");
      let ev = new Event("input", { bubbles: true });
      el.dispatchEvent(ev);
    }
  }
};
</script>
