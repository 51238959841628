<template>
  <div
    v-on-clickaway="updateDblClickStatusToTrue"
    @dblclick.self.stop="updateDblClickStatus(false)"
    @click.self="waitForDblClickAndEmitEvent"
    class="canvas-tab"
  >
    <div
      v-if="notDoubleClicked"
      @dblclick.self.stop="updateDblClickStatus(false)"
      @click.self="waitForDblClickAndEmitEvent"
      :title="canvas.canvas_name"
    >
      {{ canvas.canvas_name }}
    </div>
    <div v-else>
      <input
        v-focus
        v-model="newName"
        type="text"
        @focus="handleCanvasFocus"
        @blur="updateCanvasName"
        @keydown.enter.stop="updateCanvasName"
      />
    </div>
    <div class="inside-container">
      <div
        v-if="!isSystemCanvas(canvas.canvas_name) && showDeleteCanvas"
        @click.stop="updateDblClickStatus(false)"
        class="inline-icon"
      >
        <img
          class="canvas-action-button"
          :src="require('@/assets/icons/icon-edit.svg')"
          onload="SVGInject(this)"
          alt="edit"
        />
      </div>
      <div
        v-if="!isSystemCanvas(canvas.canvas_name) && showDeleteCanvas"
        @click.stop="handleDelete"
        class="inline-icon"
      >
        <img
          class="canvas-action-button"
          :src="require('@/assets/icons/icon-delete-canvas.svg')"
          onload="SVGInject(this)"
          alt="delete"
        />
      </div>
      <el-tooltip
        class="item"
        effect="dark"
        :content="__('Move to Event Handler Canvas')"
        placement="right"
      >
        <div
          v-if="
            !isSystemCanvas(canvas.canvas_name) &&
              showDeleteCanvas &&
              showAddToEventHandlerIcon
          "
          @click.stop="showUpdateCanvasNiNmConfirmation"
          class="inline-icon"
        >
          <img
            class="canvas-action-button"
            :src="require('@/assets/icons/move-to-event-handler-canvas.svg')"
            onload="SVGInject(this)"
            alt="move"
          />
        </div>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import _ from "lodash";
import { mapActions, mapGetters, mapState } from "vuex";
import { EventBus } from "@/EventBus";

export default {
  mixins: [clickaway],
  props: {
    canvas: {
      required: true,
      type: Object
    },
    showDeleteCanvas: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      notDoubleClicked: true,
      newName: "",
      clickCounter: 0,
      timer: null
    };
  },

  computed: {
    ...mapState("canvas", {
      renamingCanvasSuccessful: state => state.renamingCanvasSuccessful,
      newCanvas: state => state.newCanvas,
      selectedCanvas: state => state.selectedCanvas,
      task: state => state.task,
      canvasUsages: state => state.canvasUsages,
      canvases: state => state.canvases
    }),
    ...mapState("app", {
      darkTheme: state => state.darkTheme
    }),

    ...mapGetters("canvas", {
      mainCanvas: "mainCanvas"
    }),

    showAddToEventHandlerIcon() {
      return (
        !this.canvas.is_ni_nm_handler &&
        !this.canvas.is_main_canvas &&
        !this.canvas.is_event_handler
      );
    }
  },

  methods: {
    ...mapActions("canvas", {
      deleteCanvas: "deleteCanvas",
      changeSelectedCanvas: "changeSelectedCanvas",
      getNodes: "getNodes",
      setCanvasUsages: "setCanvasUsages"
    }),

    ...mapActions("folders", {
      updateSelectedTaskId: "updateSelectedTaskId",
      checkInUse: "checkInUse"
    }),

    checkCanvasInUse({ content_model, content_id }) {
      return new Promise((resolve, reject) => {
        this.setCanvasUsages([]);
        this.checkInUse({
          content_model,
          content_id
        })
          .then(data => {
            this.setCanvasUsages([...data]);
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    isSystemCanvas(canvas_name) {
      return _.includes(
        ["Main Dialog", "Disconnect", "Machine Detection", "Beep Detection"],
        canvas_name
      );
    },

    waitForDblClickAndEmitEvent() {
      this.clickCounter++;
      if (this.clickCounter === 1) {
        this.timer = setTimeout(() => {
          this.clickCounter = 0;
          this.$emit("select-canvas", this.canvas.canvas_name);
        }, 200);
      } else {
        clearTimeout(this.timer);
        this.clickCounter = 0;
      }
    },

    handleCanvasDelete() {
      this.showDeleteConfirmationDialog = false;
      this.$confirm(
        // eslint-disable-next-line
        __("This will permanently delete the canvas ':canvasname' and the nodes in it. Continue?", {
            canvasname: this.canvas.canvas_name
          }
        ),
        {
          confirmButtonText: __("OK"),
          cancelButtonText: __("Cancel"),
          type: "warning"
        }
      )
        .then(() => {
          this.deleteCanvas(this.canvas)
            .then(() => {
              this.getNodes({
                ac_id: this.task.ac_id,
                task_id: this.task.task_id
              }).then(() => {
                if (this.selectedCanvas === this.canvas.canvas_name) {
                  this.changeSelectedCanvas(this.mainCanvas.canvas_name);
                }
              });

              this.$message({
                type: "success",
                message: __("Delete canvas successful")
              });
            })
            .catch(() => {
              this.$message({
                type: "error",
                message: __("Delete canvas unsuccessful")
              });
            });
        })
        .catch(() => {});
    },

    handleDelete() {
      this.checkCanvasInUse({
        content_model: "Canvas",
        content_id: this.canvas.canvas_id
      }).then(() => {
        if (this.canvasUsages.length < 1) {
          this.handleCanvasDelete();
        } else {
          EventBus.$emit("open-delete-confirmation-dialog-box", {
            content_model: "Canvas",
            content_id: this.canvas.canvas_id
          });
        }
      });
    },

    updateCanvasName(ev) {
      this.$emit("change-canvas-name-input", false);
      if (ev) {
        ev.preventDefault();
        ev.stopPropagation();
      }
      if (!this.isTaskReadOnly) {
        if (!this.isSystemCanvas(this.newName)) {
          if (
            this.checkCanvasNameExist(this.newName) &&
            this.clickCounter === 0 &&
            this.newName !== this.canvas.canvas_name
          ) {
            this.$notify({
              type: "error",
              message: __("Duplicate name found in the canvas")
            });
            this.clickCounter++;
            this.newName = this.canvas.canvas_name;
          } else {
            this.$emit("change", {
              oldName: this.canvas.canvas_name,
              newName: this.newName.trim()
            });
            this.updateDblClickStatus(true);
            this.$emit("focus", false);
          }
        } else {
          this.clickCounter++;
          if (this.clickCounter === 2) {
            this.$notify({
              type: "error",
              message: __("Reserved Canvas name")
            });
            this.clickCounter = 0;
            this.newName = this.canvas.canvas_name;
          }
          this.updateDblClickStatus(true);
          this.$emit("focus", false);
        }
      }
    },

    /**
     * find the canvas given the name
     */
    checkCanvasNameExist(newName) {
      return _.findIndex(this.canvases, { canvas_name: newName }) !== -1;
    },
    showUpdateCanvasNiNmConfirmation() {
      const h = this.$createElement;
      let message = h("p", null, [
        __("Are you sure you wish to move "),
        h("b", null, `${this.canvas.canvas_name}`),
        __(" to Event Handler Canvases? This action cannot be undone.")
      ]);
      this.$confirm(message, {
        confirmButtonText: "Move",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(() => {
        this.updateCanvasNiNmHandler();
      });
    },

    updateCanvasNiNmHandler() {
      if (!this.isTaskReadOnly) {
        this.$emit("move-to-event-handler", this.canvas);
      }
    },

    updateDblClickStatus(val) {
      if (
        !this.isTaskReadOnly &&
        !_.includes(
          ["Main Dialog", "Disconnect", "Machine Detection", "Beep Detection"],
          this.canvas.canvas_name
        )
      ) {
        if (val) {
          this.notDoubleClicked = val;
        } else if (!this.canvas.is_main_canvas) {
          this.notDoubleClicked = val;
        }
      }
    },
    updateDblClickStatusToTrue() {
      if (!this.isTaskReadOnly) {
        if (this.canvas.canvas_name !== this.newName) {
          this.updateCanvasName();
        }
        this.notDoubleClicked = true;
      }
    },

    handleCanvasFocus() {
      this.$emit("focus", true);
      this.$emit("change-canvas-name-input", true);
    }
  },
  watch: {
    canvas: {
      immediate: true,
      deep: true,
      handler: function(val) {
        this.newName = val.canvas_name;
      }
    },
    renamingCanvasSuccessful: {
      immediate: true,
      handler: function(val) {
        if (!val) {
          this.newName = this.canvas.canvas_name;
        }
      }
    }
  }
  // directives: {
  //   focus: {
  //     inserted: function(el) {
  //       el.focus();
  //     }
  //   }
  // }
  //
};
</script>

<style scoped lang="scss">
.canvas-tab {
  background: rgba(255, 255, 255, 0.6);

  &:hover {
    .el-icon-circle-close {
      opacity: 1;
    }
    color: var(--theme-color);
  }

  &:hover {
    .el-icon-edit {
      opacity: 1;
    }
    color: var(--theme-color);
  }

  &:hover {
    ::v-deep svg {
      fill: var(--theme-color);

      path {
        fill: var(--theme-color);
      }
    }
    .canvas-action-button {
      opacity: 1;
    }
    color: var(--theme-color);
  }
}

.canvas-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 5px;
  font-size: 0.8rem;
  cursor: pointer;
  padding: 5px 0;
  width: 98%;
  input {
    background: none;
    border: none;
    outline: none;
    font-size: 0.8rem;
    border-bottom: 1px solid #6e7681;
    width: 180px;
    padding-bottom: 5px;
  }

  div {
    padding: 2px 0;
    max-width: 66%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 5px;
    display: block;
  }
  .canvas-action-button {
    margin-right: 5px;
    opacity: 0;
    font-size: 1.2em;
    height: 18px;
    width: 18px;
  }

  .inside-container {
    display: inline-flex;
    flex-direction: row;
    height: 80%;
  }
  .inline-icon {
    margin-right: 5px;
    height: 50%;
  }
}

.canvas-tab.active-canvas {
  div:first-of-type {
    font-weight: bold;
    color: var(--theme-color);
  }
}

.canvas-tab.new-canvas {
  div:first-of-type {
    color: var(--theme-color);
  }
}
</style>
