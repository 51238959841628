<script>
import _ from "lodash";
import {
  convertDotNotationToBracketNotation,
  enclosePartInBrackets,
  extractPathParts
} from "@/utils/collection";

export default {
  name: "JsonPath",
  data() {
    return {
      regEx: new RegExp(
        "\\[\\??\\((.*?)\\)](?=($|\\[))|\\['?(.*?)'?](?![-&%+\\/*=<>!\\s)]|.length)",
        "g"
      ),
      parts: [],
      jsonPath: "",
      showData: true
    };
  },
  computed: {
    isJsonPathOnCompleteResponse() {
      return ["ws_response_data", "ws_response_meta"].includes(
        _.first(this.parts)
      );
    }
  },
  methods: {
    removePart(index) {
      this.parts.splice(index, 1);
    },

    pathParts(parameterValue, regex) {
      parameterValue = parameterValue && parameterValue.toString();

      if (!parameterValue) {
        return [];
      }

      let matches = parameterValue.matchAll(regex);
      return _.map(Array.from(matches), regexMatch => {
        let match = regexMatch[1] || regexMatch[2] || regexMatch[3];

        if (/^\?\(.*?\)$/.test(match)) {
          match = match.match(/^\?\((.*?)\)$/);
          match = match[1];
        }
        let regEx = new RegExp(
          "\\$\\['(ws_response_meta|ws_response_data)']",
          "g"
        );
        return match.replace(regEx, "$");
      });
    },
    updatePart(part) {
      return index => {
        this.parts.splice(index, 1, part);
      };
    },
    parseParts() {
      let val = "";
      // let regexForPath = /(@.*?)(?=[-&|+/*%=<>!]|$)|(\(?@.length.*?(?=[\\[)]|$))/g;
      let regexForPath = /([@$].*?)\s*(?=[-&|+/*%=<>!]|\)?$)|(\?@.length.*?)\s*(?=[\\[)]|$)/g;

      let error = false;
      _.map(this.parts, part => {
        let filteredPaths = this.pathParts(part, regexForPath);
        let bracketNotationFilteredPaths = _.map(filteredPaths, filteredPath =>
          convertDotNotationToBracketNotation(filteredPath)
        );

        _.map(
          bracketNotationFilteredPaths,
          (bracketNotationFilteredPath, index) => {
            part = part.replace(
              filteredPaths[index],
              bracketNotationFilteredPath
            );
          }
        );

        if (this.isJsonPathOnCompleteResponse) {
          // parts[0] will be either ws_response_data or ws_response_meta
          // part = part.replace(/\$/, "$['" + this.parts[0] + "']", part);
          const replaceWith = this.showData
            ? "ws_response_data"
            : "ws_response_meta";
          part = part.replace(/\$/, "$['" + replaceWith + "']", part);
        }
        // do sanitization

        // match brackets
        part = part.toString().trim();
        let openingBracketMatches = part.match(/\(/g) || [];
        let closingBracketMatches = part.match(/\)/g) || [];

        let diff = openingBracketMatches.length - closingBracketMatches.length;
        if (diff !== 0) {
          error = true;
          this.$notify({
            message: __("the brackets don't match in your filter expression"),
            type: "error"
          });
          return false;
        }

        // // add missing closing parenthesis to end of the part expression
        // let i;
        // for (i = 0; i < diff; i++) {
        //   part += ")";
        // }

        // if part contains reference to @, which means it's supposed to be a filter condition
        // and its not already wrapped with ?(), then wrap the part with ?()
        part =
          (part.includes("@") || part.includes("$")) &&
          !/^\?\(.*?\)$/.test(part) &&
          !/@\.length/.test(part) &&
          !(part.includes("$") && !/[=<>!]/.test(part))
            ? "?(" + part + ")"
            : part;

        // // if the @.length is used inside a filter condition
        // part =
        //   (part.includes("@") || part.includes("$")) &&
        //   !/^\?\(.*?\)$/.test(part) &&
        //   /@\.length/.test(part) &&
        //   !(part.includes("$") && !/[=<>!]/.test(part))
        //     ? "?(" + part + ")"
        //     : part;

        // replace all instances of double quotes with single quotes
        part = part.replace(/"/g, "'");

        if (part) {
          if (/^\?/.test(part) || /^\(.*?\)$/.test(part)) {
            val += "[" + part + "]";
          } else if (/@\.length|^[^(].+?\s?[-+/*%]\s.+|\$/.test(part)) {
            val += "[(" + part + ")]";
          } else if (/^\(@\.length/.test(part)) {
            val += "[" + part + "]";
          } else {
            let bracketNotations = _.map(
              extractPathParts(part),
              enclosePartInBrackets
            );
            val += bracketNotations.join("");
          }
        }
      });

      if (!error) {
        val = "root" + val;
        return val.trim();
      }
    }
  },
  watch: {
    parts: {
      deep: true,
      handler() {
        if (_.isEqual(this.parts, _.compact(this.parts))) {
          this.parts.push("");
        }
      }
    },

    jsonPath: {
      immediate: true,
      handler(val) {
        this.parts = _.cloneDeep(this.pathParts(val, this.regEx));
      }
    }
  }
};
</script>
