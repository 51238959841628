<template>
  <el-select
    v-model="timezone"
    :placeholder="__('Select a timezone')"
    filterable
    style="width: 100%"
    class="timezoneDropDown"
    @change="changeTimezoneValue"
    default-first-option
    v-loading="isLoading"
    :disabled="disabled"
    :clearable="clearable"
  >
    <el-option-group
      v-for="(timezone, timezone_name) in availableTimezones"
      :key="timezone_name"
      :label="timezone_name"
    >
      <el-option
        v-for="timezoneItem in timezone"
        :key="timezoneItem.timezone"
        :label="timezoneItem.label"
        :value="timezoneItem.timezone"
      >
        <span style="float: left">{{ timezoneItem.label }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px">
          {{ timezoneItem.value }}
        </span>
      </el-option>
    </el-option-group>
  </el-select>
</template>

<script>
import { mapActions, mapState } from "vuex";
import _ from "lodash";

export default {
  name: "TimezoneDropdown",
  props: {
    default_timezone: {
      required: false,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    clearable: {
      required: false,
      type: Boolean,
      default: false
    },
    additionalOptions: {
      required: false,
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      timezone: ""
    };
  },
  computed: {
    ...mapState("timezones", {
      timezones: state => state.timezones,
      isLoading: state => state.isLoading
    }),

    availableTimezones() {
      return _.merge(_.cloneDeep(this.timezones), this.additionalOptions);
    }
  },
  async created() {
    this.timezone = _.cloneDeep(this.default_timezone);
    await this.initializeTimezones();
  },

  methods: {
    ...mapActions("timezones", {
      getTimeZone: "getTimeZone"
    }),

    async initializeTimezones() {
      if (_.isEmpty(this.timezones)) {
        await this.getTimeZone();
      }
    },

    async changeTimezoneValue(timezone) {
      this.$emit("timezone-changed", timezone);
    }
  }
};
</script>

<style scoped lang="scss">
.timezoneDropDown {
  ::v-deep .el-select {
    .el-input.is-focus .el-input__inner {
      border-color: var(--theme-color);
    }

    .el-input__inner:hover {
      border-color: var(--theme-color);
    }

    .el-select-dropdown__item.selected {
      color: var(--theme-color);
    }

    .el-input__inner:focus {
      border-color: var(--theme-color);
    }
  }

  .el-select-group__title {
    color: var(--theme-color) !important;
  }
}
</style>
