<script>
import At from "./At.vue";
import getCaretCoordinates from "textarea-caret";
import { getAtAndIndex } from "@/utils/AtUtil";
import _ from "lodash";

export default {
  extends: At,
  name: "AtInput",
  computed: {
    style() {
      if (this.atwho) {
        const { x, y } = this.atwho;
        const { wrap } = this.$refs;
        const el = this.$el.querySelector("input");
        if (wrap) {
          const left = x + el.offsetLeft - el.scrollLeft + "px";
          const top = 165 + y + el.offsetTop - el.scrollTop + "px";
          return { left, top, "z-index": "111111 !important" };
        }
      }
      return null;
    }
  },
  methods: {
    handleValueUpdate(value) {
      const el = this.$el.querySelector("input");
      if (value !== el.value) {
        // avoid range reset
        el.value = value;
        this.dispatchInput();
      }
    },
    dispatchInput() {
      let el = this.$el.querySelector("input");
      let ev = new Event("input", { bubbles: true });
      el.dispatchEvent(ev);
    },

    handleDelete() {
      const el = this.$el.querySelector("input");
      const text = el.value.slice(0, el.selectionEnd);
      if (text) {
        const { atItems, members, deleteMatch, itemName } = this;
        const { at, index } = getAtAndIndex(text, atItems);
        if (index > -1) {
          const chunk = text.slice(index + at.length);
          // const has = members.some(v => {
          //   const name = itemName(v);
          //   return deleteMatch(name, chunk, suffix);
          // });
          let matched = {};
          let self = this;
          _.map(members, (member, key) => {
            if (!_.isEmpty(members[key])) {
              matched[key] = _.some(members[key], function(value) {
                const name = itemName(value);
                return deleteMatch(name, chunk, self.suffixes[at]);
              });
            }
          });

          const has = _.some(matched, group => group);
          if (has) {
            el.value =
              el.value.slice(0, index) + el.value.slice(el.selectionEnd - 1);
            el.selectionStart = index + 1;
            el.selectionEnd = index + 1;
            this.handleInput();
          }
        }
      }
    },

    handleInput(keep) {
      if (this.hasComposition) return;
      const el = this.$el.querySelector("input");
      this.$emit("input", el.value);

      const text = el.value.slice(0, el.selectionEnd);
      if (text) {
        const { atItems, avoidEmail, allowSpaces } = this;
        let show = true;
        const { at, index } = getAtAndIndex(text, atItems);
        if (index < 0) show = false;
        const prev = text[index];
        const chunk = text.slice(index + at.length, text.length);
        if (avoidEmail) {
          if (/^[a-z0-9]$/i.test(prev)) show = false;
        }
        if (!allowSpaces && /\s/.test(chunk)) {
          show = false;
        }

        if (/^\s/.test(chunk)) show = false;
        if (!show) {
          this.closePanel();
        } else {
          const { members, filterMatch, itemName } = this;
          if (!keep) {
            // fixme: should be consistent with At.vue
            this.$emit("at", chunk);
          }

          let matched = {};

          _.map(members, (member, key) => {
            if (!_.isEmpty(members[key])) {
              const filterMembers = _.filter(members[key], function(value) {
                const name = itemName(value);
                return filterMatch(name, chunk, at);
              });
              if (!_.isEmpty(filterMembers)) {
                matched[key] = filterMembers;
              }
            }
          });
          let matchedProps = {};
          let keysToFind = this.prefixToProp[at];
          _.map(keysToFind, keyToFind => {
            if (!_.isEmpty(matched[keyToFind])) {
              matchedProps[keyToFind] = _.cloneDeep(matched[keyToFind]);
            }
          });
          this.filteredMembers = _.cloneDeep(matchedProps);
          if (!_.isEmpty(matchedProps)) {
            this.openPanel(matchedProps, chunk, index, at, keep);
          } else {
            this.closePanel();
          }
        }
      } else {
        this.closePanel();
      }
    },

    openPanel(list, chunk, offset, at) {
      const fn = () => {
        const el = this.$el.querySelector("input");
        const atEnd = offset + at.length;
        const rect = getCaretCoordinates(el, atEnd);
        const inputPos = el.getBoundingClientRect();
        this.atwho = {
          chunk,
          offset,
          list,
          atEnd,
          x: rect.left + inputPos.left,
          y: rect.top - 4 + inputPos.top,
          cur: _.head(_.keys(this.filteredMembers)) + "_0"
        };
      };
      if (this.atwho) {
        fn();
      } else {
        setTimeout(fn, 10);
      }
    },

    insertText(text, ta) {
      const start = ta.selectionStart;
      const end = ta.selectionEnd;
      ta.value = ta.value.slice(0, start) + text + ta.value.slice(end);
      const newEnd = start + text.length;
      ta.selectionStart = newEnd;
      ta.selectionEnd = newEnd;
      this.dispatchInput();
    },
    insertItem() {
      const { list, cur, atEnd } = this.atwho;
      const { atItems, itemName } = this;
      const el = this.$el.querySelector("input");
      const text = el.value.slice(0, atEnd);
      const { at, index } = getAtAndIndex(text, atItems);
      el.selectionStart = index + at.length;
      el.focus();
      const current = cur.split("_");
      const curItem = list[current[0]][current[1]];
      const t = itemName(curItem) + this.suffixes[at];
      this.insertText(t, el);
      this.$emit("insert", curItem);
      this.handleInput();
      this.closePanel();
    }
  }
};
</script>
