import Ajv from "ajv";
import addFormats from "ajv-formats";
import _ from "lodash";

let ajv;

/**
 * Set up the ajv validator library and add the necessary formats
 * @return {Array}
 */
export const setupJsonSchemaValidator = () => {
  // options can be passed, e.g. {allErrors: true}
  ajv = new Ajv({ allowUnionTypes: true });
  addFormats(ajv);
};

/**
 * Validates the provided json data based on the provided schema using ajv lib
 * @return {Object}
 * @param data json data that needs to be validated
 * @param schema schema required to validate against the provided json
 */
export const validateJsonUsingSchema = (data, schema) => {
  let errors = [];
  let valid = true;
  try {
    // if it has payload object, then perform schema validation
    if (_.has(data, "payload")) {
      valid = ajv.validate(schema, data.payload);
      if (!valid) {
        errors = ajv.errors;
      }
    }
    return { valid, errors };
  } catch (e) {
    errors = ajv ? ajv.errors : [];
    return { valid: false, errors };
  }
};

/**
 * Get the JSON  schema error string reflecting the appropriate message for validation fail at a specific path
 * @return {string}
 * @param errors represents the errors array returned by ajv lib
 */
export const getJsonSchemaErrorString = errors => {
  // ajv always gives two error objects no matter how many errors it finds in the json,
  // first one is the one we have to consider as that will reflect based on the provided json payload
  let errorMessage = _.get(errors, "0.message");
  let errorPath = _.get(errors, "0.instancePath");
  let errorKeyword = _.get(errors, "0.keyword");

  errorMessage = modifySchemaErrorMsg(
    errors,
    errorPath,
    errorKeyword,
    errorMessage
  );

  return __("JSON Schema Error: :message for  path ':path'", {
    message: errorMessage,
    path: errorPath
  });
};

/**
 * Modifies the error message for errors reported for a certain attribute in the defined schema
 * @return {string}
 * @param errors
 * @param errorPath
 * @param errorKeyword
 * @param errorMessage
 */
const modifySchemaErrorMsg = (
  errors,
  errorPath,
  errorKeyword,
  errorMessage
) => {
  let attribute = _.last(errorPath.split("/"));
  switch (attribute) {
    case "image":
      return "must be URL format or a variable like {{studio}}";
  }

  // include allowed values in the error message for an enum type attribute
  if (errorKeyword === "enum") {
    let allowedValues = _.get(errors, "0.params.allowedValues");
    if (_.isArray(allowedValues)) {
      allowedValues = _.join(allowedValues, ",");
      return `Must be one of ${allowedValues}`;
    }
  }

  return errorMessage;
};
