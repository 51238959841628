var render = function render(){var _vm=this,_c=_vm._self._c;return _c('expression-node-group-renderless',{ref:"expression-node-group-renderless",attrs:{"node":_vm.node},scopedSlots:_vm._u([{key:"default",fn:function({
    node, // the current node (node group in this case)
    index, // the index of this node amongst its parent's children, if it is the root, returns -1
    deleteSelf, // deletes the current node group with all its children
    toggleConnectionType, // toggles between 'and' and 'or', or 'all', and 'any'
    // insert, // inserts a new node, at a given index
    addNode, // pushes a node to the end of the children list of this node group
    addGroup, // pushes a node group to the end of the children list of this node group
    conditionFactory // it stores the defined fields, fieldTypes and operators, and creates new conditions with its create method
  }){return [_c('div',{staticClass:"expression-node-group"},[_c('header',[_c('div',{staticClass:"toggle-ct",on:{"click":toggleConnectionType}},[_c('span',{class:{ selected: node.connectionType === 'and' }},[_vm._v(_vm._s(_vm.__("AND")))]),_c('span',{class:{ selected: node.connectionType === 'or' }},[_vm._v(_vm._s(_vm.__("OR")))])]),_c('div',{staticClass:"controls"},[(index == -1)?_c('el-button',{attrs:{"loading":_vm.showLoading},on:{"click":function($event){return _vm.$emit('previewDataStore')}}},[_vm._v(_vm._s(_vm.__("Preview")))]):_vm._e(),(_vm.showAddGroup)?_c('el-button',{on:{"click":function($event){return addGroup()}}},[_vm._v(_vm._s(_vm.__("Add Group")))]):_vm._e(),_c('el-button',{on:{"click":function($event){addNode(conditionFactory.create(_vm.defaultCol, '=', ''))}}},[_vm._v(_vm._s(_vm.__("Add Node")))]),(node.parentNode)?_c('i',{staticClass:"el-icon-close deleteSelf",on:{"click":deleteSelf}}):_vm._e()],1)]),_c('main',[_c('ul',_vm._l((node.children),function(child,index){return _c('li',{key:index},[(child.children)?_c('expression-node-group',{attrs:{"node":child,"default-col":_vm.defaultCol}}):_c('expression-node',{attrs:{"node":child}})],1)}),0)])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }