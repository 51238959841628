<template>
  <v-md-editor v-model="content" :left-toolbar="leftToolbar" right-toolbar="" />
</template>

<script>
import VMdEditor from "@kangc/v-md-editor";
import "@kangc/v-md-editor/lib/style/base-editor.css";
import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js";
import "@kangc/v-md-editor/lib/theme/style/vuepress.css";
import enUS from "@kangc/v-md-editor/lib/lang/en-US";

VMdEditor.lang.use("en-US", enUS);
VMdEditor.use(vuepressTheme);

export default {
  name: "MarkdownEditor",
  components: {
    VMdEditor
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    leftToolbar: {
      type: String,
      default:
        "undo redo | h bold italic strikethrough | ul ol hr | link | preview fullscreen"
    }
  },
  data() {
    return {
      content: this.value
    };
  },
  watch: {
    content(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.content = newValue;
    }
  }
};
</script>

<style scoped>
.v-md-editor {
  border: 1px solid #a0a8b5;
  box-shadow: none;
  width: auto;
}
</style>
