<script>
import contenteditable from "vue-contenteditable/src/contenteditable.vue";
import VariablePopperMixin from "@/views/build/callflow/components/node-type-forms/components/VariablePopperMixin";
function replaceAll(str, search, replacement) {
  return str.split(search).join(replacement);
}

export default {
  name: "StudioContentEditable",
  extends: contenteditable,
  mixins: [VariablePopperMixin],
  methods: {
    onPaste(event) {
      event.preventDefault();
      let text = (event.originalEvent || event).clipboardData.getData(
        "text/plain"
      );

      if (this.noNL) {
        text = replaceAll(text, "\r\n", " ");
        text = replaceAll(text, "\n", " ");
        text = replaceAll(text, "\r", " ");
      }
      if (this.noHTML) {
        window.document.execCommand("insertText", false, text);
      } else {
        text = this.parseContentEditableContentForBackend(text);
        text = text.replace(/</g, "!!lt!!").replace(/>/g, "!!gt!!");
        text = this.parseContentEditableContentForFrontEnd(text);
        text += "&nbsp;";
        window.document.execCommand("insertHTML", false, text);
      }

      this.fwdEv(event);
    }
  }
};
</script>
