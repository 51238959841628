<template>
  <div class="json-picker-container">
    <div class="json-value-picker">
      <div class="picker">
        <div v-if="jsonAvailable">
          <div style="padding-bottom: 10px">
            <span class="title"> {{ __("Click on a path") }}</span>
            <el-tooltip effect="dark" placement="top">
              <i style="padding-left: 5px;" class="el-icon-info"></i>
              <div slot="content">
                <div v-html="jsonPathPickerTooltip"></div>
              </div>
            </el-tooltip>
          </div>
          <el-button-group
            v-if="isJsonPathManipulationRequired"
            class="response-button-group"
          >
            <el-button
              size="mini"
              @click="
                !showData && (parts = []);
                showData = true;
              "
              :class="{ active: showData }"
              type="primary"
              >{{ __("Data") }}
            </el-button>
            <el-button
              size="mini"
              @click="
                showData && (parts = []);
                showData = false;
              "
              :class="{ active: !showData }"
              type="primary"
              >{{ __("Meta") }}
            </el-button>
          </el-button-group>
          <el-scrollbar>
            <vue-json-pretty
              v-if="isJsonPathManipulationRequired"
              :data="showData ? dataFromResponse : statusAndHeadersFromResponse"
              @node-click="handleJSONClick"
              :deep="10"
              :highlightMouseoverNode="true"
              :highlightSelectedNode="true"
              class="json-pretty-container"
            />
            <vue-json-pretty
              v-else
              :data="schema.sampleJSON"
              @node-click="handleJSONClick"
              :deep="10"
              :highlightMouseoverNode="true"
              :highlightSelectedNode="true"
              class="json-pretty-container"
            />
          </el-scrollbar>
        </div>

        <div style="max-width: 350px; height: 150px" v-else>
          <div class="info-msg">
            Hmm..!! <br />
            <br />
            <!-- eslint-disable-next-line -->
            {{ __("It looks like I cannot guide you since I am not able to guess the structure of the selected object. Sorry, You are on your own in building your json path to your desired property") }}
          </div>
        </div>
      </div>

      <div class="picked">
        <!--        <a href="https://docs.inferencesolutions.com/" target="_blank">-->
        <!--          Know more about using variables and creating filters in jsonpath - to-->
        <!--          do</a-->
        <!--        >-->

        <div
          style="margin-bottom: 15px; font-size:0.85rem; word-break: break-word; font-style: italic"
        >
          <!-- eslint-disable-next-line -->
          {{ __("Build your json path, use variables if needed, each input should be the key at each level to the desired property") }}
        </div>

        <div style="text-decoration: underline">
          {{ __("JSON Path to retrieve information") }}
          <span
            v-if="isJsonPathManipulationRequired && isNumber(parameter.value)"
          >
            {{ __("from") }}
            <i>{{ dataTypeLan }}</i></span
          >
        </div>
        <el-scrollbar>
          <div class="json-parts-container">
            <div style="margin-top: 10px" v-if="parts.length">
              {{ __("response") }}
            </div>
            <div v-for="(part, index) in parts" :key="index">
              <div class="parts-container">
                <input-variable-popper
                  class="input-variable"
                  :prepend-items="expressionNames"
                  :include-secure-variables="false"
                  :is-text-area="false"
                  :value="part"
                  force-reinitialize
                  @input="updatePart($event)(index)"
                  suffix="}}"
                  popper-position="top-end"
                ></input-variable-popper>

                <div class="variable-control" @click="removePart(index)">
                  <i class="el-icon-circle-close"></i>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>

    <div class="controls">
      <el-button @click="generateJsonPath">{{ __("save") }}</el-button>
      <el-button @click="$emit('close')">{{ __("close") }}</el-button>
    </div>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import SchemaGenerator from "@/utils/SchemaGenerator";
import {
  convertDotNotationToBracketNotation
  // getSubKeyObject
} from "@/utils/collection";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import _ from "lodash";
import JsonPath from "@/mixins/JsonPath";

export default {
  mixins: [JsonPath],
  props: {
    json: {
      required: true,
      type: [Object, Array, String]
    },
    parameter: {
      required: true,
      type: Object
    },
    value: {
      required: true,
      type: String
    },
    expressionNames: {
      required: false,
      type: Array,
      default: () => []
    }
  },
  components: {
    VueJsonPretty,
    InputVariablePopper
  },
  data() {
    return {
      schema: null
      // showData: false
    };
  },
  computed: {
    jsonAvailable() {
      return !_.isEmpty(this.schema.sampleJSON);
    },

    isJsonPathManipulationRequired() {
      return ["json-dropdown"].includes(_.get(this.parameter, "type"));
    },

    isNumber() {
      return val => _.isNumber(val);
    },

    isJsonPathOnCompleteResponse() {
      return (
        this.isJsonPathManipulationRequired &&
        this.isNumber(this.parameter.value)
      );
    },

    dataFromResponse: {
      get() {
        return _.get(this.schema.sampleJSON, "ws_response_data", {});
      },
      set(val) {
        this.$set(this.schema.sampleJSON, "ws_response_data", val);
      }
    },

    statusAndHeadersFromResponse: {
      get() {
        // return getSubKeyObject(this.schema.sampleJSON, ["ws_response_meta"]);
        return _.get(this.schema.sampleJSON, "ws_response_meta", {});
      },

      set(val) {
        // this.httpResponse = Object.assign({}, this.schema.sampleJSON, val);
        this.$set(this.schema.sampleJSON, "ws_response_meta", val);
      }
    },
    dataTypeLan() {
      return this.showData ? __("Response Data") : __("Meta Data");
    },

    /**
     * Content for the JSON path picker tooltip
     * @returns {String} Translated content for the JSON path picker tooltip
     */
    jsonPathPickerTooltip() {
      // eslint-disable-next-line
      let baseTooltip = __("Below is our best minimal approximation of the response. Click on a property to generate path.");

      if (_.findKey(this.dataFromResponse, "confusables") !== undefined) {
        // eslint-disable-next-line
        return `${baseTooltip}<br>${__("Confusables element will only be present when Form Node is selected, form type is Custom and Language is Lumenvox.")}`;
      }
      return baseTooltip;
    }
  },
  methods: {
    handleJSONClick({ path }) {
      path = convertDotNotationToBracketNotation(path);
      // this.jsonPath = path;
      this.jsonPath = this.modifyRoot(path);
      this.$nextTick(() => {
        if (this.jsonPath === "root") {
          this.$message({
            type: "info",
            // eslint-disable-next-line
            message: __("the entire response is automatically associated with the node")
          });
        } else {
          this.parts = this.pathParts(this.jsonPath, this.regEx);
        }
      });
    },

    modifyRoot(jsonPath) {
      if (
        _.isNumber(this.parameter.value) &&
        this.isJsonPathManipulationRequired
      ) {
        let regex = new RegExp("^root", "g");
        if (this.showData && !/^root\['ws_response_data']/.test(jsonPath)) {
          jsonPath = jsonPath.replace(regex, "root['ws_response_data']");
        } else if (
          !this.showData &&
          !/^root\['ws_response_meta']/.test(jsonPath)
        ) {
          jsonPath = jsonPath.replace(regex, "root['ws_response_meta']");
        }
      }
      return jsonPath;
    },

    generateJsonPath() {
      let jsonPath = this.parseParts();
      // if (
      //   (this.showData && !_.isEmpty(this.json)) ||
      //   (!this.showData && ["ws_response_data"].includes(_.get(this.parts, 0))) ||
      //   (_.isEmpty(this.json) &&
      //     _.isNumber(this.parameter.value) &&
      //     !["ws_response_meta"].includes(_.get(this.parts, 0)))
      // ) {
      //   let regex = new RegExp("^root", "g");
      //   jsonPath = jsonPath.replace(regex, "root['data']");
      // }
      jsonPath = this.modifyRoot(jsonPath);

      this.$emit("input", jsonPath.trim());
      this.$emit("close");
    }
  },
  watch: {
    json: {
      immediate: true,
      deep: true,
      handler(val) {
        this.schema = new SchemaGenerator(val);
      }
    },
    value: {
      immediate: true,
      handler(val) {
        this.jsonPath = val;
      }
    },
    jsonPath: {
      immediate: true,
      handler(val) {
        this.parts = _.cloneDeep(this.pathParts(val, this.regEx));
        this.showData = !(
          !_.isNumber(this.parameter.value) ||
          (_.isNumber(this.parameter.value) &&
            ["ws_response_meta"].includes(_.get(this.parts, 0)))
        );
      }
    },
    parts: {
      deep: true,
      handler() {
        if (
          this.isJsonPathManipulationRequired &&
          _.isNumber(this.parameter.value) &&
          !_.isEmpty(this.parts) &&
          this.parseParts() === this.jsonPath
        ) {
          this.parts = _.tail(this.parts);
        }
      }
    }
  }
};
</script>

<style lang="scss">
.json-picker-container {
  display: flex;
  flex-direction: column;

  .json-value-picker {
    display: flex;
    min-height: 250px;
    max-height: 450px;
    margin-bottom: 20px;

    .el-scrollbar__wrap {
      //overflow-x: hidden;

      .json-pretty-container,
      .json-parts-container {
        max-height: 400px;
      }
    }

    .picked {
      flex: 1;
      margin-left: 20px;
      margin-right: 20px;

      .parts-container {
        display: flex;
        align-items: center;

        .variable-control {
          cursor: pointer;
          padding-left: 15px;
          flex: 1 0 auto;
        }

        .input-variable {
          padding-top: 5px;
          padding-bottom: 5px;
          width: 250px;
          flex: 0 1 auto;
        }
      }
    }

    .picker {
      min-width: 350px;
      display: flex;
      flex-direction: column;

      .info-msg {
        word-break: break-word;
      }

      .title {
        padding-bottom: 20px;
      }

      .response-button-group {
        padding-bottom: 5px;

        .active {
          background-color: #0e97d4 !important;
          border-color: #0e97d4 !important;
        }
      }
    }
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
