<template>
  <el-form :model="config" class="variable-configuration-container">
    <div v-if="hasSecureVariables">
      {{ __("The task make use of following secure variables") }}
    </div>
    <div class="secure-variables">
      <el-tag
        class="secure-variable"
        :key="secureVariable"
        v-for="secureVariable in secureVariableNames"
        type="success"
      >
        {{ secureVariable }}
      </el-tag>
    </div>

    <div class="config-question">
      <div>
        {{
          __("Do you wish to attach secure variable value to task snapshot?")
        }}
      </div>
      <el-form-item prop="attachSecureVariableValue">
        <el-checkbox
          style="padding-left: 10px;"
          v-model="config.attachSecureVariableValue"
        >
          {{ __("Attach") }}
        </el-checkbox>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import BaseExportConfig from "./BaseExportConfig";
import _ from "lodash";

export default {
  mixins: [BaseExportConfig],
  computed: {
    secureVariables() {
      return _.filter(
        this.contents,
        variable => variable.variable_type === "secure"
      );
    },
    secureVariableNames() {
      return _.map(this.secureVariables, "variable_name");
    },
    hasSecureVariables() {
      return !_.isEmpty(this.secureVariables);
    }
  }
};
</script>

<style scoped lang="scss">
.variable-configuration-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  .secure-variables {
    padding-top: 10px;
    margin-left: -5px;
    .secure-variable {
      margin: 5px;
      height: 28px;
      line-height: 26px;
      font-size: 12px;
    }
  }

  .config-question {
    margin-top: 25px;
    display: flex;
    align-items: center;

    .el-form-item {
      margin: 0;
    }
  }
}
</style>
