<script>
import { Select } from "element-ui";
import { mapState } from "vuex";

export default {
  mixins: [Select],
  data() {
    return {
      optionClicked: false
    };
  },
  computed: {
    ...mapState("canvas", {
      clickedNode: state => state.clickedNode
    })
  },
  methods: {
    handleOptionSelect(option, byClick) {
      this.optionClicked = true;
      Select.methods.handleOptionSelect.call(this, option, byClick);
    },
    handleHover() {
      this.$nextTick(() => {
        if (this.hoverIndex !== -1 && !this.optionClicked) {
          let item = this.options[this.hoverIndex];
          this.$emit("hover", item.value, false);
        }
      });
    }
  },
  watch: {
    hoverIndex: {
      immediate: true,
      handler: "handleHover"
    },
    visible: {
      immediate: true,
      handler(val) {
        this.optionClicked = !val;
      }
    }
  }
};
</script>

<style scoped></style>
