<template>
  <div>
    <el-form-item
      v-for="setting in generalProfileSettings"
      :key="setting.key"
      :label="__(setting.label)"
      :prop="`web_service_node.data.additional_settings.open_ai.${setting.key}`"
    >
      <input-variable-popper
        v-if="isOtherInputField(setting)"
        :value="openAISettings[setting.key]"
        @input="setOpenAISetting(setting.key, $event)"
        :is-text-area="false"
        :placeholder="__('Enter your text')"
      />
      <input-variable-popper
        v-if="isAutoCompleteField(setting)"
        :value="openAISettings[setting.key]"
        @input="setOpenAISetting(setting.key, $event)"
        :is-text-area="false"
        is-auto-complete-input
        :autocomplete-list="autocompleteModelsList"
        :placeholder="__('Enter your text')"
      />
      <el-select
        class="w-full"
        v-if="isDropdownInputField(setting)"
        v-model="selectedUseCase"
        placeholder="Select"
      >
        <el-option
          v-for="item in useCases"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item
      :label="__('Assign Summary text to variable')"
      v-if="isSummaryUseCase"
    >
      <create-or-select
        :items="singleValuedVariables"
        label="variable_name"
        value="variable_id"
        :placeholder="__('Select or create a new variable')"
        :new-item-message="__('new variable')"
        :current_select="currentlySelectedVariable"
        @change="handleVariableChange"
      />
      <el-col :span="4">
        <span class="row-message" v-if="isNewVariableCreatedToStoreSummary">
          {{ __("new variable") }}
        </span>
      </el-col>
    </el-form-item>
    <el-form-item :label="__('Summary Language')" v-if="isSummaryUseCase">
      <input-variable-popper
        :value="
          nodeToBind.web_service_node.data.additional_settings.open_ai
            .summary_language || 'English US'
        "
        @input="setOpenAISetting('summary_language', $event)"
        :is-text-area="false"
        is-auto-complete-input
        :autocomplete-list="autocompleteLanguageList"
        :placeholder="__('Select Summary Language')"
      />
    </el-form-item>
    <el-form-item style="margin: 25px 0;" :label="__('Configuration')">
      <el-tabs v-model="activeTab" class="tabs">
        <el-tab-pane label="Intents" name="intents" v-if="showIntentsTab">
          <el-form-item label="Add Intents">
            <el-select
              :value="openAISettings['intents']"
              @input="setOpenAISetting('intents', $event)"
              multiple
              filterable
              clearable
              allow-create
              default-first-option
              class="w-full"
              placeholder="Enter your new intents here"
            >
            </el-select>
          </el-form-item>
          <el-form-item :label="__('User Prompt')">
            <input-variable-popper
              :value="openAISettings['intent_user_prompt']"
              @input="setOpenAISetting('intent_user_prompt', $event)"
              :is-text-area="false"
              :placeholder="__('Enter your text')"
            />
          </el-form-item>
          <el-form-item :label="__('Detect Multiple Intents')">
            <el-checkbox
              :value="openAISettings['detect_multiple_intents']"
              @input="setOpenAISetting('detect_multiple_intents', $event)"
              style="padding-left: 3px; padding-top: 5px"
            />
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane :label="__('Prompt')" name="prompt" v-if="showPromptsTab">
          <el-form-item :label="__('System Context')">
            <input-variable-popper
              :is-text-area="true"
              :rows="6"
              v-model="messagesJsonSystemContent"
              placeholder="Provide your system context here..."
              force-reinitialize
            />
          </el-form-item>
          <el-form-item :label="__('User Prompt')">
            <input-variable-popper
              :is-text-area="true"
              :rows="6"
              v-model="messagesJsonUserContent"
              placeholder="Provide user prompt here..."
              force-reinitialize
            />
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane
          :label="__('Datastore')"
          name="datastore"
          v-if="showDataStoreTab"
        >
          <el-form-item :label="__('Datastore')">
            <el-select
              :value="openAISettings['data_store_id']"
              @input="setOpenAISetting('data_store_id', $event)"
              style="width: 100%;"
              @change="onDataStoreChange"
              :no-data-text="noDataTextDS"
              :placeholder="placeHolderDS"
              :loading="dataStoresLoading"
              default-first-option
              filterable
              clearable
            >
              <el-option
                v-for="(item, index) in dataStores"
                :label="item.display_name"
                :value="item.data_store_id"
                :key="index"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="__('Map Question Column')">
            <el-select
              :value="openAISettings['question_column']"
              @input="setOpenAISetting('question_column', $event)"
              style="width: 100%;"
              :no-data-text="noDataTextDSColumns"
              :placeholder="placeHolderQuestion"
              default-first-option
              filterable
              clearable
            >
              <el-option
                v-for="(item, index) in dsColumnList"
                :label="item.col_name"
                :value="item.col_name"
                :key="index"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="__('Map Answer Column')">
            <el-select
              :value="openAISettings['answer_column']"
              @input="setOpenAISetting('answer_column', $event)"
              style="width: 100%;"
              :no-data-text="noDataTextDSColumns"
              :placeholder="placeHolderQuestion"
              default-first-option
              filterable
              clearable
            >
              <el-option
                v-for="(item, index) in dsColumnList"
                :label="item.col_name"
                :value="item.col_name"
                :key="index"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="__('User Prompt')">
            <input-variable-popper
              :value="openAISettings['intent_user_prompt']"
              @input="setOpenAISetting('intent_user_prompt', $event)"
              :is-text-area="false"
              :placeholder="__('Enter your text')"
            />
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane
          :label="__('Open AI Parameters')"
          name="open_ai_params"
          v-if="showOpenAIParametersTab"
        >
          <el-form-item
            v-for="setting in openAiParameterSettings"
            :key="setting.key"
            :prop="
              `web_service_node.data.additional_settings.open_ai.${setting.key}`
            "
          >
            <div v-if="setting.type === 'slider'">
              <div
                slot="label"
                style="width: max-content;display: flex; justify-content: center"
              >
                <div class="el-form-item__label" style="margin-right:5px">
                  {{ __(setting.label) }}
                </div>
                <el-popover
                  placement="right"
                  width="400"
                  trigger="hover"
                  :content="setting.tooltip"
                >
                  <i slot="reference" class="el-icon-info icon"></i>
                </el-popover>
              </div>
              <el-slider
                style="margin-left: 10px; width: 60%"
                :value="openAISettings[setting.key]"
                @input="setOpenAISetting(setting.key, $event)"
                :min="setting.min"
                :max="setting.max"
                :step="setting.step"
                show-input
              />
            </div>
            <div v-if="setting.type === 'multiple_input'">
              <div
                slot="label"
                style="width: max-content;display: flex; justify-content: center"
              >
                <div class="el-form-item__label" style="margin-right:5px;">
                  {{ __(setting.label) }}
                </div>
                <el-popover
                  placement="right"
                  width="400"
                  trigger="hover"
                  :content="setting.tooltip"
                >
                  <i slot="reference" class="el-icon-info icon"></i>
                </el-popover>
              </div>
              <el-select
                :value="openAISettings[setting.key]"
                @input="setOpenAISetting(setting.key, $event)"
                multiple
                :multiple-limit="4"
                filterable
                allow-create
                default-first-option
                style="width: 60%;"
                placeholder="Enter multiple sequences (Upto 4 allowed)"
              >
              </el-select>
            </div>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane :label="__('Settings')" name="settings">
          <el-form-item
            :props="musicOnHold"
            :label="__('Music On Hold')"
            style="margin-top: 20px;"
            v-if="isVoiceTask"
          >
            <wav-file-uploader
              :folder="`tasks/${taskId}/web_service_node/moh`"
              :file-url="musicOnHoldUrl"
              :file-name="musicOnHoldName"
              :max-size-in-mb="3"
              @on-success="handleMOHUploadSuccess"
              @on-delete="handleMOHFileRemove"
            />
          </el-form-item>

          <el-form-item
            prop="web_service_node.data.fetch_timeout"
            :label="__('Fetch Timeout')"
          >
            <el-input-number
              class="fetchTimeoutSetting"
              :value="nodeToBind.web_service_node.data.fetch_timeout"
              @input="
                $set(nodeToBind.web_service_node.data, 'fetch_timeout', $event)
              "
              :min="3"
              :max="180"
            ></el-input-number>
            <span style="margin-left: 10px;">{{ __("seconds") }}</span>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form-item>
  </div>
</template>

<script>
import InputVariablePopper from "../components/InputVariablePopper";
import _ from "lodash";
import { mapActions, mapGetters, mapState } from "vuex";
import CreateOrSelect from "@/views/build/callflow/components/node-type-forms/components/CreateOrSelect";
import {
  OPEN_AI_INTENT_DETECTION_USE_CASE,
  OPEN_AI_CUSTOM_PROMPTING_USE_CASE,
  OPEN_AI_SUMMARY_USE_CASE,
  OPEN_AI_USE_CASES_LIST,
  OPEN_AI_DATASTORE_FAQ_USE_CASE
} from "@/views/build/callflow/components/node-type-forms/web-service/WebservicesConstants";
import { stringifyJsonArray } from "@/utils/collection";
import { aiSummaryLanguageList } from "@/constants/openFormType";
import WavFileUploader from "@/components/uploaders/types/WavFileUploader.vue";

const variableRuleInitialize = {
  rule_value: "",
  variable_name: "",
  variable_id: -1,
  default_value: "",
  array_variable: false
};
export default {
  name: "OpenAiNode",
  components: {
    WavFileUploader,
    InputVariablePopper,
    CreateOrSelect
  },
  props: {
    selectedProfile: {
      type: Object,
      required: true
    },
    nodeToBind: {
      type: Object,
      required: true
    },
    taskId: {
      required: true,
      type: [Number, String]
    },
    musicOnHold: {
      required: false,
      type: String
    },
    musicOnHoldName: {
      required: false,
      type: String
    },
    musicOnHoldUrl: {
      required: false,
      type: String
    },
    appendToPostBody: {
      type: Function,
      required: true
    },
    isEditing: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      profileSettings: [],
      variableRuleInitialize: _.cloneDeep(variableRuleInitialize),
      autocompleteModelsList: [
        { value: "gpt-3.5-turbo" },
        { value: "gpt-4" },
        { value: "gpt-4-0314" },
        { value: "gpt-3.5-turbo-0301" }
      ],
      autocompleteLanguageList: aiSummaryLanguageList,
      useCases: OPEN_AI_USE_CASES_LIST,
      activeTab: "custom_prompt",
      noDataTextDS: __("Couldn't find a Datastore in your account"),
      placeHolderDS: __("Please select a Datastore"),
      noDataTextDSColumns: __("Select a Datastore to view the columns here"),
      placeHolderQuestion: __("Please select a question column"),
      summaryVariableRuleValue: "open_ai_summary_var"
    };
  },
  mounted() {
    this.setProfileSettings();
    this.refreshTabFocus();
    this.initializeDataStores();
  },
  computed: {
    ...mapGetters("variables", {
      singleValuedVariables: "singleValuedVariables"
    }),
    ...mapState("dataStores", {
      dataStores: state => state.dataStores,
      dataStoresLoading: state => state.loading
    }),
    ...mapState("canvas", {
      task_type: state => state.task_type
    }),
    isVoiceTask() {
      return this.task_type && this.task_type === "voice";
    },
    additionalSettings() {
      return this.nodeToBind.web_service_node.data.additional_settings;
    },
    openAISettings() {
      return this.additionalSettings.open_ai;
    },

    messagesJsonSystemContent: {
      get() {
        return (
          _.find(this.messagesJson, msg => msg.role === "system").content || ""
        );
      },
      set(val) {
        this.messagesJson = _.map(_.cloneDeep(this.messagesJson), msg => {
          if (msg.role === "system") {
            msg.content = val;
          }
          return msg;
        });
      }
    },
    messagesJsonUserContent: {
      get() {
        return (
          _.find(this.messagesJson, msg => msg.role === "user").content || ""
        );
      },
      set(val) {
        this.messagesJson = _.map(_.cloneDeep(this.messagesJson), msg => {
          if (msg.role === "user") {
            msg.content = val;
          }
          return msg;
        });
      }
    },
    messagesJson: {
      get() {
        let messagesJson = _.get(
          this.nodeToBind,
          "web_service_node.data.additional_settings.open_ai.messages_json",
          ""
        );
        messagesJson =
          !_.isEmpty(messagesJson) && _.isString(messagesJson)
            ? JSON.parse(messagesJson)
            : messagesJson;
        return _.isEmpty(messagesJson)
          ? [
              { role: "system", content: "" },
              { role: "user", content: "" }
            ]
          : messagesJson;
      },
      set(val) {
        this.$set(
          this.nodeToBind.web_service_node.data.additional_settings.open_ai,
          "messages_json",
          stringifyJsonArray(val)
        );
      }
    },
    selectedUseCase: {
      get() {
        return (
          this.nodeToBind.web_service_node.data.additional_settings.open_ai
            .usecase || OPEN_AI_CUSTOM_PROMPTING_USE_CASE
        );
      },
      set(val) {
        this.$set(
          this.nodeToBind.web_service_node.data.additional_settings.open_ai,
          "usecase",
          val
        );
        this.refreshTabFocus();
        this.removeVariableRuleForSummary();
      }
    },
    isNewVariableCreatedToStoreSummary() {
      if (_.isEmpty(this.insertedRowIdVariableRule)) {
        return false;
      }
      return _.get(this.insertedRowIdVariableRule, "msg", "") !== "";
    },
    insertedRowIdVariableRule() {
      return (
        _.find(
          this.nodeToBind.web_service_node.data.variable_rules.data,
          rule => rule.rule_value === this.summaryVariableRuleValue
        ) || this.variableRuleInitialize
      );
    },
    currentlySelectedVariable() {
      let variableRule = this.insertedRowIdVariableRule;
      const { variable_id, variable_name } = _.isEmpty(variableRule)
        ? this.variableRuleInitialize
        : variableRule;
      return variable_id === -1 ? variable_name : variable_id;
    },
    isSummaryUseCase() {
      return this.selectedUseCase === OPEN_AI_SUMMARY_USE_CASE;
    },
    showOpenAIParametersTab() {
      return (
        this.selectedUseCase === OPEN_AI_CUSTOM_PROMPTING_USE_CASE ||
        this.selectedUseCase === OPEN_AI_INTENT_DETECTION_USE_CASE ||
        this.selectedUseCase === OPEN_AI_DATASTORE_FAQ_USE_CASE
      );
    },
    showPromptsTab() {
      return this.selectedUseCase === OPEN_AI_CUSTOM_PROMPTING_USE_CASE;
    },
    showDataStoreTab() {
      return this.selectedUseCase === OPEN_AI_DATASTORE_FAQ_USE_CASE;
    },
    showIntentsTab() {
      return this.selectedUseCase === OPEN_AI_INTENT_DETECTION_USE_CASE;
    },
    selectedDataStore() {
      if (
        this.nodeToBind.web_service_node.data.additional_settings.open_ai
          .data_store_id
      ) {
        return _.find(this.dataStores, {
          data_store_id: this.nodeToBind.web_service_node.data
            .additional_settings.open_ai.data_store_id
        });
      }
      return null;
    },
    dsColumnList() {
      let result = [];
      if (this.selectedDataStore) {
        result = _.filter(this.selectedDataStore.collection_structure, function(
          chr
        ) {
          return chr.type === "string";
        });
      }
      return result;
    },
    generalProfileSettings() {
      return _.filter(this.profileSettings, function(setting) {
        return !setting.parent;
      });
    },
    openAiParameterSettings() {
      return _.filter(this.profileSettings, function(setting) {
        return setting.parent && setting.parent === "config";
      });
    },
    isAutoCompleteField() {
      return setting => {
        return setting.type === "autocomplete_input";
      };
    },
    isOtherInputField() {
      return setting => {
        return setting.type === "other_input";
      };
    },
    isDropdownInputField() {
      return setting => {
        return setting.type === "dropdown_input";
      };
    }
  },

  methods: {
    ...mapActions("dataStores", {
      getDataStores: "getDataStores"
    }),
    async initializeDataStores() {
      await this.getDataStores({ fetch_all: 1, is_log: 0 });
    },

    setAdditionalSetting(key, value) {
      this.$set(
        this.nodeToBind.web_service_node.data.additional_settings,
        key,
        value
      );
    },

    setOpenAISetting(key, value) {
      this.$set(
        this.nodeToBind.web_service_node.data.additional_settings.open_ai,
        key,
        value
      );
    },

    setProfileSettings() {
      this.profileSettings = JSON.parse(this.selectedProfile.profile_setting);
    },

    /**
     * remove a variable rule for summary use case
     */
    removeVariableRuleForSummary() {
      _.remove(
        this.nodeToBind.web_service_node.data.variable_rules.data,
        obj => obj.rule_value === this.summaryVariableRuleValue
      );
    },
    refreshTabFocus() {
      switch (this.selectedUseCase) {
        case OPEN_AI_CUSTOM_PROMPTING_USE_CASE:
          this.activeTab = "prompt";
          break;
        case OPEN_AI_SUMMARY_USE_CASE:
          this.activeTab = "settings";
          break;
        case OPEN_AI_INTENT_DETECTION_USE_CASE:
          this.activeTab = "intents";
          break;
        case OPEN_AI_DATASTORE_FAQ_USE_CASE:
          this.activeTab = "datastore";
          break;
      }
    },
    updatePostBody(value) {
      let keys = [
        "model",
        "messages_json",
        "temperature",
        "max_tokens",
        "presence_penalty",
        "frequency_penalty",
        "top_p",
        "stop",
        "api_key",
        "detect_multiple_intents",
        "intent_user_prompt",
        "intents"
      ];
      value = _.pick(value, keys);
      if (_.isEmpty(value.stop)) {
        value = _.omit(value, "stop");
      }
      value.selected_usecase = this.selectedUseCase;
      this.appendToPostBody(value);
    },
    handleVariableChange(option) {
      this.newVariableCreated = option.value === -1;
      const insertedRowIdToVariableObj = {
        variable_id: option.value,
        variable_name: option.label,
        msg: option.msg,
        rule_value: this.summaryVariableRuleValue,
        array_variable: false
      };
      this.$emit("add-rule", {
        rule: insertedRowIdToVariableObj,
        ruleValue: this.summaryVariableRuleValue
      });
      // if (_.isEmpty(this.nodeToBind.web_service_node.data.variable_rules)) {
      //   this.$set(this.nodeToBind.web_service_node.data, "variable_rules", {});
      //   this.$set(
      //     this.nodeToBind.web_service_node.data.variable_rules,
      //     "data",
      //     []
      //   );
      // } else {
      //   _.remove(
      //     this.nodeToBind.web_service_node.data.variable_rules.data,
      //     obj => obj.rule_value === this.summaryVariableRuleValue
      //   );
      // }
      // this.nodeToBind.web_service_node.data.variable_rules.data.push(
      //   insertedRowIdToVariableObj
      // );
    },
    onDataStoreChange() {
      this.$set(
        this.nodeToBind.web_service_node.data.additional_settings.open_ai,
        "question_column",
        ""
      );
      this.$set(
        this.nodeToBind.web_service_node.data.additional_settings.open_ai,
        "answer_column",
        ""
      );
    },
    /**
     * After delete music on hold clear up the variables
     */
    handleMOHFileRemove() {
      this.$emit("remove-moh-file");
    },
    handleMOHUploadSuccess({ path, url }) {
      this.$emit("upload-moh-file-success", { path, url });
    }
  },
  watch: {
    "nodeToBind.web_service_node.data.additional_settings.open_ai": {
      immediate: true,
      deep: true,
      handler: function(val) {
        if (this.nodeToBind.web_service_node.data.post_body_placeholder) {
          this.updatePostBody(val);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node_common.scss";
@import "~@/styles/element-variables.scss";
::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}
.el-form-item {
  margin-top: 10px;
}
</style>
