<template>
  <div style="display: flex; flex-direction: column">
    <div class="super-text">
      <div>{{ parameter.tooltip.toUpperCase() }}</div>
      <div v-if="parameter.info">
        <el-tooltip
          class="parameter-info"
          effect="dark"
          :content="parameter.info"
          placement="top"
        >
          <i style="padding-left: 5px" class="el-icon-info"></i>
        </el-tooltip>
      </div>
    </div>
    <input-variable-popper
      :disabled="disabled"
      v-if="parameter.type === 'input'"
      force-reinitialize
      :prepend-items="nonJsonResultExpressions"
      :is-text-area="false"
      v-model="parameter.tempValue"
      class="parameters"
      suffix="}}"
      :popper-class="`expressionPopper ${dropDownClass}`"
      popper-position="top-end"
      autocomplete="off"
      aria-autocomplete="off"
      data-lpignore="true"
      :include-secure-variables="useSecureVariables"
      icon-suffix="el-icon-edit-outline"
      @suffix-clicked="showUpdateBox(parameter)"
    ></input-variable-popper>

    <el-input
      v-if="parameter.type === 'regex_input'"
      :disabled="disabled"
      class="parameters"
      v-model="regexParameter"
    >
      <i
        slot="suffix"
        class="el-input__icon  el-icon-edit-outline"
        @click="showUpdateBox(parameter)"
      ></i>
      <template slot="prepend">/</template>
      <template slot="append"
        >/<el-dropdown
          :hide-on-click="false"
          trigger="click"
          @command="updateSelectedRegexFlags"
          autocomplete="off"
          aria-autocomplete="off"
          data-lpignore="true"
        >
          <el-button type="primary">
            {{ selectedRegexFlags }}
          </el-button>
          <el-dropdown-menu class="regex-dropdown" slot="dropdown">
            <div style="padding: 5px 20px;text-align: center;">Regex Flags</div>
            <el-divider style="margin: 2px 0" />
            <el-dropdown-item
              :command="regexFlag"
              :key="regexFlag.identifier"
              v-for="regexFlag in regexFlags"
              ><regex-flag-option
                :regex-flag-option="regexFlag"
                :selected-flags="selectedRegexFlags"
            /></el-dropdown-item>
          </el-dropdown-menu> </el-dropdown
      ></template>
    </el-input>

    <el-select
      :disabled="disabled"
      v-if="parameter.type === 'dropdown'"
      class="w-full functionsList"
      v-model="parameter.tempValue"
      :popper-class="`functionDropDown ${dropDownClass}`"
      value-key="value"
      filterable
      clearable
      :placeholder="__('Select')"
      autocomplete="off"
      aria-autocomplete="off"
      data-lpignore="true"
      default-first-option
    >
      <el-option
        v-for="(item, index) in getParameterValues(parameter, expression)"
        :key="index"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>

    <el-select
      :disabled="disabled"
      v-if="parameter.type === 'json-dropdown'"
      v-model="parameter.tempValue"
      class="w-full functionsList"
      :popper-class="`functionDropDown ${dropDownClass}`"
      value-key="node_id"
      @change="handleSelect($event)(parameter)"
      filterable
      placeholder="Select"
      autocomplete="off"
      aria-autocomplete="off"
      data-lpignore="true"
      default-first-option
    >
      <el-option
        v-for="complexVariable in availableComplexVariables"
        :key="complexVariable.node_id"
        :label="complexVariable.name"
        :value="complexVariable.node_id"
      >
      </el-option>
    </el-select>

    <timezone-dropdown
      class="w-full functionsList"
      :popper-class="`functionDropDown ${dropDownClass}`"
      value-key="node_id"
      v-if="parameter.type === 'tz-dropdown'"
      :default_timezone="parameter.tempValue || ''"
      @timezone-changed="handleTimezoneSelect($event)(parameter)"
      :additional-options="singleValuedVariablesAndExpressions"
      clearable
      autocomplete="off"
      aria-autocomplete="off"
      data-lpignore="true"
    />

    <el-select
      :disabled="disabled"
      v-if="parameter.type === 'xsi-dropdown'"
      v-model="parameter.tempValue"
      class="w-full functionsList"
      :popper-class="`functionDropDown ${dropDownClass}`"
      value-key="sub_event_id"
      @change="handleSelect($event)(parameter)"
      filterable
      :placeholder="__('Select')"
      autocomplete="off"
      aria-autocomplete="off"
      data-lpignore="true"
      default-first-option
    >
      <el-option-group
        v-for="(subscribed_event, package_name) in availableXsiEvents"
        :key="package_name"
        :label="package_name"
      >
        <el-option
          v-for="eventItem in subscribed_event"
          :key="eventItem.sub_event_id"
          :label="eventItem.xsi_event_name"
          :value="eventItem.sub_event_id"
        ></el-option>
      </el-option-group>
    </el-select>

    <el-checkbox
      :disabled="disabled"
      v-if="parameter.type === 'checkbox'"
      v-model="parameter.tempValue"
      class="caseSensitive"
      autocomplete="off"
      aria-autocomplete="off"
      data-lpignore="true"
    >
    </el-checkbox>

    <el-input
      v-if="parameter.type === 'array-rule'"
      class="expression-builder-input parameters pointer"
      disabled
      :value="
        prettify(
          jsonPathValue(parameter.tempValue, variables),
          expression.parameters[0].type === 'json-dropdown' &&
            isNumber(expression.parameters[0].tempValue)
        )
      "
      autocomplete="off"
      aria-autocomplete="off"
      data-lpignore="true"
    >
      <i
        style="cursor:pointer"
        class="el-icon-edit el-input__icon"
        slot="suffix"
        @click="configureJSONRule"
      >
      </i>
    </el-input>

    <el-dialog
      v-if="showModal"
      :visible.sync="showModal"
      :close-on-click-modal="false"
      :show-close="false"
      append-to-body
      destroy-on-close
    >
      <json-value-picker
        :json="jsonVariableDefaultValue"
        :expression-names="nonJsonResultExpressions"
        :parameter="expression.parameters[0]"
        v-model="parameter.tempValue"
        @close="showModal = false"
      ></json-value-picker>
    </el-dialog>

    <el-dialog
      v-if="showBox"
      :visible.sync="showBox"
      :close-on-click-modal="false"
      :show-close="false"
      append-to-body
      destroy-on-close
      class="parameter-dialog-box"
      ><div style="display:flex;align-items: baseline;gap: 15px">
        <div class="super-text-dialog">
          {{ getExpressionCustomName(expression.name) }} :
        </div>
        <div class="super-text-dialog">
          <div>{{ parameter.tooltip }}</div>
          <div v-if="parameter.info">
            <el-tooltip
              class="parameter-info"
              effect="dark"
              :content="parameter.info"
              placement="top"
            >
              <i style="padding-left: 5px" class="el-icon-info"></i>
            </el-tooltip>
          </div>
        </div>
      </div>
      <input-variable-popper
        v-if="updateParameter.type === 'input'"
        force-reinitialize
        :prepend-items="nonJsonResultExpressions"
        v-model="updateParameter.tempValue"
        suffix="}}"
        :popper-class="`expressionPopper ${dropDownClass}`"
        popper-position="top-end"
        autocomplete="off"
        aria-autocomplete="off"
        data-lpignore="true"
        :include-secure-variables="useSecureVariables"
        is-text-area
        class="popper-text-area"
      />
      <el-input
        v-if="updateParameter.type === 'regex_input'"
        v-model="updateRegexParameter"
        autocomplete="off"
        aria-autocomplete="off"
        data-lpignore="true"
        type="textarea"
        class="popper-text-area"
      />
      <div class="actionButtons">
        <el-button
          @click="
            showBox = false;
            $data.$parameter.tempValue = updateParameter.tempValue;
            updateParameter = null;
          "
          class="save"
        >
          {{ __("Save") }}
        </el-button>

        <el-button
          class="cancel"
          @click="
            showBox = false;
            updateParameter = null;
          "
        >
          {{ __("Cancel") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import JsonValuePicker from "@/views/build/callflow/components/expression-builder/JsonValuePicker";
import { jsonPathValue } from "@/utils/canvas";
import { mapGetters, mapState } from "vuex";
import RegexFlagOption from "@/views/build/callflow/components/expression-builder/RegexFlagOption";

import _ from "lodash";
import { prettifyJsonPath } from "@/utils/transformers";
import TimezoneDropdown from "@/components/TimezoneDropdown";

export default {
  props: {
    value: {
      required: true,
      type: Object
    },
    expression: {
      required: false,
      type: Object
    },
    expressionNames: {
      required: true,
      type: Array
    },
    customExpressionNamesMap: {
      required: false,
      type: Object,
      default: () => ({})
    },
    expressionNamePrefix: {
      required: false,
      type: String,
      default: "Expr"
    },
    expressionCustomNamePrefix: {
      required: false,
      type: String,
      default: "expr"
    },
    expressions: {
      required: true,
      type: Array
    },
    complexVariables: {
      required: false,
      type: Array,
      default: () => []
    },
    xsiSubscriptions: {
      required: false,
      type: Object,
      default: () => ({})
    },
    dropDownClass: {
      required: false,
      type: String,
      default: ""
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    useSecureVariables: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  components: {
    TimezoneDropdown,
    InputVariablePopper,
    JsonValuePicker,
    RegexFlagOption
  },
  data() {
    return {
      $parameter: null,
      updateParameter: null,
      showModal: false,
      showBox: false,
      firstInitialization: true,
      jsonPathValue,
      prettify: prettifyJsonPath
    };
  },
  computed: {
    ...mapState("canvas", {
      clickedNode: state => state.clickedNode,
      clickedRenderedNodeId: state => state.clickedRenderedNodeId
    }),

    ...mapState("expressionbuilder", {
      regexFlags: state => state.regexFlags
    }),

    ...mapGetters("variables", {
      variables: "variables",
      singleValuedVariables: "singleValuedVariables",
      singleValuedAndSecureVariables: "singleValuedAndSecureVariables",
      secureVariables: "secureVariables"
    }),

    ...mapGetters("expressionbuilder", {
      isJsonFunction: "isJsonFunction",
      javascriptFunctionsDescriptor: "javascriptFunctionsDescriptor"
    }),

    // ...mapGetters("canvas", {
    //   getParentTree: "getParentTree",
    //   getParentTreeIds: "getParentTreeIds"
    // }),

    customExpressionNameToOriginalNameMap() {
      let ret = {};
      for (let key in this.customExpressionNamesMap) {
        ret[this.customExpressionNamesMap[key]] = key;
      }
      return ret;
    },

    variablesToUse() {
      if (!this.useSecureVariables) {
        return this.singleValuedVariables;
      } else {
        return this.singleValuedAndSecureVariables;
      }
    },

    parameter() {
      return this.$data.$parameter;
    },

    isNumber() {
      return val => _.isNumber(val);
    },

    isString() {
      return val => _.isString(val);
    },

    getParameterValues() {
      return (parameter, expression) => {
        if (parameter.valuesFn && typeof parameter.valuesFn === "function") {
          let param;
          switch (expression.selectedFunction) {
            case "compare":
              param = expression.parameters[0].value;
              break;
          }
          if (param) {
            return parameter.valuesFn(param);
          }
        }
        return parameter.values || [];
      };
    },

    updateRegexParameter: {
      get() {
        return this.regexParts(this.updateParameter.tempValue)[0];
      },
      set(val) {
        this.updateParameter.tempValue =
          "/" + val + "/" + this.selectedRegexFlags;
      }
    },

    regexParameter: {
      get() {
        return this.regexParts(this.parameter.tempValue)[0];
      },
      set(val) {
        this.parameter.tempValue = "/" + val + "/" + this.selectedRegexFlags;
      }
    },

    selectedRegexFlags: {
      get() {
        return this.regexParts(this.parameter.tempValue)[1];
      },
      set(val) {
        this.parameter.tempValue = "/" + this.regexParameter + "/" + val;
      }
    },

    jsonVariableDefaultValue() {
      if (this.expression.parameters[0] && this.expression.parameters[0].meta) {
        return _.cloneDeep(this.expression.parameters[0].meta.default_value);
      } else {
        return {};
      }
    },

    nonJsonResultExpressions() {
      let expressionsToReturn = _.filter(
        this.expressionNames,
        expressionName => {
          let expression = _.find(
            this.expressions,
            expression => expression.name === expressionName
          );

          if (!expression) {
            return false;
          } else {
            // should avoid expressions that are guaranteed to return an array value
            return !["json_decode"].includes(expression.selectedFunction);
          }
        }
      );

      return _.map(expressionsToReturn, expr => {
        let index = expr.replace(this.expressionNamePrefix, "");
        return this.customExpressionNamesMap[index] || expr;
      });
    },

    singleValuedVariablesAndExpressions() {
      return {
        variables: _.map(this.variablesToUse, variable => {
          return {
            timezone: "{{" + variable.variable_id + "}}",
            label: variable.variable_name
          };
        }),
        expressions: _.map(
          _.filter(this.expressionNames, expressionName => {
            let expression = _.find(
              this.expressions,
              expression => expression.name === expressionName
            );

            if (!expression) {
              return false;
            } else {
              // these are the functions that may return a json output so that it cannot be used as input for timezone value
              return !["json_decode"].includes(expression.selectedFunction);
            }
          }),
          expressionName => {
            let index = expressionName.replace(this.expressionNamePrefix, "");
            let label = this.customExpressionNamesMap[index] || expressionName;
            return {
              label,
              timezone: "{{" + expressionName + "}}"
            };
          }
        )
      };
    },

    availableXsiEvents() {
      let expressions = _.map(
        _.filter(this.expressionNames, expressionName => {
          let expression = _.find(
            this.expressions,
            expression => expression.name === expressionName
          );

          if (!expression) {
            return false;
          } else {
            // these are the functions that may return a json output so that it can be used as json input in json functions
            return ["json_decode"].includes(expression.selectedFunction);
          }
        }),
        expressionName => {
          let index = expressionName.replace(this.expressionNamePrefix, "");
          let label = this.customExpressionNamesMap[index] || expressionName;
          return {
            name: label,
            node_id: "{{" + expressionName + "}}",
            default_value: {}
          };
        }
      );
      return _.concat(expressions, this.xsiSubscriptions)[0];
    },

    availableComplexVariables() {
      // return _.filter(this.complexVariables, variable =>
      //   this.getParentTreeIds(this.clickedRenderedNodeId).includes(
      //     variable.node_id
      //   )
      // );
      let expressions = _.map(
        _.filter(this.expressionNames, expressionName => {
          let expression = _.find(
            this.expressions,
            expression => expression.name === expressionName
          );

          if (!expression) {
            return false;
          } else {
            // Special case for JS functions
            if (
              _.get(
                this.javascriptFunctionsDescriptor[expression.selectedFunction],
                "is_kvlist",
                false
              )
            ) {
              return true;
            }

            // these are the functions that may return a json output so that it can be used as json input in json functions
            return ["json_decode"].includes(expression.selectedFunction);
          }
        }),
        expressionName => {
          let index = expressionName.replace(this.expressionNamePrefix, "");
          let label = this.customExpressionNamesMap[index] || expressionName;
          return {
            name: label,
            node_id: "{{" + expressionName + "}}",
            default_value: {}
          };
        }
      );
      return _.concat(expressions, this.complexVariables);
    }
  },
  methods: {
    showUpdateBox(parameter) {
      this.updateParameter = _.cloneDeep(parameter);
      this.showBox = true;
      this.$nextTick(async () => {
        await this.focusOnExpressionField();
      });
    },

    /**
     *
     * @param value is of pattern "/regex/flags"
     * @returns {string[]}
     */
    regexParts(value) {
      let tempValue = (value || "").toString();
      if (!tempValue) {
        return ["", ""];
      }

      const lastSlashIndex = tempValue.lastIndexOf("/");

      if (lastSlashIndex !== -1) {
        let startingIndex = 1;

        if (tempValue[0] !== "/") {
          startingIndex = 0;
        }

        return [
          tempValue.slice(startingIndex, lastSlashIndex),
          tempValue.slice(lastSlashIndex + 1)
        ];
      }
      return [tempValue, ""];
    },

    getExpressionCustomName(expr) {
      let regex = new RegExp(
        `^$` + _.escapeRegExp(this.expressionNamePrefix) + `\\d+$`
      );
      if (regex.test(expr)) {
        let index = expr.replace(this.expressionNamePrefix, "");
        return this.customExpressionNamesMap[index] || expr;
      }
      return expr;
    },

    clearInvalidOperator(expression) {
      const operatorParameter = expression.parameters[2];
      const availableOperators = _.map(
        this.getParameterValues(operatorParameter, expression),
        obj => obj.value
      );
      let currentOperator = operatorParameter.value;
      if (!_.includes(availableOperators, currentOperator)) {
        if (operatorParameter.tempValue) {
          operatorParameter.tempValue = "";
        }
      } else {
        operatorParameter.error = "";
      }
    },

    parseExpressionChange(expression) {
      switch (expression.selectedFunction) {
        case "compare":
          this.clearInvalidOperator(expression);
          break;
      }
    },

    parseParameterValue(value) {
      if (!this.isString(value)) {
        return value;
      }
      let regex = new RegExp(
        `{{(` + _.escapeRegExp(this.expressionNamePrefix) + `\\d+?)}}`,
        "g"
      );
      let matches = value.matchAll(regex);
      matches = _.map(Array.from(matches), match => {
        return match[1];
      });
      _.map(matches, match => {
        let escapedPrefix = _.escapeRegExp("{{");
        let escapedSuffix = _.escapeRegExp("}}");
        let re = new RegExp(`${escapedPrefix}(${match})${escapedSuffix}`, "g");
        match = match.replace(this.expressionNamePrefix, "");
        if (this.customExpressionNamesMap[match]) {
          value = value.replace(
            re,
            `{{${this.customExpressionNamesMap[match]}}}`
          );
        }
      });
      return value;
    },

    parseParameterTempValue(value) {
      if (!this.isString(value)) {
        return value;
      }
      let regex = new RegExp(
        `{{(` + _.escapeRegExp(this.expressionCustomNamePrefix) + `.*?)}}`,
        "g"
      );
      let matches = value.matchAll(regex);
      matches = _.map(Array.from(matches), match => {
        return match[1];
      });
      _.map(matches, match => {
        let escapedPrefix = _.escapeRegExp("{{");
        let escapedSuffix = _.escapeRegExp("}}");
        let re = new RegExp(`${escapedPrefix}(${match})${escapedSuffix}`, "g");
        if (this.customExpressionNameToOriginalNameMap[match]) {
          value = value.replace(
            re,
            `{{${this.expressionNamePrefix}${this.customExpressionNameToOriginalNameMap[match]}}}`
          );
        }
      });
      return value;
    },

    async focusOnExpressionField() {
      let el = await document.getElementsByClassName(`popper-text-area`);
      if (el && el.length) {
        let inputs = el[0].getElementsByTagName("textarea");
        if (inputs && inputs.length) {
          inputs[0].focus();
        }
      }
    },

    configureJSONRule() {
      if (this.expression.parameters[0] && this.expression.parameters[0].meta)
        this.showModal = true;
    },
    initializeParameter(val) {
      if (!_.isEqual(val, this.parameter)) {
        this.$data.$parameter = _.cloneDeep(val);
      }
      this.correctModifiers();
    },

    updateSelectedRegexFlags(regexFlag) {
      if (this.selectedRegexFlags.includes(regexFlag.identifier)) {
        let regex = new RegExp(`${regexFlag.identifier}`, "g");
        this.selectedRegexFlags = this.selectedRegexFlags.replace(regex, "");
      } else {
        this.selectedRegexFlags += regexFlag.identifier;
      }
    },

    setMetaField() {
      if (this.isJsonFunction(this.expression.selectedFunction)) {
        this.$data.$parameter = this.handleSelect(this.parameter.tempValue)(
          _.cloneDeep(this.parameter)
        );
      }
    },

    handleTimezoneSelect(item) {
      return parameter => {
        parameter.tempValue = item;
      };
    },

    handleSelect(item) {
      return parameter => {
        if (
          !(
            this.isJsonFunction(this.expression.selectedFunction) &&
            (parameter.type === "json-dropdown" ||
              parameter.type === "xsi-dropdown")
          )
        )
          return parameter;

        if (!parameter.meta) {
          parameter.meta = {};
        }

        if (parameter.type === "json-dropdown") {
          if (!_.isEmpty(this.complexVariables) && item) {
            let complexVariable = _.find(this.availableComplexVariables, {
              node_id: item
            });
            if (!_.isEmpty(complexVariable)) {
              parameter.meta.default_value =
                complexVariable.default_value || {};
              parameter.error = "";
            } else {
              parameter.error = parameter.tempValue
                ? "cannot find object"
                : "invalid object";
            }
          }
        } else {
          if (!_.isEmpty(this.xsiSubscriptions) && item) {
            let availableXsiEventsFlatMap = _.flatMap(this.availableXsiEvents);
            let complexVariable = _.find(availableXsiEventsFlatMap, {
              sub_event_id: item
            });
            if (!_.isEmpty(complexVariable)) {
              parameter.meta.default_value =
                complexVariable.default_value || {};
              parameter.error = "";
            } else {
              parameter.error = parameter.tempValue
                ? __("cannot find object")
                : __("invalid object");
            }
          }
        }
        return parameter;
      };
    },
    correctModifiers() {
      if (this.parameter.type === "regex_input") {
        let currentModifiers = Array.from(this.selectedRegexFlags);
        let validModifiers = _.map(
          this.regexFlags,
          regexFlag => regexFlag.identifier
        );

        this.selectedRegexFlags = _.intersection(
          currentModifiers,
          validModifiers
        ).join("");
      }
    }
  },
  watch: {
    expression: {
      deep: true,
      immediate: true,
      handler: function(expression) {
        this.parseExpressionChange(expression);
      }
    },
    parameter: {
      deep: true,
      handler: function(parameter) {
        parameter.value = this.parseParameterTempValue(parameter.tempValue);
        this.$emit("input", _.cloneDeep(parameter));
        if (parameter.required && !this.firstInitialization) {
          this.$emit("validate", parameter);
        }
        this.firstInitialization = false;
      }
    },
    customExpressionNamesMap: {
      deep: true,
      handler: function() {
        this.parameter.tempValue = this.parseParameterValue(
          this.parameter.value
        );
      }
    },
    value: {
      immediate: true,
      deep: true,
      handler: "initializeParameter"
    },
    complexVariables: {
      deep: true,
      immediate: true,
      handler: "setMetaField"
    },
    xsiSubscriptions: {
      deep: true,
      immediate: true,
      handler: "setMetaField"
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/expression-builder.scss";

.actionButtons {
  margin-top: 20px;
  display: flex;
}

.save {
  border: none;
  height: 36px;
  color: white;
  font-weight: 200;
  font-size: 1rem;
}

.cancel {
  border: none;
  height: 36px;
  font-weight: 200;
  font-size: 1rem;
}

.save {
  color: $expression-row-background;
  background: var(--theme-color);
  &:hover {
    background-color: var(--theme-hover-color);
    color: $expression-row-background;
  }
  &:disabled {
    background-color: var(--theme-color);
    color: white;
    &:hover {
      background-color: var(--theme-hover-color);
      color: white;
    }
  }
}

.cancel {
  background: #f5f5f5;
  color: #a0a8b5;
  &:hover {
    background: lightgrey;
    color: white;
  }
}

.parameters {
  ::v-deep .el-input-group__append,
  ::v-deep .el-input-group__prepend {
    padding: 0 5px;
    color: #a0a8b5;
  }
}
.el-input__icon {
  cursor: pointer;
}
</style>

<style lang="scss">
@import "~@/styles/expression-builder.scss";

.super-text-dialog {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  height: 12px;
  line-height: 12px;
  margin-bottom: 10px;
  display: flex;
  color: $super-text-grey;
  transition: $transition;
}
.regex-dropdown {
  max-width: 250px;

  .el-divider--horizontal {
    margin: 5px 0;
  }

  .regex-option {
    .highlight {
      color: black;
      font-size: 1.05rem;
      padding: 0 1px;
    }
  }
}
</style>
