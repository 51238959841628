<!--suppress ALL -->
<template>
  <el-dialog
    :visible.sync="openManageNumberModal"
    v-if="openManageNumberModal"
    top="6vh"
    :show-close="true"
    custom-class="editContentItemModal"
    destroy-on-close
    fullscreen
    @close="handleCancel"
  >
    <el-scrollbar :native="false" style="margin-top: 35px">
      <div style="max-height: calc(100vh-35px)">
        <el-form
          label-position="top"
          ref="taskForm"
          label-width="100px"
          class="form-container"
        >
          <el-row type="flex" style="padding-top: 34px">
            <el-col :span="10" :offset="7">
              <page-header :title="__('Assign Numbers')"></page-header>
            </el-col>
          </el-row>

          <el-row type="flex">
            <el-col :span="10" :offset="7">
              <el-form-item class="form_label_top" :label="this.task_name">
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" style="padding-top: 34px">
            <el-col :span="10" :offset="7">
              <PaginationToolbar
                :sort-by-options="sortByOptions"
                :content-api="getPhoneNumbers"
                :key="task_name"
                :list-changed-event-name="`phone-numbers-list-changed`"
              >
              </PaginationToolbar>
            </el-col>
          </el-row>

          <el-row type="flex">
            <el-col :span="10" :offset="7">
              <el-tabs
                v-model="activeTab"
                class="tabs"
                @tab-click="handleTabChanged"
              >
                <el-tab-pane :label="__('Map Numbers')" name="tab_map_numbers">
                  <el-scrollbar :native="false">
                    <div class="tabPane">
                      <el-form-item>
                        <el-table
                          :data="this.phone_numbers"
                          style="width: 100%"
                          @selection-change="handleSelectionChange"
                          v-loading="loading"
                          class="list-table"
                        >
                          <el-table-column type="selection"></el-table-column>
                          <el-table-column
                            :label="__('Phone Number')"
                            prop="phone_number"
                          >
                          </el-table-column>
                          <el-table-column label="Tags" prop="tags">
                            <template slot-scope="scope" v-if="scope.row.tags">
                              <el-tag
                                v-for="tag in tagsArray(scope.row.tags)"
                                :key="tag"
                              >
                                {{ tag }}
                              </el-tag>
                            </template>
                          </el-table-column>
                        </el-table>
                      </el-form-item>
                      <el-form-item>
                        <el-button
                          class="submitBtn"
                          :disabled="
                            checkSelectedNumbers ||
                              !this.can('build.tasks.write')
                          "
                          :loading="isSubmitting"
                          @click="mapNumbers()"
                          >{{ __("Map Numbers") }}
                        </el-button>
                        <el-button class="cancelBtn" @click="handleCancel()"
                          >{{ __("Cancel") }}
                        </el-button>
                      </el-form-item>
                    </div>
                  </el-scrollbar>
                </el-tab-pane>

                <el-tab-pane
                  :label="__('Unmap Numbers')"
                  name="tab_unmap_numbers"
                >
                  <el-scrollbar :native="false">
                    <div class="tabPane">
                      <el-form-item>
                        <el-table
                          :data="this.phone_numbers"
                          style="width: 100%"
                          @selection-change="handleSelectionChange"
                          v-loading="loading"
                          class="list-table"
                        >
                          <el-table-column type="selection"></el-table-column>
                          <el-table-column
                            :label="__('Phone Number')"
                            prop="phone_number"
                          >
                          </el-table-column>
                          <el-table-column label="Tags" prop="tags">
                            <template slot-scope="scope" v-if="scope.row.tags">
                              <el-tag
                                v-for="tag in tagsArray(scope.row.tags)"
                                :key="tag"
                              >
                                {{ tag }}
                              </el-tag>
                            </template>
                          </el-table-column>
                        </el-table>
                      </el-form-item>
                      <el-form-item>
                        <el-button
                          class="submitBtn"
                          :loading="isSubmitting"
                          @click="unmapNumbers()"
                          :disabled="
                            !this.selectedNumbers.length ||
                              !this.can('build.tasks.write')
                          "
                          >{{ __("Unmap Numbers") }}
                        </el-button>
                        <el-button class="cancelBtn" @click="handleCancel()"
                          >{{ __("Cancel") }}
                        </el-button>
                      </el-form-item>
                    </div>
                  </el-scrollbar>
                </el-tab-pane>

                <el-tab-pane
                  :label="__('Move Numbers')"
                  name="tab_move_numbers_canvas"
                >
                  <el-scrollbar :native="false">
                    <div class="tabPane">
                      <el-form-item>
                        <el-table
                          :data="this.phone_numbers"
                          style="width: 100%"
                          @selection-change="handleSelectionChange"
                          v-loading="loading"
                          class="list-table"
                        >
                          <el-table-column type="selection"></el-table-column>
                          <el-table-column
                            :label="__('Phone Number')"
                            prop="phone_number"
                          >
                          </el-table-column>
                          <el-table-column
                            :label="__('Task Name')"
                            prop="task_name"
                          >
                          </el-table-column>
                          <el-table-column label="Tags" prop="tags">
                            <template slot-scope="scope" v-if="scope.row.tags">
                              <el-tag
                                v-for="tag in tagsArray(scope.row.tags)"
                                :key="tag"
                              >
                                {{ tag }}
                              </el-tag>
                            </template>
                          </el-table-column>
                        </el-table>
                      </el-form-item>
                      <el-form-item>
                        <el-button
                          class="submitBtn"
                          :loading="isSubmitting"
                          @click="moveNumbers()"
                          :disabled="
                            !this.selectedNumbers.length ||
                              !this.can('build.tasks.write')
                          "
                          >{{ __("Move Numbers") }}
                        </el-button>
                        <el-button class="cancelBtn" @click="handleCancel()"
                          >{{ __("Cancel") }}
                        </el-button>
                        <br />
                        <span>
                          <!-- eslint-disable-next-line -->
                          {{ __("Note: The number will be moved to the current task") }}
                        </span>
                      </el-form-item>
                    </div>
                  </el-scrollbar>
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-scrollbar>
  </el-dialog>
</template>

<script>
import PaginationToolbar from "@/components/PaginationToolbar";
import PageHeader from "@/components/PageHeader";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import { mapActions, mapState } from "vuex";
import { EventBus } from "@/EventBus";
import _ from "lodash";

export default {
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    PageHeader
  },
  props: {
    task: {
      required: false,
      type: Object,
      default: null
    },
    openModal: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      task_id: "-1",
      task_name: "",
      sortByColumns: ["phone_number", "phone_number_id"],
      activeTab: "tab_map_numbers",
      openManageNumberModal: false,
      selectedTask: "",
      phone_numbers: [],
      selectedNumbers: [],
      isSubmitting: false
    };
  },
  methods: {
    ...mapActions("tasks", {
      getPhoneNumbers: "getPhoneNumbers",
      updateNumbers: "updateNumbers",
      setTaskIdFilter: "setTaskIdFilter",
      setExcludeTaskIdFilter: "setExcludeTaskIdFilter"
    }),
    tagsArray(tagsString) {
      return tagsString.split(",", 2);
    },
    handleSelectionChange(val) {
      this.selectedNumbers = val;
    },
    updateNumbersMapping(data) {
      this.isSubmitting = true;
      this.updateNumbers(data)
        .then(data => {
          this.isSubmitting = false;
          this.$message({
            type: "success",
            message: data.data.message
          });
          this.$emit("numbers-updated");
          this.handleCancel(true);
        })
        .catch(err => {
          console.log(err);
          this.isSubmitting = false;
          this.$message({
            type: "error",
            message: err.response.data.message
          });
        });
    },
    mapNumbers() {
      this.updateNumbersMapping({
        task_id: this.task_id,
        phone_numbers: this.selectedNumbers
      });
    },
    unmapNumbers() {
      this.updateNumbersMapping({
        phone_numbers: this.selectedNumbers
      });
    },
    moveNumbers() {
      this.updateNumbersMapping({
        task_id: this.task_id,
        phone_numbers: this.selectedNumbers
      });
    },
    handleManageNumbersModal(value) {
      if (value) {
        this.phone_numbers = [];
        this.activeTab = "tab_map_numbers";
        this.setTaskIdFilter("-1");
        if (this.task) {
          this.task_id = this.task.task_id;
          this.task_name = this.task.task_name;
        }
        EventBus.$emit("phone-numbers-list-changed");
      }
      this.openManageNumberModal = value;
    },
    handleCancel(refreshList = false) {
      this.selectedTask = "";
      this.selectedNumbers = [];
      this.phone_numbers = [];

      this.$emit("handleCancel", refreshList);
    },
    handleTabChanged(val) {
      this.phone_numbers = [];
      if (val.name === "tab_map_numbers") {
        this.setTaskIdFilter("-1");
      } else if (val.name === "tab_move_numbers_canvas") {
        this.setTaskIdFilter(this.task_id);
        this.setExcludeTaskIdFilter("1");
      } else {
        this.setTaskIdFilter(this.task_id);
        this.setExcludeTaskIdFilter("0");
      }
      EventBus.$emit("phone-numbers-list-changed");
    },
    handlePhoneNumbersListChanged() {
      this.phone_numbers = this.phoneNumbers;
    }
  },
  computed: {
    ...mapState("tasks", {
      phoneNumbers: state => state.phoneNumbers,
      tasks: state => state.tasks,
      loading: state => state.loading
    }),
    sortByOptions() {
      return this.createSortOptions();
    },
    checkSelectedNumbers() {
      return _.isEmpty(this.selectedNumbers);
    }
  },
  mounted() {},
  watch: {
    openModal: {
      immediate: true,
      handler: "handleManageNumbersModal"
    },
    phoneNumbers: {
      immediate: true,
      handler: "handlePhoneNumbersListChanged"
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$--color-node-hover-default: var(--theme-hover-color) !default;
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/pagination-toolbar.scss";
</style>
