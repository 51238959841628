<template>
  <!-- propagate input, for your component to work with v-model -->
  <expression-builder-renderless
    ref="expression-builder-renderless"
    :key="key"
    :fields="fields"
    :fieldTypes="fieldTypes"
    :operators="operators"
    :value="value"
  >
    <!-- the root expression node group, initialized from the root of the Core.ExpressionBuilder instance -->
    <expression-node-group
      ref="query-condition-node-group"
      :node="value.root"
      @previewDataStore="$emit('previewDataStore')"
      :default-col="defaultCol"
      :showAddGroup="showAddGroup"
    ></expression-node-group>
  </expression-builder-renderless>
</template>

<script>
import {
  Core,
  Components,
  Conditions
} from "vue-renderless-expression-builder";
import ExpressionNodeGroup from "./QueryConditionNodeGroup";

//uncomment below if you want to use default field types
const {
  // defaultFieldTypes,
  returnDefaultFieldTypes,
  returnDefaultOperators
} = Conditions.Defaults;

const { ExpressionBuilderRenderless } = Components;

export default {
  name: "ExpressionBuilder",
  props: {
    value: {
      type: Core.ExpressionBuilder,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    fieldTypes: {
      type: Array,
      required: false,
      default: returnDefaultFieldTypes()
    },
    defaultCol: {
      type: String,
      required: false,
      default: "_id"
    },
    operators: {
      type: Array,
      required: false,
      default: returnDefaultOperators()
    },
    showAddGroup: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    // the renderless builder component
    ExpressionBuilderRenderless,

    // your node group component, created from ExpressionNodeGroupRenderless
    ExpressionNodeGroup
  },
  data() {
    return {
      // field types - same as the default value, if you omit this prop
      // fieldTypes: returnDefaultFieldTypes(),

      // operators - same as the default value, if you omit this prop
      // operators: returnDefaultOperators(),

      key: 0
    };
  },
  method: {
    test() {
      console.log("preview event triggered");
    }
  },
  watch: {
    fields: function() {
      this.key = this.key + 1;
    }
  }
};
</script>

<style scoped></style>
