<template>
  <div class="messagingButtonsTable">
    <el-table-draggable
      @drag="onDragStart"
      @drop="onDragEnd"
      handle=".action-icon"
      class="draggable"
    >
      <el-table
        class="variable-rules"
        fit
        :data="keywordMatches"
        style="width: 100%;"
        :row-class-name="generateUniqueRowClass"
      >
        <el-table-column
          prop="button_type"
          :label="__('Type')"
          min-width="120px"
        >
          <template v-slot="scope">
            <img
              alt="icon"
              class="action-icon"
              :src="require('@/assets/icons/icon-drag-handle.svg')"
            />
            <el-row type="flex">
              <el-col :class="classes(scope.row, 'button_type')">
                <el-form-item>
                  <el-select
                    v-model="scope.row.button_type"
                    @change="handleButtonChange($event)(scope.row)"
                    :placeholder="__('Select type')"
                    default-first-option
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in button_types"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column prop="keyword" :label="__('Label')" min-width="100px">
          <template v-slot="scope">
            <el-row type="flex">
              <el-col :class="classes(scope.row, 'keyword')">
                <el-form-item :error="scope.row.keyword_error">
                  <el-input
                    v-model="scope.row.keyword"
                    :placeholder="__('Label')"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column
          prop="button_icon"
          :label="__('Icon (Optional)')"
          min-width="100px"
        >
          <template v-slot="scope">
            <el-row type="flex">
              <el-col :class="classes(scope.row, 'button_icon')">
                <el-form-item :error="scope.row.button_icon_error">
                  <!--                  <el-input v-model="scope.row.button_icon" placeholder="Icon"></el-input>-->
                  <input-variable-popper
                    v-model="scope.row.button_icon"
                    :force-reinitialize="true"
                    :is-text-area="false"
                    :placeholder="__('Icon')"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column
          prop="button_url"
          label="URL"
          min-width="100px"
          v-if="hasLinkButtons"
        >
          <template v-slot="scope">
            <el-row type="flex">
              <el-col :class="classes(scope.row, 'button_url')">
                <el-form-item v-if="showUrlField(scope.row.button_type)">
                  <input-variable-popper
                    v-model="scope.row.button_url"
                    :force-reinitialize="true"
                    :is-text-area="false"
                    :placeholder="__('URL')"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column
          prop="node_name"
          :label="__('Goto Node')"
          v-if="hasNodeDependentButtons"
        >
          <template v-slot="scope">
            <el-row
              :gutter="20"
              class="goto-node-configure"
              type="flex"
              justify="start"
            >
              <el-col :class="classes(scope.row, 'node_name')">
                <el-form-item v-if="showGotoNodeField(scope.row.button_type)">
                  <create-or-select
                    :items="extractGotoNodeOptionsFor(scope.row.button_type)"
                    :current_select="currentSelection(scope.row)"
                    :fail-to-create-message="scope.row.error"
                    :new-item-message="__('new node')"
                    :placeholder="__('Node Name')"
                    @change="handleChange($event)(scope.row)"
                    :is-grouped="true"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column class-name="row-message" width="100px" prop="msg" />
        <el-table-column class-name="cell-item-pointer" width="40px">
          <template v-slot="scope">
            <span @click="removeKeywordMatch(scope)">
              <i class="el-icon-circle-close"></i>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-table-draggable>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
import CreateOrSelect from "./CreateOrSelect";
import ElTableDraggable from "element-ui-el-table-draggable";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import {
  REPLY_BUTTON,
  LINK_BUTTON,
  POSTBACK_BUTTON,
  PROACTIVE_POSTBACK_BUTTON,
  BUTTON_TYPES
} from "@/constants/messagingNodeButtons";

export default {
  components: {
    CreateOrSelect,
    ElTableDraggable,
    InputVariablePopper
  },
  props: {
    value: {
      required: true,
      type: Array
    },
    gotoOptions: {
      required: true,
      type: Array
    },
    gotoOptionsForPostback: {
      required: true,
      type: Array
    },

    /**
     * The type of the node (i.e. proactive messaging, conversation messaging, etc...) that implements this component
     */
    nodeType: {
      required: false,
      type: String
    }
  },
  data() {
    const keyword_match = {
      keyword: "",
      node_name: "",
      node_id: -1,
      keyword_type: "Button",
      button_type: "",
      button_icon: "",
      button_url: ""
    };
    return {
      keyword_match,
      $keyword_matches: [{ ...keyword_match }],
      $gotoOptions: [],
      button_types: BUTTON_TYPES.filter(button =>
        button.available(this.nodeType)
      )
    };
  },
  computed: {
    ...mapState("canvas", {
      isNodeSubmit: state => state.isNodeSubmit
    }),
    ...mapGetters("canvas", {
      canvasOfNode: "canvasOfNode"
    }),

    classes() {
      return (row, key) => {
        // ignore ui validation cues for button icon field
        if (key !== "button_icon") {
          if (!row.button_type && !row.keyword && !row.button_url) {
            return "empty-row empty-val no-red-highlight";
          } else if (!row[key]) {
            return "empty-val";
          }
        }
        return "non-empty-val no-red-highlight";
      };
    },

    extractGotoNodeOptionsFor() {
      return button_type => {
        let options = _.map(
          this.fetchGotoOptionsSource(button_type),
          child => ({
            label: child.node_name,
            value: child.node_id,
            group_name: this.canvasOfNode(child.node_id) || ""
          })
        );
        // alphabetized first by canvas and then by node
        return _.sortBy(options, ["group_name", "label"], ["desc"]);
      };
      // return this.$data.$gotoOptions.concat(options);
    },

    keywordMatches() {
      return this.$data.$keyword_matches;
    },

    /**
     * checks if any rich media buttons in the list are link button type
     * @returns boolean
     */
    hasLinkButtons() {
      return _.some(
        this.$data.$keyword_matches,
        btn => btn.button_type === LINK_BUTTON
      );
    },

    /**
     * checks if any rich media buttons in the list are node dependent
     * @returns boolean
     */
    hasNodeDependentButtons() {
      return _.some(this.$data.$keyword_matches, btn =>
        _.includes(
          [REPLY_BUTTON, POSTBACK_BUTTON, PROACTIVE_POSTBACK_BUTTON],
          btn.button_type
        )
      );
    },

    currentSelection() {
      return row => {
        const { node_id, node_name } = row;
        return node_id === -1 ? node_name : node_id;
      };
    }
  },
  methods: {
    generateUniqueRowClass({ rowIndex }) {
      return "row row-" + rowIndex;
    },

    /**
     * decides whether URL input field should be shown
     * @returns boolean
     * @param button_type
     */
    showUrlField(button_type) {
      return button_type === LINK_BUTTON;
    },

    /**
     * decides whether the goto node dropdown should be shown
     * @returns boolean
     * @param button_type
     */
    showGotoNodeField(button_type) {
      const allowedButtons = [
        REPLY_BUTTON,
        POSTBACK_BUTTON,
        PROACTIVE_POSTBACK_BUTTON
      ];
      return allowedButtons.includes(button_type);
    },

    addNewKeywordMatch() {
      this.$data.$keyword_matches.push({ ...this.keyword_match });
    },

    removeKeywordMatch({ row, $index }) {
      this.$data.$keyword_matches.splice($index, 1);
      this.$emit("removed-msg-btn", _.get(row, "button_id"));
    },

    fetchGotoOptionsSource(button_type) {
      return [POSTBACK_BUTTON, PROACTIVE_POSTBACK_BUTTON].includes(button_type)
        ? this.gotoOptionsForPostback
        : this.gotoOptions;
    },

    initializeKeyWordMatches(keywordMatches) {
      if (!this.isNodeSubmit) {
        if (!_.isEmpty(keywordMatches) && keywordMatches.length) {
          this.$data.$keyword_matches = _.cloneDeep(keywordMatches);
        }
        if (
          !_.some(
            this.$data.$keyword_matches,
            keywordMatch => !keywordMatch.keyword.length
          )
        ) {
          this.addNewKeywordMatch();
          // this.$emit("input", _.cloneDeep(this.$data.$keyword_matches));
        }
      }
    },

    onDragStart() {
      let selector = ".matchKeywordsTable";
      const tables = document.querySelectorAll(selector);

      tables[0].classList.add("grabbing");
    },

    onDragEnd() {
      let selector = ".matchKeywordsTable";
      const tables = document.querySelectorAll(selector);

      if (tables[0].classList.contains("grabbing")) {
        tables[0].classList.remove("grabbing");
      }
    },

    handleChange(option) {
      return row => {
        this.$set(row, "node_id", option.value);
        this.$set(row, "node_name", option.label);
        this.$set(row, "msg", option.msg);
      };
    },

    handleButtonChange() {
      return row => {
        this.$set(row, "node_id", -1);
        this.$set(row, "node_name", "");
        this.$set(row, "msg", "");
      };
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: "initializeKeyWordMatches"
    },
    keywordMatches: {
      handler: function(keywordMatches) {
        this.$emit("input", _.cloneDeep(keywordMatches));
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";

.messagingButtonsTable ::v-deep .el-table--medium td {
  padding: 0;
}

.messagingButtonsTable ::v-deep .el-table--medium th {
  padding: 0;
}

.el-table {
  .el-form-item {
    padding-bottom: 15px;
    padding-top: 15px;
  }
}

.messagingButtonsTable ::v-deep .el-form-item__error {
  padding-top: 1px;
  font-size: 10px;
}

.messagingButtonsTable {
  ::v-deep .no-red-highlight .el-input__inner,
  ::v-deep .no-red-highlight .el-textarea__inner,
  ::v-deep .empty-row .el-input__inner,
  ::v-deep .empty-row .el-textarea__inner {
    border-color: $--border-color-base !important;

    &:focus {
      border-color: $--color-text-regular !important;
    }
  }
}

.messagingButtonsTable ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.variable-rules ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.messagingButtonsTable ::v-deep .el-table::before {
  background-color: white !important;
}

.messagingButtonsTable ::v-deep .el-table__body-wrapper {
  margin-top: 10px;
}

.draggable .action-icon {
  cursor: grab;
}

.grabbing {
  cursor: grabbing !important;
}

.grabbing * {
  cursor: grabbing !important;
}
</style>
