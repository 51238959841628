var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"updated-content"},[(_vm.showHistoryContentAlert)?[_c('el-alert',{attrs:{"closable":false,"type":_vm.eventType}},[_c('div',{staticClass:"content-details",on:{"click":function($event){return _vm.directToNode(_vm.history.content_id)}}},[_c('div',{staticClass:"content-icon"},[(_vm.history.content_type == 'Node')?_c('img',{staticClass:"content-cell-icon",attrs:{"src":_vm.getIconForNode(_vm.displayContent.node_type),"draggable":"false"}}):_c('img',{staticClass:"content-cell-icon",attrs:{"src":require('@/assets/icons/icon-' +
                _vm.displayContent.task_type +
                '.svg'),"draggable":"false"}})]),_c('div',{staticClass:"content-body"},[_c('div',{staticClass:"content-type"},[_vm._v(" "+_vm._s(_vm.history.content_type == "Node" ? _vm.displayContent.node_type : _vm.displayContent.task_type)+" ")]),_c('div',{staticClass:"content-name"},[_vm._v(" "+_vm._s(_vm.history.content_type == "Node" ? _vm.displayContent.node_name : _vm.displayContent.task_name)+" ")])]),_c('div',{staticClass:"show-details-btn"},[_c('el-button',{attrs:{"icon":"el-icon-d-arrow-right","circle":"","type":_vm.eventType,"size":"mini"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.showDetails = true}}})],1)])]),_c('el-dialog',{class:{ 'show-differ-json': !_vm.showFullJsonData },attrs:{"visible":_vm.showDetails,"append-to-body":true},on:{"update:visible":function($event){_vm.showDetails=$event}}},[_c('vue-json-compare',{staticClass:"edit-history-compare",attrs:{"oldData":_vm.history.original_content,"newData":_vm.history.updated_content}}),_c('el-switch',{attrs:{"active-text":_vm.__('Show all data')},model:{value:(_vm.showFullJsonData),callback:function ($$v) {_vm.showFullJsonData=$$v},expression:"showFullJsonData"}})],1)]:[(
        Object.keys(_vm.history.original_content).length &&
          Object.keys(_vm.history.updated_content).length
      )?_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},[_vm._l((_vm.history.original_content),function(field,index){return _c('el-tag',{key:'original_content_' + index,attrs:{"type":"danger","size":"small"}},[_vm._v(_vm._s(field))])}),_vm._m(0),_vm._l((_vm.history.updated_content),function(field,index){return _c('el-tag',{key:'updated_content_' + index,staticStyle:{"margin-left":"0"},attrs:{"type":"success","size":"small"}},[_vm._v(_vm._s(field))])})],2):_vm._l((_vm.displayContent),function(field,index){return _c('el-tag',{key:'display_content_' + index,attrs:{"type":_vm.eventType,"size":"small"}},[_vm._v(" "+_vm._s(field)+" ")])})]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"margin-left":"0","margin-right":"10px"}},[_c('i',{staticClass:"el-icon-right"})])
}]

export { render, staticRenderFns }