<template>
  <div style="display: flex; flex:1">
    <div style="flex: 3 0 0">
      <el-scrollbar
        :native="false"
        style="border: 1px solid #a0a8b5;  border-radius: 6px;   background-color: white;"
      >
        <div class="description">
          <input-output-variables-display
            :template-description="template.template_description"
            :input-variable="template.input_variables"
            :output-variable="template.output_variables"
            ref="inputOutputVariablesDisplay"
          />
        </div>
      </el-scrollbar>
    </div>
    <div
      style="display: flex; flex: 1 0 0; justify-content: space-between; flex-direction: column; margin-left: 30px; padding: 20px 0; min-width: 150px"
    >
      <div style="display: flex">
        <img :src="require(`@/assets/icons/icon-publisher.svg`)" /><span
          class="details"
          >{{ template.publisher }}</span
        >
      </div>
      <div style="display: flex">
        <img :src="require(`@/assets/icons/icon-import.svg`)" />
        <span class="details">{{ abbreviate(template.import_count) }}</span>
      </div>
      <div v-if="template.tags" style="display: flex">
        <img :src="require(`@/assets/icons/icon-tags.svg`)" />
        <span class="details">{{ tags }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import InputOutputVariablesDisplay from "@/views/build/tasks/create-task/components/InputOutputVariablesDisplay.vue";
export default {
  name: "TemplateDetails",
  components: { InputOutputVariablesDisplay },
  props: {
    template: {
      default() {
        return {
          template_name: "A kind of blank page"
        };
      }
    }
  },
  methods: {
    abbreviate(count) {
      let abbreviate = require("number-abbreviate");
      const cnt = abbreviate(count);
      return /\D/.test(cnt) ? cnt + "+" : cnt;
    }
  },
  computed: {
    tags() {
      if (!_.isEmpty(this.template.tags)) {
        let tags = this.template.tags.split(",");
        return tags.join(", ");
      } else {
        return "";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.description {
  height: 120px;
  max-height: 120px;
  padding: 20px;
  word-break: break-word;
  text-align: justify;
}

.details {
  padding-left: 10px;
  word-break: break-word;
}
</style>
