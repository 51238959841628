<template>
  <span>
    <span class="readmore-formatted-string" v-html="formattedString" />
    <span class="readmore-link" v-show="hasExceededTextLength(text)">
      <a
        v-show="!isReadMore"
        class="readmore-expand"
        :href="link"
        @click="triggerReadMore($event, true)"
        >...{{ moreStr }}</a
      >
      <a
        v-show="isReadMore"
        class="readmore-collapse"
        :href="link"
        @click="triggerReadMore($event, false)"
        >{{ lessStr }}</a
      >
    </span>
  </span>
</template>

<script>
export default {
  name: "ReadMore",
  props: {
    moreStr: {
      type: String,
      default: "read more"
    },
    lessStr: {
      type: String,
      default: "read less"
    },
    text: {
      type: String,
      required: true
    },
    link: {
      type: String,
      default: "#"
    },
    maxChars: {
      type: Number,
      default: 200
    }
  },
  data() {
    return {
      isReadMore: false
    };
  },
  computed: {
    /**
     * truncated string for display if string length is over max characters, need to split on newlines if present
     * @return {String}
     */
    formattedString() {
      let totalFormattedString = "";

      let splitStringArr = this.text.split(/\\n/g);
      for (var i = 0; i < splitStringArr.length; i++) {
        let item = splitStringArr[i];

        if (
          !this.isReadMore &&
          this.hasExceededTextLength(totalFormattedString + item)
        ) {
          totalFormattedString = (totalFormattedString + item).substring(
            0,
            this.maxChars
          );
          return totalFormattedString;
        }
        totalFormattedString += (i > 0 ? "\n" : "") + item;
      }
      return totalFormattedString;
    },
    /**
     * check if text length has exceeded max characters
     * @return {function(*): boolean}
     */
    hasExceededTextLength() {
      return text => text.length > this.maxChars;
    }
  },
  methods: {
    /**
     * show more text when requested
     * @param e
     * @param b
     */
    triggerReadMore(e, b) {
      if (this.link === "#") {
        e.preventDefault();
      }
      if (this.lessStr !== null || this.lessStr !== "") {
        this.isReadMore = b;
      }
    }
  }
};
</script>
<style scoped>
.readmore-formatted-string {
  white-space: pre-line;
}
.readmore-link {
  padding-left: 5px;
}
</style>
