<template>
  <div>
    <DynamicCollapse
      :value="value"
      @input="$emit('input', $event)"
      :immutable="false"
      :valueTemplate="valueTemplate()"
      addValueLabel="Add Another Card"
      :maxValue="5"
    >
      <template slot="title" slot-scope="scope">
        {{ __("Card") }} {{ scope.index + 1 }}
      </template>

      <template slot="body" slot-scope="scope">
        <el-form-item :label="__('Card Type')" prop="card_type">
          <el-select
            v-model="scope.row.card_type"
            :placeholder="__('Card Type')"
          >
            <el-option
              v-for="item in cardTypeSelectable"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <component
          :is="getConfigurator(scope.row.card_type)"
          :key="scope.row.card_type"
          v-model="scope.row"
        ></component>
        <SharedCardConfigurator
          v-model="scope.row"
          :gotoOptions="gotoOptions"
          :cardNum="scope.index + 1"
          :prop="`${prop}.${scope.index}`"
        />
      </template>

      <template slot="deleteButton" slot-scope="scope">
        {{ __("Delete Card :number", { number: scope.index + 1 }) }}
      </template>
    </DynamicCollapse>
  </div>
</template>

<script>
import DynamicCollapse from "@/components/Nimbus/DynamicCollapse";
import TextCardConfigurator from "./component/textCardConfig";
import ImageCardConfigurator from "./component/imageCardConfig";
import VideoCardConfigurator from "./component/videoCardConfig";
import SharedCardConfigurator from "./component/sharedCardConfig";
import { CardTypes } from "./constants";

export default {
  name: "CardAndCarousel",
  components: {
    DynamicCollapse,
    SharedCardConfigurator,
    TextCardConfigurator,
    ImageCardConfigurator,
    VideoCardConfigurator
  },

  props: {
    value: {
      required: false,
      type: Array,
      default: () => []
    },
    gotoOptions: {
      required: false,
      type: Array,
      default: () => []
    },

    /**
     * @see https://element.eleme.io/#/en-US/component/form#form-item-attributes Form-Item Attributes/prop
     */
    prop: {
      required: false,
      type: String,
      default: () => ""
    }
  },

  computed: {
    /**
     * List of card type that is selectable
     */
    cardTypeSelectable() {
      return (
        Object.keys(CardTypes)
          // We con't want to include the default enum
          .filter(cardType => cardType !== "default")
          .map(cardType => {
            return {
              value: CardTypes[cardType],
              label: this.translateCardType(CardTypes[cardType])
            };
          })
      );
    }
  },

  methods: {
    valueTemplate() {
      return {
        card_type: CardTypes.default,
        card_title: "",
        card_content: "",
        card_layout: "Default",
        card_media_id: null, // refer to attachment id
        card_media_url: "",
        card_media_type: "Upload",
        file_url: "", // refer to file upload url
        file_path: "", // refer to file upload path
        file_type: "",
        file_name: "", // refer to file upload name
        card_index: 0, // used as display order
        messaging_node_card_button: [] // Refer to https://five9inc.atlassian.net/browse/STUD-9842 for button structure
      };
    },

    /**
     * Get the card configurator given card type
     * @param {String} cardType
     */
    getConfigurator(cardType) {
      switch (cardType) {
        case CardTypes.Image:
          return ImageCardConfigurator;
        case CardTypes.Video:
          return VideoCardConfigurator;
        case CardTypes.Text:
        default:
          return TextCardConfigurator;
      }
    },

    /**
     * Translate card type name
     * @param {String} cardType
     */
    translateCardType(cardType) {
      switch (cardType) {
        case CardTypes.Image:
          // eslint-disable-next-line
          return __("Image");

        case CardTypes.Video:
          // eslint-disable-next-line
          return __("Video");

        case CardTypes.Text:
        default:
          // eslint-disable-next-line
          return __("Text");
      }
    }
  }
};
</script>

<style></style>
