<script>
import BaseMessagingNode from "./BaseMessagingNode";
import _ from "lodash";
import { NODE_TYPES } from "@/constants/nodes";
import {
  MESSAGE_FORMAT_CARD,
  MESSAGE_FORMAT_RICH_TEXT
} from "@/constants/messagingNodeFormat";

export default {
  name: "ReplyMessageNode",
  mixins: [BaseMessagingNode],
  created() {
    this.$set(this.nodeToBind, "node_type", NODE_TYPES.REPLY_SMS.NODE_TYPE);

    this.$set(this.nodeToBind.messaging_node.data, "sms_node_type", "reply");

    if (_.isEmpty(this.nodeToBind.messaging_node.data.phone_number)) {
      this.$set(this.nodeToBind.messaging_node.data, "phone_number", "");
    }

    this.$set(
      this.nodeToBind.messaging_node.data,
      "available_message_formats",
      [MESSAGE_FORMAT_RICH_TEXT, MESSAGE_FORMAT_CARD]
    );

    // If message format is empty, means that this should be a new node and it should be set as default.
    if (_.isEmpty(this.nodeToBind.messaging_node.data.message_format)) {
      this.$set(
        this.nodeToBind.messaging_node.data,
        "message_format",
        MESSAGE_FORMAT_RICH_TEXT
      );
    }

    this.isPostSendingActionChecked();
  }
};
</script>
