<template>
  <el-dialog
    @keydown.esc.exact.native="handleEscapeKeyDownEvent"
    v-if="showExpressionBuilder"
    :visible.sync="showExpressionBuilderClone"
    :modal="false"
    :close-on-click-modal="false"
    :show-close="false"
    append-to-body
    custom-class="expressionBuilderModal"
    fullscreen
    destroy-on-close
  >
    <expression-builder
      :value="getValue"
      :reset-value="getResetValue"
      :new="expressionsInitiallyEmpty"
      :source="source"
      @input="$emit('input', $event)"
      @cancel="$emit('cancel')"
      :use-secure-variables="useSecureVariables"
    />
  </el-dialog>
</template>

<script>
import ExpressionBuilder from "./ExpressionBuilder";
import _ from "lodash";
import { mapGetters } from "vuex";
import { SYSTEM_VARIABLE } from "@/constants/systemVariables";

export default {
  components: { ExpressionBuilder },
  props: {
    value: {
      required: true,
      type: String
    },
    source: {
      required: false,
      type: String,
      default: "all"
    },
    activeVariable: {
      required: false,
      type: Number,
      default: -1
    },
    showExpressionBuilder: {
      required: true,
      type: Boolean
    },
    useSecureVariables: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showExpressionBuilderClone: false
    };
  },

  computed: {
    ...mapGetters("variables", {
      getSystemVariableIdFromName: "getSystemVariableIdFromName"
    }),

    expressionsInitiallyEmpty() {
      return _.isEmpty(JSON.parse(this.value));
    },

    getResetValue() {
      return JSON.stringify(this.generateInitialExpressions());
    },

    getValue() {
      if (this.expressionsInitiallyEmpty) {
        return this.getResetValue;
      }
      return this.parseExpression(this.value);
    }
  },

  methods: {
    handleEscapeKeyDownEvent(ev) {
      ev.preventDefault();
      ev.stopPropagation();
      // dont close the expression builder if the user clicks esc as it can quickly destroy all the expressions
      // they have built but not saved.
      // this.$emit("close");
    },

    parseExpression(value) {
      let valueJson = JSON.parse(value);
      let expressions = _.get(valueJson, "expressions");
      switch (this.source) {
        case "keyword_finder":
          if (!_.isEmpty(expressions)) {
            let expr1 = _.get(expressions, "Expr1");
            if (!_.isEmpty(expr1) && !_.has(expr1, "fixed")) {
              expr1["fixed"] = 1;
              _.set(expressions, "Expr1", expr1);
            }
            _.set(valueJson, "expressions", expressions);
          }
          break;
        default:
          break;
      }

      return JSON.stringify(valueJson);
    },

    generateInitialExpressions() {
      switch (this.source) {
        case "conversation":
          return {
            expressions: {
              Expr1: {
                function: "text_input",
                parameter1: `{{${this.getSystemVariableIdFromName(
                  SYSTEM_VARIABLE.MESSAGE_TEXT
                )}}}`
              }
            },
            expression_to_evaluate: "Expr1"
          };

        case "keyword_finder":
          return {
            expressions: {
              Expr1: {
                function: "text_input",
                parameter1: `{{${this.activeVariable}}}`,
                fixed: 1
              },
              Expr2: {
                function: "regex",
                parameter1: "{{Expr1}}"
              }
            },
            expression_to_evaluate: "Expr2"
          };

        default:
          return {
            expressions: {
              Expr1: {
                function: "text_input",
                parameter1: ""
              }
            },
            expression_to_evaluate: "Expr1"
          };
      }
    }
  },

  watch: {
    showExpressionBuilder: {
      immediate: true,
      handler(val) {
        this.showExpressionBuilderClone = val;
      }
    }
  }
};
</script>
