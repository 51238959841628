<template>
  <div>
    <SSMLElements
      slot="reference"
      @insert_ssml="handleInsertSSML"
      @toggle_content="handleToggleContent"
      @undo="handleUndo"
      @redo="handleRedo"
      v-if="isContentEditable && showSSMLEditor"
    ></SSMLElements>
    <component
      :is="component"
      :members="members"
      :suffix="suffix"
      :ats="ats"
      :popper-class="popperClass"
      :scroll-ref="scrollRef"
    >
      <el-popover
        :placement="popperPosition"
        width="200"
        :trigger="isContentEditable ? 'click' : 'focus'"
        :popper-class="popperClass"
        :offset="popperOffset"
      >
        <div className="popper-contents">
          <div>
            {{ __("To insert a variable")
            }}{{ includePrompts ? __("/prompt") : "" }}, {{ __("type") }}
            <code>{{ templateString }}</code>
            {{ __("and then select a variable")
            }}{{ includePrompts ? __("/prompt") : "" }}
          </div>
        </div>
        <template v-if="isContentEditable">
          <studio-content-editable
            :placeholder="placeholder"
            tag="div"
            slot="reference"
            @input="updateParent"
            :contenteditable="ssmlMode"
            :class="['editableContent', { 'error-border': hasErrors }]"
            v-model="content.data"
            :noHTML="false"
            noNL
            ref="promptInput"
            @mouseup="saveSelection"
            @keyup="saveSelection"
            @keydown="handleBackSpaceInFireFox"
          />
        </template>
        <template v-else-if="isJsonInput">
          <v-json-editor
            v-model="jsonContent"
            :options="editorOptions"
            @error="editorError = true"
            :plus="false"
            slot="reference"
            style="height: 100%"
            @input="updateParent"
          ></v-json-editor>
        </template>
        <template v-else-if="isRichTextArea">
          <vue-editor
            v-model="content"
            slot="reference"
            :editorToolbar="customToolbar"
            @input="updateParent"
          >
          </vue-editor>
        </template>
        <template v-else-if="isMarkdownEditor">
          <markdown-editor
            v-model="content"
            slot="reference"
            @input="updateParent"
          ></markdown-editor>
        </template>
        <template v-else-if="isAutoCompleteInput">
          <el-autocomplete
            class="w-full"
            slot="reference"
            v-model="content"
            :fetch-suggestions="querySearch"
            :placeholder="placeholder"
            @input="updateParent"
          ></el-autocomplete>
        </template>
        <template v-else-if="isTextArea">
          <el-input
            type="textarea"
            :rows="rows"
            resize="vertical"
            :placeholder="placeholder"
            v-model="content"
            :disabled="disabled"
            slot="reference"
            :readonly="readonlyText"
            @input="updateParent"
            ><i
              v-if="iconPrefix"
              slot="prefix"
              :class="`el-input__icon ` + iconPrefix"
              @click="$emit('prefix-clicked')"
            ></i>
            <i
              v-if="iconSuffix"
              slot="suffix"
              :class="`el-input__icon ` + iconSuffix"
              @click="$emit('suffix-clicked')"
            ></i>
          </el-input>
        </template>
        <template v-else>
          <el-input
            v-model="content"
            slot="reference"
            :disabled="disabled"
            :placeholder="placeholder"
            :readonly="readonlyText"
            @input="updateParent"
          >
            <i
              v-if="iconPrefix"
              slot="prefix"
              :class="`el-input__icon ` + iconPrefix"
              @click="$emit('prefix-clicked')"
            ></i>
            <i
              v-if="iconSuffix"
              slot="suffix"
              :class="`el-input__icon ` + iconSuffix"
              @click="$emit('suffix-clicked')"
            ></i>
            <template v-if="includePrepend" slot="prepend">
              {{ prependString }}
            </template>
          </el-input>
        </template>
      </el-popover>
    </component>
  </div>
</template>

<script>
import AtTa from "@/components/AtTextarea";
import AtRta from "@/components/AtRichTextArea";
import AtIn from "@/components/AtInput";
import At from "@/components/At";
import _ from "lodash";
import he from "he";
import SSMLElements from "@/components/SSMLElements";
import VJsonEditor from "v-jsoneditor";
import StudioContentEditable from "@/views/build/callflow/components/node-type-forms/components/Studio7ContentEditable";
import VariablePopperMixin from "@/views/build/callflow/components/node-type-forms/components/VariablePopperMixin";
import { VueEditor } from "vue2-editor";
import MarkdownEditor from "@/components/MarkdownEditor.vue";

he.encode.options.useNamedReferences = true;
he.encode.options.decimal = true;

export default {
  name: "InputVariablePopper",
  mixins: [VariablePopperMixin],
  props: {
    value: {
      required: true,
      type: String
    },
    forceReinitialize: {
      required: false,
      type: Boolean,
      default: false
    },
    popperClass: {
      required: false,
      type: String,
      default: ""
    },
    popperPosition: {
      required: false,
      type: String,
      default: "top-end"
    },
    popperOffset: {
      required: false,
      type: Number,
      default: 0
    },
    rows: {
      required: false,
      type: Number,
      default: 6
    },
    isTextArea: {
      required: false,
      type: Boolean,
      default: true
    },
    isRichTextArea: {
      required: false,
      type: Boolean,
      default: false
    },
    isMarkdownEditor: {
      required: false,
      type: Boolean,
      default: false
    },
    isAutoCompleteInput: {
      required: false,
      type: Boolean,
      default: false
    },
    autocompleteList: {
      required: false,
      type: Array
    },
    isContentEditable: {
      required: false,
      type: Boolean,
      default: false
    },
    isJsonInput: {
      required: false,
      type: Boolean,
      default: false
    },
    prependItems: {
      required: false,
      type: Array,
      default: () => []
    },
    placeholder: {
      required: false,
      type: String,
      default: ""
    },
    at: {
      required: false,
      type: String,
      default: "{{"
    },
    suffix: {
      required: false,
      type: String,
      default: "}}"
    },
    includeSecureVariables: {
      required: false,
      type: Boolean,
      default: true
    },
    includePrompts: {
      required: false,
      type: Boolean,
      default: false
    },
    includePayments: {
      required: false,
      type: Boolean,
      default: false
    },
    includeAudioVariables: {
      required: false,
      type: Boolean,
      default: false
    },
    includeCAVVariables: {
      required: false,
      type: Boolean,
      default: true
    },
    onlyAudioVariables: {
      required: false,
      type: Boolean,
      default: false
    },
    includePrepend: {
      required: false,
      type: Boolean,
      default: false
    },
    prependString: {
      required: false,
      type: String,
      default: ""
    },
    ats: {
      type: Array,
      default: () => ["{{"]
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showSSMLEditor: {
      type: Boolean,
      default: true
    },
    scrollRef: {
      type: String,
      default: ""
    },
    readonlyText: {
      //Set the readonly property of input element
      type: Boolean,
      required: false,
      default: false
    },
    iconSuffix: {
      type: String,
      required: false,
      default: ""
    },
    iconPrefix: {
      type: String,
      required: false,
      default: ""
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false
    },
    customElementsName: {
      type: String,
      required: false,
      default: ""
    },
    onlyEntityElements: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    MarkdownEditor,
    StudioContentEditable,
    AtTa,
    AtRta,
    AtIn,
    At,
    SSMLElements,
    VJsonEditor,
    VueEditor
  },
  data() {
    return {
      content: null,
      prevContent: null,
      replacedEncodedStrings: {},
      selectionRange: null,
      initialized: false,
      contentWithoutSSML: "",
      contentWithSSML: "",
      ssmlMode: true,
      undoChangeList: [],
      redoChangeList: [],
      customToolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        // ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "image"],
        ["clean"] // remove formatting button
      ]
    };
  },
  computed: {
    templateString() {
      return "{{";
    },

    component() {
      if (this.isContentEditable) {
        return "at";
      }
      if (this.isTextArea) {
        return "at-ta";
      } else if (this.isRichTextArea) {
        return "at-rta";
      } else {
        return "at-in";
      }
    },
    members() {
      // return _.concat(this.prependItems, this.variableNames);
      if (this.onlyEntityElements) {
        return {
          entities: this.prependItems
        };
      }

      if (this.onlyAudioVariables) {
        return {
          audio: this.audioVariableNames
        };
      }

      let members = {
        expressions: this.prependItems,
        variables: this.variableNames,
        system: this.getSystemVariableNames
      };

      if (this.includeSecureVariables && !_.isEmpty(this.secureVariableNames)) {
        members["secure"] = this.secureVariableNames;
      }

      if (!_.isEmpty(this.xSipVariableNames)) {
        members["xSip"] = this.xSipVariableNames;
      }

      if (this.includeAudioVariables && !_.isEmpty(this.audioVariableNames)) {
        members["audio"] = this.audioVariableNames;
      }

      if (this.includePrompts && this.promptNames) {
        members["prompts"] = this.promptNames;
      }

      if (this.includePayments && this.paymentVariableNames) {
        members["payment"] = this.paymentVariableNames;
      }

      if (this.includeCAVVariables && this.cavVariablesNames) {
        members["cav"] = this.cavVariablesNames;
      }
      return members;
    },
    jsonContent: {
      get() {
        return JSON.parse(this.content);
      },
      set(val) {
        this.content = _.isObject(val) ? JSON.stringify(val) : val;
      }
    },
    editorOptions() {
      return {
        mainMenuBar: false,
        mode: "code"
      };
    },
    hasErrors() {
      return this.hasError;
    }
  },
  methods: {
    handleBackSpaceInFireFox(e) {
      const isFF = !!navigator.userAgent.match(/firefox/i);
      if (isFF && e.key === "Backspace") {
        let selection = window.getSelection();
        if (!selection.isCollapsed || !selection.rangeCount) {
          return;
        }

        let curRange = selection.getRangeAt(selection.rangeCount - 1);
        if (
          curRange.commonAncestorContainer.nodeType == 3 &&
          curRange.startOffset > 0
        ) {
          // we are in child selection. The characters of the text node is being deleted
          return;
        }

        let range = document.createRange();
        let contentEditable = document.querySelector(".editableContent");
        if (selection.anchorNode != contentEditable) {
          // selection is in character mode. expand it to the whole editable field
          range.selectNodeContents(contentEditable);
          range.setEndBefore(selection.anchorNode);
        } else if (selection.anchorOffset > 0) {
          range.setEnd(contentEditable, selection.anchorOffset);
        } else {
          // reached the beginning of editable field
          return;
        }
        range.setStart(contentEditable, range.endOffset - 1);

        let previousNode = range.cloneContents().lastChild;
        if (previousNode && previousNode.contentEditable == "false") {
          // this is some rich content, e.g. smile. We should help the user to delete it
          range.deleteContents();
          e.preventDefault();
        }
      }
    },

    handleUndo() {
      let currentSSML = this.content.data;
      let olderSSML = this.undoChangeList.pop();
      if (olderSSML) {
        this.content.data = olderSSML;
        this.updateParent(false);
        this.redoChangeList.push(currentSSML);
      }
    },

    handleRedo() {
      let currentSSML = this.content.data;
      let futureSSML = this.redoChangeList.pop();
      if (futureSSML) {
        this.content.data = futureSSML;
        this.updateParent(false);
        this.undoChangeList.push(currentSSML);
      }
    },

    updateParent(flushRedoList = true) {
      if (this.onlyEntityElements) {
        this.$emit("input", this.content);
        return;
      }
      let val = this.isContentEditable
        ? this.parseContentEditableContentForBackend(this.content.data)
        : this.parseVariablePopperContentForBackend(this.content);
      this.$emit("input", val);
      if (flushRedoList) {
        this.redoChangeList = [];
      }
    },

    handleInsertSSML(args) {
      // this.replacedEncodedStrings[args.openingTag] = he.encode(args.openingTag);
      // this.replacedEncodedStrings[args.closingTag] = he.encode(args.closingTag);

      const { start, end } = this.getSelectionStartEnd();
      let promptInput = this.$refs.promptInput.$el;
      if (args.command === "break") {
        this.undoChangeList.push(this.content.data);
        this.content.data =
          promptInput.innerHTML.substring(0, start) +
          args.openingTag +
          promptInput.innerHTML.substring(end) +
          "&nbsp;";
        this.content.data = this.content.data.replace(/<br>/g, "");
        this.updateParent(false);
      } else {
        if (start !== end) {
          this.undoChangeList.push(this.content.data);
          this.content.data =
            promptInput.innerHTML.substring(0, start) +
            args.openingTag +
            promptInput.innerHTML.substring(start, end) +
            args.closingTag +
            promptInput.innerHTML.substring(end) +
            "&nbsp;";
          this.updateParent(false);
        } else {
          this.$notify({
            title: "Error",
            message: __("Please select text to insert tag"),
            type: "error"
          });
        }
      }
      let re = new RegExp("<b.*?</b>", "g");
      this.contentWithoutSSML = this.content.data.replace(re, "");
      this.contentWithSSML = this.content.data;
    },

    saveSelection() {
      if (window.getSelection()) {
        this.selectionRange = window.getSelection().getRangeAt(0);
      }
    },

    handleToggleContent() {
      if (this.ssmlMode) {
        this.ssmlMode = false;
        this.content.data = this.contentWithoutSSML;
      } else {
        this.ssmlMode = true;
        this.content.data = this.contentWithSSML;
      }
    },

    getSelectionStartEnd() {
      let start = 0;
      let end = 0;
      if (this.selectionRange) {
        let range = this.selectionRange;
        let preCaretRange = range.cloneRange();
        let tmp = document.createElement("div");
        let tmp1 = document.createElement("div");
        preCaretRange.selectNodeContents(this.$refs.promptInput.$vnode.elm);
        preCaretRange.setEnd(range.startContainer, range.startOffset);
        tmp.appendChild(preCaretRange.cloneContents());
        if (navigator.userAgent.search("Firefox")) {
          tmp.innerHTML = tmp.innerHTML.replace(
            /<b contenteditable="false"><i><\/i><\/b>/g,
            ""
          );
        }
        start = tmp.innerHTML.length;
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        tmp1.appendChild(preCaretRange.cloneContents());
        if (navigator.userAgent.search("Firefox")) {
          tmp1.innerHTML = tmp1.innerHTML.replace(
            /<b contenteditable="false"><i><\/i><\/b>/g,
            ""
          );
        }
        end = tmp1.innerHTML.length;
      }
      return { start: start, end: end };
    },

    querySearch(queryString, cb) {
      let suggestions = this.autocompleteList;
      let results = queryString
        ? suggestions.filter(this.createFilter(queryString))
        : suggestions;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter(queryString) {
      return suggestion => {
        return (
          suggestion.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function(val) {
        if (!this.initialized || this.forceReinitialize) {
          if (this.isContentEditable) {
            if (this.prevContent === val) {
              return;
            }
            val = val.replace(/</g, "!!lt!!").replace(/>/g, "!!gt!!");
            val = this.parseContentEditableContentForFrontEnd(val);
          } else {
            val = this.parseVariablePopperContentForFrontend(val);
          }
          if (!this.isContentEditable) {
            this.content = val;
          } else {
            this.prevContent = val;
            this.content = {};
            this.$set(this.content, "data", val);
          }
        }
        if (val) {
          this.initialized = true;
        }
      }
    },
    content: {
      immediate: true,
      deep: true,
      handler: function(val) {
        if (this.isContentEditable && this.ssmlMode) {
          let re = new RegExp("<b.*?</b>", "g");
          this.contentWithSSML = val.data;
          this.contentWithoutSSML = val.data.replace(re, "");
          this.$emit("content-wo-ssml", this.contentWithoutSSML);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.el-input__icon {
  cursor: pointer;
}

.error-border {
  border: 1px solid red !important;
}

::v-deep .at_variables {
  color: lightseagreen;
  padding: 2px;
}

::v-deep .at_system {
  color: lightseagreen;
  padding: 2px;
}

::v-deep .at_secure {
  color: lightseagreen;
  padding: 2px;
}

::v-deep .at_xSip {
  color: lightseagreen;
  padding: 2px;
}

::v-deep .at_payment {
  color: lightseagreen;
  padding: 2px;
}

::v-deep .at_cav {
  color: #7b61ff;
  padding: 2px;
}

::v-deep .at_audio {
  color: seagreen;
  padding: 2px;
}

::v-deep .at_prompts {
  color: #ffffff;
  background: darkslategrey;
  padding: 2px;
  border-radius: 2px;
}

.popper-contents {
  font-size: 0.75rem;
}

::v-deep .editableContent {
  min-width: 660px;
  word-break: break-word;

  b {
    color: #e65855;
    font-size: small;
  }
}

::v-deep .el-input-group__append,
::v-deep .el-input-group__prepend {
  color: var(--theme-color) !important;
}

[contenteditable="true"]:empty:not(:focus):before {
  content: attr(placeholder);
  color: grey;
}
</style>
