import { render, staticRenderFns } from "./CreateOrSelect.vue?vue&type=template&id=756db533&scoped=true"
import script from "./CreateOrSelect.vue?vue&type=script&lang=js"
export * from "./CreateOrSelect.vue?vue&type=script&lang=js"
import style0 from "./CreateOrSelect.vue?vue&type=style&index=0&id=756db533&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "756db533",
  null
  
)

export default component.exports