<template>
  <div class="matchKeywordsTable">
    <studio-table-draggable
      @drag="onDragStart"
      @drop="onDragEnd"
      handle=".action-icon"
      class="draggable"
      draggableTableId="matchkeyword-drag-table"
    >
      <el-table
        class="variable-rules"
        id="matchkeyword-drag-table"
        fit
        :data="keywordMatches"
        style="width: 100%;"
      >
        <el-table-column prop="keyword" :label="__('Keyword')">
          <template slot="header">
            <div class="cell">
              <div>
                {{ __("Keyword") }}
                <el-popover placement="right" width="500" trigger="click">
                  <el-table :data="keywordRuleExamples">
                    <el-table-column
                      width="250"
                      property="keyword_rule"
                      :label="__('Keyword Rule')"
                    >
                      <template slot-scope="scope">
                        <p style="word-break: break-word;">
                          {{ scope.row.keyword_rule }}
                        </p>
                      </template>
                    </el-table-column>
                    <el-table-column
                      width="150"
                      property="input_text"
                      :label="__('Input Text')"
                    >
                      <template slot-scope="scope">
                        <p style="word-break: break-word;">
                          {{ scope.row.input_text }}
                        </p>
                      </template>
                    </el-table-column>
                    <el-table-column
                      width="100"
                      property="result"
                      :label="__('Result')"
                    ></el-table-column>
                  </el-table>
                  <span slot="reference">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="__('Click for examples')"
                      placement="bottom"
                    >
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </span>
                </el-popover>
              </div>
            </div>
          </template>
          <template slot-scope="scope">
            <img
              alt="icon"
              class="action-icon"
              :src="require('@/assets/icons/icon-drag-handle.svg')"
            />
            <el-row type="flex" style="width: 100%">
              <el-col>
                <el-form-item :error="errorMessage(scope.row)">
                  <el-input v-model="scope.row.keyword"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column prop="node_name" :label="__('Goto Node')">
          <template slot-scope="scope">
            <el-row
              :gutter="20"
              class="goto-node-configure"
              type="flex"
              justify="start"
            >
              <el-col>
                <el-form-item>
                  <create-or-select
                    :items="extractGotoNodeOptions"
                    :current_select="currentSelection(scope.row)"
                    :fail-to-create-message="scope.row.error"
                    new-item-message="new node"
                    :placeholder="__('Node Name')"
                    @change="handleChange($event)(scope.row)"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </el-table-column>

        <el-table-column
          prop="condition_option"
          :label="__('Match whole words only')"
          width="140px"
        >
          <template slot="header">
            <div>
              <span style="word-break: break-word;">
                <!-- eslint-disable-next-line -->
                {{ __("Match whole words only") }}
              </span>
              <el-popover placement="bottom" width="600" trigger="click">
                <p style="word-break: break-word;">
                  <!-- eslint-disable-next-line -->
                  {{ __("When enabled, match keyword will perform “whole words only” search to determine a match. Default is disabled.") }}
                </p>
                <el-table :data="fullWordMatchExample">
                  <el-table-column
                    width="150"
                    property="keyword_rule"
                    :label="__('Keyword Rule')"
                  >
                    <template slot-scope="scope">
                      <p style="word-break: break-word;">
                        {{ scope.row.keyword_rule }}
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column
                    width="250"
                    property="input_text"
                    :label="__('Input Text')"
                  >
                    <template slot-scope="scope">
                      <p style="word-break: break-word;">
                        {{ scope.row.input_text }}
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column
                    width="100"
                    property="resultEnable"
                    :label="__('Enabled')"
                  ></el-table-column>
                  <el-table-column
                    width="100"
                    property="resultDisable"
                    :label="__('Disabled')"
                  ></el-table-column>
                </el-table>
                <span slot="reference">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="__('Click for help')"
                    placement="bottom"
                  >
                    <i class="el-icon-info"></i>
                  </el-tooltip>
                </span>
              </el-popover>
            </div>
          </template>
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.condition_option.full_word_match"
            ></el-switch>
          </template>
        </el-table-column>

        <el-table-column class-name="row-message" width="100px" prop="msg" />
        <el-table-column class-name="cell-item-pointer" width="40px">
          <template slot-scope="scope">
            <span @click="removeKeywordMatch(scope.$index)">
              <i class="el-icon-circle-close"></i>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </studio-table-draggable>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import CreateOrSelect from "./CreateOrSelect";
import StudioTableDraggable from "./StudioTableDraggable";

export default {
  components: {
    CreateOrSelect,
    StudioTableDraggable
  },
  props: {
    value: {
      required: true,
      type: Array
    },
    gotoOptions: {
      required: true,
      type: Array
    },
    errors: {
      required: false,
      type: Object,
      default: () => {}
    }
  },
  data() {
    const keyword_match = {
      keyword: "",
      node_name: "",
      node_id: -1,
      condition_option: this.defaultConditionOption()
    };
    return {
      keyword_match,
      $keyword_matches: [{ ...keyword_match }],
      $gotoOptions: [],
      keywordRuleExamples: [
        /* eslint-disable */
        {
          keyword_rule: `${__("large")} && ${__("pizza")} || ${__("small")} && ${__("pizza")}`,
          input_text: __("Can I order a large pizza?"),
          result: __("Match")
        },
        {
          keyword_rule: `${__("large")} && ${__("pizza")} || ${__("small")} && ${__("pizza")}`,
          input_text: __("Can I order a small pizza?"),
          result: __("Match")
        },
        {
          keyword_rule: `${__("large pizza")} || ${__("small pizza")}`,
          input_text: __("Can I order a large pepperoni pizza?"),
          result: __("No Match")
        },
        {
          keyword_rule: `${__("large pepperoni pizza")} || ${__("small pizza")}`,
          input_text: __("Can I order a large pepperoni pizza?"),
          result: __("Match")
        },
        {
          keyword_rule: `${__("large")} && ${__("pizza")} && ${__("drink")} || ${__("small")} && ${__("pizza")}`,
          input_text: __("Can I order a large pepperoni pizza with a drink?"),
          result: __("Match")
        },
        {
          keyword_rule: `${__("large")} && ${__("pizza")} && ${__("drink")} || ${__("small")} && ${__("pizza")}`,
          input_text: __("Can I order a pepperoni pizza with a drink?"),
          result: __("No Match")
        },
        {
          keyword_rule: `${__("pizza")} || ${__("drink")}`,
          input_text: __("Can I order a box of pizza with a drink?"),
          result: __("Match")
        }
        /* eslint-enable */
      ],
      fullWordMatchExample: [
        /* eslint-disable */
        {
          keyword_rule: `${__("no")}`,
          input_text: __("Can I change the order to another name"),
          resultEnable: __("No Match"), // Word match
          resultDisable: __("Match") // Partial match
        },
        {
          keyword_rule: `${__("no")} || ${__("nah")}`,
          input_text: __("Can I change the order to another name"),
          resultEnable: __("No Match"), // Word match
          resultDisable: __("Match") // Partial match
        },
        {
          keyword_rule: `${__("no")} && ${__("yes")}`,
          input_text: __("Can I change the order to another name"),
          resultEnable: __("No Match"), // Word match
          resultDisable: __("No Match") // Partial match
        },
        {
          keyword_rule: `${__("another")}`,
          input_text: __("Can I change the order to another name"),
          resultEnable: __("Match"), // Word match
          resultDisable: __("Match") // Partial match
        }
        /* eslint-enable */
      ]
    };
  },
  computed: {
    ...mapState("canvas", {
      isNodeSubmit: state => state.isNodeSubmit
    }),
    errorMessage() {
      return row => {
        return row.keyword_error || _.get(this.errors, row.keyword);
      };
    },
    extractGotoNodeOptions() {
      const options = _.map(this.gotoOptions, child => ({
        label: child.node_name,
        value: child.node_id
      }));
      return this.$data.$gotoOptions.concat(options);
    },

    keywordMatches() {
      return this.$data.$keyword_matches;
    },

    currentSelection() {
      return row => {
        const { node_id, node_name } = row;
        return node_id === -1 ? node_name : node_id;
      };
    }
  },
  methods: {
    addNewKeywordMatch() {
      this.$data.$keyword_matches.push({ ...this.keyword_match });
    },

    removeKeywordMatch(index) {
      this.$data.$keyword_matches.splice(index, 1);
    },

    /**
     * Get the default condition option object
     */
    defaultConditionOption() {
      return {
        full_word_match: false
      };
    },

    /**
     * Validate the structure of keyword matches
     * @param {Array|Object} keywordMatches
     */
    validateKeywordMatches(keywordMatches) {
      // Make sure keyword matches contains all expected value
      const isInputTypeArray = Array.isArray(keywordMatches);
      keywordMatches = !isInputTypeArray ? [keywordMatches] : keywordMatches;

      keywordMatches.map(keywordMatch => {
        // Only allow plain old javascript object
        if (
          [null, undefined].indexOf(keywordMatch.condition_option) !== -1 ||
          Object.getPrototypeOf(keywordMatch.condition_option) !==
            Object.prototype
        ) {
          keywordMatch.condition_option = { ...this.defaultConditionOption() };
        }
        return keywordMatch;
      });

      return isInputTypeArray ? keywordMatches : keywordMatches[0];
    },

    initializeKeyWordMatches(keywordMatches) {
      keywordMatches = this.validateKeywordMatches(keywordMatches);
      if (!this.isNodeSubmit) {
        if (!_.isEmpty(keywordMatches) && keywordMatches.length) {
          this.$data.$keyword_matches = _.cloneDeep(keywordMatches);
        }
        if (
          !_.some(
            this.$data.$keyword_matches,
            keywordMatch => !keywordMatch.keyword.length
          )
        ) {
          this.addNewKeywordMatch();
          this.$emit("input", _.cloneDeep(this.$data.$keyword_matches));
        }
      }
    },

    onDragStart() {
      let selector = ".matchKeywordsTable";
      const tables = document.querySelectorAll(selector);

      tables[0].classList.add("grabbing");
    },

    onDragEnd() {
      let selector = ".matchKeywordsTable";
      const tables = document.querySelectorAll(selector);

      if (tables[0].classList.contains("grabbing")) {
        tables[0].classList.remove("grabbing");
      }
    },

    handleChange(option) {
      return row => {
        this.$set(row, "node_id", option.value);
        this.$set(row, "node_name", option.label);
        this.$set(row, "msg", option.msg);
      };
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: "initializeKeyWordMatches"
    },
    keywordMatches: {
      handler: function(keywordMatches) {
        this.$emit("input", _.cloneDeep(keywordMatches));
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";
/*.goto-node-configure {*/
/*  flex-direction: column;*/
/*}*/
/*.goto-node-configure .el-form-item {*/
/*  margin-bottom: 0;*/
/*}*/

/*.goto-node-configure ::v-deep .el-form-item__content {*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  justify-content: space-between;*/
/*}*/

/*.goto-node-configure ::v-deep .el-form-item__error {*/
/*  flex-grow: 1;*/
/*  flex-shrink: 0;*/
/*  align-self: center;*/
/*}*/

.goto-node-configure {
  width: 100%;
}

.matchKeywordsTable ::v-deep .el-table--medium td {
  padding: 0;
}

.matchKeywordsTable ::v-deep .el-table--medium th {
  padding: 0;
}

.el-table {
  .el-form-item {
    padding-bottom: 15px;
    padding-top: 15px;
  }
}

.matchKeywordsTable ::v-deep .el-form-item__error {
  padding-top: 1px;
  font-size: 10px;
}

.matchKeywordsTable ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.variable-rules ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.matchKeywordsTable ::v-deep .el-table::before {
  background-color: white !important;
}

.matchKeywordsTable ::v-deep .el-table__body-wrapper {
  margin-top: 10px;
}

.draggable .action-icon {
  cursor: grab;
}

.grabbing {
  cursor: grabbing !important;
}

.grabbing * {
  cursor: grabbing !important;
}
</style>
