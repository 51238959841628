<template>
  <div>{{ __("Empty container. Reload again!") }}</div>
</template>
<script>
import * as SIP from "sip.js/dist/sip-0.10.0";
import _ from "lodash";

export default {
  name: "SipDialerUtil.vue",

  data() {
    return {
      session: "",
      ua: "",
      hangupType: "NOCALL",
      inputNumber: "",
      onCall: false,
      callIncoming: false,
      audioConstraints: {
        audio: true,
        video: false
      },
      mediaStream: "",
      peerConnectionVar: "",
      setRemoteStream: "",
      callStatusObj: {
        status: "",
        severity: "",
        notifyMsg: ""
      }
    };
  },

  computed: {
    media() {
      const audio = document.getElementById("remoteAudio");
      const audio2 = document.getElementById("localAudio");
      return {
        stream: this.audioConstraints,
        render: {
          remote: audio,
          local: audio2
        }
      };
    },
    getCallStatus() {
      return this.callStatusObj.status;
    }
  },
  methods: {
    timeNow() {
      let d = new Date(),
        h = (d.getHours() < 10 ? "0" : "") + d.getHours(),
        m = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
      let s = (d.getSeconds() < 10 ? "0" : "") + d.getSeconds();
      return h * m * s;
    },
    registerUA(wsserver, useragent, register_flag) {
      let myAuth = Math.round(Math.random(this.timeNow()) * 10000).toString();
      try {
        this.ua = new SIP.UA({
          uri: myAuth + "@" + wsserver,
          wsServers: "wss://" + wsserver + ":443",
          allowOutOfDialogRefer: true,
          traceSip: true,
          authorizationUser: "1000",
          password: "1000",
          displayName: __("WebRTC App"),
          sessionDescriptionHandlerFactoryOptions: {
            constraints: {
              audio: true,
              video: false
            },
            peerConnectionOptions: {
              rtcConfiguration: {
                rtcpMuxPolicy: "negotiate"
              }
            }
          },
          register: register_flag,
          dtmfType: SIP.C.dtmfType.RTP,
          userAgentString: useragent,
          log: {
            builtinEnabled: process.env.VUE_APP_WEBRTC_LOG === "true"
          }
        });
      } catch (e) {
        console.log(e.message);
        this.setCallStatus(
          "Call Setup Failed",
          "error",
          "",
          __("Call Setup Failed")
        );
      }
    },
    makeCall(user_input, wsserver) {
      try {
        // startRingTone();
        this.setCallStatus("Ringing", "info", "", __("Ringing"));
        this.session = this.ua.invite("sip:" + user_input + "@" + wsserver, {
          sessionDescriptionHandlerOptions: {
            constraints: {
              audio: true,
              video: false
            }
          }
        });
        let sessionCopy = this.session;
        let self = this;
        this.session.on("accepted", function() {
          // stopRingTone();
          self.peerConnectionVar =
            sessionCopy.sessionDescriptionHandler.peerConnection;
          let remoteStream = new MediaStream();
          self.peerConnectionVar.getReceivers().forEach(function(receiver) {
            var track = receiver.track;
            if (track) {
              remoteStream.addTrack(track);
            }
          });
          this.setRemoteStream = remoteStream;
          self.$refs.remoteAudio.srcObject = remoteStream;
          self.setCallStatus("Connected", "info", "", __("Connected"));

          //Call the async function
          self.gatherStats(self);
        });

        this.session.on("referRequested", function(referClientContext) {
          self.session.bye();
          self.hangUp();
          let referTO = referClientContext.request.headers["Refer-To"][0].raw;
          referTO = referTO.substring(1, referTO.length - 1);
          console.log("referClientContext", referTO, referClientContext);
          referClientContext.accept();
        });

        this.session.on("bye", function() {
          self.ua.transport.disconnect();
          self.setCallStatus(
            "Call Disconnected",
            "error",
            "",
            __("Call Disconnected")
          );
        });

        this.session.on("rejected", function(response) {
          console.log(response);
          //Disconnect the websocket
          self.ua.transport.disconnect();
          self.setCallStatus("Call Rejected", "error", "", __("Call Rejected"));
        });
      } catch (err) {
        this.session.bye();
      }
    },
    async gatherStats(self) {
      console.log(self);
      console.log(self.peerConnectionVar);
      const sender = this.peerConnectionVar.getSenders()[0];
      await new Promise(resolve => setTimeout(resolve, 1500)); // ... wait a bit needed
      const currentReport = await sender.getStats();
      console.log(currentReport);
      // compare the elements from the current report with the baseline
      for (let now of currentReport.values()) {
        if (now.type !== "outbound-rtp") continue;
        const packetsSent = now.packetsSent;
        if (packetsSent === 0) {
          if (this.session.status !== 8 && this.session.status !== 9) {
            this.session.bye();
          }
          this.setCallStatus(
            "Network Failure",
            "error",
            __("UDP is Blocked. Please try again with different network"),
            __("Network Failure")
          );

          //Disconnect the websocket
          this.ua.transport.disconnect();
        }
      }
    },

    mute(input) {
      if (Object.entries(this.peerConnectionVar.getSenders()[0]).length === 0) {
        const sender = this.peerConnectionVar.getSenders()[0];
        sender.track.enabled = !input;
      }
    },

    /*Function  to disconnect the connection*/
    hangUp() {
      try {
        if (!_.isEmpty(this.session)) {
          // Check the session status to send bye (STATUS_CANCELED: 8 STATUS_TERMINATED: 9)
          if (this.session.status !== 8 && this.session.status !== 9) {
            if (this.getCallStatus === "Connected") {
              this.session.bye();
            }
          }
        }

        //Disconnect the TCP connection
        this.ua.transport.disconnect();
        this.setCallStatus(
          "Call Disconnected",
          "error",
          "",
          __("Call Disconnected")
        );
      } catch (err) {
        console.log(err);
      }
    },
    /* Function to send DTMF tones */
    sipSendDTMF(c) {
      if (c >= 0 && c <= 9) {
        this.session.dtmf(c);
      } else if (c === "*") {
        this.session.dtmf("*");
      } else if (c === "#") {
        this.session.dtmf("#");
      }
    },
    /**
     * set the call status
     * @param status
     * @param severity
     * @param msg
     * @param label
     */
    setCallStatus(status, severity, msg, label) {
      this.callStatusObj = {
        status: status,
        severity: severity,
        notifyMsg: msg,
        label: label
      };
    }
  }
};
</script>
<style scoped></style>
