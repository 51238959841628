<template>
  <div :style="{ padding: contentPadding }">
    <el-row
      v-for="itr in iterableDisplay"
      v-bind:key="itr.idx"
      :name="itr.idx"
      :style="{ padding: contentPadding }"
      :gutter="20"
    >
      <div
        style="font-size: 18px; font-weight: 700;"
        :style="{ marginBottom: contentPadding }"
      >
        <slot name="title" :row="itr.v" :index="itr.idx"></slot>
      </div>
      <div style="display: flex;">
        <el-col :span="!immutable ? 23 : 24" style="padding-left: 0;">
          <div>
            <slot
              name="body"
              :row="itr.v"
              :index="itr.idx"
              :implicitAddItem="implicitAddItem"
            ></slot>
          </div>
        </el-col>
        <el-col
          :span="1"
          v-if="!immutable"
          style="display: flex; align-items: center;"
        >
          <div>
            <el-link :underline="false" @click="removeItem(itr.idx)">
              <span>
                <slot name="deleteButton" :row="itr.v" :index="itr.idx">
                  <DynamicCollapseDeleteButton
                    v-if="removeValueLabel === 'X'"
                  />
                  <span v-else>{{ removeValueLabel }}</span>
                </slot>
              </span>
            </el-link>
          </div>
        </el-col>
      </div>
    </el-row>

    <div
      v-if="!immutable && explicitAddItem"
      :style="{ paddingTop: addValuePaddingTop }"
    >
      <el-button
        :type="addContentButtonType"
        @click="addItem"
        v-if="!hasMaxItem"
        icon="el-icon-plus"
        >{{ addValueLabel }}</el-button
      >
      <el-button
        :type="addContentButtonType"
        disabled
        v-else
        icon="el-icon-plus"
        >{{ addValueLabel }}</el-button
      >
    </div>
  </div>
</template>

<script>
import DynamicRowInput from "./mixins/DynamicRowInput";
import DynamicCollapseDeleteButton from "./DynamicCollapseDeleteButton";

export default {
  name: "DynamicRow",
  mixins: [DynamicRowInput],
  components: {
    DynamicCollapseDeleteButton
  },

  props: {
    removeValueLabel: {
      type: String,
      default: "X"
    }
  },

  computed: {
    addValuePaddingTop() {
      return this.iterableDisplay.length == 0 ? "0px" : "24px";
    }
  }
};
</script>

<style></style>
