import __ from "@/translation";

export const fieldTypes = [
  {
    name: "objectId",
    label: __("objectId"),
    availableOperators: [
      "=",
      "!=",
      ">",
      "<",
      "in",
      "notIn",
      "isNull",
      "notIsNull"
    ]
  },
  {
    name: "text",
    label: __("text"),
    availableOperators: [
      "=",
      "!=",
      ">",
      "<",
      "endsWith",
      "notEndsWith",
      "startsWith",
      "notStartsWith",
      "isNull",
      "notIsNull",
      "isEmpty",
      "notIsEmpty",
      "isNullOrEmpty",
      "in",
      "notIn",
      "contains"
    ]
  },
  {
    name: "date",
    label: __("date"),
    availableOperators: ["=", "!=", "isNull", "notIsNull", ">", "<", "between"]
  },
  {
    name: "datetime",
    label: __("datetime"),
    availableOperators: ["=", "!=", "isNull", "notIsNull", ">", "<", "between"]
  },
  {
    name: "timestamp",
    label: __("timestamp"),
    availableOperators: ["=", "!=", "isNull", "notIsNull", ">", "<", "between"]
  },
  {
    name: "number",
    label: __("number"),
    availableOperators: [
      "=",
      "!=",
      "in",
      "notIn",
      "isNull",
      "notIsNull",
      ">",
      "<",
      "between"
    ]
  },
  {
    name: "double",
    label: __("double"),
    availableOperators: ["=", "!=", "isNull", "notIsNull", ">", "<", "between"]
  },
  {
    name: "boolean",
    label: __("boolean"),
    availableOperators: ["=", "!="]
  },
  {
    name: "radio",
    label: __("radio"),
    availableOperators: ["=", "!="]
  },
  {
    name: "multipleChoice",
    label: __("multiple choice"),
    availableOperators: ["in", "notIn"]
  },
  {
    name: "select",
    label: __("select"),
    availableOperators: ["=", "!="]
  },
  {
    name: "audio",
    label: __("audio"),
    availableOperators: ["isEmpty", "notIsEmpty"]
  }
];

// operators for query condition
export const operators = [
  {
    name: "=",
    label: __("equal to")
  },
  {
    name: "!=",
    label: __("not equal to")
  },
  {
    name: ">",
    label: __("greater than")
  },
  {
    name: "<",
    label: __("less than")
  },
  {
    name: "in",
    label: __("in")
  },
  {
    name: "notIn",
    label: __("not in")
  },
  {
    name: "startsWith",
    label: __("starts with")
  },
  {
    name: "contains",
    label: __("contains")
  },
  {
    name: "notStartsWith",
    label: __("doesn't start with")
  },
  {
    name: "endsWith",
    label: __("ends with")
  },
  {
    name: "notEndsWith",
    label: __("doesn't end with")
  },
  {
    name: "isEmpty",
    label: __("is empty")
  },
  {
    name: "notIsEmpty",
    label: __("is not empty")
  },
  {
    name: "isNullOrEmpty",
    label: __("is null or empty")
  },
  {
    name: "isNull",
    label: __("is null")
  },
  {
    name: "notIsNull",
    label: __("is not null")
  },
  {
    name: "between",
    label: __("between")
  }
];
