<script>
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";
import moment from "moment-timezone";
import { getSubKeyObject } from "@/utils/collection";
import { NODE_TYPES } from "@/constants/nodes";
import { getComplexVariables } from "@/api/variables";
import VariableChecks from "@/views/mixins/VariableChecks";
import { EventBus } from "@/EventBus";

export default {
  inject: ["$onDemandCampaigns", "$phoneNumberToTaskMap"],
  mixins: [VariableChecks],
  data() {
    const validateNodeName = (rule, value, callback) => {
      if (
        Object.keys(this.nodeToBind).includes("node_id") &&
        value ===
          _.find(this.getValidNodes, { node_id: this.nodeToBind.node_id })
            .node_name
      ) {
        callback();
      } else {
        !_.map(this.getValidNodes, node => node.node_name.toString()).includes(
          value.toString()
        )
          ? callback()
          : callback(__("Node name already exists"));
      }
    };
    return {
      node: {},
      nodeToBind: {},
      newVariableCreated: false,
      rules: {
        node_name: [
          {
            required: true,
            message: __("Node name cannot be empty"),
            trigger: "blur"
          },
          { validator: validateNodeName, trigger: "blur" }
        ]
      },
      onDemandCampaigns: [],
      phoneNumberToTaskMap: [],
      complexVariables: [],
      isMounted: false,
      attemptedToSubmit: false,
      errorBag: {},
      errorNotification: message => {
        if (!_.has(this.errorBag, message)) {
          this.errorBag[message] = this.$notify({
            type: "error",
            message: message,
            position: "bottom-left",
            duration: 0,
            onClose: this.handleNotificationClose
          });
        }
      }
    };
  },
  props: {
    clickedNode: {
      required: true,
      type: Object
    },
    task_id: {
      required: true,
      type: [String, Number]
    },
    selectedNodeType: {
      required: true,
      type: String
    }
  },
  computed: {
    ...mapState("canvas", {
      isEditing: state => state.isEdit,
      isNodeSubmit: state => state.isNodeSubmit,
      isNodeCancelCompleted: state => state.isNodeCancelCompleted,
      nodes: state => state.nodes,
      task_type: state => state.task_type,
      compareNodesOnBaseColumns: state => state.compareNodesOnBaseColumns,
      canvases: state => state.canvases,
      nodeUpdated: state => state.nodeUpdated,
      addNewNodeBelow: state => state.addNewNodeBelow,
      attemptConvertSameNodeToGoto: state => state.attemptConvertSameNodeToGoto,
      validationsInProgress: state => state.validationsInProgress
    }),

    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),

    ...mapGetters("canvas", {
      getChildren: "getChildren",
      getParent: "getParent",
      getOrphans: "getOrphans",
      getValidNodes: "getValidNodes",
      getRootNode: "getRootNode",
      nodeTypeOptions: "nodeTypeOptions",
      isTaskReadOnly: "isTaskReadOnly",
      getOriginalNode: "getOriginalNode",
      currentCanvas: "currentCanvas",
      nodeNameCopy: "nodeNameCopy"
    }),

    ...mapState("variables", {
      accountVariablesMap: state => state.accountVariablesMap
    }),

    ...mapGetters("variables", {
      variables: "variables",
      singleValuedVariables: "singleValuedVariables",
      singleValuedAndSecureVariables: "singleValuedAndSecureVariables",
      secureVariables: "secureVariables",
      audioVariables: "audioVariables",
      variablesUsedInExpressionBuilder: "variablesUsedInExpressionBuilder",
      arrayVariable: "arrayVariable",
      arrayVariableName: "arrayVariableName",
      variableNames: "variableNames",
      variablesExceptArrayVariables: "variablesExceptArrayVariables",
      cavVariables: "cavVariables",
      cavVariableNames: "cavVariablesNames"
    }),

    generateGotoOptions() {
      const nodeToUse = !this.isEditing ? this.node : this.nodeToBind;
      let childrenToUse = [nodeToUse];

      if (this.addNewNodeBelow) {
        childrenToUse = this.getChildren(nodeToUse.node_id);
      }
      let options = _.uniqBy(
        _.filter(
          _.concat(
            childrenToUse,
            this.getOrphans,
            this.addNewNodeBelow
              ? []
              : !this.attemptConvertSameNodeToGoto
              ? [nodeToUse]
              : []
          ),
          // orphans will have the root of the current canvas also,
          // so the options should not have the current root
          gotoNodeOption =>
            gotoNodeOption.node_id !==
              this.getRootNode(nodeToUse.node_id).node_id ||
            (!this.addNewNodeBelow &&
              gotoNodeOption.node_id ===
                this.getRootNode(nodeToUse.node_id).node_id &&
              nodeToUse.node_id === this.getRootNode(nodeToUse.node_id).node_id)
        ),
        function(item) {
          return item.node_id;
        }
      );
      options = _.sortBy(options, ["node_name"], ["desc"]);
      return options;
    },

    onDemandCampaignsFn() {
      return this.$onDemandCampaigns();
    },

    phoneNumberToTaskMapFn() {
      return this.$phoneNumberToTaskMap();
    },

    process() {
      return this.nodeToBind.node_id ? this.editNode : this.addNewNode;
    },

    hasInvalidVariable() {
      return value => {
        let invalidVariables = this.invalidVariableIdsPresentInAValue(value);
        return _.isEmpty(invalidVariables)
          ? ""
          : "invalid variable, " + invalidVariables[0];
      };
    }
  },
  methods: {
    ...mapActions("canvas", {
      addNewNode: "addNewNode",
      editNode: "editNode",
      setClickedNode: "setClickedNode",
      toggleNodeSubmit: "toggleNodeSubmit",
      toggleNodeUpdateStatus: "toggleNodeUpdateStatus",
      toggleNodeCancelCompletedState: "toggleNodeCancelCompletedState",
      toggleValidationsInProgress: "toggleValidationsInProgress",
      updateNodeNameCopy: "updateNodeNameCopy"
    }),

    ...mapActions("variables", {
      forceFetchVariables: "forceFetchVariables"
    }),

    handleCancel() {
      this.$emit("handleCancel");
      // reset the node name copy
    },

    handleNotificationClose(notification) {
      if (_.has(this.errorBag, notification.message)) {
        delete this.errorBag[notification.message];
      }
    },

    handleFieldValidation() {
      this.attemptedToSubmit &&
        this.$refs.nodeForm.validate((valid, errors) => {
          let currentErrMessages = _.map(
            this.errorBag,
            (notification, message) => message
          );

          let newErrMessages = _.flatten(
            _.map(errors, err => {
              return _.map(err, "message");
            })
          );

          let correctedErrors = _.difference(
            currentErrMessages,
            newErrMessages
          );

          let newErrMessagesToAdd = _.difference(
            newErrMessages,
            currentErrMessages
          );

          _.map(correctedErrors, message => {
            this.errorBag[message].close();
            delete this.errorBag[message];
          });

          _.map(newErrMessagesToAdd, message => {
            setTimeout(() => this.errorNotification(message), 100);
          });
        });
    },

    async loadComplexVariables() {
      if (_.isEmpty(this.complexVariables) && !_.isEmpty(this.clickedNode)) {
        await getComplexVariables(this.clickedNode.task_id)
          .then(({ data }) => {
            this.complexVariables = data.data;
          })
          .catch(err => {
            console.log(err);
          });
      }
    },

    cleanUpWhenUserCancels() {
      return new Promise(resolve => {
        resolve();
      });
    },

    cleanUpNodeToPrepareForSubmit() {
      console.warn(
        `Implement cleanUpNodeToPrepareForSubmit() method in component for ${this.selectedNodeType} node,
        and call from cleanUpNode() method to perform node specific cleanup if any. This method is also
        used for doing object comparison while doing editing to enable/disable update button`
      );
      return _.cloneDeep(this.nodeToBind);
    },

    cleanUpNode() {
      console.warn(
        `Implement cleanUpNode() method in component for ${this.selectedNodeType} node,
        and call createOrEditNode() method after doing node specific cleanup if any`
      );
      this.createOrEditNode();
    },

    createOrEditNode() {
      if (!this.isTaskReadOnly) {
        this.attemptedToSubmit = true;
        this.$refs.nodeForm.validate((valid, errors) => {
          if (valid) {
            // window.aptrinsic("track", "click", {
            //   node: this.nodeToBind,
            //   nodeInContext: this.node,
            //   canvasId: this.currentCanvas.canvas_id
            // });
            this.process({
              node: this.nodeToBind,
              nodeInContext: this.node,
              canvasId: this.currentCanvas.canvas_id
            })
              .then(async () => {
                this.newVariableCreated
                  ? await this.forceFetchVariables()
                  : null;
                this.setClickedNode(null);
                this.toggleNodeSubmit(false);
              })
              .catch(() => {
                // this.newVariableCreated = false;
                this.toggleNodeSubmit(false);
              });
          } else {
            this.showErrorMessages(errors);
            this.toggleNodeSubmit(false);
            // this.newVariableCreated = false;
            return false;
          }
        });
      }
    },

    showErrorMessages(errors) {
      let errMessages = _.flatten(
        _.map(errors, err => {
          return _.map(err, "message");
        })
      );

      _.map(errMessages, message => {
        setTimeout(() => this.errorNotification(message), 100);
      });
    },

    generateName() {
      let nodeName =
        _.find(this.nodeTypeOptions(this.selectedAccountId, this.task_type), {
          NODE_TYPE: this.selectedNodeType
        }).NEW_NODE_PREFIX +
        String(moment().unix())
          .split("")
          .reverse()
          .join("")
          .substr(0, 6);

      return _.map(this.nodes, "node_name").includes(nodeName)
        ? this.generateName()
        : nodeName;
    },

    stringifyJsonArray(array) {
      return Array.isArray(array)
        ? JSON.stringify(array)
        : !array
        ? "[]"
        : array;
    }
  },
  mounted() {
    this.isMounted = true;
  },
  created() {
    EventBus.$on("update-connecting-nodes", (child, parent) => {
      this.$set(this.nodeToBind, "connector_child", child);
      this.$set(this.nodeToBind, "connector_parent", parent);
    });
  },
  beforeDestroy() {
    EventBus.$off("update-connecting-nodes");
    _.map(this.errorBag, notification => notification.close());
    this.errorBag = {};
  },
  watch: {
    onDemandCampaignsFn: {
      immediate: true,
      handler(val) {
        this.onDemandCampaigns = val;
      }
    },
    phoneNumberToTaskMapFn: {
      immediate: true,
      handler(val) {
        this.phoneNumberToTaskMap = val;
      }
    },
    clickedNode: {
      immediate: true,
      handler: function() {
        if (_.isEmpty(this.clickedNode)) {
          this.handleCancel();
        }

        this.node = _.cloneDeep(this.clickedNode);
        this.nodeToBind = {};
        if (this.isEditing) {
          this.nodeToBind = _.cloneDeep(this.node);
          this.$set(
            this.nodeToBind,
            "node_type",
            this.nodeToBind.node_type.data.node_type
          );

          // node name copy is not null then set node to bind with
          if (this.nodeNameCopy !== null) {
            this.$set(this.nodeToBind, "node_name", this.nodeNameCopy);
          }
        } else {
          this.$set(this.nodeToBind, "node_name", this.generateName());
          this.$set(this.nodeToBind, "ac_id", +this.selectedAccountId);
          this.$set(this.nodeToBind, "task_id", +this.task_id);
          this.$set(this.nodeToBind, "connector_parent", {});
          this.$set(this.nodeToBind.connector_parent, "data", [
            { pid: +this.node.node_id }
          ]);
        }
      }
    },
    isNodeSubmit: {
      handler: function(value) {
        if (value) {
          this.cleanUpNode();
        }
      }
    },
    isNodeCancelCompleted: {
      async handler(val) {
        if (!val) {
          await this.cleanUpWhenUserCancels();
          this.toggleNodeCancelCompletedState(true);
        }
      }
    },
    // watcher to see if something changed in the node configuration
    nodeToBind: {
      deep: true,
      immediate: true,
      handler: function() {
        this.$nextTick(() => {
          if (!this.isNodeSubmit) {
            this.handleFieldValidation();
            const nodeType = _.find(NODE_TYPES, {
              NODE_TYPE: this.selectedNodeType
            });
            const columnsToCompare = _.concat(
              this.compareNodesOnBaseColumns,
              (nodeType.UNIQUE_PROP || "").split(",") // this will be like messaging_node or integration_node or variable_node etc based on selected node type
            );

            // have modified the name then update nodeNameCopy in the store
            if (
              this.isEditing &&
              this.nodeToBind.node_name !== this.clickedNode.node_name
            ) {
              this.updateNodeNameCopy(this.nodeToBind.node_name);
            }

            this.toggleNodeUpdateStatus(
              !_.isEqual(
                getSubKeyObject(
                  this.cleanUpNodeToPrepareForSubmit(),
                  columnsToCompare
                ),
                getSubKeyObject(this.clickedNode, columnsToCompare)
              )
            );
          }
        });
      }
    }
  },
  filters: {
    truncate(value, limit = 15) {
      if (value && value.length <= limit) {
        return value;
      } else if (value) {
        return value.substring(0, limit) + "...";
      } else {
        return value;
      }
    }
  }
};
</script>
