<template>
  <div
    v-on-clickaway="updateDblClickStatusToTrue"
    @dblclick="updateDblClickStatus(false)"
    :class="`canvas-tab ${darkModeClass}`"
  >
    <div v-if="notDoubleClicked">{{ canvas.canvas_name }}</div>
    <div v-else @keydown.enter="updateCanvasName">
      <input v-focus v-model="newName" type="text" />
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";

export default {
  mixins: [clickaway],
  props: {
    canvas: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      notDoubleClicked: true,
      newName: ""
    };
  },

  computed: {
    ...mapState("canvas", {
      renamingCanvasSuccessful: state => state.renamingCanvasSuccessful,
      newCanvas: state => state.newCanvas
    }),
    ...mapState("app", {
      darkTheme: state => state.darkTheme
    }),
    ...mapGetters("canvas", {
      isTaskReadOnly: "isTaskReadOnly"
    }),
    darkModeClass() {
      return this.darkTheme ? "dark" : "";
    }
  },

  methods: {
    updateCanvasName(ev) {
      if (ev) {
        ev.preventDefault();
        ev.stopPropagation();
      }
      if (!this.isTaskReadOnly) {
        this.$emit("change", {
          oldName: this.canvas.canvas_name,
          newName: this.newName.trim()
        });
        this.updateDblClickStatus(true);
      }
    },
    updateDblClickStatus(val) {
      if (!this.isTaskReadOnly) {
        if (val) {
          this.notDoubleClicked = val;
        } else if (
          !this.canvas.is_main_canvas &&
          !_.isEqual(this.canvas, this.newCanvas)
        ) {
          this.notDoubleClicked = val;
        }
      }
    },
    updateDblClickStatusToTrue() {
      if (!this.isTaskReadOnly) {
        if (this.canvas.canvas_name !== this.newName) {
          this.updateCanvasName();
        }
        this.notDoubleClicked = true;
      }
    }
  },
  watch: {
    canvas: {
      immediate: true,
      deep: true,
      handler: function(val) {
        this.newName = val.canvas_name;
      }
    },
    renamingCanvasSuccessful: {
      immediate: true,
      handler: function(val) {
        if (!val) {
          this.newName = this.canvas.canvas_name;
        }
      }
    }
  }
  // directives: {
  //   focus: {
  //     inserted: function(el) {
  //       el.focus();
  //     }
  //   }
  // }
};
</script>

<style scoped lang="scss">
.canvas-tab {
  border: 1px solid lightgrey;
  border-bottom: 4px solid rgba(255, 255, 255, 0.6);
  color: lightgrey;
  box-shadow: 0 0 18px -4px rgba(0, 0, 0, 0.15);
  background: rgba(255, 255, 255, 0.6);

  &:hover {
    color: #5c9c7e;
    box-shadow: 0 0 18px -4px rgba(0, 0, 0, 0.3);
    background: rgba(255, 255, 255, 1);
  }
}

.canvas-tab.dark {
  border: 1px solid lightgrey;
  border-bottom: 4px solid rgba(255, 255, 255, 0.6);
  color: lightgrey;
  box-shadow: 0 0 18px -4px rgba(0, 0, 0, 0.15);
  background: rgba(90, 90, 90, 0.6);

  &:hover {
    color: #5c9c7e;
    box-shadow: 0 0 18px -4px rgba(0, 0, 0, 0.3);
    background: rgba(90, 90, 90, 1);
  }
}

.canvas-tab {
  padding: 5px 15px 5px 15px;
  margin-left: 2px;
  font-size: 0.8rem;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: -5px;
  cursor: pointer;
  height: 18px;
  max-height: 20px;
  max-width: 120px;
  div:first-of-type {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  input {
    background: none;
    border: none;
    outline: none;
    font-size: 0.8rem;
  }

  div {
    padding-bottom: 4px;
  }
}

.canvas-tab.dark.active-canvas {
  div:first-of-type {
    color: #5c9c7e;
  }

  border-bottom: 4px solid #5c9c7e;
  box-shadow: 0 0 18px -4px rgba(0, 0, 0, 0.3);
  transition: border-bottom-color 0.5s ease-in-out;
  background: #29313c;
}

.canvas-tab.active-canvas {
  div:first-of-type {
    color: #5c9c7e;
  }

  border-bottom: 4px solid #5c9c7e;
  box-shadow: 0 0 18px -4px rgba(0, 0, 0, 0.3);
  transition: border-bottom-color 0.5s ease-in-out;
  background: rgba(255, 255, 255, 1);
}
</style>
