/* eslint-disable no-undef */
export default {
  conditionLabelNode() {
    OrgChart.templates.condition_label = Object.assign(
      {},
      OrgChart.templates.generic
    );
    OrgChart.templates.condition_label.size = [255, 30];
    OrgChart.templates.condition_label.node =
      '<svg width="255" height="85" viewBox="0 0 255 85" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<rect  width="241" height="30" rx="5" fill="#212932" class="nodeRect" style="cursor: default"/>\n' +
      '<rect  width="221" height="40" rx="5" x=15 y=40 fill="transparent" class="drop-box" stroke="grey" stroke-width="2" stroke-dasharray="10 3" style="display: none"/>\n' +
      "</svg>";

    OrgChart.templates.condition_label.field_1 =
      '<text width="240" text-overflow="ellipsis" style="font-size: 14px;font-weight: bold;cursor: default" fill="white" text-anchor="middle" x="127" y="18" class="nodeName">{val}</text>';

    OrgChart.templates.condition_label.edge =
      '<path  stroke="#686868" stroke-width="1px" fill="none" edge-id="[{id}][{child-id}]" d="M{xa},{ya} C{xb},{yb} {xc},{yc} {xd},{yd}"/>';

    OrgChart.templates.condition_label.plus = "";

    OrgChart.templates.condition_label.minus = "";

    OrgChart.templates.condition_label.expandCollapseSize = 36;

    OrgChart.templates.condition_label.nodeMenuButton = "";

    OrgChart.templates.condition_label.img_0 = "";

    OrgChart.templates.condition_label.img_1 = "";
  }
};
