<template>
  <div class="extractWordsTable">
    <expression-builder-dialog
      :value="expressions(variableToSet)"
      :show-expression-builder="displayExpressionBuilderModal"
      @input="saveExpression($event)(variableToSet)"
      :source="source"
      :activeVariable="activeVariable"
      @cancel="handleCancel"
      @close="handleCancel"
      :use-secure-variables="useSecureVariables"
    />
    <el-table class="extract-words" fit :data="wordRules" style="width: 100%">
      <el-table-column prop="variable_name" :label="__('Variable')">
        <template slot-scope="scope">
          <el-row
            class="goto-variable-configure extract-words-column"
            type="flex"
            justify="start"
          >
            <el-col :class="classes(scope.row, 'variable_name')">
              <el-form-item>
                <create-or-select
                  v-if="!useImmutableVariables"
                  :current_select="currentSelection(scope.row)"
                  :items="variables"
                  label="variable_name"
                  value="variable_id"
                  :new-item-message="newItemMsg"
                  :placeholder="__('Variable Name')"
                  @change="handleChange($event)(scope.row)"
                  :fail-to-create-message="scope.row.error"
                />
                <div v-else>
                  {{ scope.row.variable_name }}
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-table-column>

      <el-table-column prop="rule_value" :label="rule_title || __('Word Rule')">
        <template slot-scope="scope">
          <el-row type="flex" class="ruleValue extract-words-column">
            <el-col :class="classes(scope.row, 'rule_value')">
              <el-form-item v-if="!useExpressionBuilder">
                <el-input v-model="scope.row.rule_value"></el-input>
              </el-form-item>
              <el-form-item v-else>
                <expression-input
                  @edit-input="handleVariableSet(scope.row)"
                  v-model="scope.row.rule_value"
                  :complex-variables="complexVariables"
                ></expression-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-table-column>

      <el-table-column class-name="row-message" width="100px" prop="msg" />
      <el-table-column
        v-if="addDescription"
        min-width="180"
        prop="description"
        :label="__('Description')"
      >
        <template slot-scope="scope">
          <el-col :class="classes(scope.row, 'description')">
            <el-form-item
              prop="description"
              :error="descriptionRule(scope.row)"
            >
              <el-input v-model="scope.row.description"></el-input>
            </el-form-item>
          </el-col>
        </template>
      </el-table-column>

      <el-table-column
        v-if="addConfigurable"
        prop="configurable"
        :label="__('Configurable')"
      >
        <template slot="header">
          <div style="display: flex; justify-content: center">
            <span>Configurable</span>
            <el-popover placement="top-start" width="400" trigger="hover">
              <div style="word-break: break-word">
                <!-- eslint-disable-next-line -->
                {{ __("check this box to force the user to override this value while duplicating this task from template or an exported ctf file") }}
              </div>
              <i
                slot="reference"
                style="padding-left: 5px"
                class="el-icon-info icon"
              ></i>
            </el-popover></div
        ></template>
        <template slot-scope="scope">
          <el-form-item style="display:flex; justify-content: center">
            <el-checkbox
              :value="!!scope.row.import_configurable"
              @input="scope.row.import_configurable = $event"
            ></el-checkbox>
          </el-form-item>
        </template>
      </el-table-column>

      <el-table-column
        v-if="!useImmutableVariables"
        class-name="cell-item-pointer"
        width="40px"
      >
        <template slot-scope="scope">
          <span @click="removeWordRule(scope.$index)">
            <i class="el-icon-circle-close"></i>
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import _ from "lodash";
import CreateOrSelect from "./CreateOrSelect";
import { getComplexVariables } from "@/api/variables";
import ExpressionBuilderDialog from "@/views/build/callflow/components/expression-builder/ExpressionBuilderDialog";
import ExpressionInput from "@/views/build/callflow/components/expression-builder/ExpressionInput";
import { SYS_VAR } from "@/constants/systemVariables";

export default {
  components: { ExpressionInput, ExpressionBuilderDialog, CreateOrSelect },
  props: {
    value: {
      required: true,
      type: Array
    },
    source: {
      required: false,
      type: String,
      default: ""
    },
    variables: {
      required: true,
      type: Array
    },
    rule_title: {
      required: false,
      type: String
    },
    useExpressionBuilder: {
      required: false,
      type: Boolean,
      default: true
    },
    addDescription: {
      required: false,
      type: Boolean,
      default: false
    },
    addConfigurable: {
      required: false,
      type: Boolean,
      default: false
    },
    addEmptyRow: {
      required: false,
      type: Boolean,
      default: true
    },
    useImmutableVariables: {
      required: false,
      type: Boolean,
      default: false
    },
    useSecureVariables: {
      required: false,
      type: Boolean,
      default: true
    },
    inactiveExpressionInput: {
      required: false,
      type: Boolean,
      default: false
    },
    inactiveExpressionReason: {
      required: false,
      type: String,
      default: ""
    },
    activeVariable: {
      required: false,
      type: Number,
      default: -1
    },
    allowXSIPVariables: {
      required: false,
      type: Boolean,
      default: false
    },
    allowSystemVariables: {
      required: false,
      type: Boolean,
      default: false
    },
    allowPaymentVariables: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    const word_rule = {
      rule_value: "",
      variable_name: "",
      variable_id: -1,
      // default_value: "",
      description: "",
      import_configurable: false
    };

    const generateWordRule = () =>
      _.pickBy(word_rule, (value, key) => {
        if (key === "import_configurable") {
          return this.addConfigurable;
        }
        if (key === "description") {
          return this.addDescription;
        }
        return true;
      });

    return {
      variableToSet: {},
      word_rule: generateWordRule(),
      $word_rules: [{ ...generateWordRule() }],
      complexVariables: [],
      newItemMsg: __("new variable")
    };
  },
  computed: {
    ...mapState("canvas", {
      isNodeSubmit: state => state.isNodeSubmit,
      clickedNode: state => state.clickedNode,
      showExpressionBuilder: state => state.showExpressionBuilder
    }),

    ...mapGetters("variables", {
      secureVariableNames: "secureVariableNames",
      xSipVariableNames: "xSipVariableNames",
      cavVariableNames: "cavVariablesNames"
    }),

    descriptionRule() {
      return row => {
        if (row.import_configurable && !row.description) {
          return __("Description cannot be empty");
        } else {
          return "";
        }
      };
    },

    classes() {
      return (row, key) => {
        if (!row.rule_value && !row.description && !row.variable_name) {
          return "empty-row empty-val";
        }
        if (key === "description" && !row.import_configurable) {
          return "no-red-highlight";
        }
        if (!row[key]) {
          return "empty-val";
        } else {
          return "non-empty-val";
        }
      };
    },

    ...mapGetters("expressionbuilder", {
      parameterCountFor: "parameterCountFor",
      isJsonFunction: "isJsonFunction"
    }),

    displayExpressionBuilderModal: {
      get: function() {
        return this.showExpressionBuilder;
      },

      set: function(val) {
        this.setExpressionBuilderModalShow(val);
      }
    },

    expressions: {
      get: function() {
        return row => {
          return row.rule_value || "{}";
        };
      }
    },

    // unUsedVariables() {
    //   return row => {
    //     const unUsedVariables = _.filter(
    //       this.variables,
    //       variable =>
    //         !_.some(
    //           _.filter(this.wordRules, rule => rule.variable_id !== -1),
    //           rule => rule.variable_id === variable.variable_id
    //         )
    //     );
    //
    //     return row.variable_id !== -1
    //       ? _.concat(row, unUsedVariables)
    //       : unUsedVariables;
    //   };
    // },
    //
    // usedVariables() {
    //   return row => {
    //     return _.concat(
    //       _.filter(this.variables, variable => {
    //         return _.some(
    //           _.filter(this.wordRules, rule => rule.variable_id !== -1),
    //           rule => rule.variable_id === variable.variable_id
    //         );
    //       }),
    //       _.filter(
    //         _.filter(
    //           this.wordRules,
    //           rule => rule.variable_id === -1 && rule.variable_name
    //         ),
    //         rule =>
    //           rule.variable_id === -1 && row.rule_value !== rule.rule_value
    //       )
    //     );
    //   };
    // },

    wordRules() {
      return this.$data.$word_rules;
    },

    currentSelection() {
      return row => {
        const { variable_id, variable_name } = row;
        return variable_id === -1 ? variable_name : variable_id;
      };
    }
  },
  methods: {
    ...mapActions("canvas", {
      setExpressionBuilderModalShow: "setExpressionBuilderModalShow"
    }),

    addNewWordRule() {
      this.addEmptyRow && this.$data.$word_rules.push({ ...this.word_rule });
    },

    removeWordRule(index) {
      this.$data.$word_rules.splice(index, 1);
    },

    handleVariableSet(variable) {
      if (this.inactiveExpressionInput) {
        this.$message({
          message: this.inactiveExpressionReason,
          type: "error"
        });
      } else {
        this.variableToSet = variable;
        this.displayExpressionBuilderModal = true;
      }
    },

    saveExpression({ expression, complexVariables }) {
      return row => {
        this.displayExpressionBuilderModal = false;
        row.rule_value = expression;
        this.complexVariables = complexVariables;
      };
    },

    handleCancel() {
      this.displayExpressionBuilderModal = false;
    },

    initializeWordRules(wordRules) {
      if (!this.isNodeSubmit) {
        if (!_.isEmpty(wordRules) && wordRules.length) {
          this.$data.$word_rules = _.cloneDeep(wordRules);
        }
        if (
          !_.some(
            this.$data.$word_rules,
            wordRule => !wordRule.rule_value.length
          )
        ) {
          this.addNewWordRule();
          this.$emit("input", _.cloneDeep(this.$data.$word_rules));
        }
      }
    },

    handleChange(option) {
      return row => {
        this.$nextTick(() => {
          this.$set(row, "variable_name", option.label);
          this.$set(row, "variable_id", option.value);

          const sysVarPattern = "^" + SYS_VAR.PREFIX + "\\" + SYS_VAR.SEPARATOR;

          const optionLabelMatchesXSIPVariable = new RegExp("^XSIP_", "i").test(
            option.label
          );

          const optionLabelMatchesSystemVariable = new RegExp(
            `${sysVarPattern}`,
            "i"
          ).test(option.label);

          const optionLabelMatchesPaymentVariable = new RegExp(
            `^payment\\.`,
            "i"
          ).test(option.label);

          if (
            !this.allowXSIPVariables &&
            option.msg === this.newItemMsg &&
            !_.isEmpty(this.xSipVariableNames) &&
            optionLabelMatchesXSIPVariable
          ) {
            row.error = __("cannot set XSIP variable value");
          } else if (
            !this.allowSystemVariables &&
            option.msg === this.newItemMsg &&
            optionLabelMatchesSystemVariable
          ) {
            row.error = __("cannot set SYSTEM variable");
          } else if (
            !this.allowPaymentVariables &&
            option.msg === this.newItemMsg &&
            optionLabelMatchesPaymentVariable
          ) {
            row.error = __("cannot set PAYMENT variable");
          } else {
            row.error = "";

            if (
              (optionLabelMatchesSystemVariable && this.allowSystemVariables) ||
              (optionLabelMatchesXSIPVariable && this.allowXSIPVariables) ||
              (optionLabelMatchesPaymentVariable && this.allowPaymentVariables)
            ) {
              option.msg = "";
            }
            this.$set(row, "msg", option.msg);
          }
        });
      };
    },

    groupWordRules(wordRules) {
      return _(_.filter(wordRules, rule => rule.variable_name.length))
        .groupBy("variable_name")
        .values()
        .value();
    },

    duplicateVariablesFound(groups) {
      return _.some(
        groups,
        group => !_.some(group, groupItem => groupItem.variable_id > -1)
      );
    },

    duplicateGroups(wordRules) {
      const groups = this.groupWordRules(wordRules);
      return !this.duplicateVariablesFound(groups)
        ? []
        : _.filter(
            groups,
            group => !_.some(group, groupItem => groupItem.variable_id > -1)
          );
    }
  },

  async created() {
    if (
      this.useExpressionBuilder &&
      _.isEmpty(this.complexVariables) &&
      !_.isEmpty(this.clickedNode)
    ) {
      await getComplexVariables(this.clickedNode.task_id)
        .then(({ data }) => {
          this.complexVariables = data.data;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: "initializeWordRules"
    },
    wordRules: {
      handler: function(wordRules) {
        // the below map method is not functional right now as no logic is being
        // tested to remove the variable conflict (ie, different
        // word rule mapped to same variable)
        _.map(this.duplicateGroups(wordRules), group => {
          const variable = _.find(this.variables, {
            variable_name: group[0].variable_name
          });
          if (!_.isEmpty(variable)) {
            group[0].variable_id = variable.variable_id;
          }
          return group;
        });
        this.$emit("input", _.cloneDeep(wordRules));
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";
@import "~@/styles/expression-builder.scss";
/*.goto-variable-configure {*/
/*  flex-direction: column;*/
/*}*/
/*.goto-variable-configure .el-form-item {*/
/*  margin-bottom: 0;*/
/*}*/

.extractWordsTable ::v-deep .el-table--medium td {
  padding: 15px 0 0 0;
}

.extractWordsTable ::v-deep .el-table--medium th {
  padding: 0;
}

.el-table {
  .el-form-item {
    padding-bottom: 15px;
    /*padding-top: 4px;*/
  }
}

.extract-words-column {
  display: flex;
  flex: 1;
}

.extractWordsTable ::v-deep .el-form-item__error {
  padding-top: 1px;
  font-size: 10px;
}

.extractWordsTable {
  ::v-deep .goto-variable-configure {
    flex: 1;
  }

  ::v-deep .no-red-highlight .el-input__inner,
  ::v-deep .no-red-highlight .el-textarea__inner,
  ::v-deep .empty-row .el-input__inner,
  ::v-deep .empty-row .el-textarea__inner,
  ::v-deep .non-empty-val .el-input__inner,
  ::v-deep .non-empty-val .el-textarea__inner {
    border-color: $--border-color-base !important;

    &:focus {
      border-color: $--color-text-regular !important;
    }
  }
}

/*.goto-variable-configure ::v-deep .el-form-item__content {*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  justify-content: space-between;*/
/*}*/

/*.goto-variable-configure ::v-deep .el-form-item__error {*/
/*  flex-grow: 1;*/
/*  flex-shrink: 0;*/
/*  align-self: center;*/
/*}*/
/*.extract-words ::v-deep .el-table__row {*/
/*  display: flex;*/
/*}*/

.extract-words ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.extractWordsTable ::v-deep .el-table::before {
  background-color: white !important;
}

.extractWordsTable ::v-deep .el-table__body-wrapper {
  margin-top: 10px;
}
</style>
