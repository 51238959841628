<template>
  <el-form
    label-position="top"
    :model="task"
    ref="taskForm"
    label-width="100px"
    :rules="rules"
    v-loading="isSubmitting"
    class="form-container"
  >
    <el-row type="flex" style="padding-top: 34px">
      <el-col :span="12" :offset="6">
        <page-header :title="getTitle"></page-header>
      </el-col>
    </el-row>

    <el-row
      style="margin-bottom: 15px"
      v-if="isTaskTypeDifferentThanTaskFilter && !confirmed"
    >
      <el-col class="info-task-type" :span="12" :offset="6">
        <div style="display: flex;flex-direction: column">
          <div class="warning-message">
            {{ getWarningMessage }}. <br />
            {{ __("Do you want to continue?") }}
          </div>

          <div style="text-align: right; margin-top: 10px;">
            <el-button @click="$emit('cancel')">{{ __("No") }}</el-button>
            <el-button @click="confirmed = true" type="primary">{{
              __("Yes")
            }}</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <div v-else>
      <el-row
        type="flex"
        style="display: flex; align-items: center; margin-bottom: 34px"
      >
        <el-col
          :span="12"
          :offset="6"
          style="display: flex;align-items: center"
        >
          <TaskImageLibrary
            :image-path-prop="task.task_image"
            @image-selected="handleImageSelected"
          ></TaskImageLibrary>
          <TemplateDetails
            :template="template"
            v-if="template"
            style="margin-left: 30px"
          ></TemplateDetails>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="12" :offset="6">
          <el-form-item
            class="form_label_top"
            :label="__('Task Name')"
            prop="task_name"
          >
            <el-input
              :value="task.task_name"
              @input="$set(task, 'task_name', $event)"
              :disabled="task.protected"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="12" :offset="6">
          <el-form-item class="form_label_top" :label="__('Tags')">
            <TagsInput
              :tags="task.tags"
              @tag-update="handleTagUpdate"
            ></TagsInput>
          </el-form-item>
        </el-col>
      </el-row>
      <!--      voice task form-->
      <transition name="el-zoom-in-top">
        <div v-if="task.task_type === 'voice'">
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item
                class="form_label_top"
                :label="__('Text to Speech Voice')"
                prop="voice_task.data.tts_voice_id"
              >
                <tts-dropdown-detail
                  :disabled="task.protected"
                  :default_tts_id="task.voice_task.data.tts_voice_id"
                  @tts-changed="handleTtsSelected"
                ></tts-dropdown-detail>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item
                class="form_label_top"
                :label="__('Beep Detection')"
              >
                <el-checkbox
                  :value="task.voice_task.data.beep_detection"
                  @input="$set(task.voice_task.data, 'beep_detection', $event)"
                  :disabled="task.protected || !can('AMD DETECTION')"
                ></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item
                class="form_label_top"
                :label="__('Machine Detection')"
              >
                <el-checkbox
                  :value="task.voice_task.data.machine_detection"
                  @input="
                    $set(task.voice_task.data, 'machine_detection', $event)
                  "
                  :disabled="task.protected || !can('AMD DETECTION')"
                ></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="6" :offset="6">
              <el-form-item
                class="form_label_top"
                :label="__('Operator Transfer')"
              >
                <el-checkbox
                  :value="task.voice_task.data.send_to_operator"
                  @input="
                    $set(task.voice_task.data, 'send_to_operator', $event)
                  "
                  :disabled="task.protected"
                ></el-checkbox>
              </el-form-item>
            </el-col>
            <transition name="el-zoom-in-top">
              <el-col :span="6" v-show="task.voice_task.data.send_to_operator">
                <el-form-item
                  :label="__('Operator Number')"
                  prop="voice_task.data.no_resource_operator_number"
                >
                  <el-input
                    :value="task.voice_task.data.no_resource_operator_number"
                    @input="
                      $set(
                        task.voice_task.data,
                        'no_resource_operator_number',
                        $event
                      )
                    "
                    placeholder="Operator transfer Phone No."
                    :disabled="task.protected"
                  ></el-input>
                </el-form-item>
              </el-col>
            </transition>
          </el-row>
        </div>
      </transition>
      <!--      workflow task form-->
      <transition name="el-zoom-in-top">
        <div v-if="task.task_type === 'workflow'">
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item class="form_label_top" :label="__('Status')">
                <div slot="label" class="form-label">
                  <div>{{ __("Active") }}</div>
                  <div>
                    <el-popover
                      placement="top-start"
                      width="180"
                      trigger="hover"
                      :content="__('disable to stop the workflow')"
                    >
                      <i slot="reference" class="el-icon-info icon"></i>
                    </el-popover>
                  </div>
                </div>
                <el-switch
                  :value="!!task.workflow_task.data.is_active"
                  @input="updateIsActiveForWorkflow"
                  :disabled="task.protected"
                ></el-switch>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item
                class="form_label_top"
                label="Passed Parameters"
                prop="passed_params"
              >
                <el-select
                  v-model="passedParams"
                  multiple
                  :placeholder="__('Select')"
                  :disabled="task.protected"
                  default-first-option
                >
                  <el-option
                    v-for="passed_param in passedParameterVariables"
                    :key="passed_param.variable_id"
                    :label="passed_param.variable_name"
                    :value="passed_param.variable_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item
                :label="__('Timezone')"
                prop="workflow_task.data.schedule_time_zone"
              >
                <timezone-dropdown
                  :default_timezone="task.workflow_task.data.schedule_time_zone"
                  @timezone-changed="handleTimezoneChange"
                  :disabled="task.protected"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <div v-if="workflowTimeZone">
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item class="form_label_top" :label="__('Schedule')">
                  <el-select
                    v-model="workflowScheduleType"
                    :placeholder="__('select schedule')"
                    default-first-option
                    :disabled="task.protected"
                  >
                    <el-option :label="__('Once')" value="Once"></el-option>
                    <el-option
                      :label="__('Repeatedly')"
                      value="Repeatedly"
                    ></el-option>
                    <el-option
                      :label="__('Always On')"
                      value="Always On"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-show="showStartAt" type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  class="form_label_top"
                  :label="__('Start At')"
                  prop="workflow_task.data.start_on"
                >
                  <div slot="label" class="form-label">
                    <div>{{ __("Start At") }}</div>
                    <div>
                      <el-popover
                        placement="top-start"
                        width="400"
                        trigger="hover"
                        :content="workflowStartTimePopover"
                      >
                        <i slot="reference" class="el-icon-info icon"></i>
                      </el-popover>
                    </div>
                  </div>
                  <el-date-picker
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :format="displayDateTimeSecondsFormat"
                    :placeholder="__('Pick a date and time')"
                    v-model="startsOn"
                    :pickerOptions="pickerOptions"
                    popper-class="workflow-start-time-picker"
                    :disabled="task.protected"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="schedule" v-show="isRepeatedSchedule" type="flex">
              <el-col :span="12" :offset="6">
                <schedule-repeat-edit
                  :schedule-item-repeat="workflowSchedule"
                  @update-schedule-repeat="updateSchedule"
                  :disabled="task.protected"
                ></schedule-repeat-edit>
              </el-col>
            </el-row>
            <el-row v-show="isAlwaysOnSchedule" type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  class="form_label_top"
                  :label="__('Repeat Frequency')"
                >
                  <div class="repeat-frequency">
                    <el-time-picker
                      class="time-picker"
                      value-format="HH:mm:00"
                      format="HH:mm"
                      :value="task.workflow_task.data.repeat_frequency"
                      @input="
                        $set(
                          task.workflow_task.data,
                          'repeat_frequency',
                          $event
                        )
                      "
                      :picker-options="{
                        selectableRange: '00:01:00 - 23:59:00'
                      }"
                      :placeholder="__('Select Frequency')"
                      :disabled="task.protected"
                    >
                    </el-time-picker>
                    <div class="readable-form">
                      {{
                        humanReadableRepeatFrequency(
                          task.workflow_task.data.repeat_frequency
                        )
                      }}
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
      </transition>
      <!--      messaging task form-->
      <transition name="el-zoom-in-top">
        <div v-if="taskTypeHasTimeout">
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item
                class="form_label_top"
                :label="__('Conversation Session Timeout (Minutes)')"
                :prop="sessionTimeoutPropForTaskType"
              >
                <el-input-number
                  v-model="taskSessionTimeoutModel"
                  :min="5"
                  :max="180"
                  :disabled="task.protected"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="12" :offset="6">
              <el-form-item
                class="form_label_top hide-system-logs"
                :label="__('Log Conversations')"
              >
                <el-checkbox
                  :value="task.is_system_log_shown"
                  @input="$set(task, 'is_system_log_shown', $event)"
                  :label="__('Log conversation messages in system logs')"
                ></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </transition>

      <el-row type="flex">
        <el-col :span="12" :offset="6">
          <el-form-item
            class="form_label_top node-logging-profile"
            :label="__('Node Logging profile')"
            prop="logging_profiles"
          >
            <!--              <el-input v-model="task.task_name"></el-input>-->
            <div v-if="!hasNodeLoggingProfiles" class="info">
              <div class="warning-exclamation" />
              <div style="margin-left: 5px; display: flex">
                <div>
                  {{ __("You do not have any node logging profiles set up") }}
                </div>
              </div>
            </div>
            <el-select
              v-else
              v-model="getNodeLogProfile"
              :placeholder="__('Select')"
              default-first-option
              clearable
              multiple
              :disabled="task.protected"
            >
              <el-option-group
                v-for="group in nodeLoggingProfileOptions"
                :key="group.label"
                :label="group.label"
              >
                <el-option
                  v-for="item in group.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex" v-show="!allowDebugTaskLogs">
        <el-col :span="12" :offset="6">
          <el-form-item
            class="form_label_top enable-debug-logs"
            :label="__('Enable Debug Logs')"
          >
            <el-checkbox
              :value="task.debug_logs_enabled"
              @input="$set(task, 'debug_logs_enabled', $event)"
              :label="
                __('Debug logs will be automatically turned off after 2 hours')
              "
            ></el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item>
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-button
              class="submitBtn"
              @click="submitForm"
              :loading="isSubmitting"
              v-if="!configVariables && !configureCreateOptions"
              :disabled="!can('build.tasks.write')"
              >{{ saveLan }}
            </el-button>
            <el-button
              class="submitBtn"
              @click="handleConfigureVariables"
              v-else-if="configVariables"
              >{{ __("Configure Variables") }}
            </el-button>
            <el-button
              class="submitBtn"
              @click="handleConfigureImportOptions"
              v-else-if="configureCreateOptions"
              >{{ __("Configure Import Options") }}
            </el-button>
            <el-button class="cancelBtn" @click="$emit('cancel')"
              >{{ __("Cancel") }}
            </el-button>
          </el-col>
        </el-row>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";
import { EventBus } from "@/EventBus";
import TimezoneDropdown from "@/components/TimezoneDropdown";
import { createTimeInTz, currentTimeInTz, stringTime } from "@/utils/time";
import ScheduleRepeatEdit from "@/components/ScheduleRepeatEdit";
import TemplateDetails from "@/views/build/tasks/create-task/TemplateDetails";
import PageHeader from "@/components/PageHeader";
import TaskImageLibrary from "@/views/build/tasks/create-task/components/TaskImageLibrary";
import TagsInput from "@/views/build/tasks/create-task/components/TagsInput";
import { validateTask } from "@/api/tasks";
import TtsDropdownDetail from "@/views/build/content/prompts/components/TtsDropdownDetail";

export default {
  name: "createOrEditForm",
  components: {
    PageHeader,
    TimezoneDropdown,
    ScheduleRepeatEdit,
    TemplateDetails,
    TaskImageLibrary,
    TagsInput,
    TtsDropdownDetail
  },
  props: {
    languages: {
      type: Object,
      required: true
    },
    resetInputFields: {
      type: Boolean,
      required: false,
      default: false
    },
    filePath: {
      type: String,
      required: false,
      default: ""
    },
    configVariables: {
      type: Boolean,
      required: false,
      default: false
    },
    configureCreateOptions: {
      type: Boolean,
      required: false,
      default: false
    },
    createType: {
      type: String,
      required: true
    },
    task: {
      type: Object,
      required: true
    },
    taskInfo: {
      type: Object,
      required: true
    },
    id: {
      type: Number,
      required: false,
      default: -1
    },
    template: {
      type: Object,
      required: false,
      default: () => ({
        template_id: -1,
        template_name: "",
        template_description: "",
        import_count: 0,
        publisher: "",
        tags: "",
        image_path: "task_image_placeholder.svg"
      })
    }
  },
  data() {
    const validateWorkflowStartTime = (rule, value, callback) => {
      let selectedTimeInTz = createTimeInTz(
        this.task.workflow_task.data.start_on,
        this.task.workflow_task.data.schedule_time_zone
      );
      if (
        !this.isAlwaysOnSchedule &&
        (this.taskInfo.workflow_task.data.start_on !==
          this.task.workflow_task.data.start_on ||
          this.taskInfo.workflow_task.data.schedule_time_zone !==
            this.task.workflow_task.data.schedule_time_zone) &&
        this.setWorkflowStartTime.isAfter(selectedTimeInTz, "seconds")
      ) {
        callback(
          `Date Time has already passed in selected timezone, ${this.workflowTimeZone}`
        );
      } else {
        callback();
      }
    };

    let validateTaskName = async (rule, value, callback) => {
      let hasError = false;
      await validateTask(value)
        .then(res => {
          if (res.data.found && value !== this.taskInfo.task_name) {
            hasError = true;
          }
        })
        .catch(() => {
          hasError = false;
        });

      if (hasError) {
        callback(__("Task name already exists"));
      } else {
        callback();
      }
    };

    const validatePhoneNumber = (rule, value, callback) => {
      if (this.task.voice_task.data.send_to_operator) {
        if (
          !/^\+?\d*$/.test(
            this.task.voice_task.data.no_resource_operator_number
          )
        ) {
          callback(__("phone number is invalid"));
        } else if (!this.task.voice_task.data.no_resource_operator_number) {
          callback(__("Cannot be empty when operator transfer is enabled"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      setWorkflowStartTime: null,
      rules: {
        task_name: [
          {
            required: true,
            message: __("Task name cannot be empty")
          },
          {
            max: 300,
            message: __("Length should be no more than 300 characters"),
            trigger: "blur"
          },
          { validator: validateTaskName, trigger: "blur" }
        ],
        voice_task: {
          data: {
            tts_voice_id: [
              {
                required: true,
                message: __("Text to Speech Voice is required"),
                trigger: "change"
              }
            ],
            no_resource_operator_number: [
              {
                required: false,
                trigger: "blur",
                validator: validatePhoneNumber
              }
            ]
          }
        },
        messaging_task: {
          data: {
            msg_session_timeout: [
              {
                required: true,
                message: __("Enter a conversation session time out"),
                trigger: "blur"
              }
            ]
          }
        },
        chatbot_task: {
          data: {
            chatbot_session_timeout: [
              {
                required: true,
                message: __("Enter a conversation session time out"),
                trigger: "blur"
              }
            ]
          }
        },
        workflow_task: {
          data: {
            schedule_time_zone: [
              {
                required: true,
                message: __("Timezone cannot be empty"),
                trigger: "blur"
              }
            ],
            start_on: [
              {
                required: true,
                message: __("Start At time cannot be empty"),
                trigger: "blur"
              },
              {
                validator: validateWorkflowStartTime,
                trigger: "blur"
              }
            ]
          }
        },
        task_type: [
          {
            required: true,
            message: __("Select a task type"),
            trigger: "change"
          }
        ]
      },
      create_task_from: "blank",
      isSubmitting: false,
      confirmed: false,
      allowDebugTaskLogs: true
    };
  },

  methods: {
    ...mapActions("tasks", {
      createTask: "createTask",
      updateTask: "updateTask"
    }),

    async getAllowDebugTaskLogs() {
      this.allowDebugTaskLogs = await this.showFeature(
        this.$getConst("ALLOW_DEBUG_SYSTEM_TASK_LOGS")
      );
    },

    async handleTtsSelected(tts_voice) {
      // this.task.voice_task.data.tts_voice_id = tts_voice;
      this.$set(this.task.voice_task.data, "tts_voice_id", tts_voice);
    },

    handleImageSelected(imagePath) {
      // this.task.task_image = imagePath;
      this.$set(this.task, "task_image", imagePath);
    },
    capitalize(t) {
      return _.capitalize(t);
    },

    handleTagUpdate(tags) {
      // this.task.tags = tags;
      this.$set(this.task, "tags", tags);
    },
    humanReadableRepeatFrequency(frequency) {
      let parts = frequency.split(":");
      if (!+parts[0]) {
        return `Every ${+parts[1]} minute${+parts[1] === 1 ? "" : "s"}`;
      }

      if (+parts[0] && !+parts[1]) {
        return `Every ${+parts[0]} hour${+parts[0] === 1 ? "" : "s"}`;
      }

      return `Every ${+parts[0]} hour and ${+parts[1]} minute${
        +parts[1] === 1 ? "" : "s"
      }`;
    },

    updateSchedule(schedule) {
      this.workflowSchedule = schedule;
    },

    updateIsActiveForWorkflow(val) {
      // this.task.workflow_task.data.is_active = val;
      this.$set(this.task.workflow_task.data, "is_active", val);
    },

    handleTimezoneChange(tz) {
      // this.task.workflow_task.data.schedule_time_zone = tz;
      this.$set(this.task.workflow_task.data, "schedule_time_zone", tz);
    },

    handleConfigureVariables() {
      this.$refs.taskForm.validate(valid => {
        if (valid) {
          this.$emit("config-variables");
        } else {
          return false;
        }
      });
    },
    handleConfigureImportOptions() {
      this.$refs.taskForm.validate(valid => {
        if (valid) {
          this.$emit("config-create-options");
        } else {
          return false;
        }
      });
    },
    submitForm() {
      this.$refs.taskForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          const process = this.id === -1 ? this.createTask : this.updateTask;
          let task = _.cloneDeep(this.task);
          task.ac_id = this.selectedAccountId;
          task.template_id = this.template ? this.template.template_id : -1;
          task.create_type = this.createType;
          task.file_path = this.filePath;
          task.config_variables = [];
          process({ task })
            .then(data => {
              this.isSubmitting = false;
              this.id === -1
                ? this.$notify({
                    type: "success",
                    message: __("Task added Successfully")
                  })
                : this.$notify({
                    type: "success",
                    message: __("Task updated successfully")
                  });
              if (this.id === -1) {
                this.$emit("created", data.data.task_id);
              } else {
                this.$emit("cancel", data.data);
                EventBus.$emit("list-changed", data.data);
              }
            })
            .catch(err => {
              console.log(err);
              this.isSubmitting = false;
              this.$notify({
                type: "error",
                message: err.message
              });
            });
        } else {
          console.log("error submit");
          this.isSubmitting = false;
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),
    ...mapState("tasks", {
      taskFilter: state => state.taskFilter
    }),
    ...mapGetters("variables", {
      passedParameterVariables: "passedParameterVariables",
      getPassedParameterNameFromId: "getPassedParameterNameFromId"
    }),
    ...mapGetters("nodeLoggingProfiles", {
      nodeLoggingProfileOptions: "nodeLoggingProfileOptions",
      hasNodeLoggingProfiles: "hasNodeLoggingProfiles"
    }),
    ...mapGetters("app", {
      displayDateTimeSecondsFormat: "displayDateTimeSecondsFormat"
    }),

    isTaskTypeDifferentThanTaskFilter() {
      if (this.createType === "blank") {
        return false;
      }
      return this.task.task_type !== this.taskFilter;
    },
    getWarningMessage() {
      let msg = "";
      if (this.createType === "import") {
        msg = __("The imported task is of ':taskType' type.", {
          taskType: this.capitalize(this.task.task_type)
        });
      } else if (this.createType === "template") {
        msg = __("The task template is of ':taskType' type.", {
          taskType: this.capitalize(this.task.task_type)
        });
      }
      return (
        msg +
        __(" The new task created will be of type ':taskType'", {
          taskType: this.capitalize(this.task.task_type)
        })
      );
    },

    profileValues() {
      let values = [];

      _.each(this.nodeLoggingProfileOptions, profileType => {
        _.each(profileType.options, profile => {
          values.push(profile.value);
        });
      });

      return values;
    },

    getNodeLogProfile: {
      get() {
        if (
          _.has(this.task, "logging_profiles.data") &&
          !_.isEmpty(this.task.logging_profiles.data)
        ) {
          return _.filter(
            _.map(this.task.logging_profiles.data, profile => {
              let value =
                _.get(profile, "model_name") + "-" + _.get(profile, "model_id");

              if (
                _.some(
                  this.profileValues,
                  profileValue => profileValue === value
                )
              ) {
                return value;
              }
              return null;
            })
          );
        } else {
          return [];
        }
      },
      set(val) {
        if (_.isEmpty(val)) {
          this.$delete(this.task, "logging_profiles");
        } else {
          // easiest way to achieve this, any change in selected options
          // we will re-initialize the list
          this.$set(this.task, "logging_profiles", {
            data: []
          });

          _.map(val, profile => {
            let items = profile.split("-");
            let profiles = _.cloneDeep(this.task.logging_profiles.data);
            profiles.push({
              model_id: +items[1],
              model_name: items[0],
              node_types: "all"
            });
            this.$set(this.task.logging_profiles, "data", profiles);
            // this.task.logging_profiles.data.push({
            //   model_id: +items[1],
            //   model_name: items[0],
            //   node_types: "all"
            // });
          });
        }
      }
    },
    getTitle() {
      if (this.createType === "import") {
        return __("Import from CTF");
      }
      return this.id === -1
        ? !this.template
          ? __("Add Task")
          : __("Create from :templateName", {
              templateName: this.template.template_name
            })
        : __("Edit Task");
    },
    passedParams: {
      get() {
        return _.map(
          this.task.workflow_task.data.passed_params.data,
          "variable_id"
        );
      },
      set(variableIds) {
        this.$set(
          this.task.workflow_task.data.passed_params,
          "data",
          _.map(variableIds, variableId => ({
            variable_id: variableId
          }))
        );
        // this.task.workflow_task.data.passed_params.data = _.map(
        //   variableIds,
        //   variableId => ({
        //     variable_id: variableId
        //   })
        // );
      }
    },

    pickerOptions() {
      let self = this;
      return {
        shortcuts: [
          {
            text: __("current time at :timeZone", {
              timeZone: self.workflowTimeZone
            }),
            onClick(picker) {
              picker.$emit(
                "pick",
                stringTime(currentTimeInTz(self.workflowTimeZone))
              );
            }
          }
        ]
      };
    },

    workflowTimeZone() {
      return this.task.task_type === "workflow"
        ? this.task.workflow_task.data.schedule_time_zone
        : "";
    },

    workflowScheduleType: {
      get() {
        return this.task.task_type === "workflow"
          ? this.task.workflow_task.data.workflow_schedule
          : "";
      },

      set(val) {
        if (this.task.task_type === "workflow") {
          this.$set(this.task.workflow_task.data, "workflow_schedule", val);
        }
      }
    },

    startsOn: {
      get() {
        if (!_.has(this.task, "workflow_task.data.start_on")) {
          return "";
        }
        return this.task.workflow_task.data.start_on;
      },
      set(val) {
        this.$set(this.task.workflow_task.data, "start_on", val);
      }
    },

    workflowSchedule: {
      get() {
        return this.task.task_type === "workflow"
          ? this.task.workflow_task.data.schedule.data
          : {};
      },

      set(val) {
        if (this.task.task_type === "workflow") {
          this.$set(this.task.workflow_task.data.schedule, "data", val);
        }
      }
    },

    isAlwaysOnSchedule() {
      return this.workflowScheduleType === "Always On";
    },

    isRepeatedSchedule() {
      return this.workflowScheduleType === "Repeatedly";
    },

    isOnceSchdeule() {
      return this.workflowScheduleType === "Once";
    },

    showStartAt() {
      return this.isOnceSchdeule || this.isRepeatedSchedule;
    },

    getTTSVoiceName() {
      let language = _.find(_.flatMap(this.languages), language => {
        return (
          language.tts_voice_id === this.taskInfo.voice_task.data.tts_voice_id
        );
      });
      return language ? language.voice_name : "";
    },

    taskTypeHasTimeout() {
      const taskType = this.task.task_type;
      return (
        taskType === "messaging" ||
        taskType === "whatsapp" ||
        taskType === "chatbot"
      );
    },
    sessionTimeoutPropForTaskType() {
      if (this.task.task_type === "chatbot")
        return "chatbot_task.data.chatbot_session_timeout";

      return "messaging_task.data.msg_session_timeout";
    },
    taskSessionTimeoutModel: {
      get() {
        if (this.task.task_type === "chatbot") {
          return this.task.chatbot_task.data.chatbot_session_timeout;
        } else {
          return this.task.messaging_task.data.msg_session_timeout;
        }
      },
      set(val) {
        if (this.task.task_type === "chatbot") {
          this.$set(
            this.task.chatbot_task.data,
            "chatbot_session_timeout",
            val
          );
        } else {
          this.$set(this.task.messaging_task.data, "msg_session_timeout", val);
        }
      }
    },
    saveLan() {
      return this.id !== -1 ? __("Save Changes") : __("Go To Callflow");
    },

    /**
     * Message for the workflow start time popover
     * @returns {String} Translated message for the workflow start time popover content
     */
    workflowStartTimePopover() {
      // eslint-disable-next-line
      return __("time to start executing the workflow in the selected timezone");
    }
  },
  watch: {
    workflowScheduleType: {
      handler: function() {
        if (!this.showStartAt && _.isEmpty(this.startsOn)) {
          this.startsOn = stringTime(currentTimeInTz(this.workflowTimeZone));
          this.$refs.taskForm &&
            this.$refs.taskForm.validateField("workflow_task.data.start_on");
        }
      }
    },
    startsOn: {
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          if (_.has(this.task, "workflow_task.data.schedule.data.starts_on")) {
            this.$set(
              this.task.workflow_task.data.schedule.data,
              "starts_on",
              val
            );
          }
        });
      }
    },
    workflowTimeZone: {
      immediate: true,
      handler(val) {
        if (val && this.task.task_type === "workflow") {
          let now = currentTimeInTz(val);
          this.setWorkflowStartTime = now;
          if (
            this.id === -1 ||
            _.isEmpty(this.startsOn) ||
            (this.id !== -1 && this.isAlwaysOnSchedule)
          ) {
            this.startsOn = stringTime(now);
          }
          this.$refs.taskForm &&
            this.$refs.taskForm.validateField("workflow_task.data.start_on");
        }
      }
    },
    resetInputFields(val) {
      if (val) {
        this.$refs["taskForm"].resetFields();
      }
    },
    operations: {
      immediate: true,
      async handler() {
        await this.getAllowDebugTaskLogs();
      }
    }
  }
};
</script>

<style lang="scss">
.node-logging-profile {
  .info {
    color: #696969;
    display: flex;
    align-items: center;
    line-height: 1.5;
    margin-top: 5px;
    font-size: 0.8125rem;
    letter-spacing: 0.005rem;
  }
}

.workflow-start-time-picker {
  &.el-date-picker.has-sidebar.has-time {
    width: 500px;
  }

  .el-picker-panel *[slot="sidebar"],
  .el-picker-panel__sidebar {
    width: 176px;

    .el-picker-panel__shortcut {
      word-break: break-word;
    }
  }

  .el-picker-panel *[slot="sidebar"] + .el-picker-panel__body,
  .el-picker-panel__sidebar + .el-picker-panel__body {
    margin-left: 176px;
  }
}
</style>
<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/node_common.scss";

::v-deep .page-header {
  padding: 0 !important;
}

.form-container {
  .repeat-frequency {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .time-picker {
      width: 50%;
    }

    .readable-form {
      padding-left: 10px;
    }
  }

  .schedule {
    margin-bottom: 36px;
  }
}

.info-task-type {
  display: flex;
  align-items: center;
  line-height: 1.5;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 15px;

  .warning-message {
    word-break: break-word;
  }
}
</style>
