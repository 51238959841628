<template>
  <el-collapse-item :name="session.session_id">
    <!-- Title for the collapse item -->
    <template v-slot:title>
      <div class="session-header">
        <span class="session-id">
          UUID: {{ formatSessionId(session.session_id) }}
        </span>
      </div>
    </template>

    <div class="call-detail" ref="callDetail" @scroll="handleScroll">
      <el-skeleton :loading="isCallLoading" animated>
        <template #template>
          <!-- Skeleton template for loading state -->
          <el-skeleton-item variant="text" style="height: 20px;" />
          <el-skeleton-item variant="text" style="height: 20px;" />
        </template>
        <template #default>
          <!-- Actual content when not loading -->
          <div v-for="(log, index) in logs" :key="index">
            <live-call-transcription
              :log="log"
              :is-node-logs-enabled="isNodeLogsEnabled"
              @clicked="$emit('log-clicked', log)"
            />
          </div>
          <span v-if="isCurrent && isAtBottom" class="live-indicator"
            >LIVE</span
          >
        </template>
      </el-skeleton>
    </div>
  </el-collapse-item>
</template>

<script>
import LiveCallTranscription from "./LiveCallTranscription.vue";
import { debounce } from "throttle-debounce";

export default {
  name: "ConversationItem",
  components: {
    LiveCallTranscription
  },
  props: {
    session: {
      type: Object,
      required: true
    },
    logs: {
      type: Array,
      default: () => []
    },
    isCallLoading: {
      type: Boolean,
      default: false
    },
    isCurrent: {
      type: Boolean,
      default: false
    },
    isNodeLogsEnabled: {
      type: Boolean,
      default: false
    },
    isPaused: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isAtBottom: true
    };
  },
  methods: {
    formatSessionId(sessionId) {
      if (sessionId && sessionId.length > 25) {
        return `...${sessionId.slice(-25)}`;
      }
      return sessionId || "Unknown ID";
    },
    executeScrollLogic() {
      const callDetail = this.$refs.callDetail;
      const threshold = 50;
      const position = callDetail.scrollTop + callDetail.clientHeight;
      const height = callDetail.scrollHeight;

      if (height - position <= threshold) {
        this.isAtBottom = true;
        this.$emit("resume-live");
      } else {
        this.isAtBottom = false;
        this.$emit("pause-live");
      }
    },
    handleScroll() {
      this.debouncedHandleScroll();
    },
    scrollToBottom() {
      const callDetail = this.$refs.callDetail;
      callDetail.scrollTop = callDetail.scrollHeight;
    }
  },
  created() {
    this.debouncedHandleScroll = debounce(500, this.executeScrollLogic);
  },
  watch: {
    logs() {
      if (this.isCurrent && !this.isPaused) {
        this.scrollToBottom();
      }
    }
  },
  mounted() {
    this.scrollToBottom();
  }
};
</script>

<style scoped>
.session-id {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.session-header {
  display: flex; /* Flex for better alignment */
  justify-content: space-between;
  align-items: center;
}

.live-indicator {
  float: right;
  font-size: 14px;
  font-weight: bold;
  color: red;
  align-items: center;
  padding-right: 8px;

  &::before {
    content: "●";
    color: red;
    font-size: 12px;
    margin-right: 5px;
  }
}

.call-detail {
  margin-top: 10px;
  min-height: 60px;
  max-height: 400px; /* Adjusted to fit within the max height constraints */
  overflow-y: auto;
  position: relative;
}

.buffer-indicator {
  position: relative;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 0, 0.8);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  color: #333;
}

/* Styling for skeleton loading and empty states if needed */
.el-skeleton__text {
  width: 90%;
  height: 20px;
  margin: 10px;
}
</style>
