import { render, staticRenderFns } from "./DataStoresExportConfig.vue?vue&type=template&id=3f8ce789&scoped=true"
import script from "./DataStoresExportConfig.vue?vue&type=script&lang=js"
export * from "./DataStoresExportConfig.vue?vue&type=script&lang=js"
import style0 from "./DataStoresExportConfig.vue?vue&type=style&index=0&id=3f8ce789&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f8ce789",
  null
  
)

export default component.exports