<template>
  <div>
    <el-row class="card-style-container">
      <el-radio-group
        v-model="selectedCardLayout"
        class="card-style-radio-group"
      >
        <el-col :span="3" v-for="style in cardLayouts" :key="style.label">
          <el-tooltip
            placement="top"
            effect="studio-theme"
            :content="style.description"
          >
            <div class="radio-item">
              <component :is="style.icon" />
              <el-radio :label="style.label" class="card-style-radio">
                <!--make el-radio name empty-->
                <span />
              </el-radio>
              <p>{{ style.name }}</p>
            </div>
          </el-tooltip>
        </el-col>
      </el-radio-group>
    </el-row>
  </div>
</template>

<script>
import DefaultCard from "@/views/build/callflow/components/node-type-forms/messaging/carousel/component/buttonStylePreview/defaultCard.vue";
import HorizontalCard from "@/views/build/callflow/components/node-type-forms/messaging/carousel/component/buttonStylePreview/horizontalCard.vue";
import StackedCard from "@/views/build/callflow/components/node-type-forms/messaging/carousel/component/buttonStylePreview/stackedCard.vue";

const CARD_LAYOUTS = {
  DEFAULT: "Default",
  HORIZONTAL: "Horizontal",
  STACKED: "Stacked"
};

export default {
  name: "cardLayoutConfig",
  props: {
    value: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      cardLayouts: [
        {
          label: CARD_LAYOUTS.DEFAULT,
          name: __("Default"),
          icon: DefaultCard,
          description: __("The default arrangement style for cards")
        },
        {
          label: CARD_LAYOUTS.HORIZONTAL,
          name: __("Horizontal"),
          icon: HorizontalCard,
          description: __("Text, images, and buttons arranged side by side")
        },
        {
          label: CARD_LAYOUTS.STACKED,
          name: __("Stacked"),
          icon: StackedCard,
          description: __("Text, images, and buttons stacked vertically")
        }
      ]
    };
  },
  computed: {
    selectedCardLayout: {
      get() {
        return this.value;
      },

      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>

<style scoped>
.card-style-container {
  margin-top: 30px;
}

.card-style-radio-group {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.radio-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 20px;
}

.radio-item p {
  font-size: 1rem;
  margin: 10px 0 10px 0;
}

.card-style-radio {
  margin: 10px 0 0 0;
}

.card-style-radio ::v-deep .el-radio__label {
  padding: 0 !important;
}
</style>
