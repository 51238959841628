<template>
  <div class="content-component" @click="handleClearSelection">
    <el-button
      :disabled="!can('content.' + additionalValidateRoute + '.write')"
      class="createBtn"
      @click.stop="createContentItem"
      >{{ __("Add File") }}
    </el-button>
    <div style="width: 100%">
      <el-main style="margin-top: 136px;">
        <el-form class="form-container">
          <PaginationToolbar
            :sort-by-options="sortByOptions"
            :content-api="contentAPI"
            :show-content-view-switcher="true"
            :show-only="showOnly"
          ></PaginationToolbar>
        </el-form>
        <el-scrollbar v-loading="isLoading" :native="true">
          <div class="contentCardsContainer">
            <el-row
              v-if="files.length"
              v-show="can('content.' + additionalValidateRoute + '.read')"
              :class="contentViewClasses"
            >
              <content-list-item
                :content-view-type="contentViewType"
                v-for="(file, index) in files"
                :item-index="index"
                :key="'_content_file_' + index"
                :draggable-data="{
                  content_id: selectedItems.length
                    ? selectedIds
                    : [file[primaryKey]],
                  content_type: contentType
                }"
                :allow-multi-select="canMultiSelect"
                :card-class="{ selected: selectedItemIndex === index }"
                :icon="fileIcon(file.file_type)"
                :card-text="file.file_name"
                @delete="handleDelete(file, 'AttachmentFile', file.file_id)"
                :content-list-item="file"
                :fields-to-display="displayFields"
                @select="handleSelect(index, file)"
                @edit="handleEdit(index, file)"
                @checked-content="handleSelectMultiple($event)(file)"
                @toggle-select-all="handleToggleSelectAll(files, ...arguments)"
                :is-all-selected="isAllSelected"
                :total-items-selected="selectedItems.length"
              ></content-list-item>
            </el-row>
            <el-row
              v-else-if="!isLoading"
              style="padding-top: 100px;"
              type="flex"
              justify="center"
            >
              {{ __("No Files") }}
            </el-row>
          </div>
        </el-scrollbar>
      </el-main>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import ContentListItem from "@/views/build/content/mixins/ContentListItem";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import _ from "lodash";

export default {
  name: "FilesList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    ContentListItem
  },
  data() {
    return {
      additionalValidateRoute: "files",
      sortByColumns: ["file_name", "file_id"],
      isContent: true,
      viewMode: "allow_read",
      displayFields: [
        {
          field_name: "file_name",
          display_name: __("Name")
        },
        {
          field_name: "file_type",
          display_name: __("File Type")
        }
      ]
    };
  },
  props: {
    canMultiSelect: {
      required: false,
      type: Boolean,
      default: true
    },
    showOnly: {
      required: false,
      type: String,
      default: () => "all"
    }
  },
  computed: {
    ...mapState("files", {
      files: state => state.files,
      isLoading: state => state.isLoading
    }),

    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),

    selectedIds() {
      return _.map(this.selectedItems, this.primaryKey);
    }
  },

  methods: {
    ...mapActions("files", {
      contentAPI: "getFiles"
    }),

    fileIcon(fileType) {
      if (fileType.includes("audio")) {
        return "icon-microphone.svg";
      } else if (fileType.includes("pdf")) {
        return "icon-file-pdf.svg";
      } else if (fileType.includes("txt")) {
        return "icon-file-txt.svg";
      } else if (fileType.includes("json")) {
        return "icon-file-json.svg";
      } else if (fileType.includes("jpeg")) {
        return "icon-file-jpg.svg";
      } else {
        return "icon-file.svg";
      }
    },

    fileBorder(fileType) {
      if (fileType.includes("audio")) {
        return "teal";
      } else if (fileType.includes("pdf")) {
        return "blue";
      } else if (fileType.includes("txt")) {
        return "blue";
      } else {
        return "red";
      }
    }
  }

  /*,
  watch: {
    selectedAccountId: "initializeContents"
  }*/
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-card.scss";
@import "~@/styles/pagination-toolbar.scss";
</style>
