<template>
  <div>
    <el-form class="variable-setter">
      <el-table fit :data="variables" :show-header="false">
        <el-table-column prop="variable">
          <template slot-scope="scope">
            <div class="variable-setter-cell-item">
              <div class="super-text">{{ title.toUpperCase() }}</div>
              <div class="variable">{{ getVariable(scope.row.variable) }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="value">
          <template slot-scope="scope">
            <div class="variable-setter-cell-item">
              <div class="super-text">{{ __("TEST VALUE") }}</div>
              <el-input v-model="scope.row.value" class="testValue"></el-input>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    value: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: "variable"
    },
    customItemsNameMap: {
      type: Object,
      required: false,
      default: () => ({})
    },
    customItemNamePrefix: {
      type: String,
      required: false,
      default: "Expr"
    }
  },
  data() {
    return {
      variables: []
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function() {
        this.variables = _.cloneDeep(this.value);
      }
    },
    variables: {
      deep: true,
      handler: function(value) {
        return !_.isEqual(value, this.value)
          ? this.$emit("input", _.cloneDeep(this.variables))
          : null;
      }
    }
  },
  methods: {
    getVariable(variableName) {
      let regex = new RegExp(
        `^` + _.escapeRegExp(this.customItemNamePrefix) + `\\d+$`
      );
      if (!regex.test(variableName)) {
        return "{{ " + variableName + " }}";
      } else {
        let index = variableName.replace(this.customItemNamePrefix, "");
        return "{{ " + (this.customItemsNameMap[index] || variableName) + " }}";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.variable-setter {
  margin-bottom: 15px !important;

  .variable-setter-cell-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;
    .super-text {
      word-break: break-word;
    }
  }
}
</style>
