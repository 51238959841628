<template>
  <div>
    <el-row :gutter="5" type="flex">
      <el-col :span="8" v-if="!isRawResponse">
        <span class="inner-title"
          >{{ __("Assign path to") }}
          {{
            treatAsAudioVariablesOption
              ? "audio"
              : treatAsSecureVariablesOption
              ? "secure"
              : ""
          }}
          {{ __("variable") }}</span
        >
      </el-col>
      <el-col :span="8" v-else>
        <span class="inner-title"
          >{{ __("Assign raw data to variable") }}
        </span>
      </el-col>

      <el-col
        v-if="!treatAsAudioVariablesOption && !treatAsSecureVariablesOption"
        :span="12"
        v-show="!isRawResponse"
      >
        <span class="inner-title">{{ __("Default Value (optional)") }}</span>
      </el-col>
    </el-row>
    <el-row :gutter="5" type="flex">
      <el-col :span="!isRawResponse ? 8 : 24">
        <create-or-select
          :items="variableOptions"
          label="variable_name"
          value="variable_id"
          :current_select="currentSelection"
          :placeholder="__('Variable Name')"
          :new-item-message="newVariableMessage"
          @change="handleChange"
        />
      </el-col>
      <el-col :span="12" v-show="!isRawResponse">
        <input-variable-popper
          v-if="!treatAsAudioVariablesOption && !treatAsSecureVariablesOption"
          v-model="jsonPathConfigurer.default_value"
          :is-text-area="false"
          force-reinitialize
          :placeholder="__('Default Value')"
          :include-secure-variables="false"
        ></input-variable-popper>
      </el-col>

      <el-col :span="4" v-show="!isRawResponse">
        <el-button
          style="width:100%"
          type="primary"
          @click="handleJsonPathConfigurerClick"
          :disabled="!isJsonPathConfigurationComplete || disableSubmit"
          >{{ __("Assign") }}
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import CreateOrSelect from "./CreateOrSelect";
import { mapGetters } from "vuex";
import _ from "lodash";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";

const jsonPathConfigurer = {
  rule_value: "",
  variable_name: "",
  variable_id: -1,
  default_value: "",
  array_variable: false,
  variable_type: "single_value"
};

export default {
  components: {
    CreateOrSelect,
    InputVariablePopper
  },
  props: {
    disableSubmit: {
      required: false,
      type: Boolean,
      default: false
    },
    isResultObject: {
      required: false,
      type: Boolean,
      default: false
    },
    ruleValue: {
      required: true,
      type: String
    },
    resetRuleWhen: {
      required: true,
      type: Boolean
    },
    audioCols: {
      required: false,
      type: Array,
      default: () => []
    },
    secureCols: {
      required: false,
      type: Array,
      default: () => []
    },
    parts: {
      required: false,
      type: Array,
      default: () => []
    },
    isRawResponse: {
      required: false,
      type: Boolean,
      default: false
    },
    currentRawResponseVariableId: {
      required: false,
      type: Number,
      default: null
    },
    assignNewVariable: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      jsonPathConfigurer: _.cloneDeep(jsonPathConfigurer)
    };
  },
  computed: {
    ...mapGetters("variables", {
      singleValuedAndSecureVariables: "singleValuedAndSecureVariables",
      secureVariables: "secureVariables",
      audioVariables: "audioVariables"
    }),

    treatAsAudioVariablesOption() {
      return this.isTargetPathAnAudio && !this.isResultObject;
    },

    treatAsSecureVariablesOption() {
      return this.isTargetPathSecureVariable && !this.isResultObject;
    },

    variableOptions() {
      return this.treatAsAudioVariablesOption
        ? this.audioVariables
        : this.treatAsSecureVariablesOption
        ? this.secureVariables
        : this.singleValuedAndSecureVariables;
    },

    newVariableMessage() {
      return this.isTargetPathAnAudio
        ? __("new audio variable")
        : this.isTargetPathSecureVariable
        ? __("new secure variable")
        : __("new variable");
    },

    currentSelection() {
      const { variable_id, variable_name } = this.jsonPathConfigurer;
      return variable_id === -1 ? variable_name : variable_id;
    },

    isJsonPathConfigurationComplete() {
      return (
        this.jsonPathConfigurer.rule_value.length &&
        this.jsonPathConfigurer.variable_name.length &&
        !_.some(
          this.variableRules,
          rule =>
            rule.variable_name === this.jsonPathConfigurer.variable_name &&
            this.jsonPathConfigurer.variable_id === -1
        )
      );
    },

    tailOfJsonPath() {
      return _.reverse([...this.parts])[1] || "";
    },

    isTargetPathAnAudio() {
      return _.includes(this.audioCols, this.tailOfJsonPath);
    },

    isTargetPathSecureVariable() {
      return _.includes(this.secureCols, this.tailOfJsonPath);
    }
  },

  methods: {
    handleChange(option) {
      this.$set(this.jsonPathConfigurer, "variable_id", option.value);
      this.$set(this.jsonPathConfigurer, "variable_name", option.label);
      this.$set(this.jsonPathConfigurer, "msg", option.msg);
      if (this.isRawResponse) {
        this.$set(this.jsonPathConfigurer, "rule_value", "raw_response");
        let rule = {
          ...this.jsonPathConfigurer,
          array_variable: false
        };
        this.$emit("finish", rule);
      }
    },

    addNewVariableRule(var_name) {
      this.$set(this.jsonPathConfigurer, "variable_name", var_name);
      this.handleJsonPathConfigurerClick();
    },

    resetJSONPathConfigurer() {
      this.jsonPathConfigurer = _.cloneDeep(jsonPathConfigurer);
    },

    handleJsonPathConfigurerClick() {
      // should not let users assign complex value into single valued variables
      if (this.isJsonPathResultObject) {
        return;
      }

      let rule = {
        ...this.jsonPathConfigurer,
        // array_variable: this.isJsonPathResultObject // should be always false :)
        array_variable: false
      };

      this.resetJSONPathConfigurer();
      this.$emit("finish", rule);
    }
  },
  watch: {
    ruleValue: {
      immediate: true,
      handler(val) {
        this.jsonPathConfigurer.rule_value = val;
      }
    },
    treatAsAudioVariablesOption: {
      handler(val) {
        this.jsonPathConfigurer.default_value = "";
        this.jsonPathConfigurer.variable_id = -1;
        this.jsonPathConfigurer.variable_name = "";
        this.jsonPathConfigurer.variable_type = val ? "audio" : "single_value";
      }
    },
    treatAsSecureVariablesOption: {
      handler(val) {
        this.jsonPathConfigurer.default_value = "";
        this.jsonPathConfigurer.variable_id = -1;
        this.jsonPathConfigurer.variable_name = "";
        this.jsonPathConfigurer.variable_type = val ? "secure" : "single_value";
      }
    },
    resetRuleWhen: {
      immediate: true,
      handler(val) {
        if (val) {
          this.resetJSONPathConfigurer();
        }
      }
    },
    assignNewVariable: {
      immediate: true,
      handler(val) {
        if (val) {
          this.addNewVariableRule("messageContent");
        }
      }
    },
    isRawResponse(newVal, oldVal) {
      if (newVal === true && oldVal === false && this.currentSelection !== "") {
        this.$set(this.jsonPathConfigurer, "rule_value", "raw_response");
        let rule = {
          ...this.jsonPathConfigurer,
          array_variable: false
        };
        this.$emit("finish", rule);
      }
    }
  },
  created() {
    if (this.currentRawResponseVariableId) {
      this.jsonPathConfigurer.variable_id = this.currentRawResponseVariableId;
    }
  }
};
</script>

<style scoped></style>
