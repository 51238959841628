<template>
  <expression-node-renderless
    :node="node"
    v-slot="{
      node, // the current node
      // index, // the index of this node amongst its parent's children
      // // function, updates the condition of the current node,
      // // takes params: field, operator, value ('firstName', 'startsWith', 'Jo')
      // // this can also be done by the injected condition factory's createAndUpdate method, as in the example below
      // updateCondition,
      deleteSelf // deletes the current node
      // conditionFactory // it stores the defined fields, fieldTypes and operators,
      // // and creates new conditions with its create method - injected by the parent ExpressionBuilderRenderless
    }"
  >
    <div class="expression-node">
      <!-- For selecting which field to use in this node's condition -->
      <!--      <el-table fit :data="[]" style="width: 100%">-->
      <!--        <el-table-column prop="key" :label="keyLabel" v-if="displayKey">-->
      <!--          <template slot-scope="scope">-->
      <!--            &lt;!&ndash;          <at-in :members="variableNames" at="{{" suffix="}}">&ndash;&gt;-->
      <!--            &lt;!&ndash;            <el-input v-model="scope.row.key"></el-input>&ndash;&gt;-->
      <!--            &lt;!&ndash;          </at-in>&ndash;&gt;-->

      <!--          </template>-->
      <!--        </el-table-column>-->
      <!--      </el-table>-->
      <div class="field-and-operator">
        <el-select
          size="small"
          v-model="fieldName"
          filterable
          default-first-option
        >
          <el-option
            v-for="item in fieldOptions"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <!-- For selecting which operator to use in this node's condition -->
        <el-select
          size="small"
          v-model="operatorName"
          filterable
          default-first-option
        >
          <el-option
            v-for="item in operatorOptions"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <template
        v-if="
          ![
            'isNull',
            'notIsNull',
            'isEmpty',
            'notIsEmpty',
            'isNullOrEmpty'
          ].includes(operatorName)
        "
      >
        <!-- Input component, based on the type of the field selected -->
        <div class="values" v-if="operatorName !== 'between'">
          <!-- simple input for text -->
          <input-variable-popper
            v-if="['text', 'objectId'].includes(selectedFieldObject.type)"
            v-model="conditionValue"
            :is-text-area="false"
            force-reinitialize
          ></input-variable-popper>

          <!-- number input for number -->
          <input-variable-popper
            v-else-if="
              selectedFieldObject.type === 'number' ||
                selectedFieldObject.type === 'double'
            "
            type="number"
            v-model="conditionValue"
            :is-text-area="false"
            force-reinitialize
          ></input-variable-popper>

          <!-- select component for select type field -->
          <el-select
            v-else-if="selectedFieldObject.type === 'select'"
            default-first-option
            v-model="conditionValue"
          >
            <el-option
              v-for="item in currentSelectTypeFieldChoices"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            ></el-option>
          </el-select>

          <!-- radio button component for radio (CHOICE) type field -->
          <el-radio-group
            v-else-if="selectedFieldObject.type === 'radio'"
            v-model="conditionValue"
          >
            <el-radio
              v-for="item in currentSelectTypeFieldChoices"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            ></el-radio>
          </el-radio-group>

          <!-- an implementation of the boolean field type -->
          <input-variable-popper
            v-if="selectedFieldObject.type === 'boolean'"
            v-model="conditionValue"
            :is-text-area="false"
            force-reinitialize
          ></input-variable-popper>

          <!-- date picker for date field type -->
          <el-date-picker
            v-else-if="selectedFieldObject.type === 'date'"
            v-model="conditionValue"
            value-format="yyyy-MM-dd"
            :format="displayDateFormat"
          ></el-date-picker>

          <!-- date time picker for date field type -->
          <el-date-picker
            v-else-if="['datetime'].includes(selectedFieldObject.type)"
            v-model="conditionValue"
            value-format="yyyy-MM-dd HH:mm:ss"
            :format="displayDateTimeSecondsFormat"
            type="datetime"
            placeholder="Pick date time"
          ></el-date-picker>

          <el-date-picker
            v-else-if="selectedFieldObject.type === 'timestamp'"
            v-model="conditionValueTimestamp"
            value-format="timestamp"
            :format="displayDateTimeSecondsFormat"
            type="datetime"
            placeholder="Pick date time"
          ></el-date-picker>
        </div>
        <div class="values" v-else>
          <!-- range number input for number -->
          <div
            v-if="
              selectedFieldObject.type === 'number' ||
                selectedFieldObject.type === 'double'
            "
            class="between-condition"
          >
            <input-variable-popper
              type="number"
              v-model="minConditionValue"
              :is-text-area="false"
              force-reinitialize
            ></input-variable-popper>
            <input-variable-popper
              type="number"
              v-model="maxConditionValue"
              :is-text-area="false"
              force-reinitialize
            ></input-variable-popper>
          </div>

          <div
            v-else-if="selectedFieldObject.type === 'date'"
            class="between-condition"
          >
            <!-- range date picker for date field type -->
            <el-date-picker
              v-model="minConditionValue"
              value-format="yyyy-MM-dd"
              :format="displayDateFormat"
            ></el-date-picker>
            <el-date-picker
              v-model="maxConditionValue"
              value-format="yyyy-MM-dd"
              :format="displayDateFormat"
            ></el-date-picker>
          </div>

          <div
            v-else-if="selectedFieldObject.type === 'datetime'"
            class="between-condition"
          >
            <!-- range date time picker for date field type -->
            <el-date-picker
              v-model="minConditionValue"
              value-format="yyyy-MM-dd HH:mm:ss"
              :format="displayDateTimeSecondsFormat"
              type="datetime"
              :placeholder="__('Pick date time')"
            ></el-date-picker>
            <el-date-picker
              v-model="maxConditionValue"
              value-format="yyyy-MM-dd HH:mm:ss"
              :format="displayDateTimeSecondsFormat"
              type="datetime"
              :placeholder="__('Pick date time')"
            ></el-date-picker>
          </div>

          <div
            v-else-if="selectedFieldObject.type === 'timestamp'"
            class="between-condition"
          >
            <!-- range date time picker for date field type -->
            <el-date-picker
              v-model="minConditionValueTimestamp"
              value-format="timestamp"
              :format="displayDateTimeSecondsFormat"
              type="datetime"
              :placeholder="__('Pick date time')"
            ></el-date-picker>

            <el-date-picker
              v-model="maxConditionValueTimestamp"
              value-format="timestamp"
              :format="displayDateTimeSecondsFormat"
              type="datetime"
              :placeholder="__('Pick date time')"
            ></el-date-picker>
          </div>
        </div>
      </template>
      <div class="close-container">
        <i class="el-icon-close deleteSelf" @click="deleteSelf"></i>
      </div>
    </div>
  </expression-node-renderless>
</template>

<script>
import { Components, Core } from "vue-renderless-expression-builder";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import _ from "lodash";
import { mapGetters } from "vuex";
import { fieldTypes } from "@/utils/queryConditionBuilder";
import {
  convertLocalTimestampMsToTzTimestampMs,
  convertTzTimestampMsToLocalTimestampMs
} from "@/utils/time";

const { ExpressionNodeRenderless } = Components;

export default {
  name: "ExpressionNode",
  props: {
    node: {
      type: Core.ExpressionNode,
      required: true
    }
  },
  /**
   * condition factory is available under this name,
   * injected into all child components of the ExpressionBuilderRenderless component
   *
   * it stores field defined on the builder (fields prop)
   * field types defined on the builder (fieldTypes prop)
   * operators defined on the builder (operators)
   *
   * exposes create and createAndUpdate methods
   *
   * create(fieldName, operatorName, value) - creates a condition
   * createAndUpdate(node, fieldName, operatorName, value) - creates a condition and updates the condition the node provided
   *
   * */
  inject: {
    conditions: "$__qb_condition_factory__"
  },
  components: {
    InputVariablePopper,
    ExpressionNodeRenderless
  },
  data() {
    return {
      // mapping fields to the format supported by the element UI select component
      fieldOptions: this.conditions.fields.map(f => ({
        value: f.name,
        text: f.label
      })),

      // creating select options for the bool field type (implemented with bootstrap radio group)
      boolRadioGroup: [
        {
          text: "True",
          value: true
        },
        {
          text: "False",
          value: false
        }
      ]
    };
  },
  methods: {
    /**
     * Returns an operator object {name, label} from an operator name
     */
    getOperator(operatorName) {
      return this.conditions.operators.find(oObj => oObj.name === operatorName);
    },
    valueFormatByType(columnType) {
      return columnType === "timestamp" ? columnType : "yyyy-MM-dd HH:mm:ss";
    }
  },
  computed: {
    ...mapGetters("app", {
      displayDateFormat: "displayDateFormat",
      displayDateTimeSecondsFormat: "displayDateTimeSecondsFormat",
      timezone: "timezone"
    }),

    // fieldName computed, to use as v-model
    fieldName: {
      get() {
        return this.node.condition.fieldName;
      },
      set(val) {
        // use this fn to validate the choice
        // when changing fields, null-out the value and operator as well, to avoid data inconsistencies
        // this can be better handled, I do this for the sake of simplicity here
        let selectedFieldObject = this.conditions.fields.find(
          f => f.name === val
        );

        let fieldType = _.find(
          fieldTypes,
          fieldType => fieldType.name === selectedFieldObject.type
        );

        if (!fieldType) {
          fieldType = _.find(
            fieldTypes,
            fieldType => fieldType.name === "text"
          );
        }

        this.conditions.createAndUpdate(
          this.node,
          val,
          fieldType.availableOperators[0],
          ""
        );
      }
    },

    // operatorName computed, to use as v-model
    operatorName: {
      get() {
        return this.node.condition.operatorName;
      },
      set(val) {
        // use this fn to validate the choice
        this.conditions.createAndUpdate(
          this.node,
          this.node.condition.fieldName,
          val,
          this.node.condition.value || ""
        );
      }
    },

    // value computed, to use as v-model
    conditionValueTimestamp: {
      get() {
        let value = this.node.condition.value || "";
        if (!value) {
          return value;
        }
        return convertTzTimestampMsToLocalTimestampMs(value, this.timezone);
      },
      set(val) {
        let timestampMs = !val
          ? ""
          : convertLocalTimestampMsToTzTimestampMs(val, this.timezone);
        this.$set(this.node.condition, "value", timestampMs || "");
      }
    },

    conditionValue: {
      get() {
        return this.node.condition.value || "";
      },
      set(val) {
        this.$set(this.node.condition, "value", val || "");
      }
    },

    minConditionValue: {
      get() {
        return _.split(this.conditionValue, ";;")[0] || "";
      },
      set(val) {
        let conditionValues = _.split(this.conditionValue, ";;");
        conditionValues[0] = val;
        this.conditionValue = conditionValues.join(";;");
      }
    },
    maxConditionValue: {
      get() {
        return _.split(this.conditionValue, ";;")[1] || "";
      },
      set(val) {
        let conditionValues = _.split(this.conditionValue, ";;");
        conditionValues[1] = val;
        this.conditionValue = conditionValues.join(";;");
      }
    },

    minConditionValueTimestamp: {
      get() {
        let value = _.split(this.conditionValue, ";;")[0] || "";
        if (!value) {
          return value;
        }
        return convertTzTimestampMsToLocalTimestampMs(value, this.timezone);
      },
      set(val) {
        let conditionValues = _.split(this.conditionValue, ";;");
        conditionValues[0] = !val
          ? ""
          : convertLocalTimestampMsToTzTimestampMs(val, this.timezone);
        conditionValues[1] = _.get(conditionValues, 1, "");
        this.conditionValue = conditionValues.join(";;");
      }
    },
    maxConditionValueTimestamp: {
      get() {
        let value = _.split(this.conditionValue, ";;")[1] || "";
        if (!value) {
          return value;
        }
        return convertTzTimestampMsToLocalTimestampMs(value, this.timezone);
      },
      set(val) {
        let conditionValues = _.split(this.conditionValue, ";;");
        conditionValues[0] = _.get(conditionValues, 0, "");
        conditionValues[1] = !val
          ? ""
          : convertLocalTimestampMsToTzTimestampMs(val, this.timezone);
        this.conditionValue = conditionValues.join(";;");
      }
    },

    /**
     * Returns the field object, from the field's name
     * Field object interface:
     *
     * interface ConditionFactoryField {
     *    type: string;
     *    name: string;
     *    label: string;
     *    operators?: string[];
     *    choices?: any[];  // used, if select-type type is used
     * }
     *
     * @return {*}
     */
    selectedFieldObject() {
      if (this.node.condition.fieldName == null) return;
      return this.conditions.fields.find(
        f => f.name === this.node.condition.fieldName
      );
    },

    /**
     * Operators for the current field, mapped to the format supported by bootstrap select and radio components
     * @return {{text: *, value: *}[]|*[]}
     */
    operatorOptions() {
      if (!this.selectedFieldObject) return [];

      return this.selectedFieldObject.operators.map(o => {
        const operatorObj = this.getOperator(o);
        return {
          value: operatorObj.name,
          text: operatorObj.label
        };
      });
    },

    /**
     * Choices for the currently selected field, mapped to the format supported by bootstrap select and radio components
     * @return {{text: *, value: *}[]|*[]}
     */
    currentSelectTypeFieldChoices() {
      if (
        this.selectedFieldObject.choices &&
        this.selectedFieldObject.choices.length > 0
      )
        return this.selectedFieldObject.choices.map(c => ({
          value: c.value,
          text: c.name
        }));
      else return [];
    }
  }
};
</script>

<style scoped lang="scss">
.expression-node {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0.5rem;
  position: relative;
  justify-content: space-between;

  .values {
    flex: 1;

    ::v-deep .el-input {
      width: 100%;
      .el-input__inner {
        height: 32px;
      }
    }
  }
  & > div:not(:first-child) {
    margin-left: 5px;
  }

  .deleteSelf {
    margin-left: 10px;
    font-size: large;
    font-weight: lighter;

    &:hover {
      font-weight: bold;
    }
  }

  .field-and-operator {
    display: flex;

    & > div:not(:first-child) {
      margin-left: 5px;
    }
  }

  .between-condition {
    display: flex;
    justify-content: center;
    align-items: center;
    & > div:not(:first-child) {
      margin-left: 5px;
    }
  }
  /*svg {*/
  /*  cursor: pointer;*/
  /*}*/

  /*input,*/
  /*select {*/
  /*  width: 10rem;*/
  /*  margin-right: 2rem;*/
  /*  height: 2rem;*/
  /*}*/
}
</style>
