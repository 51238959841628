<template>
  <div class="updated-content">
    <template v-if="showHistoryContentAlert">
      <el-alert :closable="false" :type="eventType">
        <div class="content-details" @click="directToNode(history.content_id)">
          <div class="content-icon">
            <img
              v-if="history.content_type == 'Node'"
              class="content-cell-icon"
              :src="getIconForNode(displayContent.node_type)"
              draggable="false"
            />
            <img
              v-else
              class="content-cell-icon"
              :src="
                require('@/assets/icons/icon-' +
                  displayContent.task_type +
                  '.svg')
              "
              draggable="false"
            />
          </div>
          <div class="content-body">
            <div class="content-type">
              {{
                history.content_type == "Node"
                  ? displayContent.node_type
                  : displayContent.task_type
              }}
            </div>
            <div class="content-name">
              {{
                history.content_type == "Node"
                  ? displayContent.node_name
                  : displayContent.task_name
              }}
            </div>
          </div>

          <div class="show-details-btn">
            <el-button
              icon="el-icon-d-arrow-right"
              circle
              :type="eventType"
              size="mini"
              @click.stop.prevent="showDetails = true"
            ></el-button>
          </div>
        </div>
      </el-alert>

      <el-dialog
        :class="{ 'show-differ-json': !showFullJsonData }"
        :visible.sync="showDetails"
        :append-to-body="true"
      >
        <vue-json-compare
          class="edit-history-compare"
          :oldData="history.original_content"
          :newData="history.updated_content"
        ></vue-json-compare>
        <el-switch
          v-model="showFullJsonData"
          :active-text="__('Show all data')"
        ></el-switch>
      </el-dialog>
    </template>
    <template v-else>
      <div
        v-if="
          Object.keys(history.original_content).length &&
            Object.keys(history.updated_content).length
        "
        style="display: flex;flex-wrap: wrap;"
      >
        <el-tag
          type="danger"
          size="small"
          v-for="(field, index) in history.original_content"
          :key="'original_content_' + index"
          >{{ field }}</el-tag
        >
        <span style="margin-left: 0; margin-right: 10px;"
          ><i class="el-icon-right"></i
        ></span>
        <el-tag
          type="success"
          v-for="(field, index) in history.updated_content"
          :key="'updated_content_' + index"
          size="small"
          style="margin-left: 0;"
          >{{ field }}</el-tag
        >
      </div>
      <el-tag
        v-else
        :type="eventType"
        size="small"
        v-for="(field, index) in displayContent"
        :key="'display_content_' + index"
      >
        {{ field }}
      </el-tag>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import vueJsonCompare from "vue-json-compare";
import { EventBus } from "@/EventBus";

export default {
  components: {
    vueJsonCompare
  },
  props: {
    history: {
      required: true,
      type: Object
    }
  },

  computed: {
    showHistoryContentAlert() {
      return (
        this.history.content_type == "Node" ||
        (this.history.content_type == "Task" &&
          this.history.description == "Updated task")
      );
    },
    eventType() {
      if (
        !_.isEmpty(this.history.original_content) &&
        _.isEmpty(this.history.updated_content)
      ) {
        if (this.showHistoryContentAlert) {
          return "error";
        } else {
          return "danger";
        }
      } else if (
        _.isEmpty(this.history.original_content) &&
        !_.isEmpty(this.history.updated_content)
      ) {
        return "success";
      }
      return "info";
    },

    displayContent() {
      if (
        !_.isEmpty(this.history.original_content) &&
        _.isEmpty(this.history.updated_content)
      ) {
        return this.history.original_content;
      } else if (
        _.isEmpty(this.history.original_content) &&
        !_.isEmpty(this.history.updated_content)
      ) {
        return this.history.updated_content;
      }
      return this.history.updated_content;
    }
  },

  data() {
    return {
      showDetails: false,
      showFullJsonData: false
    };
  },

  methods: {
    getIconForNode(type) {
      return "/icons/" + type + ".svg";
    },

    directToNode(nodeId) {
      if (this.history.content_type == "Node") {
        EventBus.$emit("direct-to-node", nodeId);
      }
    }
  }
};
</script>

<style lang="scss">
.updated-content {
  position: relative;
  padding-bottom: 5px;
  padding-top: 5px;

  .el-tag {
    margin-right: 10px;

    &.el-tag--small {
      height: 24px;
      padding: 0 8px;
      line-height: 22px;
      font-size: 12px;
    }

    &.el-tag--success {
      background-color: #eef8ed !important;
      border-color: #dcf1da !important;
      color: #51b848 !important;
    }

    &.el-tag--danger {
      background-color: #feedee !important;
      border-color: #fedadd !important;
      color: #f84856 !important;
    }

    &:not(:first-of-type) {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .el-alert__content {
    padding-left: 0;
    max-width: 100%;
    .content-details {
      display: flex;
      align-items: center;
      .content-icon {
        height: 30px;
        outline-style: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding-right: 8px;
      }

      .content-body {
        max-width: 80%;
        .content-type {
          font-weight: bold;
        }
        .content-name {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  .show-details-btn {
    visibility: hidden;
    position: absolute;
    right: 8px;
  }

  &:hover {
    .show-details-btn {
      visibility: visible;
    }
  }
}

.show-differ-json {
  .c-line-none {
    display: none;
  }
}

.edit-history-compare {
  .c-json-outter {
    display: none;
  }
}
</style>
